export default {
  "language": {
    "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])}
  },
  "menu": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "timerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer List"])},
    "structureCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Structure"])},
    "gameCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Game"])},
    "gameList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game List"])},
    "storeManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Store Info"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to HOLDIX"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLDIX is the Realtime Timer List Platform."])},
    "rememberLoginInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Login Info"])},
    "button": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
      "findId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID"])},
      "findPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Password"])}
    },
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us : 010-7519-7037"])},
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Hours : 00:00 ~ 24:00"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "signUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-Up"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "button": {
      "availableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Duplicate ID"])},
      "availableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Duplicate Nickname"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "moveToLoginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to the Login page"])}
    }
  },
  "main": {
    "sir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to HOLDIX!"])}
  },
  "structure": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Structure"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Structure"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Structure"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Structure"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure Title"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the structure title."])},
    "modifyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Structure Title"])},
    "value": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Settings"])},
      "earlyBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting + Early Chips"])},
      "buyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting Chips"])},
      "firstReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st Re-buy Chips"])},
      "secondReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd Re-buy Chips"])},
      "thirdReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd Re-buy Chips"])},
      "addOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-on Chips"])},
      "buyInAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy-in"])},
      "registrationDeadline": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late Reg."])},
        "selectLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Lv."])}
      },
      "prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prize"])},
      "entryLimit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-entry"])},
        "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])},
        "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])}
      },
      "reEntryCount": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-entry Count"])},
        "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time(s)"])}
      }
    },
    "level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Settings"])},
      "smallBlind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Blind"])},
      "bigBlind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big Blind"])},
      "ante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ante"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration(Min.)"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Level"])},
      "addBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Break"])}
    },
    "prize": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prize Settings"])}
    },
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structures"])}
  },
  "timerDesign": {
    "backgroundImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer Background Image"])},
    "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach"])},
    "attachPopup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Timer Background Image"])},
      "notice": {
        "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- File size: 5MB limit"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- File name: Only allow Korean, English, and numbers"])},
        "extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Extensions: png, jpeg, jpg"])},
        "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- The current Timer Background Image file will be replaced by the attachment."])}
      },
      "dragndrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag the Timer Background Image file and drop it here."])},
      "buttonAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open file explorer"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer Design Settings"])},
    "selectSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Section"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section 1"])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section 2"])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section 3"])},
    "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section 4"])},
    "section5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section 5"])},
    "section6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section 6"])},
    "selectColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Color"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Timer Design"])},
    "displayGameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Title"])},
    "displayStructureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure Title"])}
  },
  "game": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Title"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Game"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Game"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the game name."])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game List"])},
    "listNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only games created within the last 8 hours or games that are not in the finished state will be displayed."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Game"])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "regStatus": {
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reg. In Progress"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late Reg."])}
    },
    "readyToStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready To Start"])}
  },
  "controller": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Controller"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "level": {
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move Level"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Level"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Info"])}
    },
    "player": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Settings"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Player(s)"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
      "earlyBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting + Early"])},
      "buyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting"])},
      "firstReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st Re-buy"])},
      "secondReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd Re-buy"])},
      "thirdReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd Re-buy"])},
      "addOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add-on"])}
    }
  },
  "common": {
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the list"])}
  },
  "message": {
    "login": {
      "enterIdPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID and Password."])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login session has expired. Please log in again."])},
      "error": {
        "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login session has expired. Please log in again."])},
        "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary permissions. Redirecting to the Login page."])}
      }
    },
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to logout?"])}
    },
    "signUp": {
      "enterId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID."])},
      "availableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available ID."])},
      "unavailableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate ID already exist."])},
      "enterNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter nickname."])},
      "availableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available nickname."])},
      "unavailableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate nickname already exist."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully registered! You will be automatically redirected to the Login page."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to sign up."])},
      "checkDupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check duplicate ID first."])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password."])},
      "enterConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password confirmation."])},
      "checkPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and password confirmation do not match."])},
      "checkDupNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check duplicate nickname first."])}
    },
    "structure": {
      "reset": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reset the structure you're currently editing?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure has been successfully reset."])}
      },
      "save": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save the structure?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure has been successfully saved."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to save structure."])}
      },
      "create": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to move to the Structure Create page?"])}
      },
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the structure?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure has been successfully deleted."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to delete structure."])}
      }
    },
    "timerDesign": {
      "save": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save the timer design settings?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer design settings have been successfully saved."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to delete timer design settings."])}
      },
      "attachPopup": {
        "error": {
          "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자, 한글, 영문을 포함한 공백없는 파일명만 가능합니다."])},
          "fileCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일은 하나만 첨부해주세요."])},
          "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일크기는 5MB 이하로 제한됩니다."])}
        }
      }
    },
    "game": {
      "create": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create the game?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created. You will be automatically redirected to the Game List page."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to create game."])}
      },
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the game?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game has been successfully deleted."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to save game."])}
      }
    },
    "controller": {
      "timer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to the Timer page"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to move to the Timer page?"])}
      },
      "apply": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Changes"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to apply changes to the Timer?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully applied to the Timer."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to apply changes."])}
      },
      "action": {
        "type": {
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START"])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAUSE"])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["END"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " Timer"])},
        "confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change Timer status to ", _interpolate(_named("action")), "?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully changed to ", _interpolate(_named("action")), " status."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to change Timer status."])},
        "handle": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is in the CREATE status."])},
          "init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is in the INIT status."])},
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is already in PLAY status."])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is already in PAUSE status."])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game has already ended."])}
        },
        "level": {
          "confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to move Timer level to Level ", _interpolate(_named("level")), "?"])},
          "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully moved to Level ", _interpolate(_named("level")), "."])},
          "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to move level."])}
        }
      }
    },
    "common": {
      "refresh": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game List has been successfully refreshed."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to refresh game list."])}
      },
      "shortenUrl": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer Link successfully created"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to create Timer link."])}
      }
    }
  }
}