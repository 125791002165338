<template>
  <VRow>
    <VCol cols="12">
      <VCard>
        <VCardTitle>
          <h5 class="text-h5">회원 정보 검색</h5>
        </VCardTitle>
        <VCardText class="d-flex align-center">
          <v-text-field
            hide-details="true"
            variant="outlined"
            class="mr-2 text-sm"
            density="compact"
            placeholder="유저 ID 입력"
          />
          <VBtn
            color="primary"
            class="w-25 btn rounded my-2 pa-2 my-md-0 text-center"
          >
            검색
          </VBtn>
        </VCardText>
      </VCard>
    </VCol>
    <VCol cols="12">
      <VCard class="pa-2">
        <VRow>
          <VCol cols="5" class="d-flex justify-center align-center">
            <div class="text-center profile-img">
              <div>
                <VImg
                  string="20"
                  class="mb-2 profile-img"
                  src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                />
              </div>
              <span class="text-sm">sky123</span>
            </div>
          </VCol>
          <VCol cols="7" class="text-center">
            <div class="w-100 d-flex justify-end">
              <VBtn
                color="primary"
                class="btn text-sm rounded ma-2 px-2 my-md-0 text-center"
              >
                회원정보 수정
              </VBtn>
            </div>
            <span class="mt-5 d-block text-sm">보유 포인트 : 121,283,712</span>
            <span class="mt-5 d-block text-sm">획득 승점 : 2,123</span>
            <span class="my-5 d-block text-sm">종합순위 : 5위</span>
          </VCol>
        </VRow>
      </VCard>
    </VCol>
    <VCol cols="12">
      <VCard class="pa-2">
        <VRow>
          <VCol cols="12" class="d-flex justify-end pb-0">
            <span class="text-sm">보유 포인트 : </span>
            <span class="pl-2 text-sm">213,550,000</span>
          </VCol>
          <VCol cols="12">
            <span class="text-title">선물하기</span>
          </VCol>
          <VCol cols="8" class="pr-0">
            <VRow class="align-center">
              <VCol cols="4" class="pr-0 pb-2">
                <span class="text-md">받는 분:</span></VCol
              >
              <VCol cols="8" class="px-0 pb-2"
                ><v-text-field
                  hide-details="false"
                  class="pl-2 pr-5"
                  placeholder="아이디 입력"
                  density="compact"
              /></VCol>
              <VCol cols="4" class="pr-0 pt-2">
                <span class="text-md">포인트 :</span></VCol
              >
              <VCol cols="8" class="px-0 pt-2"
                ><v-text-field
                  hide-details="false"
                  class="pl-2 pr-5"
                  placeholder="포인트 입력"
                  density="compact"
              /></VCol>
            </VRow>
          </VCol>
          <VCol cols="4" class="d-flex justify-center align-center pl-0">
            <VBtn
              color="primary"
              class="btn text-sm rounded ma-2 px-2 my-md-0 text-center"
            >
              전송
            </VBtn>
          </VCol>
        </VRow>
      </VCard>
    </VCol>
    <VCol cols="12">
      <VExpansionPanels>
        <VExpansionPanel>
          <VExpansionPanelTitle>
            <span>상금 획득 내역</span>
          </VExpansionPanelTitle>
          <VExpansionPanelText>
            <VTable class="text-no-wrap invoice-list-table">
              <!-- 👉 Table head -->
              <thead>
                <tr class="tr">
                  <th scope="col" class="pl-2 pr-1 text-left text-sm">#ID</th>
                  <th scope="col" class="px-1 text-left text-sm">매장</th>
                  <th scope="col" class="px-1 text-right text-sm">바이인</th>
                  <th scope="col" class="px-1 text-center text-sm">날짜</th>
                  <th scope="col" class="pr-2 pl-1 text-right text-sm">
                    획득 포인트
                  </th>
                </tr>
              </thead>

              <!-- 👉 Table Body -->
              <tbody>
                <tr v-for="i in 5" :key="i">
                  <!-- 👉 Id -->
                  <td class="pl-2 pr-1 text-sm text-primary">#5036</td>

                  <!-- 👉 매장 -->
                  <td class="px-1 text-sm text-left text-medium-emphasis">
                    박성균
                  </td>

                  <!-- 👉 바이인 -->
                  <td class="px-1 text-sm text-medium-emphasis text-right">
                    $4,112
                  </td>

                  <!-- 👉 날짜 -->
                  <td class="px-1 text-sm text-center">2023.06.01</td>

                  <!-- 👉 획득 포이트 -->
                  <td class="pr-2 pl-1 text-sm text-right" style="width: 8rem">
                    1,330,000
                  </td>
                </tr>
              </tbody>

              <!-- 👉 table footer  -->
            </VTable>
          </VExpansionPanelText>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCol>
    <VCol>
      <VCard>
        <Line :data="data" :options="options" />
      </VCard>
    </VCol>
  </VRow>
</template>
<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";
import * as chartConfig from "./chartConfig.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement
);
export default {
  name: "App",
  components: {
    Line,
  },
  data() {
    return chartConfig;
  },
};
</script>
<style>
.text-sm {
  font-size: 12px;
}
.text-md {
  font-size: 15px;
}
.text-title {
  font-size: 25px;
}
.profile-img {
  width: 80px;
  height: auto;
}
</style>
