// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// import labs snippets (include v-data-table etc.)
import * as labs from 'vuetify/labs/components'
// import colors
// import colors from 'vuetify/lib/util/colors.mjs'

// Vuetify
import { createVuetify } from 'vuetify'

// Translations provided by Vuetify
import { ko, en, ja } from 'vuetify/locale'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components: {
    ...labs,
  },
  breakpoint: {
    mobileBreakpoint: 'xs'
  },
  theme: {
    themes:{
      light:{
        colors: {
        primary: '#9155FD',
        'secondary': '#8A8D93',
        'on-secondary': '#fff',
        'success': '#56CA00',
        'info': '#16B1FF',
        'warning': '#FFB400',
        'error': '#FF4C51',
        'on-primary': '#FFFFFF',
        'on-success': '#FFFFFF',
        'on-warning': '#FFFFFF',
        'background': '#28243D',
        'on-background': '#E7E3FC',
        'surface': '#312D4B',
        'on-surface': '#E7E3FC',
        'grey-50': '#2A2E42',
        'grey-100': '#2F3349',
        'grey-200': '#4A5072',
        'grey-300': '#5E6692',
        'grey-400': '#7983BB',
        'grey-500': '#8692D0',
        'grey-600': '#AAB3DE',
        'grey-700': '#B6BEE3',
        'grey-800': '#CFD3EC',
        'grey-900': '#E7E9F6',
        }
      },
    },
    variables: {
      'border-color': '#E7E3FC',

      // Shadows
      'shadow-key-umbra-opacity': 'rgba(20, 18, 33, 0.08)',
      'shadow-key-penumbra-opacity': 'rgba(20, 18, 33, 0.12)',
      'shadow-key-ambient-opacity': 'rgba(20, 18, 33, 0.04)',
    },
  },
  locale: {
    locale: 'ko',
    fallback: 'en',
    messages: { ko, en, ja }
  }
})
