<template>
  <div class="d-flex flex-column">
    <VCard class="pa-3">
      <VRow>
        <VCol cols="12" md="4" class="pa-3">
          <VCardTitle class="">
            <h5 class="text-h5">{{ $t('structure.create') }}</h5>
          </VCardTitle>
        </VCol>
        <VCol cols="12" md="8" class="w-100 pa-3">
          <VRow class="pa-0">
            <VCol cols="6" md="3">
              <div
                class="rounded my-0 py-2 my-md-0 bg-secondary pa-0 text-center"
              >
                <VIcon size="30" icon="mdi-controller-classic" />

                <span class="text-md ml-2">{{ $t('game.create') }}</span>
              </div>
            </VCol>
            <VCol cols="6" md="3" class="hover">
              <div
                class="rounded btn my-0 py-2 my-md-0 bg-info pa-0 text-center"
                @click="clickResetStructure"
              >
                <VIcon size="30" icon="mdi-new-box" />
                <span class="text-md ml-2">{{ $t('structure.reset') }}</span>
              </div>
              <!-- <v-btn class="rounded my-0 py-2 my-md-0 bg-info pa-0 text-center" icon="mdi-new-box" @click="clickResetStructure">새 스트럭처</v-btn> -->
            </VCol>
            <VCol cols="6" md="3">
              <div
                class="rounded my-0 py-2 my-md-0 bg-secondary pa-0 text-center"
              >
                <VIcon size="30" icon="mdi-delete-empty-outline" />
                <span class="text-md ml-2">{{ $t('structure.delete') }}</span>
              </div>
            </VCol>
            <VCol cols="6" md="3" class="hover">
              <div
                class="rounded btn my-0 py-2 my-md-0 bg-warning pa-0 text-center"
                @click="clickSaveStructure"
              >
                <VIcon size="30" icon="mdi-content-save-outline" />
                <span class="text-md ml-2">{{ $t('structure.save') }}</span>
              </div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCard>

    <VCard class="pa-3 mt-4">
      <VCol class="pa-3">
        <VRow class="w-100 border-b mb-5 pb-5 d-flex justify-space-between">
          <VCol cols="6">
            <v-text-field
              hide-details="false"
              class="text-h5 border-opacity-100 pl-2 pr-5 border-b border-primary"
              variant="plain"
              :placeholder="$i18n.t('structure.namePlaceholder')"
              v-model="structure.master.name"
              ref="structureNameField"
            />
          </VCol>
          <!-- <VCol cols="6">
            <VRow class="justify-end">
              <VCol cols="4">
                <div
                  class="px-5 py-2 d-flex justify-center align-center text-center border border-primary border-opacity-100 rounded-lg mr-2"
                >
                  <span class="text-body-2">타이머 배경화면</span>
                </div>
              </VCol>
              <VCol cols="3">
                <VBtn
                  class="btn w-100"
                  elevation="2"
                  ripple
                  rounded="lg"
                  color="primary"
                  width="7%"
                  density="default"
                  @click.stop="clickOpenAttachmentPopup"
                >
                  첨부
                </VBtn>
              </VCol>
              <VCol cols="3">
                <VBtn
                  class="btn w-100"
                  ripple
                  elevation="3"
                  rounded="lg"
                  color="primary"
                  width="7%"
                  density="default"
                >
                  삭제
                </VBtn>
              </VCol>
            </VRow>
          </VCol> -->
        </VRow>

        <VRow class="mt-0">
          <div class="w-100 text-center">
            <span class="text-h5">{{ $t('structure.value.title') }}</span>
          </div>
          <VCol cols="12" md="6">
            <VRow class="justify-center">
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.earlyBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.earlyBuyIn"
                    variant="plain"
                    placeholder="10,000"
                  />
                </div>
              </VCol>
              <VCol cols="2" />
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.buyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.buyIn"
                    variant="plain"
                    placeholder="10,000"
                  />
                </div>
              </VCol>
              <VCol cols="2" />

              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.firstReBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.firstReBuyIn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.firstReBuyIn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.firstReBuyIn"
                  inset
                  @click="toggleFirstReBuyIn"
                />
              </VCol>
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.secondReBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.secondReBuyIn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.secondReBuyIn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.secondReBuyIn"
                  inset
                  @click="toggleSecondReBuyIn"
                  :disabled="structure.value.insetSwitch.disabled.secondReBuyIn"
                />
              </VCol>
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.thirdReBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.thirdReBuyIn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.thirdReBuyIn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.thirdReBuyIn"
                  inset
                  @click="toggleThirdReBuyIn"
                  :disabled="structure.value.insetSwitch.disabled.thirdReBuyIn"
                />
              </VCol>
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.addOn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.addOn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.addOn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.addOn"
                  inset
                  @click="toggleAddOn"
                />
              </VCol>
            </VRow>
          </VCol>
          <!-- pc화면전용 스페이스 -->
          <!-- <VCol cols="12" md="4" class="d-none d-md-block" /> -->

          <VCol cols="12" md="6">
            <VRow class="justify-center">
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.buyInAmount') }}</span>
                <div class="w-50">
                  <v-text-field
                    hide-details="true"
                    density="compact"
                    class="text-right right-input border-b py-1 px-2 border-primary border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.master.buyInAmount"
                    variant="plain"
                    placeholder="10,000"
                  />
                </div>
              </VCol>
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.registrationDeadline.title') }}</span>
                <div
                  class="text-body-1 text-secondary w-50 d-flex align-center"
                >
                  <VSelect
                    :items="structure.master.registrationDeadline.items"
                    v-model="structure.master.registrationDeadline.selected"
                    :label="$i18n.t('structure.value.registrationDeadline.selectLevel')"
                    item-title="level"
                    item-value="seq"
                    density="compact"
                    variant="outlined"
                    hide-details="true"
                    class="px-2 text-center d-flex justify-end"
                  />
                </div>
              </VCol>
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.prize') }}</span>
                <div class="w-50">
                  <v-text-field
                    hide-details="true"
                    density="compact"
                    class="text-right right-input border-b py-1 px-2 border-primary border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.master.prize"
                    variant="plain"
                    placeholder="70% or 200GTD"
                  />
                </div>
              </VCol>
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.entryLimit.title') }}</span>
                <div
                  class="text-body-1 text-secondary d-flex align-center justify-space-between"
                >
                  <VRadioGroup inline v-model="structure.master.entryLimitFlag">
                    <VRadio :label="$i18n.t('structure.value.entryLimit.limited')" :value="1" />
                    <VRadio :label="$i18n.t('structure.value.entryLimit.unlimited')" :value="0" />
                  </VRadioGroup>
                </div>
              </VCol>
              <v-col
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.reEntryCount.title') }}</span>

                <div class="d-flex align-center">
                  <v-text-field
                    hide-details="true"
                    density="compact"
                    class="text-right right-input border-b mb-2 border-primary border-opacity-100 w-50 text-body-1 text-gray-100"
                    v-model="structure.master.entryLimit"
                    variant="plain"
                  />
                  <span class="text-body-1 text-gray-100">{{ $t('structure.value.reEntryCount.times') }}</span>
                </div>
              </v-col>
            </VRow>
          </VCol>
        </VRow>

        <!-- LEVEL 행 헤더 -->
        <VRow class="mt-5">
          <div class="w-100 text-center">
            <span class="text-h5">{{ $t('structure.level.title') }}</span>
          </div>
          <VCol cols="3" class="d-flex justify-start align-center">
            <div class="w-75 text-center">
              <span class="text-body-1 text-grey-800">Level</span>
            </div>
          </VCol>
          <VCol cols="8">
            <VRow>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.smallBlind') }}</VInput
                  >
                </div>
              </VCol>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.bigBlind') }}</VInput
                  >
                </div>
              </VCol>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.ante') }}</VInput
                  >
                </div>
              </VCol>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.duration') }}</VInput
                  >
                </div>
              </VCol>
            </VRow>
          </VCol>
          <VCol
            cols="1"
            class="text-center d-flex justify-space-around align-center"
          >
            <span class="text-body-1 text-grey-800">{{ $t('structure.level.delete') }}</span>
          </VCol>
        </VRow>
        <!-- 1행 끝 -->

        <!-- level start -->
        <div class="flex m-10">
          <draggable class="dragArea list-group w-full" :list="structure.level" @change="levelUpdate">
            <div v-for="(item, index) in structure.level" :key="index">
              <v-row v-if="item.level === 0" class="align-center">
                <v-col cols="3" class="text-center hover-move">
                  <div
                    class="bg-warning py-2 w-75 pr-3 d-flex justify-center align-center"
                  >
                    <v-icon class="mr-2" icon="mdi-pause" size="35" />
                    <span>Break</span>
                  </div>
                </v-col>
                <v-col cols="8" class="d-flex justify-space-between">
                  <div class="w-100 text-center bg-warning rounded-lg py-3">
                    <v-text-field
                      center-affix="true"
                      hide-details="true"
                      density="compact"
                      variant="plain"
                      class="text-center centered centered-input"
                      v-model="item.duration"
                      @blur="validateDuration(item, index)"
                    />
                  </div>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-icon
                    icon="mdi-trash-can-outline"
                    class="text-btn"
                    size="35"
                    @click="clickRemoveLevel(index)"
                  />
                </v-col>
              </v-row>
              <v-row v-else class="align-center">
                <v-col cols="3" class="text-center hover-move">
                  <div
                    class="bg-primary py-2 w-75 pr-3 d-flex justify-center align-center"
                  >
                    <v-icon class="mr-2" icon="mdi-play" size="35" />
                    <span>Level {{ item.level }}</span>
                  </div>
                </v-col>
                <v-col cols="8">
                  <VRow>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.smallBlind"
                        />
                      </div>

                      <!-- oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );"
                        type="number"
                        hide-details -->
                    </VCol>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.bigBlind"
                        />
                      </div>
                    </VCol>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.ante"
                        />
                      </div>
                    </VCol>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.duration"
                          @blur="validateDuration(item, index)"
                        />
                      </div>
                      <!-- <span>{{ displayDuration(item.duration) }}</span> -->
                    </VCol>
                  </VRow>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-icon
                    icon="mdi-trash-can-outline"
                    size="35"
                    class="text-btn"
                    @click="clickRemoveLevel(index)"
                  />
                </v-col>
              </v-row>
            </div>
          </draggable>
        </div>

        <v-row class="mt-5">
          <v-col
            cols="6"
            class="text-center d-flex justify-center align-center"
          >
            <v-btn
              class="v-col v-col-6 px-5 btn text-center bg-primary rounded-lg py-2"
              @click="clickAddLevel"
              >{{ $t('structure.level.addLevel') }}</v-btn
            >
          </v-col>
          <v-col
            cols="6"
            class="text-center d-flex justify-center align-center"
          >
            <v-btn
              class="v-col v-col-6 px-5 btn text-center bg-warning rounded-lg py-2"
              @click="clickAddBreak"
              >{{ $t('structure.level.addBreak') }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- level end -->
      </VCol>
    </VCard>

    <!-- ckeditor5 -->
    <VCard class="pa-3 mt-4">
      <VRow class="mt-0">
        <div class="w-100 text-center">
          <span class="text-h5">{{ $t('structure.prize.title') }}</span>
        </div>
        <VCol>
          <div>
            <ck-editor
              v-model="ckeditor.content"
              :editor="ckeditor.editor"
              :config="ckeditor.editorConfig"
            />
            <EditorStyle />
          </div>
        </VCol>
      </VRow>
    </VCard>
  </div>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />

  <!-- Popup -->
  <Popup
    v-show="popup.show"
    :popup-props="popup.props"
    @popup-event="handlePopupEvent"
  />
</template>

<script>
import Alert from "@/components/AlertDefault";
import Popup from "@/components/PopupDefault";

// CKEditor5
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// TODO CKEditor5 스타일 변경이 되지 않아서 임시로 아래에 설정함
import EditorStyle from "@/components/EditorStyle";

import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: "StructureCreate",
  components: {
    Alert,
    Popup,
    "ck-editor": CKEditor.component,
    EditorStyle,
    draggable: VueDraggableNext,
  },
  data() {
    return {
      structure: {
        master: {
          id: "",
          name: "",
          buyInAmount: 0,
          registrationDeadline: {
            selected: 0,
            items: [],
          },
          prize: "",
          entryLimitFlag: 1,
          entryLimit: 1,
        },
        value: {
          earlyBuyIn: 0,
          buyIn: 0,
          firstReBuyIn: 0,
          secondReBuyIn: 0,
          thirdReBuyIn: 0,
          addOn: 0,
          insetSwitch: {
            firstReBuyIn: true,
            secondReBuyIn: false,
            thirdReBuyIn: false,
            addOn: false,
            disabled: {
              secondReBuyIn: false,
              thirdReBuyIn: true,
            },
          },
        },
        level: [
          { level: 1,	 smallBlind: 100,    bigBlind: 200,    ante: 0,      duration: 12 },
          { level: 2,	 smallBlind: 200,    bigBlind: 300,    ante: 0,      duration: 12 },
          { level: 3,	 smallBlind: 200,    bigBlind: 400,    ante: 400,    duration: 12 },
          { level: 4,	 smallBlind: 300,    bigBlind: 600,    ante: 600,    duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 5,	 smallBlind: 400,    bigBlind: 800,    ante: 800,    duration: 12 },
          { level: 6,	 smallBlind: 500,    bigBlind: 1000,   ante: 1000,   duration: 12 },
          { level: 7,	 smallBlind: 600,    bigBlind: 1200,   ante: 1200,   duration: 12 },
          { level: 8,	 smallBlind: 800,    bigBlind: 1600,   ante: 1600,   duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 9,	 smallBlind: 1000,   bigBlind: 2000,   ante: 2000,   duration: 12 },
          { level: 10, smallBlind: 1200,   bigBlind: 2400,   ante: 2400,   duration: 12 },
          { level: 11, smallBlind: 1500,   bigBlind: 3000,   ante: 3000,   duration: 12 },
          { level: 12, smallBlind: 2000,   bigBlind: 4000,   ante: 4000,   duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 13, smallBlind: 2500,   bigBlind: 5000,   ante: 5000,   duration: 12 },
          { level: 14, smallBlind: 3000,   bigBlind: 6000,   ante: 6000,   duration: 12 },
          { level: 15, smallBlind: 4000,   bigBlind: 8000,   ante: 8000,   duration: 12 },
          { level: 16, smallBlind: 5000,   bigBlind: 10000,  ante: 10000,  duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 17, smallBlind: 6000,   bigBlind: 12000,  ante: 12000,  duration: 12 },
          { level: 18, smallBlind: 8000,   bigBlind: 16000,  ante: 16000,  duration: 12 },
          { level: 19, smallBlind: 10000,  bigBlind: 20000,  ante: 20000,  duration: 12 },
          { level: 20, smallBlind: 15000,  bigBlind: 30000,  ante: 30000,  duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 21, smallBlind: 20000,  bigBlind: 40000,  ante: 40000,  duration: 12 },
          { level: 22, smallBlind: 25000,  bigBlind: 50000,  ante: 50000,  duration: 12 },
          { level: 23, smallBlind: 30000,  bigBlind: 60000,  ante: 60000,  duration: 12 },
          { level: 24, smallBlind: 40000,  bigBlind: 80000,  ante: 80000,  duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 25, smallBlind: 50000,  bigBlind: 100000, ante: 100000, duration: 12 },
          { level: 26, smallBlind: 60000,  bigBlind: 120000, ante: 120000, duration: 12 },
          { level: 27, smallBlind: 80000,  bigBlind: 160000, ante: 160000, duration: 12 },
          { level: 28, smallBlind: 100000, bigBlind: 200000, ante: 200000, duration: 12 },
        ],
      },
      url: {
        structure: "/api/structure",
      },
      popup: {
        show: false,
        owner: "", // open popup call function name
        props: {
          title: "",
          contents: "",
        },
      },
      alert: {
        msg: "",
        type: "success",
      },

      /* CKEditor */
      ckeditor: {
        // ✅ v-model
        content:
          '<p>&nbsp;&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><h2>&nbsp; &nbsp; &nbsp;&nbsp;<span style="color:hsl(60,75%,60%);"><strong>HOLDIX</strong></span></h2><p>&nbsp;</p><p>&nbsp;</p><p><span style="color:hsl(30,75%,60%);"><strong>&nbsp; &nbsp;HOLDIX 새틀라이트</strong></span></p><p><span style="color:rgb(241,196,15);"><strong>&nbsp;3엔트리당 시드권 1장</strong></span></p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; LEVEL12 레지마감</strong></span></p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; &nbsp; &nbsp;(BREAK3까지)&nbsp;</strong></span></p><p><strong>&nbsp; &nbsp; &nbsp; &nbsp;</strong><span style="color:rgb(224,62,45);"><strong>리바인제한 2번</strong></span></p><p>&nbsp;</p><p>&nbsp;</p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; &nbsp;1등 : 시드권 장</strong></span></p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; &nbsp;2등 : 시드권 장</strong></span></p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p><span style="color:hsl(0,0%,90%);"><i><strong>*CHIPS*</strong></i></span></p><p><span style="color:hsl(0,0%,90%);"><strong>45,000/50,000/60,000</strong></span></p><p><span style="color:hsl(0,0%,90%);"><strong>*BREAK1 까지 얼리칩 +5,000</strong></span></p>',
        // ✅ :editor
        editor: ClassicEditor,
        // ✅ :config
        editorConfig: {
          // height: '1000px',
          fontSize: {
            options: [10,20,25,30,35,40,45,50,60,70],
          },
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "strikethrough",
              "underline",
              "|",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "highlight",
              "|",
              "link",
              "specialCharacters",
              "|",
              "undo",
              "redo",
              "|",
              // 'selectAll', 'fontFamily', 'horizontalLine',
              "|",
            ],
          },
        },
      },
    };
  },
  created() {},
  watch: {
    "structure.level": {
      deep: true,
      immediate: true,
      handler() {
        this.calcRegistrationDeadline();
      },
    },
  },
  methods: {
    /* Main Buttons */
    clickResetStructure() {
      this.openPopup(
        this.$i18n.t('structure.reset'),
        this.$i18n.t('message.structure.reset.confirm'),
        "resetStructure"
      );
    },
    resetStructure() {
      this.clearStructure();
      this.openAlert(this.$i18n.t('message.structure.reset.success'), "success");
    },
    clearStructure() {
      this.structure = {
        master: {
          id: "",
          name: "",
          buyInAmount: 0,
          registrationDeadline: {
            selected: 0,
            items: [],
          },
          prize: "",
          entryLimitFlag: 1, // 0:false, 1:true
          entryLimit: 1,
        },
        value: {
          earlyBuyIn: 0,
          buyIn: 0,
          firstReBuyIn: 0,
          secondReBuyIn: 0,
          thirdReBuyIn: 0,
          addOn: 0,
          insetSwitch: {
            firstReBuyIn: true,
            secondReBuyIn: false,
            thirdReBuyIn: false,
            addOn: false,
            disabled: {
              secondReBuyIn: false,
              thirdReBuyIn: true,
            },
          },
        },
        level: [
          { level: 1,	 smallBlind: 100,    bigBlind: 200,    ante: 0,      duration: 12 },
          { level: 2,	 smallBlind: 200,    bigBlind: 300,    ante: 0,      duration: 12 },
          { level: 3,	 smallBlind: 200,    bigBlind: 400,    ante: 400,    duration: 12 },
          { level: 4,	 smallBlind: 300,    bigBlind: 600,    ante: 600,    duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 5,	 smallBlind: 400,    bigBlind: 800,    ante: 800,    duration: 12 },
          { level: 6,	 smallBlind: 500,    bigBlind: 1000,   ante: 1000,   duration: 12 },
          { level: 7,	 smallBlind: 600,    bigBlind: 1200,   ante: 1200,   duration: 12 },
          { level: 8,	 smallBlind: 800,    bigBlind: 1600,   ante: 1600,   duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 9,	 smallBlind: 1000,   bigBlind: 2000,   ante: 2000,   duration: 12 },
          { level: 10, smallBlind: 1200,   bigBlind: 2400,   ante: 2400,   duration: 12 },
          { level: 11, smallBlind: 1500,   bigBlind: 3000,   ante: 3000,   duration: 12 },
          { level: 12, smallBlind: 2000,   bigBlind: 4000,   ante: 4000,   duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 13, smallBlind: 2500,   bigBlind: 5000,   ante: 5000,   duration: 12 },
          { level: 14, smallBlind: 3000,   bigBlind: 6000,   ante: 6000,   duration: 12 },
          { level: 15, smallBlind: 4000,   bigBlind: 8000,   ante: 8000,   duration: 12 },
          { level: 16, smallBlind: 5000,   bigBlind: 10000,  ante: 10000,  duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 17, smallBlind: 6000,   bigBlind: 12000,  ante: 12000,  duration: 12 },
          { level: 18, smallBlind: 8000,   bigBlind: 16000,  ante: 16000,  duration: 12 },
          { level: 19, smallBlind: 10000,  bigBlind: 20000,  ante: 20000,  duration: 12 },
          { level: 20, smallBlind: 15000,  bigBlind: 30000,  ante: 30000,  duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 21, smallBlind: 20000,  bigBlind: 40000,  ante: 40000,  duration: 12 },
          { level: 22, smallBlind: 25000,  bigBlind: 50000,  ante: 50000,  duration: 12 },
          { level: 23, smallBlind: 30000,  bigBlind: 60000,  ante: 60000,  duration: 12 },
          { level: 24, smallBlind: 40000,  bigBlind: 80000,  ante: 80000,  duration: 12 },
          { level: 0,	 smallBlind: 0,      bigBlind: 0,      ante: 0,      duration: 10 },
          { level: 25, smallBlind: 50000,  bigBlind: 100000, ante: 100000, duration: 12 },
          { level: 26, smallBlind: 60000,  bigBlind: 120000, ante: 120000, duration: 12 },
          { level: 27, smallBlind: 80000,  bigBlind: 160000, ante: 160000, duration: 12 },
          { level: 28, smallBlind: 100000, bigBlind: 200000, ante: 200000, duration: 12 },
        ],
      };
    },
    clickSaveStructure() {
      // FIXME
      // validation : levels 가 없으면 저장안됨

      this.openPopup(
        this.$i18n.t('structure.save'),
        this.$i18n.t('message.structure.save.confirm'),
        "saveStructure"
      );
    },
    saveStructure() {
      if(!this.validateName()){
        this.$refs.structureNameField.focus()
        return;
      }

      const registrationDeadlineSeq =
        this.structure.master.registrationDeadline.selected;
      let registrationDeadlineLevel = Number(
        this.structure.level[registrationDeadlineSeq].level
      );
      // seq, level 이 둘다 0이면 첫번째 레벨이 레지마감이므로 그냥 둔다.
      if (registrationDeadlineLevel === 0 && registrationDeadlineSeq != 0) {
        // 아니면 고른 레지마감레벨이 휴식시간 전이므로 seq에서 1을 빼준다.
        registrationDeadlineLevel = Number(
          this.structure.level[registrationDeadlineSeq - 1].level
        );
      }

      const param = {
        name: this.structure.master.name,
        storeId: this.$store.getters.storeId,
        buyInAmount: Number(this.structure.master.buyInAmount),
        registrationDeadline: registrationDeadlineSeq,
        registrationDeadlineLevel: registrationDeadlineLevel,
        prize: this.structure.master.prize,
        entryLimit: Number(this.structure.master.entryLimit),
        entryLimitFlag: Number(this.structure.master.entryLimitFlag),
        earlyBuyIn: Number(this.structure.value.earlyBuyIn),
        buyIn: Number(this.structure.value.buyIn),
        firstReBuyIn: Number(this.structure.value.firstReBuyIn),
        secondReBuyIn: Number(this.structure.value.secondReBuyIn),
        thirdReBuyIn: Number(this.structure.value.thirdReBuyIn),
        addOn: Number(this.structure.value.addOn),
        levelList: this.structure.level,
        prizeContent: this.ckeditor.content,
        createUserId: this.$store.getters.id,
        updateUserId: this.$store.getters.id,
      };
      this.$axios
        .post(this.url.structure, param)
        .then((res) => {
          if (res.data.code === 1) {
            this.openAlert(this.$i18n.t('message.structure.save.success'), "success");
            this.clearStructure();
          } else if(res.data.code === -1) {
            this.openAlert('스트럭쳐가 저장 되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          } else {
            this.openAlert(this.$i18n.t('message.structure.save.fail')+'(' + res.data.message + ')', "error");
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert(this.$i18n.t('message.structure.save.fail'), "error");
        });
    },

    /* Popup */
    openPopup(title, contents, owner) {
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
    },
    closePopup() {
      this.popup.owner = "";
      this.togglePopup();
    },
    togglePopup() {
      this.popup.show = !this.popup.show;
    },
    handlePopupEvent(isConfirm) {
      if (isConfirm) {
        if (this.popup.owner === "resetStructure") {
          this.resetStructure();
        } else if (this.popup.owner === "saveStructure") {
          this.saveStructure();
        } else {
          console.log("Undefined Popup owner.");
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },

    /* Alert */
    openAlert(msg, type) {
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    calcRegistrationDeadline() {
      let item = [];
      const levels = this.structure.level;
      for (let i = 0; i < this.structure.level.length; i++) {
        if (levels[i].level === 0 && i != 0) {
          item[item.length - 1].seq++;
        } else {
          item.push({ seq: i, level: levels[i].level });
        }
      }
      this.structure.master.registrationDeadline.items = item;
    },
    toggleFirstReBuyIn() {
      this.structure.value.insetSwitch.firstReBuyIn =
        !this.structure.value.insetSwitch.firstReBuyIn;
      this.structure.value.firstReBuyIn = 0;
      if (this.structure.value.insetSwitch.firstReBuyIn) {
        this.structure.master.entryLimit = 1;

        this.structure.value.insetSwitch.disabled.secondReBuyIn = false;
      } else {
        this.structure.master.entryLimit = 0;

        this.structure.value.insetSwitch.secondReBuyIn = false;
        this.structure.value.insetSwitch.disabled.secondReBuyIn = true;
        this.structure.value.secondReBuyIn = 0;

        this.structure.value.insetSwitch.thirdReBuyIn = false;
        this.structure.value.insetSwitch.disabled.thirdReBuyIn = true;
        this.structure.value.thirdReBuyIn = 0;
      }
    },
    toggleSecondReBuyIn() {
      this.structure.value.insetSwitch.secondReBuyIn =
        !this.structure.value.insetSwitch.secondReBuyIn;
      this.structure.value.secondReBuyIn = 0;
      if (this.structure.value.insetSwitch.secondReBuyIn) {
        this.structure.master.entryLimit = 2;

        this.structure.value.insetSwitch.disabled.thirdReBuyIn = false;
      } else {
        this.structure.master.entryLimit = 1;

        this.structure.value.insetSwitch.thirdReBuyIn = false;
        this.structure.value.insetSwitch.disabled.thirdReBuyIn = true;
        this.structure.value.thirdReBuyIn = 0;
      }
    },
    toggleThirdReBuyIn() {
      this.structure.value.insetSwitch.thirdReBuyIn =
        !this.structure.value.insetSwitch.thirdReBuyIn;
      this.structure.value.thirdReBuyIn = 0;

      if (this.structure.value.insetSwitch.thirdReBuyIn) {
        this.structure.master.entryLimit = 3;
      } else {
        this.structure.master.entryLimit = 2;
      }
    },
    toggleAddOn() {
      this.structure.value.insetSwitch.addOn =
        !this.structure.value.insetSwitch.addOn;
      this.structure.value.addOn = 0;
    },
    /* clickEntryLimit(entryLimitFlag){
        if(entryLimitFlag){
          this.structure.master.entryLimit = 1;
        }else{
          this.structure.master.entryLimit = 0;

          this.structure.value.insetSwitch.secondReBuyIn = false;
          this.structure.value.insetSwitch.disabled.secondReBuyIn = true;
          this.structure.value.secondReBuyIn = 0;

          this.structure.value.insetSwitch.thirdReBuyIn = false;
          this.structure.value.insetSwitch.disabled.thirdReBuyIn = true;
          this.structure.value.thirdReBuyIn = 0;
        }
      }, */
    /* displayDuration(duration) {
        return duration + ":00";
      }, */
    clickAddLevel() {
      // level 최고값 계산
      let level = this.structure.level;
      let levelLength = this.structure.level.length;
      let nextLevel = 1;
      if (level != null && levelLength != 0) {
        for (let i = levelLength - 1; i >= 0; i--) {
          if (level[i].level != 0) {
            nextLevel = level[i].level + 1;
            break;
          }
        }
      }
      // 추가할 레벨 정보
      const levelInfo = {
        // seq: levelLength,
        level: nextLevel,
        smallBlind: 100,
        bigBlind: 200,
        ante: 300,
        duration: 10,
      };
      this.structure.level.push(levelInfo);
    },
    clickAddBreak() {
      // let levelLength = this.structure.level.length;
      const breakInfo = {
        // seq: levelLength,
        level: 0,
        smallBlind: 0,
        bigBlind: 0,
        ante: 0,
        duration: 10,
      };
      this.structure.level.push(breakInfo);
    },
    clickRemoveLevel(index) {
      if (this.structure.level[index].level === 0) {
        // Break 삭제
        this.structure.level.splice(index, 1);
      } else {
        // Level 삭제
        this.structure.level.splice(index, 1);
        // Break 제외한 Level 재정렬
        for (let i = index; i < this.structure.level.length; i++) {
          if (this.structure.level[i].level != 0) {
            this.structure.level[i].level -= 1;
          }
        }
      }
    },
    levelUpdate(){
      const levels = this.structure.level;
      let levelCount = 1;
      for(let i=0; i<levels.length; i++){
        if(levels[i].level != 0){
          levels[i].level = levelCount++
        }
      }
    },

    // Validation
    validateDuration(item, index){
      const numericPattern = /^[0-9]+$/;
      const inputDuration = item.duration;
      if(!numericPattern.test(inputDuration) || inputDuration < 1 || inputDuration > 60){
        alert('지속시간(분) 항목에는 1 ~ 60 사이의 숫자만 입력가능합니다.');
        this.structure.level[index].duration = 12;
      }
    },
    validateName(){
      if(!this.structure.master.name){
        alert('스트럭처명은 필수항목입니다.');
        return false;
      }
      return true;
    }
  },
};
</script>
<style scoped>
.v-field__input {
  padding-top: 0;
}
.input {
  font-size: 24px;
}
.input::placeholder {
  font-size: 24px;
}
.w-35 {
  width: 30%;
}
#textField {
  font-size: 24px;
}
.hover :hover {
  cursor: pointer;
}
.hover-move :hover {
  cursor: move;
}
.btn {
  vertical-align: middle;
}

.v-field__input {
  padding-top: 2px;
}
.centered-input >>> input {
  text-align: center;
}
.right-input >>> input {
  text-align: right;
}

.centered-input :deep() input {
  text-align: center;
}

.centered-input :deep() input[type="number"] {
  -moz-appearance: textfield;
}
.centered-input :deep() input::-webkit-outer-spin-button,
.centered-input :deep() input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
