import { createWebHistory, createRouter } from "vue-router";
import { store } from "@/store/index";

import MainPage from "@/views/MainPage";

import StructureCreate from "@/views/structure/StructureCreate";

import GameList from "@/views/game/GameList";
import GameCreate from "@/views/game/GameCreate";
import GameController from "@/views/game/GameController";

import DisplayBoard from "@/views/display/DisplayBoard";
import StoreCreate from "@/views/store/StoreCreate";
import StoreEdit from "@/views/store/StoreEdit";
import TimerList from "@/views/TimerList";
import StoreDetail from "@/views/store/StoreDetail";
import StoreInfoList from "@/views/store/StoreInfoList";
import AddressManage from "@/views/admin/AddressManage";
import PasswordManage from "@/views/admin/PasswordManage";
import StoreManage from "@/views/store/ProfileManage";
import AdManage from "@/views/admin/AdManage";
import SignUp from "@/views/store/Signup";

import Award from "@/views/AwardPage";
import UserDetail from "@/views/store/UserDetail";

import UserList from "@/views/store/UserList";

import LoginPage from "@/views/login/LoginPage";
import LogoutPage from "@/views/login/LogoutPage";

let requireLogin = () => (to, from, next) => {
  if (store.getters.token === null) {
    alert("로그인 후 이용해주세요.");
    router.push({ path: "/login" });
    return;
  }
  next();
};

/* let requireLogout = () => (to, from, next) => {
  if (store.getters.token !== null) {
    alert('로그아웃 후 이용해주세요');
    router.push('/');
    return;
  }
  next();
}; */

const routes = [
  {
    path: "/fullscreen",
    name: "FullScreenLayout",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-full-screen" */
        "@/layouts/fullscreen/FullScreen"
      ),
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/logout",
        name: "LogoutPage",
        component: LogoutPage,
      },
      {
        path: "/display-board",
        name: "DisplayBoard",
        component: DisplayBoard,
        beforeEnter: requireLogin(),
      },
      {
        path: "/sign-up",
        name: "SignUp",
        component: SignUp,
      },
    ],
  },

  {
    path: "/",
    name: "DefaultLayout",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/layouts/default/Index"
      ),
    children: [
      {
        path: "/",
        redirect: { name: "MainPage" },
      },
      {
        path: "/main",
        name: "MainPage",
        component: MainPage,
      },
      {
        path: "/structure-create",
        name: "StructureCreate",
        component: StructureCreate,
        beforeEnter: requireLogin(),
      },
      {
        path: "/game-list",
        name: "GameList",
        component: GameList,
        beforeEnter: requireLogin(),
      },
      {
        path: "/game-create",
        name: "GameCreate",
        component: GameCreate,
        beforeEnter: requireLogin(),
      },
      {
        path: "/game-controller",
        name: "GameController",
        component: GameController,
        beforeEnter: requireLogin(),
      },
      // 추가
      {
        path: "/store-manage",
        name: "StoreManage",
        component: StoreManage,
        beforeEnter: requireLogin(),
      },
      {
        path: "/store-create",
        name: "StoreCreate",
        component: StoreCreate,
        beforeEnter: requireLogin(),
      },
      {
        path: "/store-edit",
        name: "StoreEdit",
        component: StoreEdit,
        beforeEnter: requireLogin(),
      },
      {
        path: "/user-list",
        name: "UserList",
        component: UserList,
        beforeEnter: requireLogin(),
      },
      {
        path: "/award",
        name: "Award",
        component: Award,
        beforeEnter: requireLogin(),
      },
      {
        path: "/user-detail",
        name: "UserDetail",
        component: UserDetail,
        beforeEnter: requireLogin(),
      },
      {
        path: "/store-info-list",
        name: "StoreInfoList",
        component: StoreInfoList,
        beforeEnter: requireLogin(),
      },
      {
        path: "/address-manage",
        name: "AddressManage",
        component: AddressManage,
        beforeEnter: requireLogin(),
      },
      {
        path: "/password-manage",
        name: "PasswordManage",
        component: PasswordManage,
        beforeEnter: requireLogin(),
      },
      {
        path: "/ad-manage",
        name: "AdManage",
        component: AdManage,
        beforeEnter: requireLogin(),
      },
      // 여기까지
      {
        path: "/timer-list",
        name: "TimerList",
        component: TimerList,
      },
      {
        path: "/store-detail",
        name: "StoreDetail",
        component: StoreDetail,
        beforeEnter: requireLogin(),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
