<template>
  <v-container fluid>
    <v-card class="pa-3">
      <v-cardTitle class="mb-5">
        <h5 class="text-h5">광고 관리</h5>
      </v-cardTitle>

      <!-- address1 -->
      <div>
        <v-row>
          <v-col cols="1" />
          <v-col cols="10">
            <v-card-subtitle
              style="font-weight: bold; color: white;"
            >
              <h3>대상 지역</h3>
            </v-card-subtitle>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row>
          <v-col cols="2" />
          <v-col cols="8">
            <v-select
              variant="outlined"
              hide-details
              density="compact"
              label="주소1"
              v-model="code.address1.selected"
              :items="code.address1.items"
              item-title="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="2" />
        </v-row>
      </div>

      <br><v-divider inset></v-divider><br>

      <!-- ad master -->
      <div>
        <v-row>
          <v-col cols="1" />
          <v-col cols="10">
            <v-card-subtitle
              style="font-weight: bold; color: white;"
            >
              <h3>헤더 정보</h3>
            </v-card-subtitle>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row>
          <v-col cols="2" />
          <v-col cols="2" class="d-flex align-center">
            <span>Page 전환시간(초)</span>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="ad.master.cycleTimeInSecond"
              variant="outlined"
              label="광고 한 Page당 노출시간"
              type="number"
              inputmode="numeric"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" />
        </v-row>
        <v-row>
          <v-col cols="2" />
          <v-col cols="2" class="d-flex align-center">
            <span>활성여부</span>
          </v-col>
          <v-col cols="3">
            <v-switch
              v-model="ad.master.isValid"
              hide-details
              label="Off/On"
              inset
            />
          </v-col>
          <v-col cols="1">
            <v-chip v-if="ad.master.isValid" color="success">활성</v-chip>
            <v-chip v-else color="error">비활성</v-chip>
          </v-col>
          <v-col cols="2" />
        </v-row>
        <v-row>
          <v-col cols="8"/>
          <v-col cols="2">
            <v-btn
              class="btn"
              rounded="lg"
              color="primary"
              width="100%"
              min-width="5rem"
              @click="registerAdMaster"
            >
              저장
            </v-btn>
          </v-col>
          <v-col cols="2"/>
        </v-row>
      </div>

      <br><v-divider inset></v-divider><br>

      <!-- ad page -->
      <div>
        <v-row>
          <v-col cols="1" />
          <v-col cols="10">
            <v-card-subtitle
              style="font-weight: bold; color: white;"
            >
              <h3>광고 페이지 정보</h3>
            </v-card-subtitle>
          </v-col>
          <v-col cols="1" />
        </v-row>

        <v-row>
          <v-col cols="1" />
          <v-col cols="10">
            <v-table>
              <thead>
                <tr>
                  <th class="text-left"
                  v-for="(item, index) in ad.page.headers"
                  :key="index"
                  >
                    {{ item.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in ad.page.items"
                  :key="index"
                >
                  <td>{{ item.seq }}</td>
                  <td>{{ item.fileName }}</td>
                  <td>{{ item.extension }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.updateUserId }}</td>
                  <td>{{ displayTime(item.updateDate) }}</td>
                  <td>
                    <v-icon
                      icon="mdi-trash-can-outline"
                      class="text-btn"
                      size="35"
                      @click="clickRemoveAdPage(index)"
                      />
                  </td>
                  <td>
                    <v-icon
                      icon="mdi-menu-up"
                      class="text-btn"
                      size="35"
                      @click="clickSeqUp(index)"
                    />
                    <v-icon
                      icon="mdi-menu-down"
                      class="text-btn"
                      size="35"
                      @click="clickSeqDown(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
          <v-col cols="1" />
        </v-row>

        <!-- buttons -->
        <v-row>
          <v-col cols="6"/>
          <v-col cols="2">
            <v-btn
              class="btn"
              rounded="lg"
              color="primary"
              width="100%"
              min-width="5rem"
              @click="clickOpenAttachmentPopup"
            >
              새 페이지 추가
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              class="btn"
              rounded="lg"
              color="primary"
              width="100%"
              min-width="5rem"
              @click="clickModifyPageOrder"
            >
              순서 변경 후 저장
            </v-btn>
          </v-col>
          <v-col cols="2"/>
        </v-row>
      </div>

      <br><v-divider inset></v-divider><br>

    </v-card>
  </v-container>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />
  <!-- Popup -->
  <Popup v-show="popup.show" :popup-props="popup.props" @popup-event="handlePopupEvent"/>
  <!-- Attachment Popup -->
  <AttachmentPopup
    v-show="attachment.show"
    :attach-popup-props="attachment.props"
    @popup-event="handleAttachPopupEvent"
  />
</template>
<script>
import Alert from "@/components/AlertDefault";
import Popup from "@/components/PopupDefault";
import AttachmentPopup from "@/components/PopupAttachmentForAd";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

export default {
  name: "AdManage",
  components: {
    Alert,
    Popup,
    AttachmentPopup,
  },
  data: () => ({
    ad: {
      master: {
        address1: '',
        isValid: true,
        cycleTimeInSecond: 3,
      },
      page: {
        headers: [
          { title: '순서',  key: 'seq', },
          { title: '파일명',key: 'file', },
          { title: '확장자',key: 'extension', },
          { title: '설명',  key: 'description', },
          { title: '마지막 수정자',  key: 'updateUserId', },
          { title: '마지막 수정일',  key: 'updateDate', },
          { title: '삭제',  key: 'remove', },
          { title: '순서변경', key: 'move', },
        ],
        items: [
          { id: '111', seq: 0, file: 'file0', decription: 'holdix0'},
        ],
        selected: []
      }
    },
    code: {
      address1: {
        selected: '',
        items : []
      },
    },

    popup: {
      show: false,
      owner: '',
      id: '',
      props: {
        title: '',
        contents: ''
      }
    },
    alert: {
      msg: "",
      type: "success",
    },
    attachment: {
      show: false,
      props: {
        url: '',
        address1: '',
        seq: 0,
      },
    },

    url: {
      /* ad */
      ad: "/api/ad",
      adMaster: "/api/ad/master",
      adPage: "/api/ad/page",
      /* find */
      address1: "/api/code/address/tier1",
    }
  }),
  async created() {
    await this.loadAddress1();
    this.loadAdInfo();
  },
  watch: {
    "code.address1.selected"(val){
      this.ad.master.address1 = val;
      this.loadAdInfo();
    }
  },
  methods: {
    /* display */
    displayTime(time){
      return dayjs(time).tz().format("YYYY-MM-DD HH:mm:ss");
    },

    /* APIs */
    loadAddress1() {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.address1, {
            parentId: "N/A",
            tier: 1
          })
          .then((res) => {
            this.code.address1.items = res.data;
            this.code.address1.selected = this.code.address1.items[0].id;
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    loadAdInfo(){
      let url = this.url.ad + "/" + this.ad.master.address1;
      this.$axios
        .get(url)
        .then((res) => {
          if(res.data == null || res.data == ""){
            this.openAlert("해당 주소에 데이터가 없습니다. 헤더 정보부터 저장해주세요.", "error");
            this.ad.master.isValid = false;
            this.ad.master.cycleTimeInSecond = 0;
            this.ad.page.items = [];
          }else{
            this.ad.master.address1 = res.data.address1;
            this.ad.master.isValid = (res.data.isValid === 1 ? true : false);
            this.ad.master.cycleTimeInSecond = res.data.cycleTime / 1000;
            this.ad.page.items = res.data.pages;
            this.openAlert("데이터 조회를 성공했습니다.", "success");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* ad master */
    registerAdMaster(){
      this.$axios
        .post(this.url.adMaster, {
          address1: this.ad.master.address1,
          isValid: (this.ad.master.isValid === true ? 1 : 0),
          cycleTime: this.ad.master.cycleTimeInSecond * 1000,
          createUserId: this.$store.getters.id,
          updateUserId: this.$store.getters.id,
        })
        .then((res) => {
          if(res.data === 1){
            this.openAlert("저장되었습니다.", "success");
          }else if(res.data === 2){
            this.openAlert("수정되었습니다.", "success");
          }else{
            this.openAlert("저장을 실패하였습니다.", "error");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* ad page */
    clickSeqUp(index){
      if(index === 0){
        return;
      }else{
        // swap
        [this.ad.page.items[index-1], this.ad.page.items[index]] = [this.ad.page.items[index], this.ad.page.items[index-1]]
        this.reorderSeq();
      }
    },
    clickSeqDown(index){
      if(index === this.ad.page.items.length-1){
        return;
      }else{
        // swap
        [this.ad.page.items[index+1], this.ad.page.items[index]] = [this.ad.page.items[index], this.ad.page.items[index+1]]
        this.reorderSeq();
      }
    },
    reorderSeq(){
      // seq 값 다시 순서대로 넣어주기
      for (let i = 0; i < this.ad.page.items.length; i++) {
        this.ad.page.items[i].seq = i + 1;
      }
    },
    clickRemoveAdPage(index){
      this.openPopup(
        '광고 페이지 삭제',
        this.ad.page.items[index].seq + "번째 광고 페이지를 삭제하시겠습니까?",
        'removeAdPage',
        index
      );
    },
    removeAdPage(){
      // this.popup.id = this.ad.page.items에서의 삭제할 element index
      const index = this.popup.id;
      const id = this.ad.page.items[index].id;
      const fileId = this.ad.page.items[index].fileId;

      this.$axios
        .delete(this.url.adPage + "/" + id, { data: fileId })
        .then((res) => {
          if (res.status === 200) {
            this.openAlert(res.data, "success");
          } else {
            this.openAlert(res.data, "error");
          }

          // reload
          this.loadAdInfo();
        })
        .catch((error) => {
          console.log(error);
          this.openAlert("광고 파일 삭제를 실패하였습니다.", "error");
        });
    },

    /* Alert */
    openAlert(msg, type) {
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    /* Popup */
    openPopup(title, contents, owner, id){
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
      // confirm 시 해당 item 삭제를 위한 id 저장
      this.popup.id = id;
    },
    closePopup(){
        this.popup.owner = '';
        this.popup.id = '';
        this.togglePopup();
    },
    togglePopup(){
      this.popup.show = !this.popup.show;
    },
    handlePopupEvent(isConfirm){
      if(isConfirm){
        if(this.popup.owner === 'removeAdPage'){
          this.removeAdPage();
        }else if(this.popup.owner === 'modifyPageOrder'){
          this.modifyPageOrder();
        }else{
          console.log('Undefined Popup owner.');
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },

    /* Attachment */
    clickOpenAttachmentPopup() {
      this.attachment.props.url = this.url.adPage;
      this.attachment.props.address1 = this.ad.master.address1;

      // 현재 max seq + 1 보내기
      let length = 0;
      let maxSeq = 0;
      if(this.ad.page.items.length != 0){
        length = this.ad.page.items.length;
        maxSeq = this.ad.page.items[length-1].seq;
      }
      this.attachment.props.seq = maxSeq + 1;

      this.attachment.show = !this.attachment.show;
    },
    handleAttachPopupEvent() {
      this.attachment.props.address1 = '';
      this.attachment.props.seq = '';

      this.attachment.show = !this.attachment.show;

      // reload
      this.loadAdInfo();
    },

    clickModifyPageOrder(){
      this.openPopup(
        '광고 페이지 순서 수정',
        "광고 페이지 순서를 수정하시겠습니까?",
        'modifyPageOrder',
        0
      );
    },
    modifyPageOrder(){
      this.$axios
        .put(this.url.adPage, this.ad.page.items
        )
        .then((res) => {
          if(res.data === 1){
            this.openAlert('광고 페이지 순서가 수정되었습니다.', 'success');
            // reload
            this.loadAdInfo();
          }else{
            this.openAlert('광고 페이지 순서 수정을 실패하였습니다.', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('광고 페이지 순서 수정을 실패하였습니다.', 'error');
        });
    },

  }
}
</script>
