<template>
  <div class="popup">
    <div class="popup-body">
      <VCard class="pa-3 popupContent">
        <VRow>
          <VCol cols="12">
            <span class="text-h5">{{ $t('timerDesign.attachPopup.title') }}</span>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12">
            <span class="text-h7">
              {{ $t('timerDesign.attachPopup.notice.fileSize') }}<br>
              {{ $t('timerDesign.attachPopup.notice.fileName') }}<br>
              {{ $t('timerDesign.attachPopup.notice.extensions') }}<br>
              {{ $t('timerDesign.attachPopup.notice.etc') }}
            </span>
          </VCol>
          <VCol cols="12 border-t border-b border-opacity-30 border-secondary">
            <div v-bind="getRootProps()">
              <input v-bind="getInputProps()">
              <VCard
                class="pa-3"
                variant="tonal"
                :text="$i18n.t('timerDesign.attachPopup.dragndrop')"
              >
              </VCard>
              <!-- <p v-if="isDragActive">타이머 배경화면 파일을 여기에 놓아주세요.</p> -->
              <!-- <p v-else>타이머 배경화면 파일을 드래그 앤 드랍해주세요.</p> -->
              <!-- <VBtn @click.stop="open">첨부</VBtn> -->
            </div>
          </VCol>
        </VRow>
        <VRow class="justify-center px-3">
          <VCol cols="6">
            <VBtn
              class="btn w-100 h-100 py-3 text-16"
              color="success"
              @click.stop="open"
            >
            {{ $t('timerDesign.attachPopup.buttonAttach') }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              class="btn w-100 h-100 py-3 text-16"
              color="error"
              @click.stop="clickClose"
            >
            {{ $t('common.close') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCard>
    </div>
  </div>
</template>

<script>
// import { toRefs } from 'vue'
import { useDropzone } from 'vue3-dropzone';
import axios from '@/common/axios-interceptor';
import { store } from "@/store/index";

export default {
name: "PopupAttachment",
props: {
  attachPopupProps: {
    type: Object,
    required: true
    /*
      url: '',
      structureId: ''
    */
  }
},
setup(props){
  // console.log('props')
  // console.log(props)
  // const { attachPopupProps } = toRefs(props)
  // console.log(attachPopupProps)
  // console.log(attachPopupProps.structureId)

  // const url = "/api/structure-attachment";

  const saveFiles = (files) => {
    let url = props.attachPopupProps.url;
    let formData = new FormData();

    formData.append('file', files[0]);
    formData.append('storeId', store.getters.storeId);
    formData.append('structureId', props.attachPopupProps.structureId);
    formData.append('userId', store.getters.id);
    formData.append('filename', 'display_board_bg');

    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        alert(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert(error.data);
      })
  }

  function onDrop(acceptFiles, rejectReasons){
    console.log(rejectReasons);

    if(rest.fileRejections != null && rest.fileRejections.value.length === 0){
      // do custom validation

      const lastIdxOfDot = acceptFiles[0].name.lastIndexOf('.');
      const filename = acceptFiles[0].name.slice(0, lastIdxOfDot);

      // regex로 숫자+한영+공백없이만 파일명에 포함되게 검사
      const regex = new RegExp(/^[0-9a-zA-Z가-힣]*$/);
      if(!regex.test(filename)){
        alert('숫자, 한글, 영문을 포함한 공백 및 특수문자 없는 파일명만 가능합니다.');
        // alert(this.$i18n.t('message.timerDesign.attachPopup.error.fileName'));
        return;
      }

      saveFiles(acceptFiles);
    }else{
      const errorArray = rest.fileRejections.value[0].errors;
      let errorString = '';
      errorArray.forEach(error => {
        // 에러 표시
        switch(error.code){
          case 'too-many-files':
            errorString += '파일은 하나만 첨부해주세요.\n';
            // errorString += this.$i18n.t('message.timerDesign.attachPopup.error.fileCount') + '\n';
            break;
          case 'file-too-large':
            errorString += '파일크기는 5MB 이하로 제한됩니다.\n';
            // errorString += this.$i18n.t('message.timerDesign.attachPopup.error.fileSize') + '\n';
            break;
          default:
            errorString += (error.code + ':' + error.message) + '\n';
            break;
        }
      });

      alert(errorString);
      return;
    }
  }

  const accept = ['.png', '.jpeg', '.jpg'];
  const maxSize = 1024 * 1024 * 5; // 5MB
  const multiple = 0; // just 1 file

  const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop, accept, maxSize, multiple });

  return {
    getRootProps,
    getInputProps,
    ...rest
  };

},
methods: {
  clickClose(){
    this.$emit('popup-event');
  }
}
}
</script>

<style>
.popupContent{
  min-width: 50%;
}

.popup{
  position: relative;
  z-index:9999;
}
</style>
