<template>
  <VCard class="pa-3 mb-3">
    <VCardTitle class="py-3 mb-3">
      <div class="d-flex align-center">
        <VRow class="justify-space-between">
          <VCol cols="12">
            <h5 class="text-h5">회원 명단</h5>
          </VCol>
          <VCol lg="4" cols="6">
            <div class="w-100 min-w-200px">
              <VSelect
                class="text-xs w-100"
                hide-detail="true"
                item-title="name"
                item-value="id"
                label="스트럭처"
                size
              />
            </div>
          </VCol>
          <VCol lg="4" cols="6">
            <div class="d-flex min-w-200px justify-space-between">
              <span class="text-body-1">손님에게 배포된 포인트 : </span>
              <span class="text-body-1">333,333,000 </span>
            </div>
            <div class="d-flex min-w-200px justify-space-between">
              <span class="text-body-1">가게에게 배포된 포인트 : </span>
              <span class="text-body-1">333,333,000 </span>
            </div>
            <div class="d-flex min-w-200px justify-space-between">
              <span class="text-body-1">회수된 포인트 : </span>
              <span class="text-body-1">333,333,000 </span>
            </div>
          </VCol>
        </VRow>
      </div>
    </VCardTitle>
  </VCard>
  <VCard>
    <VCardTitle>
      <div class="d-flex justify-end align-center">
        <div class="w-25">
          <v-text-field
            hide-details="false"
            class="pl-2 pr-5"
            variant="outlined"
            placeholder="Search User"
            density="compact"
          />
        </div>
        <VBtn ripple rounded="lg" color="primary" density="default" class="btn">
          검색
        </VBtn>
      </div>
    </VCardTitle>
    <VCardItem>
      <VTable class="text-no-wrap invoice-list-table">
        <!-- 👉 Table head -->
        <thead>
          <tr class="tr">
            <!-- 👉 Check/Uncheck all checkbox -->
            <th scope="col" class="text-center">
              <div style="width: 1rem">
                <VCheckbox hide-details="true" />
              </div>
            </th>
            <th scope="col" class="text-left">#ID</th>

            <th scope="col" class="text-left">이름</th>
            <th scope="col" class="text-left">전화번호</th>
            <th scope="col" class="text-left">회원유형</th>
            <th scope="col" class="text-right">보유포인트</th>
            <th class="text-right" scope="col">보유 승점</th>
            <th scope="col" class="text-center">비밀번호 초기화</th>
          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr v-for="i in 5" :key="i">
            <!-- 👉 Individual checkbox -->
            <td>
              <div style="width: 1rem">
                <VCheckbox hide-details="true" />
              </div>
            </td>

            <!-- 👉 Id -->
            <td>
              <!-- <RouterLink>
                #{{ invoice.id }}
              </RouterLink> -->
              <div class="d-flex align-center">
                <span>2</span>
                <div class="ml-2 d-flex items-center">
                  <VImg
                    :src="require('@/assets/images/logos/profile.png')"
                    width="30"
                    class="logo"
                  />
                </div>
                <div class="ml-2 d-flex flex-column">
                  <h6 class="text-sm font-weight-medium mb-0">name</h6>
                  <span class="text-xs text-medium-emphasis"
                    >email@holdix.com</span
                  >
                </div>
              </div>
            </td>

            <!-- 👉 Trending -->

            <!-- 👉 이름 -->
            <td class="text-left text-medium-emphasis">박성균</td>

            <!-- 👉 전화번호 -->
            <td class="text-medium-emphasis text-left">010-9999-9999</td>

            <!-- 👉 회원유형 -->
            <td class="text-left">Admin</td>

            <!-- 👉 보유 포이트 -->
            <td class="text-right" style="width: 8rem">30,000</td>
            <!-- 👉 보유 승점 -->
            <td class="text-right" style="width: 8rem">1,300,000</td>
            <!-- 👉 비밀번호 초기화 -->
            <td style="width: 4rem">
              <div class="d-flex justify-center">
                <VIcon icon="mdi-history" class="text-btn" size="20" />
              </div>
            </td>
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
      </VTable>

      <VCardText class="d-flex flex-wrap align-center justify-end gap-4 pa-2">
        <!-- 👉 Rows per page -->
        <div class="d-flex align-center me-3" style="width: 171px">
          <span class="text-no-wrap me-3">Rows per page:</span>
          <VSelect
            density="compact"
            variant="outlined"
            hide-details="true"
            class="invoice-pagination-select"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <!-- 👉 Pagination and pagination meta -->
        <div class="d-flex align-center">
          <h6 class="text-sm font-weight-regular">paginationData</h6>

          <VPagination size="small" />
        </div>
      </VCardText>
    </VCardItem>
  </VCard>
</template>
<script></script>
<style>
.min-w-200px {
  min-width: 200px;
}
.v-field__input {
}
.tr {
  background-color: rgb(61, 55, 89);
}
.logo {
  filter: invert(100%) sepia(0%) saturate(5543%) hue-rotate(356deg)
    brightness(110%) contrast(95%);
}
</style>
