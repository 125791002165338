<template>
  <div></div>
</template>

<script>
</script>

<style>
/* 사용자 지정 CSS */
.ck-editor__editable {
  /* 에디터의 텍스트 색상을 원하는 색상으로 변경 */
  color: #ffffff;

  /* 에디터의 배경색을 원하는 색상으로 변경 */
  background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
