<template>
  <VCard class="pa-3 mb-3">
    <VCardTitle>
      <h5 class="text-h5">{{ $t('game.list') }}</h5>
    </VCardTitle>
    <VCardTitle>
      <div class="d-flex justify-space-between align-center">
        <VRow>
          <VCol cols="8">
            <span class="text-sm">
              {{ $t('game.listNotice') }}
            </span>
          </VCol>
          <VCol cols="2">
            <VBtn
              class="btn w-100 h-100 py-3 text-16"
              color="warning"
              prepend-icon="mdi-refresh"
              @click.stop="clickRefreshGameList"
            >
              {{ $t('common.refresh') }}
            </VBtn>
          </VCol>
          <VCol cols="2">
            <VBtn
              class="btn w-100 h-100 py-3 text-16"
              color="info"
              prepend-icon="mdi-pencil-plus"
              @click.stop="moveToCreateGame"
            >
              {{ $t('game.add') }}
            </VBtn>
          </VCol>
        </VRow>
      </div>
    </VCardTitle>
  </VCard>

  <div v-for="(item, index) in games" :key="index">
    <VCard class="pa-3 mb-3">
      <!-- 아래 VRow 에 통채로 이벤트 넣으시면 됩니다. -->
      <VRow class="ma-0 pa-0 hover" @click.stop="moveToGameControl({ item })">
        <VCol
          v-if="item.game.master.registrationStatus === 'OPEN'"
          class="bg-success text-center"
          xl="1"
          cols="2"
        >
          <span>{{ $t('game.regStatus.open') }}</span>
        </VCol>
        <VCol v-else class="bg-error text-center" xl="1" cols="2">
          <span>{{ $t('game.regStatus.closed') }}</span>
        </VCol>
        <VCol cols="2" class="bg-secondary"></VCol>
        <VCol
          cols="7"
          xl="8"
          class="bg-secondary d-flex align-center justify-center"
        >
          <span>{{ item.structure.name }}</span>
          <span>,</span>
          <span class="pl-2">{{ item.game.master.name }}</span>
        </VCol>
        <VCol cols="1" class="bg-secondary d-flex align-center justify-center">
          <VIcon
            class="hover text-btn"
            icon="mdi-trash-can-outline"
            size="35"
            @click.stop="clickRemoveGame({ item })"
          />
        </VCol>
      </VRow>
      <VRow class="ma-0 pa-0 border-b border-secondary border-opacity-50 mb-2">
        <VCol cols="2" xl="1" class="d-flex w-100 align-center justify-center">
          <span class="text-white">{{ displayLevel(item.game.level) }}</span>
        </VCol>
        <VCol cols="2" class="d-flex w-100 justify-space-evenly align-center">
          <VIcon
            class="hover text-btn"
            icon="mdi-controller-classic"
            size="35"
            @click.stop="moveToGameControl({ item })"
          />

          <VIcon
            class="hover text-btn"
            icon="mdi-monitor"
            size="35"
            @click.stop="moveToDisplayBoard({ item })"
          />

          <VIcon
            class="hover text-btn"
            icon="mdi-link"
            size="35"
            @click.stop="clickGetShortenUrl({ item })"
          />
        </VCol>
        <VCol cols="8" xl="9" class="d-flex align-center">
          <VRow>
            <div class="v-col v-col-3 v-col-md-6">
              <span class="text-body-2"
                >{{ $t('common.createdDate') }} : {{ item.game.master.createDate }}</span
              >
            </div>

            <div class="v-col v-col-3 v-col-md-2 d-flex align-center">
              <VIcon class="mr-3" icon="mdi-account-group" size="22" />
              <span class="text-body-2"
                >{{ item.game.player.current }}/{{
                  item.game.player.total
                }}</span
              >
            </div>
            <div class="v-col v-col-3 v-col-md-2 text-center">
              <span class="text-body-2">{{ $t('game.status.title') }}</span>
            </div>
            <div class="v-col v-col-3 v-col-md-2 text-center">
              <VChip
                v-if="item.game.master.status === 'READY'"
                class="px-2"
                label
                color="warning"
                size="mideum"
              >
                READY
              </VChip>
              <VChip
                v-if="item.game.master.status === 'PLAYING'"
                class="px-2"
                label
                color="success"
                size="mideum"
              >
                PLAYING
              </VChip>
              <VChip
                v-if="item.game.master.status === 'PAUSED'"
                class="px-2"
                label
                color="secondary"
                size="mideum"
              >
                PAUSED
              </VChip>
              <VChip
                v-if="item.game.master.status === 'END'"
                class="px-2"
                label
                color="error"
                size="mideum"
              >
                END
              </VChip>
            </div>
          </VRow>
        </VCol>
      </VRow>
    </VCard>
  </div>


  <!-- 새로고침 alert -->
  <Alert ref="alert" :alert-props="alert" />

  <!-- snackbar -->
  <!-- <Snackbar ref="snackbar" :msg="snackbar.msg" /> -->

  <!-- 삭제 popup -->
  <Popup v-show="popup.show" :popup-props="popup.props" @popup-event="handlePopupEvent"/>

</template>

<script>
import Alert from "@/components/AlertDefault";
// import Snackbar from "@/components/SnackbarDefault";
import Popup from "@/components/PopupDefault";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

export default {
  name: "GameList",
  components: {
    Alert,
    // Snackbar,
    Popup
  },
  data() {
    return {
      games: [
        /*
        game: {
          master: {
            id: '',
            name: '',
            status: '',
            registrationStatus: '',
            createDate: null
          },
          player: {
            current: 0,
            total: 0
          },
          level: 0
        },
        structure: {
          name: '',
          prize: ''
        }
        */
      ],
      url: {
        gameList: '/api/game/list',
        game: '/api/game',
        shortenUrl: '/api/shorten-url',
      },
      popup: {
        show: false,
        owner: '',
        id: '',
        props: {
          title: '게임 삭제',
          contents: '게임을 삭제하시겠습니까?'
        }
      },
      /* snackbar: {
        msg: ''
      }, */
      alert: {
        msg: '',
        type: 'success'
      }
    };
  },
  created() {
    this.readGameList();
  },
  methods: {
    /* Display */
    displayLevel(level) {
      if (level === 0) {
        return this.$i18n.t('game.readyToStart');
      } else {
        return "Level " + level;
      }
    },

    /* Popup */
    openPopup(title, contents, owner, id){
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
      // confirm 시 해당 item 삭제를 위한 id 저장
      this.popup.id = id;
    },
    closePopup(){
        this.popup.id = '';
        this.togglePopup();
    },
    togglePopup(){
      this.popup.show = !this.popup.show;
    },
    /* openSnackbar(msg){
        this.snackbar.msg = msg;
        this.$refs.snackbar.open();
    }, */
    openAlert(msg, type){
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    /* Buttons */
    clickRefreshGameList() {
      this.readGameList()
      .then(() =>{
        this.openAlert(this.$i18n.t('message.common.refresh.success'), 'success');
      })
      .catch(() =>{
        this.openAlert(this.$i18n.t('message.common.refresh.fail'), 'error');
      });
    },
    moveToCreateGame() {
      this.$router.push({
        name: "GameCreate",
        params: { storeId: this.$store.getters.storeId },
      });
    },
    moveToGameControl({ item }) {
      console.log(item.game.master.id);
      this.$router.push({
        name: "GameController",
        params: { id: item.game.master.id },
      });
    },
    moveToDisplayBoard({ item }) {
      this.$router.push({
        name: "DisplayBoard",
        params: { id: item.game.master.id },
      });
    },
    clickGetShortenUrl({ item }){
      this.$axios
        .post("/api/shorten-url", {
          gameId: item.game.master.id,
          token: this.$store.getters.token,
          userId: this.$store.getters.id
        })
        .then((res) => {console.log(res);
          console.log(res.data.result.url);
          this.openPopup(
            this.$i18n.t('message.common.shortenUrl.success'),
            res.data.result.url,
            'shortenUrl',
            ''
          );

        })
        .catch((error) => {
          console.log(error);
          this.openAlert("'" + item.structure.name + ", " + item.game.master.name + "' " + this.$i18n.t('message.common.shortenUrl.fail'), 'error');
        });
    },
    /* Remove Game Popup */
    clickRemoveGame({ item }){
      /* this.popup.props.contents = "'" + item.structure.name + ", " + item.game.master.name + "' 게임을 삭제하시겠습니까?";
      this.togglePopup();
      // confirm 시 해당 item 삭제를 위한 id 저장
      this.popup.id = item.game.master.id; */
      this.openPopup(
        this.$i18n.t('game.delete'),
        "'" + item.structure.name + ", " + item.game.master.name + "' " + this.$i18n.t('message.game.delete.confirm'),
        'removeGame',
        item.game.master.id
      );
    },
    handlePopupEvent(isConfirm){
      if(isConfirm){
        if (this.popup.owner === "removeGame") {
          this.removeGame();
        } else if (this.popup.owner === "shortenUrl") {
          console.log("do nothing");
        } else {
          console.log("Undefined Popup owner.");
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },

    /* APIs */
    readGameList() {
      // 게임 상태가 END가 아니거나 24시간 이내 생성된 게임 표시
      return this.$axios
        .post(this.url.gameList, {
          storeId: this.$store.getters.storeId,
        })
        .then((res) => {
          console.log(res.data);
          this.games = [];
          const games = res.data;
          games.forEach((game) => {
            this.games.push({
              game: {
                master: {
                  id: game.id,
                  name: game.name,
                  status: game.status,
                  registrationStatus: game.registrationStatus,
                  createDate: dayjs(game.createDate)
                    .tz()
                    .format("YYYY-MM-DD HH:mm:ss"),
                  // createDate: game.createDate
                },
                player: {
                  current: game.currentPlayer,
                  total: game.totalPlayer,
                },
                level: game.level,
              },
              structure: {
                name: game.structureName,
                prize: game.prize,
              },
            });
          });
          return 1;
        })
        .catch((error) => {
          console.log(error);
          return 0;
        });
    },
    removeGame() {
      this.$axios.delete(this.url.game, { data: this.popup.id })
      .then((res) => {
        if(res.data === 1){
          this.openAlert(this.$i18n.t('message.game.delete.success'), 'success');
        }else{
          this.openAlert(this.$i18n.t('message.game.delete.fail'), 'error');
        }
        this.readGameList();
      })
      .catch((error) => {
        console.log(error);
        this.openAlert(this.$i18n.t('message.game.delete.fail'), 'error');
      });
    }
  },
};
</script>
<style>
.hover :hover {
  cursor: pointer;
}
</style>
