<template>
  <VContainer fluid>
    <VCard class="pa-3 mb-3">
      <VRow class="">
        <VCol cols="12" md="2">
          <VCardTitle class="">
            <h5 class="text-h5">{{ $t('controller.title') }}</h5>
          </VCardTitle>
        </VCol>

        <VCol cols="6" md="2">
          <VBtn
            color="primary"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0 text-center"
            @click.stop="clickMoveToDisplayBoard"
          >
            <VIcon start size="30" icon="mdi-monitor" />
            {{ $t('controller.timer') }}
          </VBtn>
        </VCol>
        <VCol cols="6" md="2">
          <VBtn
            color="info"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0 text-center"
            @click.stop="clickSendControl"
          >
            <VIcon start size="30" icon="mdi-check-circle-outline" />
            {{ $t('controller.apply') }}
          </VBtn>
        </VCol>
        <VCol cols="6" md="2">
          <VBtn
            color="warning"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0 text-center"
            @click.stop="clickSendAction('PLAY')"
          >
            <VIcon start size="30" icon="mdi-play-outline" />
            {{ $t('controller.start') }}
          </VBtn>
        </VCol>
        <VCol cols="6" md="2">
          <VBtn
            color="secondary"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0"
            @click.stop="clickSendAction('PAUSE')"
          >
            <VIcon start size="30" icon="mdi-pause" />
            {{ $t('controller.pause') }}
          </VBtn>
        </VCol>
        <VCol clos="12" md="2" class="">
          <VBtn
            color="error"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0 text-center"
            @click.stop="clickSendAction('END')"
          >
            <VIcon start icon="mdi-door-closed" size="30" />
            {{ $t('controller.end') }}
          </VBtn>
        </VCol>
      </VRow>
    </VCard>
    <VCard class="pa-3 mb-3">
      <VRow class="">
        <VCol cols="6">
          <VRow class="">
            <VCol cols="4" class="d-flex justify-space-between align-center">
              <span class="text-grey-700">{{ $t('game.name') }}</span>
            </VCol>

            <VCol cols="8" class="d-flex justify-space-between align-center">
              <div
                class="pa-3 w-100 border-b text-center border-primary border-opacity-100"
              >
                <span class="text-sm">{{ game.master.name }}</span>
              </div>
            </VCol>

            <VCol cols="4" class="d-flex justify-space-between align-center">
              <span class="text-grey-700">{{ $t('structure.name') }}</span>
            </VCol>

            <VCol cols="8" class="d-flex justify-space-between align-center">
              <div
                class="pa-3 w-100 border-b text-center border-primary border-opacity-100"
              >
                <span class="text-sm">{{ structure.master.name }}</span>
              </div>
            </VCol>
          </VRow>
        </VCol>
        <VCol cols="12" md="6">
          <VRow class="justify-start align-center">
            <VCol cols="2" class="d-none d-md-block" />
            <VCol cols="4" md="2" class="px-2 py-2 pb-md-3">
              <span class="text-grey-700">{{ $t('controller.level.move') }}</span>
            </VCol>
            <VCol cols="4">
              <div class="text-body-1 text-secondary w-100 d-flex flex-column">
                <VSelect
                  :items="structure.level.items"
                  hide-details="true"
                  v-model="structure.level.selected"
                  :label="$i18n.t('structure.value.registrationDeadline.selectLevel')"
                  item-title="level"
                  item-value="seq"
                  density="compact"
                  class="px-2"
                />
              </div>
            </VCol>
            <VCol cols="4">
              <div>
                <VBtn
                  color="success"
                  class="w-100 h-100 btn rounded my-0 pa-2 my-md-0"
                  @click.stop="clickSendLevel"
                >
                  <VIcon start size="30" icon="mdi-play-outline" />
                  {{ $t('controller.level.start') }}
                </VBtn>
              </div>
            </VCol>

            <VCol cols="4" md="2" class="d-none d-md-block" />
            <VCol cols="2" class="d-flex justify-space-between align-center">
              <span class="text-grey-700">{{ $t('controller.level.info') }}</span>
            </VCol>

            <VCol cols="8" class="d-flex justify-space-between align-center">
              <div
                class="pa-3 w-100 border-b text-center border-primary border-opacity-100"
              >
                <span class="text-sm">{{ displayLevelInfo }}</span>
              </div>
            </VCol>

            <!-- <VCol cols="4" md="2" class="d-none d-md-block" />
            <VCol cols="10" class="text-left mb-5">
              <span>{{ displayLevelInfo }}</span>
            </VCol> -->
          </VRow>
        </VCol>
      </VRow>
    </VCard>

    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="text-center w-100 d-flex justify-center align-center">
            <span class="text-h5">{{ $t('controller.player.title') }}</span>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <VRow class="">
            <!-- 반복 컴포넌트 시작 부분 -->
            <VCol cols="6" class="d-flex align-center">
              <VCol cols="3" md="4" class="pa-0 d-flex flex-column align-center">
                <span class="text-h5 nowrap">{{ $t('controller.player.current') }}</span>
              </VCol>
              <VCol cols="9" md="8" class="pa-0 d-flex flex-column align-center">
                <div class="d-flex w-50 align-center justify-space-evenly">
                  <VIcon
                    icon="mdi-minus-box-outline"
                    size="30"
                    @click.stop="clickSubCurrentPlayer"
                    class="text-btn"
                  />
                  <div class="d-flex w-50 align-center textField">
                    <v-text-field
                      hide-details="true"
                      center-affix="true"
                      class="centered-input"
                      label=""
                      type="number"
                      density="compact"
                      hide-spin-buttons
                      readonly
                      v-model="game.player.current"
                    />
                  </div>
                  <VIcon
                    icon="mdi-plus-box-outline"
                    size="30"
                    @click.stop="clickAddCurrentPlayer"
                    class="text-btn"
                  />
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex align-center">
             <VCol cols="3" md="4" class="pa-0 d-flex flex-column align-center">
                <span class="text-h5">{{ $t('controller.player.entry') }}</span>
              </VCol>
              <VCol cols="9" md="8" class="pa-0 d-flex flex-column align-center">
                <div class="d-flex align-center w-30 justify-center">
                  <VTextField
                    v-if="version !== 2"
                    hide-details="true"
                    density="compact"
                    class="text-center centered-input"
                    label=""
                    type="number"
                    readonly
                    v-model="game.player.total"
                  />
                  <span
                    v-if="version === 2"
                    hide-details="true"
                    density="compact"
                    class="text-center centered-input"
                    readonly
                  >
                    {{ game.player.buyIn + game.player.earlyBuyIn }}
                  </span>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                cols="3"
                md="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">{{ $t('controller.player.earlyBuyIn') }}</span>
                  <div class="pa-3 rounded-lg">
                    <span>{{
                      addCommasToNumber(structure.value.earlyBuyIn)
                    }}</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="9" md="8" class="justify-center d-flex">
                <div
                  class="d-flex w-75 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex align-center justify-space-between"
                    >
                      <VIcon
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubEarlyBuyInPlayer"
                      />
                      <VTextField
                        hide-details="true"
                        label=""
                        density="compact"
                        variant="outlined"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.earlyBuyIn"
                      />

                      <VIcon
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddEarlyBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <!-- 마지막 부분 -->
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                cols="3"
                md="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">{{ $t('controller.player.firstReBuyIn') }}</span>
                  <div class="pa-3 rounded-lg">
                    <span v-if="!game.player.disabled.firstReBuyIn">{{
                      addCommasToNumber(structure.value.firstReBuyIn)
                    }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="9" md="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-75 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                      <VIcon
                        v-if="!game.player.disabled.firstReBuyIn"
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubFirstReBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.firstReBuyIn"
                      />
                      <VIcon
                        v-if="!game.player.disabled.firstReBuyIn"
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddFirstReBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="3"
                md="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">{{ $t('controller.player.buyIn') }}</span>
                  <div class="pa-3 rounded-lg">
                    <span>{{ addCommasToNumber(structure.value.buyIn) }}</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="9" md="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-75 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                      <VIcon
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.buyIn"
                      />
                      <VIcon
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="3"
                md="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">{{ $t('controller.player.secondReBuyIn') }}</span>
                  <div class="pa-3 rounded-lg">
                    <span v-if="!game.player.disabled.secondReBuyIn">{{
                      addCommasToNumber(structure.value.secondReBuyIn)
                    }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="9" md="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-75 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                      <VIcon
                        v-if="!game.player.disabled.secondReBuyIn"
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubSecondReBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.secondReBuyIn"
                      />
                      <VIcon
                        v-if="!game.player.disabled.secondReBuyIn"
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddSecondReBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="3"
                md="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">{{ $t('controller.player.addOn') }}</span>
                  <div class="pa-3 rounded-lg">
                    <span>{{ addCommasToNumber(structure.value.addOn) }}</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="9" md="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-75 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                      <VIcon
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubAddOnPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.addOn"
                      />
                      <VIcon
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddAddOnPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="3"
                md="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">{{ $t('controller.player.thirdReBuyIn') }}</span>
                  <div class="pa-3 rounded-lg">
                    <span v-if="!game.player.disabled.thirdReBuyIn">{{
                      addCommasToNumber(structure.value.thirdReBuyIn)
                    }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="9" md="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-75 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                      <VIcon
                        v-if="!game.player.disabled.thirdReBuyIn"
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubThirdReBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.thirdReBuyIn"
                      />
                      <VIcon
                        v-if="!game.player.disabled.thirdReBuyIn"
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddThirdReBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
          </VRow>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mt-3">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="w-100 d-flex justify-center align-center">
            <span class="text-h5">{{ $t('structure.prize.title') }}</span>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <VRow class="mt-0">
            <VCol>
              <ck-editor
                v-model="ckeditor.content"
                :editor="ckeditor.editor"
                :config="ckeditor.editorConfig"
              />
            </VCol>
          </VRow>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mt-3">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="w-100 d-flex justify-center align-center">
            <span class="text-h5">{{ $t('structure.level.title') }}</span>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <!-- LEVEL 행 헤더 -->
          <VRow class="mt-5">
            <VCol cols="3" class="d-flex justify-start align-center">
              <div class="w-75 text-center">
                <span class="text-body-1 text-grey-800">Level</span>
              </div>
            </VCol>
            <VCol cols="8" class="d-flex justify-space-between">
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >{{ $t('structure.level.smallBlind') }}</VInput
                >
              </div>
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >{{ $t('structure.level.bigBlind') }}</VInput
                >
              </div>
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >{{ $t('structure.level.ante') }}</VInput
                >
              </div>
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >{{ $t('structure.level.duration') }}</VInput
                >
              </div>
            </VCol>
          </VRow>
          <!-- 1행 끝 -->
          <!-- v-for start -->
          <div v-for="(item, index) in structure.level.items" :key="index">
            <v-row v-if="item.level === 0" class="align-center">
              <v-col cols="3" class="text-center">
                <div
                  class="bg-warning py-2 w-75 pr-3 d-flex justify-center align-center"
                >
                  <v-icon class="mr-2" icon="mdi-pause" size="35" />
                  <span>Break</span>
                </div>
              </v-col>
              <v-col cols="8" class="d-flex justify-space-between">
                <div class="w-100 text-center bg-warning rounded-lg py-3">
                  <v-text-field
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    class="text-center centered centered-input"
                    v-model="item.duration"
                    readonly
                  />
                </div>
              </v-col>
            </v-row>
            <v-row v-else class="align-center">
              <v-col cols="3" class="text-center hover">
                <div
                  class="btn hover bg-primary py-2 w-75 pr-3 d-flex justify-center align-center"
                  @click.stop="clickSendLevelFromList({ item })"
                >
                  <v-icon class="mr-2" icon="mdi-play" size="35" />
                  <span>Level {{ item.level }}</span>
                </div>
              </v-col>
              <v-col cols="8" class="d-flex justify-space-between">
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    center-affix="true"
                    class="centered-input w-100"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.smallBlind"
                    readonly
                  />
                </div>
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    class="centered-input w-100"
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.bigBlind"
                    readonly
                  />
                </div>
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    class="centered-input w-100"
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.ante"
                    readonly
                  />
                </div>
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    class="centered-input w-100"
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.duration"
                    readonly
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- v-for end -->
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- alert -->
    <Alert ref="alert" :alert-props="alert" />

    <!-- Popup -->
    <Popup
      v-show="popup.show"
      :popup-props="popup.props"
      @popup-event="handlePopupEvent"
    />
  </VContainer>
</template>
<script>
import Alert from "@/components/AlertDefault";
import Popup from "@/components/PopupDefault";

import WebSocketService from "@/websocket/WebSocketService.js";

// CKEditor5
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "GameController",
  components: {
    Alert,
    Popup,
    "ck-editor": CKEditor.component,
  },
  props: ["id"],
  data() {
    return {
      version: 1,
      game: {
        master: {
          id: "",
          name: "",
        },
        player: {
          current: 0,
          total: 0,
          earlyBuyIn: 0,
          buyIn: 0,
          firstReBuyIn: 0,
          secondReBuyIn: 0,
          thirdReBuyIn: 0,
          addOn: 0,
          disabled: {
            firstReBuyIn: false,
            secondReBuyIn: false,
            thirdReBuyIn: false,
          },
        },
        stack: {
          avg: 0,
          total: 0,
        },
      },
      store: {
        // TODO real id
        id: "04ea2bb9-d237-11ed-b7ba-309c23e6ab85",
      },
      structure: {
        master: {
          id: "",
          name: "",
          entryLimit: 1,
        },
        value: {
          earlyBuyIn: 10000,
          buyIn: 10000,
          firstReBuyIn: 10000,
          secondReBuyIn: 10000,
          thirdReBuyIn: 10000,
          addOn: 10000,
        },
        level: {
          items: [
            /* { level: 1, smallBlind: 100, bigBlind: 200, ante: 300, duration: 10 },
            { level: 2, smallBlind: 200, bigBlind: 300, ante: 400, duration: 10 },
            { level: 3, smallBlind: 300, bigBlind: 400, ante: 500, duration: 10 },
            { level: 0, smallBlind: 0, bigBlind: 0, ante: 0, duration: 5 },
            { level: 4, smallBlind: 400, bigBlind: 500, ante: 600, duration: 10 },
            { level: 5, smallBlind: 500, bigBlind: 600, ante: 700, duration: 10 },
            { level: 0, smallBlind: 0, bigBlind: 0, ante: 0, duration: 5 },
            { level: 6, smallBlind: 600, bigBlind: 700, ante: 800, duration: 10 },
            { level: 7, smallBlind: 700, bigBlind: 800, ante: 900, duration: 10 } */
          ],
          selected: 0,
        },
      },
      url: {
        gameInfo: "/api/game/info",
        gamePlayer: "/api/game/player",
        latestAction: "/api/game-log/latest",
        gameLog: "/api/game-log",
        gamePrize: "/api/game/prize"
      },
      popup: {
        show: false,
        owner: "", // open popup call function name
        param: "", // callback function param
        props: {
          title: "",
          contents: "",
        },
      },
      alert: {
        msg: "",
        type: "success",
      },
      webSocketService: null,
      /* CKEditor */
      ckeditor: {
        // ✅ v-model
        content:
          '<p><span style="color:hsl(0,0%,0%);"><strong>GOLDMOON 세틀라이트</strong></span><br><span style="color:hsl(0,0%,0%);">6엔트리당 KSOP 시드권 1장</span><br><span style="color:hsl(0,0%,0%);">1등 : KSOP 시드권 3장</span><br><span style="color:hsl(0,0%,0%);">2등 : KSOP 시드권 1장</span><br><span style="color:hsl(0,0%,0%);">3등 : GOLDMOON 실버티켓 1장</span></p>',
        // ✅ :editor
        editor: ClassicEditor,
        // ✅ :config
        editorConfig: {
          // height: '1000px',
          fontSize: {
            options: [10,20,25,30,35,40,45,50,60,70],
          },
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "strikethrough",
              "underline",
              "|",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "highlight",
              "|",
              "link",
              "specialCharacters",
              "|",
              "undo",
              "redo",
              "|",
              // 'selectAll', 'fontFamily', 'horizontalLine',
              "|",
            ],
          },
        },
      },

      /* Expansion Panel : 플레이어 설정 패널 초기 오픈*/
      panel: [0],
    };
  },
  created() {
    this.connect();
    let gameId = this.$route.params.id;

    // 전광판에서 뒤로가기로 왔을때를 대비해 storedParam에서 game id를 꺼내오고 삭제해줌
    if (
      this.$route.params.id == null ||
      this.$route.params.id === "" ||
      this.$route.params.id == undefined
    ) {
      gameId = this.$store.getters.storedParam;
    }else{
      // 새로고침을 위해 game id를 storedParam에 저장
      const storedParam = this.$route.params.id;
      this.$store.dispatch('storedParam', { storedParam })
    }

    // game 정보 조회
    this.$axios
      .post(this.url.gameInfo, {
        id: gameId,
        // id: this.$route.params.id,
      })
      .then((res) => {
        console.log(res.data);
        this.game.master.id = res.data.id;
        this.game.master.name = res.data.name;

        this.game.player.current = res.data.currentPlayer;
        this.game.player.total = res.data.totalPlayer;
        this.game.player.earlyBuyIn = res.data.earlyBuyInPlayer;
        this.game.player.buyIn = res.data.buyInPlayer;
        this.game.player.firstReBuyIn = res.data.firstReBuyInPlayer;
        this.game.player.secondReBuyIn = res.data.secondReBuyInPlayer;
        this.game.player.thirdReBuyIn = res.data.thirdReBuyInPlayer;
        this.game.player.addOn = res.data.addOnPlayer;

        this.structure.master.id = res.data.structureId;
        this.structure.master.name = res.data.structureName;
        this.structure.master.entryLimit = res.data.entryLimit;

        this.structure.value.earlyBuyIn = res.data.earlyBuyIn;
        this.structure.value.buyIn = res.data.buyIn;
        this.structure.value.firstReBuyIn = res.data.firstReBuyIn;
        this.structure.value.secondReBuyIn = res.data.secondReBuyIn;
        this.structure.value.thirdReBuyIn = res.data.thirdReBuyIn;
        this.structure.value.addOn = res.data.addOn;

        this.structure.level.items = res.data.levelList;

        this.ckeditor.content = res.data.prizeContent;

        this.$nextTick(() => {
          // structure.master.entryLimit 에 따라 disable
          const entryLimit = this.structure.master.entryLimit;
          if (entryLimit === 0) {
            this.game.player.disabled.firstReBuyIn = true;
            this.game.player.disabled.secondReBuyIn = true;
            this.game.player.disabled.thirdReBuyIn = true;
          } else if (entryLimit === 1) {
            this.game.player.disabled.firstReBuyIn = false;
            this.game.player.disabled.secondReBuyIn = true;
            this.game.player.disabled.thirdReBuyIn = true;
          } else if (entryLimit === 2) {
            this.game.player.disabled.firstReBuyIn = false;
            this.game.player.disabled.secondReBuyIn = false;
            this.game.player.disabled.thirdReBuyIn = true;
          } else if (entryLimit === 3) {
            this.game.player.disabled.firstReBuyIn = false;
            this.game.player.disabled.secondReBuyIn = false;
            this.game.player.disabled.thirdReBuyIn = false;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // display board version 세팅
    this.version = this.$store.getters.boardVersion || 1;

    /* {
      "id": "2e77e3ae-f24e-48f0-8a52-6bf1f662e5b9",
      "name": "",
      "status": "READY",
      "structureId": "da329f12-6df5-4988-a944-b35259555e4f",
      "structureName": "3만 세틀라이트",
      "createUserId": "admin",
      "createDate": "2023-05-28T15:20:58.448278800Z",
      "current": 0,
      "total": 0,
      "earlyBuyIn": 0,
      "buyIn": 0,
      "firstReBuyIn": 0,
      "secondReBuyIn": 0,
      "thirdReBuyIn": 0,
      "addOn": 0
    } */
  },
  beforeUnmount() {
    this.disconnect();

    // 게임컨트롤러로 돌아갈때만 storedParam 유지하고 아닐때는 clear
    if(this.$route.path !== '/game-controller'){
      // storedParam 초기화
      this.$store.dispatch("storedParam", {});
    }
  },
  computed: {
    displayLevelInfo() {
      const selectedLevel = this.structure.level.selected;
      const selectedLevelInfo = this.structure.level.items[selectedLevel] || {
        level: 1,
        smallBlind: 0,
        bigBlind: 0,
        ante: 0,
        duration: 0,
      };
      return (
        "Blinds: " +
        selectedLevelInfo.smallBlind +
        " / " +
        selectedLevelInfo.bigBlind +
        ", Ante: " +
        selectedLevelInfo.ante
        /* "Blinds " +
        selectedLevelInfo.smallBlind +
        " / " +
        selectedLevelInfo.bigBlind +
        ", Ante " +
        selectedLevelInfo.ante */
      );
    },
  },
  watch: {
    "game.player.current"() {
      this.calcAvgStack();
    },
    "game.player.earlyBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.buyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.firstReBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.secondReBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.thirdReBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.addOn"() {
      this.calcTotalStack();
      this.calcAvgStack();
    },
  },
  methods: {
    /* Button Actions */
    clickMoveToDisplayBoard() {
      this.openPopup(
        this.$i18n.t('message.controller.timer.title'),
        this.$i18n.t('message.controller.timer.confirm'),
        "moveToDisplayBoard"
      );
    },
    moveToDisplayBoard() {
      this.$router.push({
        name: "DisplayBoard",
        params: { id: this.game.master.id },
      });
    },
    clickSendControl() {
      this.openPopup(
        this.$i18n.t('message.controller.apply.title'),
        this.$i18n.t('message.controller.apply.confirm'),
        "sendControl"
      );
    },
    async sendControl() {
      await this.calcTotalPlayer();
      await this.calcTotalStack();
      await this.calcAvgStack();

      const result = this.sendControlToDisplayBoard();
      if (result === 1) {
        this.openAlert(this.$i18n.t('message.controller.apply.success'), "success");
      } else {
        this.openAlert(this.$i18n.t('message.controller.apply.fail'), "error");
      }
    },
    clickSendAction(action) {
      let msg = "";
      if (action === "PLAY") {
        msg = this.$i18n.t('message.controller.action.type.start');
        this.popup.param = action;
      } else if (action === "PAUSE") {
        msg = this.$i18n.t('message.controller.action.type.pause');
        this.popup.param = action;
      } else if (action === "END") {
        msg = this.$i18n.t('message.controller.action.type.end');
        this.popup.param = action;
      } else {
        console.log("Undefined action.");
        return;
      }

      this.openPopup(
        this.$i18n.t('message.controller.action.title', { action: msg }),
        this.$i18n.t('message.controller.action.confirm', { action: msg }),
        "sendActionPreHandler"
      );
    },
    sendAction(action) {
      let result = this.sendActionToDisplayBoard(action);

      let msg = "";
      if (action === "PLAY") {
        msg = this.$i18n.t('message.controller.action.type.start');
      } else if (action === "PAUSE") {
        msg = this.$i18n.t('message.controller.action.type.pause');
      } else if (action === "END") {
        msg = this.$i18n.t('message.controller.action.type.end');
      } else {
        msg = "Undefined action.";
        result = 0;
      }

      if (result === 1) {
        this.openAlert(this.$i18n.t('message.controller.action.success', { action: msg }), "success");
      } else {
        this.openAlert(this.$i18n.t('message.controller.action.fail'), "error");
      }
    },
    async sendActionPreHandler() {
      const action = this.popup.param;
      let latestAction = "";
      // latest action을 검색한다.
      await this.readLatestAction()
        .then((res) => {
          latestAction = res;
        })
        .catch((error) => {
          console.log(error);
          return;
        });

      console.log("sendActionPreHandler");
      console.log(action);
      console.log(latestAction);
      // latest action에 따른 동작
      let message = "";
      if (action === "PLAY") {
        switch (latestAction) {
          case "CREATE":
            // 시작대기로
            this.saveInitGameLog("INIT");
            this.sendAction("PLAY");
            return;
          case "INIT":
            message = this.$i18n.t('message.controller.action.handle.init');
            break;
          case "PLAY":
            message = this.$i18n.t('message.controller.action.handle.play');
            break;
          case "PAUSE":
            // 진행으로
            this.sendAction("PLAY");
            return;
          case "END":
            message = this.$i18n.t('message.controller.action.handle.end');
            break;
          default:
            message = "Undefined Last Action.";
            break;
        }
        this.openAlert(message, "warning");
      } else if (action === "PAUSE") {
        switch (latestAction) {
          case "CREATE":
            message = this.$i18n.t('message.controller.action.handle.create');
            break;
          case "INIT":
            message = this.$i18n.t('message.controller.action.handle.init');
            break;
          case "PLAY":
            // 일시정지로
            this.sendAction("PAUSE");
            return;
          case "PAUSE":
            message = this.$i18n.t('message.controller.action.handle.pause');
            break;
          case "END":
            message = this.$i18n.t('message.controller.action.handle.end');
            break;
          default:
            message = "Undefined Last Action.";
            break;
        }
        this.openAlert(message, "warning");
      } else if (action === "END") {
        if (latestAction === "END") {
          this.openAlert(this.$i18n.t('message.controller.action.handle.end'), "warning");
        } else {
          // 게임종료로
          this.sendAction("END");
          return;
        }
      } else {
        this.openAlert("Undefined Last Action.", "warning");
      }
    },
    clickSendLevel() {
      const selectedLevel = this.structure.level.selected;
      const level = this.structure.level.items[selectedLevel].level;
      this.openPopup(
        this.$i18n.t('controller.level.move'),
        this.$i18n.t('message.controller.action.level.confirm', { level: level } ),
        "sendLevel"
      );
    },
    clickSendLevelFromList({ item }) {
      this.structure.level.selected = item.seq;
      this.clickSendLevel();
    },
    sendLevel() {
      const result = this.sendLevelToDisplayBoard();
      const selectedLevel = this.structure.level.selected;
      const level = this.structure.level.items[selectedLevel].level;

      if (result === 1) {
        this.openAlert(this.$i18n.t('message.controller.action.level.success', { level: level } ), "success");
      } else {
        this.openAlert(this.$i18n.t('message.controller.action.level.fail'), "error");
      }
    },

    /* Popup */
    openPopup(title, contents, owner) {
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
    },
    closePopup() {
      this.popup.owner = "";
      this.popup.param = "";
      this.togglePopup();
    },
    togglePopup() {
      this.popup.show = !this.popup.show;
    },
    handlePopupEvent(isConfirm) {
      if (isConfirm) {
        if (this.popup.owner === "moveToDisplayBoard") {
          this.moveToDisplayBoard();
        } else if (this.popup.owner === "sendControl") {
          this.sendControl();
        } else if (this.popup.owner === "sendActionPreHandler") {
          this.sendActionPreHandler();
        } else if (this.popup.owner === "sendLevel") {
          this.sendLevel();
        } else {
          console.log("Undefined Popup owner.");
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },

    /* Alert */
    openAlert(msg, type) {
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    /* APIs */
    modifyGamePlayer() {
      this.$axios
        .put(this.url.gamePlayer, {
          id: this.game.master.id,
          current: this.game.player.current,
          total: this.game.player.total,
          earlyBuyIn: this.game.player.earlyBuyIn,
          buyIn: this.game.player.buyIn,
          firstReBuyIn: this.game.player.firstReBuyIn,
          secondReBuyIn: this.game.player.secondReBuyIn,
          thirdReBuyIn: this.game.player.thirdReBuyIn,
          addOn: this.game.player.addOn,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modifyGamePrize() {
      this.$axios
        .put(this.url.gamePrize, {
          id: this.game.master.id,
          prizeContent: this.ckeditor.content,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    readLatestAction() {
      /* this.$axios
        .post(this.url.latestAction, { id: this.game.master.id })
        .then((res) => {
          console.log(res.data);
          // this.gameLog.latestAction = res.data.action;
          return res.data.action;
        })
        .catch((error) => {
          console.log(error);
        }); */

      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.latestAction, { id: this.game.master.id })
          .then((res) => {
            resolve(res.data.action);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    saveInitGameLog(action) {
      // Create 상태인 게임을 Init 할 때만 사용. 첫번째 레벨 정보로 세팅
      const gameLog = {
        id: this.game.master.id,
        action: action,
        levelSeq: this.structure.level.items[0].seq,
        level: this.structure.level.items[0].level,
        remainLevelTime: Number(this.structure.level.items[0].duration) * 60,
        remainRegTime: Number(this.structure.level.items[0].remainRegTime) * 60,
        nextBreakTime: Number(this.structure.level.items[0].nextBreak) * 60,
        totalTime: 0,
        actionUserId: this.$store.getters.id,
      };
      this.$axios
        .post(this.url.gameLog, gameLog)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /* Socket API Settings */
    connect() {
      this.webSocketService = new WebSocketService();
      this.webSocketService.connect();
    },
    disconnect() {
      let headers = { Authorization: this.$store.getters.token };
      if (this.webSocketService) {
        this.webSocketService.disconnect(headers);
      }
    },
    /* Socket API Calls */
    sendControlToDisplayBoard() {
      let headers = { Authorization: this.$store.getters.token };

      try {
        this.modifyGamePlayer();
        this.modifyGamePrize();

        if (!this.webSocketService.isConnected) {
          this.connect();
        }
        // Player
        const msg = {
          currentPlayer: this.game.player.current,
          totalPlayer: this.game.player.total,
          earlyBuyInPlayer: this.game.player.earlyBuyIn,
          buyInPlayer: this.game.player.buyIn,
          firstReBuyInPlayer: this.game.player.firstReBuyIn,
          secondReBuyInPlayer: this.game.player.secondReBuyIn,
          thirdReBuyInPlayer: this.game.player.thirdReBuyIn,
          addOnPlayer: this.game.player.addOn,
          totalChips: this.game.stack.total,
          avgStacks: this.game.stack.avg,
        };
        this.webSocketService.send(
          "/pub/receiveControl/gameId=" + this.game.master.id,
          JSON.stringify(msg),
          headers
        );

        // Prize Content
        this.webSocketService.send(
          "/pub/receivePrize/gameId=" + this.game.master.id,
          JSON.stringify(this.ckeditor.content),
          headers
        );

        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },
    sendActionToDisplayBoard(action) {
      let headers = { Authorization: this.$store.getters.token };

      try {
        if (!this.webSocketService.isConnected) {
          this.connect();
        }
        const msg = {
          action: action,
          actionUserId: this.$store.getters.id,
        };
        this.webSocketService.send(
          "/pub/receiveAction/gameId=" + this.game.master.id,
          JSON.stringify(msg),
          headers
        );
        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },
    sendLevelToDisplayBoard() {
      let headers = { Authorization: this.$store.getters.token };

      try {
        if (!this.webSocketService.isConnected) {
          this.connect();
        }
        const msg = {
          seq: this.structure.level.selected,
          updateUserId: this.$store.getters.id,
        };
        this.webSocketService.send(
          "/pub/receiveLevel/gameId=" + this.game.master.id,
          JSON.stringify(msg),
          headers
        );
        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },

    /* display */
    addCommasToNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    /* total stack */
    calcTotalStack() {
      return new Promise((resolve, reject) => {
        try {
          this.game.stack.total =
            this.structure.value.earlyBuyIn * this.game.player.earlyBuyIn +
            this.structure.value.buyIn * this.game.player.buyIn +
            this.structure.value.firstReBuyIn * this.game.player.firstReBuyIn +
            this.structure.value.secondReBuyIn * this.game.player.secondReBuyIn +
            this.structure.value.thirdReBuyIn * this.game.player.thirdReBuyIn +
            this.structure.value.addOn * this.game.player.addOn;

          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    /* avg stack */
    calcAvgStack() {
      return new Promise((resolve, reject) => {
        try {
          if (this.game.player.current === 0) {
            this.game.stack.avg = 0;
          } else {
            this.game.stack.avg = Number(
              (this.game.stack.total / this.game.player.current).toFixed(0)
            );
          }
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    preventMinusValue(value) {
      if (value - 1 < 0) {
        return 0;
      } else {
        return value - 1;
      }
    },
    /* total */
    calcTotalPlayer() {
      return new Promise((resolve, reject) => {
        try {
          this.game.player.total =
            this.game.player.earlyBuyIn +
            this.game.player.buyIn +
            this.game.player.firstReBuyIn +
            this.game.player.secondReBuyIn +
            this.game.player.thirdReBuyIn;

          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    /* current */
    clickSubCurrentPlayer() {
      this.game.player.current = this.preventMinusValue(
        this.game.player.current
      );
    },
    clickAddCurrentPlayer() {
      this.game.player.current++;
    },
    /* earlyBuyIn */
    clickSubEarlyBuyInPlayer() {
      if (this.game.player.earlyBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.earlyBuyIn = this.preventMinusValue(
        this.game.player.earlyBuyIn
      );
    },
    clickAddEarlyBuyInPlayer() {
      this.game.player.earlyBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* buyIn */
    clickSubBuyInPlayer() {
      if (this.game.player.buyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.buyIn = this.preventMinusValue(this.game.player.buyIn);
    },
    clickAddBuyInPlayer() {
      this.game.player.buyIn++;
      this.clickAddCurrentPlayer();
    },
    /* firstReBuyIn */
    clickSubFirstReBuyInPlayer() {
      if (this.game.player.firstReBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.firstReBuyIn = this.preventMinusValue(
        this.game.player.firstReBuyIn
      );
    },
    clickAddFirstReBuyInPlayer() {
      this.game.player.firstReBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* secondReBuyIn */
    clickSubSecondReBuyInPlayer() {
      if (this.game.player.secondReBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.secondReBuyIn = this.preventMinusValue(
        this.game.player.secondReBuyIn
      );
    },
    clickAddSecondReBuyInPlayer() {
      this.game.player.secondReBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* thirdReBuyIn */
    clickSubThirdReBuyInPlayer() {
      if (this.game.player.thirdReBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.thirdReBuyIn = this.preventMinusValue(
        this.game.player.thirdReBuyIn
      );
    },
    clickAddThirdReBuyInPlayer() {
      this.game.player.thirdReBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* addOn */
    clickSubAddOnPlayer() {
      this.game.player.addOn = this.preventMinusValue(this.game.player.addOn);
    },
    clickAddAddOnPlayer() {
      this.game.player.addOn++;
    },
  },
};
</script>
<style scoped>
.textField {
  text-align: center;
}

.nowrap{
  white-space: noWrap;
}
.w-30 {
  inline-size: 30%;
}

.centered-input :deep() input {
  text-align: center;
}

.centered-input :deep() input[type="number"] {
  -moz-appearance: textfield;
  padding-left: 0;
  padding-right: 0;
}
.v-field
  .v-field--active
  .v-field--center-affix
  .v-field--dirty
  .v-field--no-label
  .v-field--variant-outlined
  .v-theme--light
  .v-locale--is-ltr {
  padding-right: 0;
}
.centered-input :deep() input::-webkit-outer-spin-button,
.centered-input :deep() input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.btn :hover {
  cursor: pointer;
}
</style>
