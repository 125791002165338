<template>
  <!-- 삭제 popup -->
  <Popup v-show="popup.show" :popup-props="popup.props" @popup-event="handlePopupEvent"/>
</template>

<script>
import Popup from "@/components/PopupDefault";

export default {
  name: "LogoutPage",
  components: {
    Popup
  },
  data() {
    return {
      popup: {
        show: false,
        id: '',
        props: {
          title: '로그아웃',
          contents: '로그아웃 하시겠습니까?'
        }
      },
    }
  },
  created() {
    this.popup.props.title = this.$i18n.t('logout.title');
    this.popup.props.contents = this.$i18n.t('message.logout.confirm');
    this.togglePopup();
  },
  methods: {
    logout(){
      this.$store.dispatch('logout')
    },
    /* Popup */
    togglePopup(){
      this.popup.show = !this.popup.show;
    },
    openPopup(title, contents){
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
    },
    closePopup(){
        this.togglePopup();
    },
    handlePopupEvent(isConfirm){
      if(isConfirm){
        this.$store.dispatch('logout');

        this.$nextTick(() => {
          this.closePopup();
          this.$router.push({
            name: 'LoginPage'
          })
        });
      }else{
        this.$nextTick(() => {
          this.closePopup();
          this.$router.go(-1)
        });
      }

    },
  }
}
</script>
