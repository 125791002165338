import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import modules from './modules.js'

const persistedstate = createPersistedState({
  paths: [
    'token',
    'lastActionTime',
    'id',
    'nickname',
    'name',
    'phoneNo',
    'email',
    'role',
    'language',
    'boardVersion',
    'storeId',
    'storedParam'
  ],
  // localStorage 대신 sessionStorage 사용하여 창 닫으면 로그인 삭제
  storage: window.sessionStorage,
})

export const store = createStore({
  state:      modules.state,
  getters:    modules.getters,
  mutations:  modules.mutations,
  actions:    modules.actions,
  plugins:    [persistedstate]
})
