<template>
  <div class="d-flex flex-column">
    <VCard class="pa-3">
      <VCardTitle class="">
        <h5 class="text-h5">{{ $t('game.create') }}</h5>
      </VCardTitle>
      <VRow>
        <VCol cols="12" md="4" class="pa-3">
          <v-text-field
            hide-details="true"
            class="text-h5 border-opacity-100 ml-10 pr-10 border-b border-primary"
            variant="plain"
            :placeholder="$i18n.t('game.namePlaceholder')"
            v-model="game.name"
          />
        </VCol>
        <VCol cols="12" md="8" class="w-100 pa-3">
          <VRow class="pa-0">
            <VCol cols="6" md="3" class="hover">
              <div
                class="rounded my-0 py-2 btn my-md-0 bg-success pa-0 text-center"
                @click="clickCreateGame"
              >
                <VIcon size="30" icon="mdi-controller-classic" />
                <span class="text-md ml-2">{{ $t('game.create') }}</span>
              </div>
            </VCol>
            <VCol cols="6" md="3" class="hover">
              <div
                class="rounded my-0 py-2 btn my-md-0 bg-info pa-0 text-center"
                @click="clickCreateStructure"
              >
                <VIcon size="30" icon="mdi-new-box" />
                <span class="text-md ml-2">{{ $t('structure.create') }}</span>
              </div>
            </VCol>
            <VCol cols="6" md="3" class="hover">
              <div
                class="rounded my-0 py-2 btn my-md-0 bg-primary pa-0 text-center"
                @click="clickRemoveStructure"
              >
                <VIcon size="30" icon="mdi-delete-empty-outline" />
                <span class="text-md ml-2">{{ $t('structure.delete') }}</span>
              </div>
            </VCol>
            <VCol cols="6" md="3" class="hover">
              <div
                class="rounded my-0 py-2 btn my-md-0 bg-warning pa-0 text-center"
                @click="clickModifyStructure"
              >
                <VIcon size="30" icon="mdi-content-save-outline" />
                <span class="text-md ml-2">{{ $t('structure.save') }}</span>
              </div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCard>

    <VCard class="pa-3 mt-4">
      <VCol class="pa-3">
        <VRow class="w-100 border-b mb-5 pb-5 d-flex justify-space-between">
          <VCol cols="6" class="d-flex align-center">
            <VSelect
              v-if="!structure.master.toggleName"
              class="text-xs w-100"
              hide-detail="true"
              v-model="store.structures.selected"
              :items="store.structures.items"
              item-title="name"
              item-value="id"
              :label="$i18n.t('structure.list')"
              size
              >
              <template v-slot:append>
                <v-tooltip activator="parent" location="top">
                  {{ $t('structure.modifyTitle') }}
                </v-tooltip>
                <v-icon
                  class="text-btn"
                  size="25"
                  icon="mdi-pencil-box"
                  @click="structure.master.toggleName=!structure.master.toggleName"
                />
              </template>
            </VSelect>
            <v-text-field
              v-else
              class="text-xs w-100"
              v-model="structure.master.name"
              :label="$i18n.t('structure.name')"
            >
              <template v-slot:append>
                <v-tooltip activator="parent" location="top">
                  {{ $t('common.backToList') }}
                </v-tooltip>
                <v-icon
                  class="text-btn"
                  size="25"
                  icon="mdi-close-box"
                  @click="structure.master.toggleName=!structure.master.toggleName"
                />
              </template>
            </v-text-field>
          </VCol>
          <VCol cols="6">
            <VRow class="justify-end">
              <VCol cols="7">
                <div
                  class="px-5 py-2 d-flex justify-center align-center text-center border border-primary border-opacity-100 rounded-lg mr-2"
                >
                  <span class="text-body-2" @click.stop="clickOpenBgImagePopup"
                    >{{ $t('timerDesign.backgroundImage') }}</span
                  >
                  <!-- <VBtn
                    class="w-100 btn"
                    ripple
                    rounded="lg"
                    color="primary"
                    width="7%"
                    density="default"
                    @click.stop="clickOpenBgImagePopup"
                  >
                  타이머 배경화면
                  </VBtn> -->
                </div>
              </VCol>
              <VCol cols="3">
                <VBtn
                  class="w-100 btn"
                  ripple
                  rounded="lg"
                  color="primary"
                  width="7%"
                  density="default"
                  @click.stop="clickOpenAttachmentPopup"
                >
                {{ $t('timerDesign.attach') }}
                </VBtn>
              </VCol>
            </VRow>
            <VRow v-if="role === 'SUPER_ADMIN'" class="justify-end px-1 py-5">
              <VCol cols="5" class="px-1 py-5">
                <span class="text-20">스트럭처 연계 버튼</span>
              </VCol>
              <VCol cols="3" class="px-1 py-5">
                <VBtn
                  class="btn"
                  rounded="lg"
                  color="primary"
                  width="100%"
                  min-width="6rem"
                  density="default"
                  @click="sendRegisterStructure"
                >
                  App 전송(생성)
                </VBtn>
              </VCol>
              <VCol cols="3" class="px-1 py-5">
                <VBtn
                  class="btn"
                  rounded="lg"
                  color="primary"
                  width="100%"
                  min-width="6rem"
                  density="default"
                  @click="sendModifyStructure"
                >
                  App 전송(수정)
                </VBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>

        <VRow class="mt-0">
          <div class="w-100 text-center">
            <span class="text-h5">{{ $t('structure.value.title') }}</span>
          </div>
          <VCol cols="12" md="6">
            <VRow class="justify-center">
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.earlyBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.earlyBuyIn"
                    variant="plain"
                    placeholder="10,000"
                  />
                </div>
              </VCol>
              <VCol cols="2" />
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.buyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.buyIn"
                    variant="plain"
                    placeholder="10,000"
                  />
                </div>
              </VCol>
              <VCol cols="2" />

              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.firstReBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.firstReBuyIn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.firstReBuyIn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.firstReBuyIn"
                  inset
                  @click="toggleFirstReBuyIn"
                />
              </VCol>
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.secondReBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.secondReBuyIn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.secondReBuyIn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.secondReBuyIn"
                  inset
                  @click="toggleSecondReBuyIn"
                  :disabled="structure.value.insetSwitch.disabled.secondReBuyIn"
                />
              </VCol>
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.thirdReBuyIn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.thirdReBuyIn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.thirdReBuyIn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.thirdReBuyIn"
                  inset
                  @click="toggleThirdReBuyIn"
                  :disabled="structure.value.insetSwitch.disabled.thirdReBuyIn"
                />
              </VCol>
              <VCol
                cols="6"
                class="px-2 py-2 d-flex align-center justify-space-between pb-md-3"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.addOn') }}</span>
                <div class="text-right w-35">
                  <v-text-field
                    density="compact"
                    hide-details="true"
                    class="border-b px-2 border-primary centered-input border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.value.addOn"
                    variant="plain"
                    placeholder="10,000"
                    :disabled="!structure.value.insetSwitch.addOn"
                  />
                </div>
              </VCol>
              <VCol cols="2" class="py-0">
                <v-switch
                  hide-details="true"
                  v-model="structure.value.insetSwitch.addOn"
                  inset
                  @click="toggleAddOn"
                />
              </VCol>
            </VRow>
          </VCol>
          <!-- pc화면전용 스페이스 -->
          <!-- <VCol cols="12" md="4" class="d-none d-md-block" /> -->

          <VCol cols="12" md="6">
            <VRow class="justify-center">
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.buyInAmount') }}</span>
                <div class="w-50">
                  <v-text-field
                    hide-details="true"
                    density="compact"
                    class="text-right right-input border-b py-1 px-2 border-primary border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.master.buyInAmount"
                    variant="plain"
                    placeholder="10,000"
                  />
                </div>
              </VCol>
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.registrationDeadline.title') }}</span>
                <div
                  class="text-body-1 text-secondary w-50 d-flex align-center"
                >
                  <VSelect
                    dense
                    variant="outlined"
                    :items="structure.master.registrationDeadline.items"
                    v-model="structure.master.registrationDeadline.selected"
                    :label="$i18n.t('structure.value.registrationDeadline.selectLevel')"
                    item-title="level"
                    item-value="seq"
                    density="compact"
                    hide-details="true"
                    class="px-2 text-center d-flex justify-end"
                  />
                </div>
              </VCol>
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.prize') }}</span>
                <div class="w-50">
                  <v-text-field
                    hide-details="true"
                    density="compact"
                    class="text-right border-b py-1 right-input px-2 border-primary border-opacity-100 text-body-1 text-gray-100"
                    v-model="structure.master.prize"
                    variant="plain"
                    placeholder="70% or 200GTD"
                  />
                </div>
              </VCol>
              <VCol
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.entryLimit.title') }}</span>
                <div
                  class="text-body-1 text-secondary d-flex align-center justify-space-between"
                >
                  <VRadioGroup inline v-model="structure.master.entryLimitFlag">
                    <VRadio :label="$i18n.t('structure.value.entryLimit.limited')" :value="1" />
                    <VRadio :label="$i18n.t('structure.value.entryLimit.unlimited')" :value="0" />
                  </VRadioGroup>
                </div>
              </VCol>
              <v-col
                cols="10"
                md="8"
                class="px-2 py-2 d-flex align-center pb-md-3 justify-space-between"
              >
                <span class="text-body-1 text-gray-100">{{ $t('structure.value.reEntryCount.title') }}</span>
                <div class="d-flex align-center">
                  <v-text-field
                    hide-details="true"
                    density="compact"
                    class="text-right right-input border-b mb-2 border-primary border-opacity-100 w-50 text-body-1 text-gray-100"
                    v-model="structure.master.entryLimit"
                    variant="plain"
                  />
                  <span class="text-body-1 text-gray-100">{{ $t('structure.value.reEntryCount.times') }}</span>
                </div>
              </v-col>
            </VRow>
          </VCol>
        </VRow>

        <!-- LEVEL 행 헤더 -->
        <VRow class="mt-5">
          <div class="w-100 text-center">
            <span class="text-h5">{{ $t('structure.level.title') }}</span>
          </div>
          <VCol cols="3" class="d-flex justify-start align-center">
            <div class="w-75 text-center">
              <span class="text-body-1 text-grey-800">Level</span>
            </div>
          </VCol>
          <VCol cols="8">
            <VRow>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.smallBlind') }}</VInput
                  >
                </div>
              </VCol>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.bigBlind') }}</VInput
                  >
                </div>
              </VCol>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.ante') }}</VInput
                  >
                </div>
              </VCol>
              <VCol cols="3">
                <div class="px-1 rounded-lg">
                  <VInput
                    density="compact"
                    hide-details="true"
                    class="w-100 text-body-1 d-flex justify-center text-grey-800"
                    >{{ $t('structure.level.duration') }}</VInput
                  >
                </div>
              </VCol>
            </VRow>
          </VCol>
          <VCol
            cols="1"
            class="text-center d-flex justify-space-around align-center"
          >
            <span class="text-body-1 text-grey-800">{{ $t('structure.level.delete') }}</span>
          </VCol>
        </VRow>
        <!-- 1행 끝 -->

        <!-- level start -->
        <div class="flex m-10">
          <draggable class="dragArea list-group w-full" :list="structure.level" @change="levelUpdate">
            <div v-for="(item, index) in structure.level" :key="index">
              <v-row v-if="item.level === 0" class="align-center">
                <v-col cols="3" class="text-center hover-move">
                  <div
                    class="bg-warning py-2 w-75 pr-3 d-flex justify-center align-center"
                  >
                    <v-icon class="mr-2" icon="mdi-pause" size="35" />
                    <span>Break</span>
                  </div>
                </v-col>
                <v-col cols="8" class="d-flex justify-space-between">
                  <div class="w-100 text-center bg-warning rounded-lg py-3">
                    <v-text-field
                      center-affix="true"
                      hide-details="true"
                      density="compact"
                      variant="plain"
                      class="text-center centered centered-input"
                      v-model="item.duration"
                      @blur="validateDuration(item, index)"
                    />
                  </div>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-icon
                    icon="mdi-trash-can-outline"
                    class="text-btn"
                    size="35"
                    @click="clickRemoveLevel(index)"
                  />
                </v-col>
              </v-row>
              <v-row v-else class="align-center">
                <v-col cols="3" class="text-center hover-move">
                  <div
                    class="bg-primary py-2 w-75 pr-3 d-flex justify-center align-center"
                  >
                    <v-icon class="mr-2" icon="mdi-play" size="35" />
                    <span>Level {{ item.level }}</span>
                  </div>
                </v-col>
                <v-col cols="8">
                  <VRow>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.smallBlind"
                        />
                      </div>

                      <!-- oninput="javascript: this.value = this.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '' );"
                        type="number"
                        hide-details -->
                    </VCol>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.bigBlind"
                        />
                      </div>
                    </VCol>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.ante"
                        />
                      </div>
                    </VCol>
                    <VCol cols="3" class="">
                      <div
                        class="w-100 py-2 border border-primary border-opacity-100 rounded-lg"
                      >
                        <v-text-field
                          class="centered-input px-2"
                          hide-details="true"
                          density="compact"
                          variant="plain"
                          v-model="item.duration"
                          @blur="validateDuration(item, index)"
                        />
                      </div>
                      <!-- <span>{{ displayDuration(item.duration) }}</span> -->
                    </VCol>
                  </VRow>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-icon
                    icon="mdi-trash-can-outline"
                    size="35"
                    class="text-btn"
                    @click="clickRemoveLevel(index)"
                  />
                </v-col>
              </v-row>
            </div>
          </draggable>
        </div>

        <v-row class="mt-5">
          <v-col
            cols="6"
            class="text-center d-flex justify-center align-center"
          >
            <v-btn
              class="v-col v-col-6 px-5 btn text-center bg-primary rounded-lg py-2"
              @click="clickAddLevel"
              >{{ $t('structure.level.addLevel') }}</v-btn
            >
          </v-col>
          <v-col
            cols="6"
            class="text-center d-flex justify-center align-center"
          >
            <v-btn
              class="v-col v-col-6 px-5 btn text-center bg-warning rounded-lg py-2"
              @click="clickAddBreak"
              >{{ $t('structure.level.addBreak') }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- level end -->
      </VCol>
    </VCard>

    <!-- ckeditor5 -->
    <VCard class="pa-3 mt-4">
      <VRow class="mt-0">
        <div class="w-100 text-center">
          <span class="text-h5">{{ $t('structure.prize.title') }}</span>
        </div>
        <VCol>
          <ck-editor
            v-model="ckeditor.content"
            :editor="ckeditor.editor"
            :config="ckeditor.editorConfig"
          />
        </VCol>
      </VRow>
    </VCard>

    <!-- 타이머 디자인 -->
    <VCard class="pa-3 mt-4">
      <VCardTitle class="text-center">
        <h5 class="text-h5">{{ $t('timerDesign.title') }}</h5>
      </VCardTitle>
      <v-divider /><br>
      <v-container fluid>
        <v-row>
          <v-spacer />
          <!-- Display Board Preview -->
          <v-col cols="8" :style="{ width:'80vw', height:'83vh' }"
            class="d-flex flex-column justify-center"
          >
            <v-card
              :style="{ width:'100%', height:'100%' }"
              :image="structure.backgroundImageUrl"
            >
              <v-row :style="{ height:'100%' }">
                <!-- Board Left -->
                <v-col cols="3"
                  class="d-flex flex-column justify-space-between align-start"
                >
                  <!-- buffer -->
                  <div class="d-flex flex-column justify-center align-center"></div>

                  <!-- Prize content -->
                  <div class="d-flex flex-column justify-center align-center">
                    <p v-html="ckeditor.content" style="font-size:2vh"></p>
                  </div>
                  <div class="align-start">
                    <!-- Control Buttons -->
                    <div
                      :class="{
                        'control-button-normal'  : !boardPreview.sections[7].selected,
                        'control-button-selected': boardPreview.sections[7].selected
                      }"
                    >
                      <v-icon
                        :style="{ 'color': boardPreview.sections[7].color }"
                        class="text-btn"
                        size="5vh"
                        icon="mdi-play-box-outline"
                      />
                      <v-icon
                        :style="{ 'color': boardPreview.sections[7].color }"
                        class="text-btn"
                        size="5vh"
                        icon="mdi-pause"
                      />
                      <v-icon
                        :style="{ 'color': boardPreview.sections[7].color }"
                        class="text-btn"
                        size="5vh"
                        icon="mdi-refresh"
                      />
                      <v-icon
                        :style="{ 'color': boardPreview.sections[7].color }"
                        class="text-btn"
                        size="5vh"
                        icon="mdi-microsoft-xbox-controller"
                      />
                    </div>

                    <!-- Holdix Logo -->
                    <div class="d-flex justify-center align-center">
                      <VImg
                        :src="require(`@/assets/images/pages/goldmoon.png`)"
                        :width="80"
                      ></VImg>
                    </div>
                  </div>
                </v-col>

                <!-- Board Center -->
                <v-col cols="6"
                  class="d-flex flex-column justify-space-between align-center"
                >
                  <v-row>
                    <v-col>
                      <!-- Game/Structure Name -->
                      <div
                        :class="{
                          'center-main-title-normal'  : !boardPreview.sections[1].selected,
                          'center-main-title-selected': boardPreview.sections[1].selected
                        }"
                        :style="{ 'color': boardPreview.sections[1].color }"
                      >
                        <span class="whitespace-nowrap" style="font-size:5vh;">{{ displayGameTitleOnPreview }}</span>
                        <span class="whitespace-nowrap" style="font-size:3vh;">{{ displayStructureTitleOnPreview }}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <!-- Timer -->
                      <div
                        :class="{
                          'center-main-timer-normal'  : !boardPreview.sections[2].selected,
                          'center-main-timer-selected': boardPreview.sections[2].selected
                        }"
                        :style="{ 'color': boardPreview.sections[2].color }"
                      >
                        <span class="whitespace-nowrap" style="font-size:15vh;">10:00</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <!-- Current Blinds/Ante -->
                      <div style="text-align:center">
                        <div class="d-inline-block">
                          <span
                            :class="{
                              'center-sub-title-normal'  : !boardPreview.sections[3].selected,
                              'center-sub-title-selected': boardPreview.sections[3].selected
                            }"
                            :style="{ 'fontSize':'4vh', 'color': boardPreview.sections[3].color }"
                          >BLINDS: </span>
                          <span
                            :class="{
                              'center-sub-value-normal'  : !boardPreview.sections[4].selected,
                              'center-sub-value-selected': boardPreview.sections[4].selected
                            }"
                            :style="{ 'fontSize':'4vh', 'color': boardPreview.sections[4].color }"
                          >&nbsp;10,000 / 20,000</span>
                        </div>
                      </div>
                      <div style="text-align:center">
                        <div class="d-inline-block">
                          <span
                            :class="{
                              'center-sub-title-normal'  : !boardPreview.sections[3].selected,
                              'center-sub-title-selected': boardPreview.sections[3].selected
                            }"
                            :style="{ 'fontSize':'4vh', 'color': boardPreview.sections[3].color }"
                          >ANTE: </span>
                          <span
                            :class="{
                              'center-sub-value-normal'  : !boardPreview.sections[4].selected,
                              'center-sub-value-selected': boardPreview.sections[4].selected
                            }"
                            :style="{ 'fontSize':'4vh', 'color': boardPreview.sections[4].color }"
                          >&nbsp;30,000</span>
                        </div>
                      </div>
                      <!-- Next Blinds/Ante -->
                      <div style="text-align:center">
                        <div class="d-inline-block">
                          <span
                            :class="{
                              'center-sub-title-normal'  : !boardPreview.sections[3].selected,
                              'center-sub-title-selected': boardPreview.sections[3].selected
                            }"
                            :style="{ 'fontSize':'2vh', 'color': boardPreview.sections[3].color }"
                          >NEXT BLINDS: </span>
                          <span
                            :class="{
                              'center-sub-value-normal'  : !boardPreview.sections[4].selected,
                              'center-sub-value-selected': boardPreview.sections[4].selected
                            }"
                            :style="{ 'fontSize':'2vh', 'color': boardPreview.sections[4].color }"
                          >&nbsp;10,000 / 20,000</span>
                          <span
                            :class="{
                              'center-sub-title-normal'  : !boardPreview.sections[3].selected,
                              'center-sub-title-selected': boardPreview.sections[3].selected
                            }"
                            :style="{ 'fontSize':'2vh', 'color': boardPreview.sections[3].color }"
                          >&nbsp;&nbsp;ANTE: </span>
                          <span
                            :class="{
                              'center-sub-value-normal'  : !boardPreview.sections[4].selected,
                              'center-sub-value-selected': boardPreview.sections[4].selected
                            }"
                            :style="{ 'fontSize':'2vh', 'color': boardPreview.sections[4].color }"
                          >&nbsp;30,000</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <!-- Control Panel -->
                  <!-- <v-row>
                    <v-col>
                      <div class="d-flex justify-space-between">
                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">Player</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">Buy In + E</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">Buy In</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">1st</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">2nd</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">3rd</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">Add On</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column justify-center">
                          <div class="text-center">
                            <span class="whitespace-nowrap" style="font-size:0.9vh;">Lv</span>
                          </div>
                          <div class="d-flex justify-center">
                            <VIcon
                              class="text-btn"
                              icon="mdi-minus-box-outline"
                              size="0.9vh"
                            />
                            <VIcon
                              class="text-btn"
                              icon="mdi-plus-box-outline"
                              size="0.9vh"
                            />
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row> -->

                </v-col>

                <!-- Board Right -->
                <v-col cols="3"
                  class="d-flex flex-column justify-space-between align-end"
                >
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          'right-side-sub-title-normal'  : !boardPreview.sections[5].selected,
                          'right-side-sub-title-selected': boardPreview.sections[5].selected
                        }"
                        :style="{ 'color': boardPreview.sections[5].color }"
                      >
                        <span style="font-size:3vh;">LEVEL 1</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          'right-side-sub-title-normal'  : !boardPreview.sections[5].selected,
                          'right-side-sub-title-selected': boardPreview.sections[5].selected
                        }"
                        :style="{ 'color': boardPreview.sections[5].color }"
                      >
                        <span style="font-size:2.5vh;">TOTAL TIME : </span>
                      </div>
                      <div
                        :class="{
                          'right-side-sub-value-normal'  : !boardPreview.sections[6].selected,
                          'right-side-sub-value-selected': boardPreview.sections[6].selected
                        }"
                        :style="{ 'color': boardPreview.sections[6].color }"
                      >
                        <span style="font-size:2vh;">01:23:45</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          'right-side-sub-title-normal'  : !boardPreview.sections[5].selected,
                          'right-side-sub-title-selected': boardPreview.sections[5].selected
                        }"
                        :style="{ 'color': boardPreview.sections[5].color }"
                      >
                        <span style="font-size:2.5vh;">TOTAL CHIPS : </span>
                      </div>
                      <div
                        :class="{
                          'right-side-sub-value-normal'  : !boardPreview.sections[6].selected,
                          'right-side-sub-value-selected': boardPreview.sections[6].selected
                        }"
                        :style="{ 'color': boardPreview.sections[6].color }"
                      >
                        <span style="font-size:2vh;">300,000</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          'right-side-sub-title-normal'  : !boardPreview.sections[5].selected,
                          'right-side-sub-title-selected': boardPreview.sections[5].selected
                        }"
                        :style="{ 'color': boardPreview.sections[5].color }"
                      >
                        <span style="font-size:2.5vh;">AVG STACKS : </span>
                      </div>
                      <div
                        :class="{
                          'right-side-sub-value-normal'  : !boardPreview.sections[6].selected,
                          'right-side-sub-value-selected': boardPreview.sections[6].selected
                        }"
                        :style="{ 'color': boardPreview.sections[6].color }"
                      >
                        <span style="font-size:2vh;">15,000</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          'right-side-sub-title-normal'  : !boardPreview.sections[5].selected,
                          'right-side-sub-title-selected': boardPreview.sections[5].selected
                        }"
                        :style="{ 'color': boardPreview.sections[5].color }"
                      >
                        <span style="font-size:2.5vh;">PLAYER : </span>
                      </div>
                      <div
                        :class="{
                          'right-side-sub-value-normal'  : !boardPreview.sections[6].selected,
                          'right-side-sub-value-selected': boardPreview.sections[6].selected
                        }"
                        :style="{ 'color': boardPreview.sections[6].color }"
                      >
                        <span style="font-size:2vh;">20 / 20</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div
                        :class="{
                          'right-side-sub-title-normal'  : !boardPreview.sections[5].selected,
                          'right-side-sub-title-selected': boardPreview.sections[5].selected
                        }"
                        :style="{ 'color': boardPreview.sections[5].color }"
                      >
                        <span style="font-size:2.5vh;">NEXT BREAK : </span>
                      </div>
                      <div
                        :class="{
                          'right-side-sub-value-normal'  : !boardPreview.sections[6].selected,
                          'right-side-sub-value-selected': boardPreview.sections[6].selected
                        }"
                        :style="{ 'color': boardPreview.sections[6].color }"
                      >
                        <span style="font-size:2vh;">10:00</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-spacer />
          <!-- Color Palette -->
          <v-col cols="3" :style="{ width:'20vw', height:'83vh' }">
            <v-card :style="{ width:'100%', height:'100%' }">
              <v-row>
                <v-col>
                  <span>{{ $t('timerDesign.selectSection') }}</span>
                  <v-radio-group v-model="boardPreview.sectionNo">
                    <v-row>
                      <v-col cols="6">
                        <v-radio :label="$i18n.t('timerDesign.preview')"    :value=0 />
                        <v-radio :label="$i18n.t('timerDesign.section1')"   :value=1 />
                        <v-radio :label="$i18n.t('timerDesign.section2')"   :value=2 />
                        <v-radio :label="$i18n.t('timerDesign.section3')"   :value=3 />
                      </v-col>
                      <v-col cols="6">
                        <v-radio :label="$i18n.t('timerDesign.button')"     :value=7 />
                        <v-radio :label="$i18n.t('timerDesign.section4')"   :value=4 />
                        <v-radio :label="$i18n.t('timerDesign.section5')"   :value=5 />
                        <v-radio :label="$i18n.t('timerDesign.section6')"   :value=6 />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>{{ $t('timerDesign.selectColor') }}</span>
                  <v-color-picker
                    class="ma-2"
                    style="box-shadow: none"
                    mode="hexa"
                    canvas-height="100"
                    :show-swatches="!boardPreview.isPaletteDisabled"
                    :swatches="boardPreview.swatches"
                    swatches-max-height="100px"
                    rounded
                    hide-inputs
                    :model-value="boardPreview.sections[boardPreview.sectionNo].color"
                    @update:model-value="updateColorPicker"
                  >
                  </v-color-picker>
                </v-col>
              </v-row>
              <v-row>
                <VCol class="hover">
                  <div
                    class="rounded my-0 py-2 btn my-md-0 bg-primary pa-0 text-center"
                    @click="clickModifyTimerDesign"
                  >
                    <VIcon size="30" icon="mdi-content-save-outline" />
                    <span class="text-md ml-2">{{ $t('timerDesign.save') }}</span>
                  </div>
                </VCol>
              </v-row>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </VCard>

  </div>


  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />

  <!-- Popup -->
  <Popup
    v-show="popup.show"
    :popup-props="popup.props"
    @popup-event="handlePopupEvent"
  />

  <!-- Attachment Popup -->
  <AttachmentPopup
    v-show="attachment.show"
    :attach-popup-props="attachment.props"
    @popup-event="handleAttachPopupEvent"
  />

  <!-- BgImage Popup -->
  <!-- <BgImagePopup ref="bgImagePopup" v-show="bgImage.show" :bg-popup-props="bgImage.props" @popup-event="handleBgImagePopupEvent"/> -->
</template>

<script>
import Alert from "@/components/AlertDefault";
import Popup from "@/components/PopupDefault";
import AttachmentPopup from "@/components/PopupAttachment";
// import BgImagePopup from "@/components/PopupBgImage";

// CKEditor5
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: "GameCreate",
  components: {
    Alert,
    Popup,
    AttachmentPopup,
    // BgImagePopup,
    "ck-editor": CKEditor.component,
    draggable: VueDraggableNext,
  },
  data() {
    return {
      game: {
        id: "",
        name: "",
      },
      store: {
        structures: {
          selected: "",
          items: [
            /* {
                id: '',
                name: ''
              } */
          ],
        },
      },
      structure: {
        master: {
          id: "",
          name: "",
          toggleName: false,
          buyInAmount: 0,
          registrationDeadline: {
            selected: 0,
            items: [],
          },
          prize: "",
          entryLimitFlag: 1,
          entryLimit: 1,
        },
        value: {
          earlyBuyIn: 0,
          buyIn: 0,
          firstReBuyIn: 0,
          secondReBuyIn: 0,
          thirdReBuyIn: 0,
          addOn: 0,
          insetSwitch: {
            firstReBuyIn: true,
            secondReBuyIn: false,
            thirdReBuyIn: false,
            addOn: false,
            disabled: {
              secondReBuyIn: false,
              thirdReBuyIn: true,
            },
          },
        },
        level: [],
        backgroundImageName: "",
        backgroundImageUrl: null,
      },
      url: {
        structureList: "/api/structure/list",
        structureInfo: "/api/structure/info",
        structure: "/api/structure",
        structureAttachment: "/api/structure-attachment",
        structureBoardDesign: "/api/structure/board-design",
        game: "/api/game",
        /* App I/F */
        send: "/api/structure/send",
      },
      popup: {
        show: false,
        owner: "", // open popup call function name
        props: {
          title: "",
          contents: "",
        },
      },
      alert: {
        msg: "",
        type: "success",
      },
      attachment: {
        show: false,
        props: {
          structureId: "",
        },
      },
      // bgImage: {
      //   show: false,
      //   props: {
      //     storeId: '',
      //     structureId: '',
      //     backgroundImageName: ''
      //   }
      // },

      /* CKEditor */
      ckeditor: {
        content:
          '<p>&nbsp;&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><h2>&nbsp; &nbsp; &nbsp;&nbsp;<span style="color:hsl(60,75%,60%);"><strong>HOLDIX</strong></span></h2><p>&nbsp;</p><p>&nbsp;</p><p><span style="color:hsl(30,75%,60%);"><strong>&nbsp; &nbsp;HOLDIX 새틀라이트</strong></span></p><p><span style="color:rgb(241,196,15);"><strong>&nbsp;3엔트리당 시드권 1장</strong></span></p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; LEVEL12 레지마감</strong></span></p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; &nbsp; &nbsp;(BREAK3까지)&nbsp;</strong></span></p><p><strong>&nbsp; &nbsp; &nbsp; &nbsp;</strong><span style="color:rgb(224,62,45);"><strong>리바인제한 2번</strong></span></p><p>&nbsp;</p><p>&nbsp;</p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; &nbsp;1등 : 시드권 장</strong></span></p><p><span style="color:rgb(236,240,241);"><strong>&nbsp; &nbsp; &nbsp;2등 : 시드권 장</strong></span></p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p><span style="color:hsl(0,0%,90%);"><i><strong>*CHIPS*</strong></i></span></p><p><span style="color:hsl(0,0%,90%);"><strong>45,000/50,000/60,000</strong></span></p><p><span style="color:hsl(0,0%,90%);"><strong>*BREAK1 까지 얼리칩 +5,000</strong></span></p>',
        editor: ClassicEditor,
        editorConfig: {
          fontSize: {
            options: [10,20,25,30,35,40,45,50,60,70],
          },
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "strikethrough",
              "underline",
              "|",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "highlight",
              "|",
              "link",
              "specialCharacters",
              "|",
              "undo",
              "redo",
              "|",
              "|",
            ],
          },
        },
      },

      /* 타이머 디자인 */
      boardPreview: {
        swatches: [
          // 빨남
          ['#FF0000', '#0005FF',],
          // 주보
          ['#FF3200', '#6400FF',],
          // 노흰
          ['#FFFF00', '#FFFFFF',],
          // 초회
          ['#00FF00', '#3C3C3C',],
          // 파검
          ['#0000FF', '#000000',],
        ],
        sectionNo: 0,
        sections: [
          { element: 'preview', selected: true, color: '#FFFFFF' },
          { element: 'center-main-title', selected: false, color: '#FFFFFF' },
          { element: 'center-main-timer', selected: false, color: '#FFFFFF' },
          { element: 'center-sub-title', selected: false, color: '#FFFFFF' },
          { element: 'center-sub-value', selected: false, color: '#FFFFFF' },
          { element: 'right-side-sub-title', selected: false, color: '#FFFFFF' },
          { element: 'right-side-sub-value', selected: false, color: '#FFFFFF' },
          { element: 'control-button', selected: false, color: '#FFFFFF' },
        ],
        isPaletteDisabled: false,
      },

      role: '',
    };
  },
  created() {
    // structure 리스트 조회
    this.readStructureList();

    // for role base button control
    this.role = this.$store.getters.role;
  },
  beforeUnmount() {
    // 컴포넌트가 파기되기 전에 Blob URL을 해제합니다.
    if (this.structure.backgroundImageUrl) {
      URL.revokeObjectURL(this.structure.backgroundImageUrl);
    }
  },
  watch: {
    async "store.structures.selected"() {
      await this.readStructureInfo();
      this.fetchBackgroundImageUrl();
    },
    "structure.level": {
      deep: true,
      immediate: true,
      handler() {
        this.calcRegistrationDeadline();
      },
    },
    "boardPreview.sectionNo"(newVal, oldVal) {
      this.boardPreview.sections[oldVal].selected = false;
      this.boardPreview.sections[newVal].selected = true;
      // 미리보기는 팔레트 동작 안하게
      if(newVal === 0){
        this.boardPreview.isPaletteDisabled = true;
      }else{
        this.boardPreview.isPaletteDisabled = false;
      }
    }
  },
  computed: {
    displayGameTitleOnPreview() {
      if(this.game.name != null
      && this.game.name != undefined
      && this.game.name != ""){
        return this.game.name;
      }else{
        return this.$i18n.t('timerDesign.displayGameTitle');
      }
    },
    displayStructureTitleOnPreview() {
      if(this.structure.master.name != null
      && this.structure.master.name != undefined
      && this.structure.master.name != ""){
        return this.structure.master.name;
      }else{
        return this.$i18n.t('timerDesign.displayStructureTitle');
      }
    },
  },
  methods: {
    clickCreateGame() {
      this.openPopup(
        this.$i18n.t('game.create'),
        this.$i18n.t('message.game.create.confirm'),
        "createGame"
      );
    },
    createGame() {
      this.$axios
        .post(this.url.game, {
          // 게임명을 입력하지 않으면 스트럭처명으로 생성한다
          name: this.game.name || this.structure.master.name,
          structureId: this.structure.master.id,
          structureName: this.structure.master.name,
          createUserId: this.$store.getters.id,
          updateUserId: this.$store.getters.id,
          prizeContent: this.ckeditor.content,
          levelSeq: this.structure.level[0].seq,
          level: this.structure.level[0].level,
          remainLevelTime: Number(this.structure.level[0].duration) * 60,
          remainRegTime: Number(this.structure.level[0].remainRegTime) * 60,
          nextBreakTime: Number(this.structure.level[0].nextBreak) * 60
        })
        .then((res) => {
          console.log(res.data);
          this.openAlert(
            this.$i18n.t('message.game.create.success'),
            "success"
          );

          setTimeout(() => {
            this.$router.push({
              name: "GameList",
            });
          }, 1500);

          /* // TODO 저장이 완료되었습니다. -> 게임 컨트롤러 화면으로 이동(GameVO 보내줌)
          let game = res.data;
          this.$router.push({
            name: "GameController",
            params: { id: game.id },
          }); */
        })
        .catch((error) => {
          console.log(error);
          this.openAlert(
            this.$i18n.t('message.game.create.fail'),
            "error"
          );
        });
    },
    clickCreateStructure() {
      this.openPopup(
        this.$i18n.t('structure.create'),
        this.$i18n.t('message.structure.create.confirm'),
        "moveToCreateStructure"
      );
    },
    moveToCreateStructure() {
      this.$router.push({
        name: "StructureCreate",
        params: { id: this.store.id },
      });
    },
    clickRemoveStructure() {
      // TODO structure 하나 남았을때 삭제할 경우 삭제하고 스트럭처 생성 화면으로 이동
      // TODO 해당 스트럭처 사용한 게임정보 전부 삭제
      this.openPopup(
        this.$i18n.t('structure.delete'),
        "'" + this.structure.master.name + "' " + this.$i18n.t('message.structure.delete.confirm'),
        "removeStructure"
      );
    },
    removeStructure() {
      this.$axios
        .delete(this.url.structure, { data: this.structure.master.id })
        .then((res) => {
          if (res.data.code === 1) {
            this.openAlert(this.$i18n.t('message.structure.delete.success'), "success");
          } else if(res.data.code === -1) {
            this.openAlert('스트럭쳐가 삭제 되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          } else {
            this.openAlert(this.$i18n.t('message.structure.delete.fail')+'(' + res.data.message + ')', "error");
          }
          this.readStructureList();
        })
        .catch((error) => {
          console.log(error);
          this.openAlert(this.$i18n.t('message.structure.delete.fail'), "error");
        });
    },
    clickModifyStructure() {
      this.openPopup(
        this.$i18n.t('structure.save'),
        "'" + this.structure.master.name + "' " + this.$i18n.t('message.structure.save.confirm'),
        "modifyStructure"
      );
    },
    modifyStructure() {
      const registrationDeadlineSeq =
        this.structure.master.registrationDeadline.selected;
      let registrationDeadlineLevel = Number(
        this.structure.level[registrationDeadlineSeq].level
      );
      // seq, level 이 둘다 0이면 첫번째 레벨이 레지마감이므로 그냥 둔다.
      if (registrationDeadlineLevel === 0 && registrationDeadlineSeq != 0) {
        // 아니면 고른 레지마감레벨이 휴식시간 전이므로 seq에서 1을 빼준다.
        registrationDeadlineLevel = Number(
          this.structure.level[registrationDeadlineSeq - 1].level
        );
      }

      let designList = [];
      for(let i=0; i<this.boardPreview.sections.length; i++){
        designList.push({
          id: this.structure.master.id,
          sectionNo: i,
          color: this.boardPreview.sections[i].color
        })
      }

      const param = {
        id: this.structure.master.id,
        name: this.structure.master.name,
        storeId: this.$store.getters.storeId,
        buyInAmount: Number(this.structure.master.buyInAmount),
        registrationDeadline: registrationDeadlineSeq,
        registrationDeadlineLevel: registrationDeadlineLevel,
        prize: this.structure.master.prize,
        entryLimit: Number(this.structure.master.entryLimit),
        entryLimitFlag: Number(this.structure.master.entryLimitFlag),
        earlyBuyIn: Number(this.structure.value.earlyBuyIn),
        buyIn: Number(this.structure.value.buyIn),
        firstReBuyIn: Number(this.structure.value.firstReBuyIn),
        secondReBuyIn: Number(this.structure.value.secondReBuyIn),
        thirdReBuyIn: Number(this.structure.value.thirdReBuyIn),
        addOn: Number(this.structure.value.addOn),
        levelList: this.structure.level,
        prizeContent: this.ckeditor.content,
        updateUserId: this.$store.getters.id,
        designList: designList,
      };
      this.$axios
        .put(this.url.structure, param)
        .then((res) => {
          if (res.data.code === 1) {
            this.openAlert(this.$i18n.t('message.structure.save.success'), "success");
            this.structure.master.toggleName = false;
            this.readStructureList(this.structure.master.id);
          } else if(res.data.code === -1) {
            this.openAlert('스트럭쳐가 저장 되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
            this.structure.master.toggleName = false;
            this.readStructureList(this.structure.master.id);
          } else {
            this.openAlert(this.$i18n.t('message.structure.save.fail')+'(' + res.data.message + ')', "error");
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert(this.$i18n.t('message.structure.save.fail'), "error");
        });
    },

    /* Popup */
    openPopup(title, contents, owner) {
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
    },
    closePopup() {
      this.popup.owner = "";
      this.togglePopup();
    },
    togglePopup() {
      this.popup.show = !this.popup.show;
    },
    handlePopupEvent(isConfirm) {
      if (isConfirm) {
        if (this.popup.owner === "createGame") {
          this.createGame();
        } else if (this.popup.owner === "moveToCreateStructure") {
          this.moveToCreateStructure();
        } else if (this.popup.owner === "removeStructure") {
          this.removeStructure();
        } else if (this.popup.owner === "modifyStructure") {
          this.modifyStructure();
        } else if (this.popup.owner === "modifyTimerDesign") {
          this.modifyTimerDesign();
        } else {
          console.log("Undefined Popup owner.");
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },

    /* Alert */
    openAlert(msg, type) {
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    calcRegistrationDeadline() {
      let item = [];
      const levels = this.structure.level;
      for (let i = 0; i < this.structure.level.length; i++) {
        if (levels[i].level === 0 && i != 0) {
          item[item.length - 1].seq++;
        } else {
          item.push({ seq: i, level: levels[i].level });
        }
      }
      this.structure.master.registrationDeadline.items = item;
    },
    toggleFirstReBuyIn() {
      this.structure.value.insetSwitch.firstReBuyIn =
        !this.structure.value.insetSwitch.firstReBuyIn;
      this.structure.value.firstReBuyIn = 0;
      if (this.structure.value.insetSwitch.firstReBuyIn) {
        this.structure.master.entryLimit = 1;

        this.structure.value.insetSwitch.disabled.secondReBuyIn = false;
      } else {
        this.structure.master.entryLimit = 0;

        this.structure.value.insetSwitch.secondReBuyIn = false;
        this.structure.value.insetSwitch.disabled.secondReBuyIn = true;
        this.structure.value.secondReBuyIn = 0;

        this.structure.value.insetSwitch.thirdReBuyIn = false;
        this.structure.value.insetSwitch.disabled.thirdReBuyIn = true;
        this.structure.value.thirdReBuyIn = 0;
      }
    },
    toggleSecondReBuyIn() {
      this.structure.value.insetSwitch.secondReBuyIn =
        !this.structure.value.insetSwitch.secondReBuyIn;
      this.structure.value.secondReBuyIn = 0;
      if (this.structure.value.insetSwitch.secondReBuyIn) {
        this.structure.master.entryLimit = 2;

        this.structure.value.insetSwitch.disabled.thirdReBuyIn = false;
      } else {
        this.structure.master.entryLimit = 1;

        this.structure.value.insetSwitch.thirdReBuyIn = false;
        this.structure.value.insetSwitch.disabled.thirdReBuyIn = true;
        this.structure.value.thirdReBuyIn = 0;
      }
    },
    toggleThirdReBuyIn() {
      this.structure.value.insetSwitch.thirdReBuyIn =
        !this.structure.value.insetSwitch.thirdReBuyIn;
      this.structure.value.thirdReBuyIn = 0;

      if (this.structure.value.insetSwitch.thirdReBuyIn) {
        this.structure.master.entryLimit = 3;
      } else {
        this.structure.master.entryLimit = 2;
      }
    },
    toggleAddOn() {
      this.structure.value.insetSwitch.addOn =
        !this.structure.value.insetSwitch.addOn;
      this.structure.value.addOn = 0;
    },
    clickAddLevel() {
      // level 최고값 계산
      let level = this.structure.level;
      let levelLength = this.structure.level.length;
      let nextLevel = 1;
      if (level != null && levelLength != 0) {
        for (let i = levelLength - 1; i >= 0; i--) {
          if (level[i].level != 0) {
            nextLevel = level[i].level + 1;
            break;
          }
        }
      }
      // 추가할 레벨 정보
      const levelInfo = {
        // seq: levelLength,
        level: nextLevel,
        smallBlind: 100,
        bigBlind: 200,
        ante: 300,
        duration: 10,
      };
      this.structure.level.push(levelInfo);
    },
    clickAddBreak() {
      // let levelLength = this.structure.level.length;
      const breakInfo = {
        // seq: levelLength,
        level: 0,
        smallBlind: 0,
        bigBlind: 0,
        ante: 0,
        duration: 10,
      };
      this.structure.level.push(breakInfo);
    },
    clickRemoveLevel(index) {
      if (this.structure.level[index].level === 0) {
        // Break 삭제
        this.structure.level.splice(index, 1);
      } else {
        // Level 삭제
        this.structure.level.splice(index, 1);
        // Break 제외한 Level 재정렬
        for (let i = index; i < this.structure.level.length; i++) {
          if (this.structure.level[i].level != 0) {
            this.structure.level[i].level -= 1;
          }
        }
      }
    },
    /* APIs */
    readStructureList(structureId) {
      this.$axios
        .post(this.url.structureList, {
          storeId: this.$store.getters.storeId,
        })
        .then((res) => {
          if (res.data.length === 0) {
            // TODO 스트럭처가 하나도 없으면 스트럭처 생성 화면으로 이동
          } else {
            this.store.structures.items = res.data;
            if(structureId){
              this.store.structures.selected = structureId;
            }else{
              // 첫번째 structure로 세팅
              this.store.structures.selected = res.data[0].id;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    readStructureInfo() {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.structureInfo, {
            id: this.store.structures.selected,
          })
          .then((res) => {
            /* Master */
            this.structure.master.id = res.data.id;
            this.structure.master.name = res.data.name;
            this.structure.master.buyInAmount = res.data.buyInAmount;
            this.structure.master.registrationDeadline.selected =
              res.data.registrationDeadline;
            this.structure.master.prize = res.data.prize;
            this.structure.master.entryLimit = res.data.entryLimit;
            this.structure.master.entryLimitFlag = res.data.entryLimitFlag;
            if (res.data.entryLimit === 1) {
              this.structure.value.insetSwitch.firstReBuyIn = true;
              this.structure.value.insetSwitch.secondReBuyIn = false;
              this.structure.value.insetSwitch.thirdReBuyIn = false;
              this.structure.value.insetSwitch.disabled.secondReBuyIn = false;
              this.structure.value.insetSwitch.disabled.thirdReBuyIn = true;
            } else if (res.data.entryLimit === 2) {
              this.structure.value.insetSwitch.firstReBuyIn = true;
              this.structure.value.insetSwitch.secondReBuyIn = true;
              this.structure.value.insetSwitch.thirdReBuyIn = false;
              this.structure.value.insetSwitch.disabled.secondReBuyIn = false;
              this.structure.value.insetSwitch.disabled.thirdReBuyIn = false;
            } else if (res.data.entryLimit >= 3) {
              this.structure.value.insetSwitch.firstReBuyIn = true;
              this.structure.value.insetSwitch.secondReBuyIn = true;
              this.structure.value.insetSwitch.thirdReBuyIn = true;
              this.structure.value.insetSwitch.disabled.secondReBuyIn = false;
              this.structure.value.insetSwitch.disabled.thirdReBuyIn = false;
            }
            /* Value */
            this.structure.value.earlyBuyIn = res.data.earlyBuyIn;
            this.structure.value.buyIn = res.data.buyIn;
            this.structure.value.firstReBuyIn = res.data.firstReBuyIn;
            this.structure.value.secondReBuyIn = res.data.secondReBuyIn;
            this.structure.value.thirdReBuyIn = res.data.thirdReBuyIn;
            this.structure.value.addOn = res.data.addOn;
            /* Level */
            this.structure.level = res.data.levelList;
            /* Background Image */
            this.structure.backgroundImageName = res.data.backgroundImageName;
            /* Prize Content */
            this.ckeditor.content = res.data.prizeContent;
            /* Display Board Design */
            this.boardPreview.sectionNo = 0;
            this.boardPreview.sections = [
              { element: 'preview', selected: true,  color: '#FFFFFF' },
              { element: 'center-main-title', selected: false, color: '#FFFFFF' },
              { element: 'center-main-timer', selected: false, color: '#FFFFFF' },
              { element: 'center-sub-title', selected: false, color: '#FFFFFF' },
              { element: 'center-sub-value', selected: false, color: '#FFFFFF' },
              { element: 'right-side-sub-title', selected: false, color: '#FFFFFF' },
              { element: 'right-side-sub-value', selected: false, color: '#FFFFFF' },
              { element: 'control-button', selected: false, color: '#FFFFFF' },
            ];
            this.boardPreview.isPaletteDisabled = false;

            const designList = res.data.designList;
            if(designList.length != 0){
              for(let i=0; i<designList.length; i++){
                this.boardPreview.sections[i].color = designList[i].color;
              }
            }

            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    /* App I/F */
    sendRegisterStructure(){
      // TODO modifyStructure 와 중복
      const registrationDeadlineSeq =
        this.structure.master.registrationDeadline.selected;
      let registrationDeadlineLevel = Number(
        this.structure.level[registrationDeadlineSeq].level
      );
      // seq, level 이 둘다 0이면 첫번째 레벨이 레지마감이므로 그냥 둔다.
      if (registrationDeadlineLevel === 0 && registrationDeadlineSeq != 0) {
        // 아니면 고른 레지마감레벨이 휴식시간 전이므로 seq에서 1을 빼준다.
        registrationDeadlineLevel = Number(
          this.structure.level[registrationDeadlineSeq - 1].level
        );
      }

      const param = {
        id: this.structure.master.id,
        name: this.structure.master.name,
        buyInAmount: Number(this.structure.master.buyInAmount),
        registrationDeadlineLevel: registrationDeadlineLevel,
        prize: this.structure.master.prize,
        entryLimit: Number(this.structure.master.entryLimit),
        entryLimitFlag: Number(this.structure.master.entryLimitFlag),
        earlyBuyIn: Number(this.structure.value.earlyBuyIn),
        buyIn: Number(this.structure.value.buyIn),
        firstReBuyIn: Number(this.structure.value.firstReBuyIn),
        secondReBuyIn: Number(this.structure.value.secondReBuyIn),
        thirdReBuyIn: Number(this.structure.value.thirdReBuyIn),
        addOn: Number(this.structure.value.addOn),
        levelList: this.structure.level,
      };

      this.$axios
        .post(this.url.send, param)
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('스트럭처 데이터가 App에 전송되었습니다.', 'success');
          }else{
            this.openAlert('스트럭처 데이터 App 전송을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('스트럭처 데이터 App 전송을 실패하였습니다.', 'error');
        });
    },
    sendModifyStructure(){
      // TODO modifyStructure 와 중복
      const registrationDeadlineSeq =
        this.structure.master.registrationDeadline.selected;
      let registrationDeadlineLevel = Number(
        this.structure.level[registrationDeadlineSeq].level
      );
      // seq, level 이 둘다 0이면 첫번째 레벨이 레지마감이므로 그냥 둔다.
      if (registrationDeadlineLevel === 0 && registrationDeadlineSeq != 0) {
        // 아니면 고른 레지마감레벨이 휴식시간 전이므로 seq에서 1을 빼준다.
        registrationDeadlineLevel = Number(
          this.structure.level[registrationDeadlineSeq - 1].level
        );
      }

      const param = {
        id: this.structure.master.id,
        name: this.structure.master.name,
        buyInAmount: Number(this.structure.master.buyInAmount),
        registrationDeadlineLevel: registrationDeadlineLevel,
        prize: this.structure.master.prize,
        entryLimit: Number(this.structure.master.entryLimit),
        entryLimitFlag: Number(this.structure.master.entryLimitFlag),
        earlyBuyIn: Number(this.structure.value.earlyBuyIn),
        buyIn: Number(this.structure.value.buyIn),
        firstReBuyIn: Number(this.structure.value.firstReBuyIn),
        secondReBuyIn: Number(this.structure.value.secondReBuyIn),
        thirdReBuyIn: Number(this.structure.value.thirdReBuyIn),
        addOn: Number(this.structure.value.addOn),
        levelList: this.structure.level,
      };

      this.$axios
        .put(this.url.send, param)
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('스트럭처 데이터가 App에 전송되었습니다.', 'success');
          }else{
            this.openAlert('스트럭처 데이터 App 전송을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('스트럭처 데이터 App 전송을 실패하였습니다.', 'error');
        });
    },

    /* Attachment */
    clickOpenAttachmentPopup() {
      this.attachment.props.url = this.url.structureAttachment;
      this.attachment.props.structureId = this.structure.master.id;
      this.attachment.show = !this.attachment.show;
    },
    handleAttachPopupEvent() {
      this.attachment.props.structureId = "";
      this.attachment.show = !this.attachment.show;
    },

    /* Background Image */
    /* clickOpenBgImagePopup(){
      console.log('clickOpenBgImagePopup')
      console.log(this.structure.backgroundImageName)

      if(this.structure.backgroundImageName == null || this.structure.backgroundImageName === ''){
        alert('타이머 배경화면이 없습니다.');
        return;
      }
      this.bgImage.props.storeId = this.$store.getters.storeId;
      this.bgImage.props.structureId = this.structure.master.id;
      this.bgImage.props.backgroundImageName = this.structure.backgroundImageName;
      this.bgImage.show = !this.bgImage.show;
      this.$nextTick(() => {
        this.$refs.bgImagePopup.open();
      })
    },
    handleBgImagePopupEvent(){
      this.bgImage.props.structureId = '';
      this.bgImage.show = !this.bgImage.show;
    }, */

    /* Display board preview */
    updateColorPicker(newVal){
      this.boardPreview.sections[this.boardPreview.sectionNo].color = newVal;
    },
    clickModifyTimerDesign() {
      this.openPopup(
        this.$i18n.t('timerDesign.save'),
        "'" + this.structure.master.name + "' " + this.$i18n.t('message.timerDesign.save.confirm'),
        "modifyTimerDesign"
      );
    },
    modifyTimerDesign(){
      let designList = [];
      for(let i=0; i<this.boardPreview.sections.length; i++){
        designList.push({
          id: this.structure.master.id,
          sectionNo: i,
          color: this.boardPreview.sections[i].color
        })
      }

      this.$axios
        .put(this.url.structureBoardDesign, designList)
        .then((res) => {
          if (res.data === 1) {
            this.openAlert(this.$i18n.t('message.timerDesign.save.success'), "success");
          } else {
            this.openAlert(this.$i18n.t('message.timerDesign.save.fail'), "error");
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert(this.$i18n.t('message.timerDesign.save.fail'), "error");
        });
    },
    fetchBackgroundImageUrl() {
      // storeid structureid filename
      // this.$axios.get('/api/structure-attachment/04ea2bb9-d237-11ed-b7ba-309c23e6ab85/77f9857f-430d-4330-80e9-93fc3fe55b51/display_board_bg.png', { responseType: 'blob' })

      let filename = this.structure.backgroundImageName;
      if (this.structure.backgroundImageName == null || this.structure.backgroundImageName == "") {
        filename = "default";
      }

      let url =
        this.url.structureAttachment +
        "/" +
        this.$store.getters.storeId +
        "/" +
        this.structure.master.id +
        "/" +
        filename;

      this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          // Blob을 이용하여 원하는 동작 수행
          this.structure.backgroundImageUrl = URL.createObjectURL(blob);
        })
        .catch((error) => {
          console.error("파일 다운로드 에러:", error);
        });
    },
    levelUpdate(){
      const levels = this.structure.level;
      let levelCount = 1;
      for(let i=0; i<levels.length; i++){
        if(levels[i].level != 0){
          levels[i].level = levelCount++
        }
      }
    },

    // Validation
    validateDuration(item, index){
      const numericPattern = /^[0-9]+$/;
      const inputDuration = item.duration;
      if(!numericPattern.test(inputDuration) || inputDuration < 1 || inputDuration > 60){
        alert('지속시간(분) 항목에는 1 ~ 60 사이의 숫자만 입력가능합니다.');
        this.structure.level[index].duration = 12;
      }
    }
  },
};
</script>
<style scoped>
.input {
  font-size: 24px;
}
.input::placeholder {
  font-size: 24px;
}
.w-35 {
  width: 30%;
}
#textField {
  font-size: 24px;
}
.hover :hover {
  cursor: pointer;
}
.hover-move :hover {
  cursor: move;
}
.btn {
  vertical-align: middle;
}
.v-field__input {
  padding-top: 2px;
}
.centered-input >>> input {
  text-align: center;
}
.right-input >>> input {
  text-align: right;
}

.centered-input :deep() input {
  text-align: center;
}

.centered-input :deep() input[type="number"] {
  -moz-appearance: textfield;
}
.centered-input :deep() input::-webkit-outer-spin-button,
.centered-input :deep() input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Display board preview */
/* Section 1 : div */
.center-main-title-normal {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: center; /* align-center */
  white-space: nowrap; /* whitespace-nowrap */
}
.center-main-title-selected {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: center; /* align-center */
  white-space: nowrap;
  font-weight: bold;
  border-style: solid;
  border-color: #FF0000;
}
/* Section 2 : div */
.center-main-timer-normal {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: center; /* align-center */
  white-space: nowrap; /* whitespace-nowrap */
}
.center-main-timer-selected {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: center; /* align-center */
  white-space: nowrap;
  font-weight: bold;
  border-style: solid;
  border-color: #FF0000;
}
/* Section 3 : span */
.center-sub-title-normal {
  /* display: inline-block; */
  white-space: nowrap; /* whitespace-nowrap */
}
.center-sub-title-selected {
  border: 3px solid #FF0000;
  /* display: inline-block; */
  white-space: nowrap; /* whitespace-nowrap */
}
/* Section 4 : span */
.center-sub-value-normal {
  /* display: inline-block; */
  white-space: nowrap; /* whitespace-nowrap */
}
.center-sub-value-selected {
  border: 3px solid #FF0000;
  /* display: inline-block; */
  white-space: nowrap; /* whitespace-nowrap */
}
/* Section 5 : div */
.right-side-sub-title-normal {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: right; /* align-right */
  white-space: nowrap; /* whitespace-nowrap */
}
.right-side-sub-title-selected {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: right; /* align-right */
  white-space: nowrap;
  font-weight: bold;
  border-style: solid;
  border-color: #FF0000;
}
/* Section 6 : div */
.right-side-sub-value-normal {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: right; /* align-right */
  white-space: nowrap; /* whitespace-nowrap */
}
.right-side-sub-value-selected {
  display: flex; /* d-flex */
  flex-direction: column; /* flex-column */
  text-align: right; /* align-right */
  white-space: nowrap;
  font-weight: bold;
  border-style: solid;
  border-color: #FF0000;
}
/* Section 7 : div */
.control-button-normal {
  display: flex; /* d-flex */
  justify: center;
}
.control-button-selected {
  display: flex; /* d-flex */
  justify: center;
  font-weight: bold;
  border-style: solid;
  border-color: #FF0000;
}
</style>
