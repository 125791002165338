<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-title>
        <div>
          <span class="text-h5">타이머 리스트</span>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <div class="pa-3">
          <VRow align="center">
            <VCol cols="4" sm="6" md="2" class="px-1">
              <VSelect
                active
                variant="outlined"
                hide-details
                density="compact"
                label="주소1"
                v-model="searchCondition.address1"
                :items="code.address1"
                item-title="name"
                item-value="id"
              />
            </VCol>
            <VCol cols="4" sm="6" md="2" class="px-1">
              <VSelect
                active
                variant="outlined"
                hide-details
                density="compact"
                label="주소2"
                v-model="searchCondition.address2"
                :items="code.address2"
                item-title="name"
                item-value="id"
              />
            </VCol>
            <VCol cols="4" sm="6" md="2" class="px-1">
              <VSelect
                active
                variant="outlined"
                hide-details
                density="compact"
                label="주소3"
                v-model="searchCondition.address3"
                :items="code.address3"
                item-title="name"
                item-value="id"
              />
            </VCol>

            <VCol cols="8" class="px-1" sm="6" md="4">
              <VSelect
                active
                variant="outlined"
                hide-details
                density="compact"
                label="게임상태"
                v-model="searchCondition.gameStatus"
                :items="code.gameStatus"
                item-title="name"
                item-value="id"
              />
              <!-- <VCombobox
                active
                variant="outlined"
                hide-details
                density="compact"
                label="게임상태"
                v-model="searchCondition.gameStatusArray"
                :items="code.gameStatus"
                multiple
                chips
              /> -->
            </VCol>
            <VCol cols="4" sm="12" md="2" class="px-1">
              <VBtn
                color="primary"
                class="w-100 btn"
                @click.stop="searchTimerList"
              >
                검색
              </VBtn>
            </VCol>
          </VRow>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <VCard class="py-3 mt-3 bg-background">
    <VExpansionPanels>
      <VExpansionPanel
        class="mb-1"
        v-for="(item, index) in timers"
        :key="index"
        @click.stop="readTimerDetail(index, { item })"
      >
        <VExpansionPanelTitle class="pb-0">
          <div class="pa-0 w-100">
            <VRow class="pt-0">
              <!-- 좌측 사이드 -->
              <VCol cols="3" class="py-2 text-center pl-0 pr-4">
                <VRow
                  :class="
                    displayGameStatusBorderColor(item.game.master.gameStatus)
                  "
                >
                  <!-- 등록중 -->
                  <VCol
                    cols="12"
                    :class="
                      displayGameStatusBgColor(item.game.master.gameStatus)
                    "
                  >
                    {{ displayGameStatus(item.game.master.gameStatus) }}
                  </VCol>

                  <!-- 남은시간 -->
                  <!-- 등록중 : 남은 시간 카운트다운 -->
                  <!-- 예약중 : 남은 시간 정지상태 -->
                  <!-- 등록마감 : 레코드 자체를 표시하지 않음 -->
                  <VCol
                    cols="12"
                    :class="
                      displayGameStatusTextColor(item.game.master.gameStatus)
                    "
                  >
                    <span v-if="item.game.master.gameStatus != 'CLOSED'">등록마감 남은시간</span>
                  </VCol>

                  <!-- 시간 -->
                  <VCol cols="12" class="text-center py-0 text-sm">
                    <p class="">{{ displayRegDeadlineTime({ item }) }}</p>
                  </VCol>
                </VRow>
              </VCol>
              <!-- 우측 사이드 -->
              <VCol cols="9" class="py-2 text-center pl-3">
                <VRow class="pb-2">
                  <VCol cols="12" class="pa-0 py-md-3">
                    <span class="text-sm text-warning">
                      {{ item.game.master.name }}
                    </span>
                  </VCol>

                  <VCol
                    cols="5"
                    md="2"
                    class="pt-0 pb-1 px-0 text-center text-xs"
                  >
                    <span class="bg-var-theme-background px-1">매장명</span>
                  </VCol>
                  <!-- PC 화면 -->
                  <VCol
                    cols="7"
                    md="8"
                    class="py-0 px-0 d-none d-md-block text-center text-xs border-l text-no-wrap"
                  >
                    {{ item.structure.master.name }}
                  </VCol>
                  <!-- 모바일 화면 -->
                  <VCol
                    cols="7"
                    md="8"
                    class="py-0 px-0 d-md-none text-center text-xs border-l text-no-wrap"
                  >
                    <VIcon icon="mdi-account-group" size="22" />
                    <span class="ml-2 pt-1">
                      {{ item.game.player.current }}/{{
                        item.game.player.total
                      }}
                    </span>
                  </VCol>

                  <VCol cols="5" md="2" class="pa-0 text-center text-xs">
                    <p class="ma-0 text-btn">
                      {{ item.store.master.name }}
                    </p>
                  </VCol>

                  <VCol class="accordian-title largehidden pa-0 pa-md-2" md="2">
                    <!-- PC -->
                    <VRow class="d-none d-md-flex justify-center">
                      <VCol
                        cols="12"
                        class="ma-0 pb-0 text-xs d-none d-md-block"
                      >
                        <span>진행상황</span>
                      </VCol>
                      <VCol cols="12" class="ma-0 text-xs">
                        <span>{{
                          displayStatus(item.game.master.status)
                        }}</span>
                      </VCol>
                    </VRow>
                    <!-- 모바일 화면 -->
                    <div class="d-flex justify-center d-md-none">
                      <VCol cols="12" class="ma-0 text-xs py-0">
                        <span class="mr-2 text-btn">스트럭처</span>
                        <VIcon size="17" class="text-btn" icon="mdi-file" />
                      </VCol>
                    </div>
                  </VCol>

                  <VCol
                    class="accordian-title d-none d-md-block"
                    cols="12"
                    md="2"
                  >
                    <VRow class="d-flex justify-center">
                      <VCol cols="12" md="12" class="ma-0 pb-0 text-xs">
                        <span>바이인</span>
                      </VCol>
                      <VCol cols="12" md="12" class="ma-0 text-xs">
                        <span>{{
                          addCommasToNumber(item.structure.master.buyInAmount)
                        }}</span>
                      </VCol>
                    </VRow>
                  </VCol>
                  <!-- PC -->
                  <VCol cols="12" md="6" class="pa-0 d-none d-md-block" />
                  <!-- PC -->
                  <VCol
                    cols="12"
                    md="2"
                    class="text-right pr-6 accordian-title d-none d-md-block"
                  >
                    <div
                      cols="6"
                      md="12"
                      class="ma-0 text-xs d-flex justify-center items-center"
                    >
                      <VIcon icon="mdi-account-group" size="18" />
                      <p class="ma-0 ml-2">
                        {{ item.game.player.current }}/{{
                          item.game.player.total
                        }}
                      </p>
                    </div>
                  </VCol>
                </VRow>

                <!-- <span class="text-xs text-success"> {{ item.game.master.name }} </span> -->
                <!-- <span class="text-xs text-info"> {{ item.game.master.name }} </span> -->
                <!-- <span class="text-xs text-error"> {{ item.game.master.name }} </span> -->
              </VCol>
            </VRow>
            <VRow class="my-3 my-md-6"> </VRow>
          </div>
        </VExpansionPanelTitle>
        <VExpansionPanelText class="bg-var-theme-background py-3">
          <div class="pa-0 mt-3">
            <VRow>
              <!-- PC화면용  -->
              <VCol
                class="d-none d-md-flex flex-column justify-space-between"
                cols="3"
              >
                <VRow>
                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>바이인칩</span>
                  </VCol>

                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>{{
                      addCommasToNumber(timerDetail.structure.value.buyIn)
                    }}</span>
                  </VCol>
                  <VCol cols="4" />
                </VRow>
                <VRow>
                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>얼리 칩</span>
                  </VCol>

                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>{{
                      addCommasToNumber(
                        timerDetail.structure.value.earlyBuyIn -
                          timerDetail.structure.value.buyIn
                      )
                    }}</span>
                  </VCol>
                  <VCol cols="4" />
                </VRow>
                <VRow>
                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>리바인1 칩</span>
                  </VCol>

                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>{{
                      displayReBuyIn(
                        1,
                        timerDetail.structure.value.firstReBuyIn
                      )
                    }}</span>
                  </VCol>
                  <VCol cols="4" />
                </VRow>
                <VRow>
                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>리바인2 칩</span>
                  </VCol>

                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>{{
                      displayReBuyIn(
                        2,
                        timerDetail.structure.value.secondReBuyIn
                      )
                    }}</span>
                  </VCol>
                  <VCol cols="4" />
                </VRow>
                <VRow>
                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>리바인3 칩</span>
                  </VCol>

                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>{{
                      displayReBuyIn(
                        3,
                        timerDetail.structure.value.thirdReBuyIn
                      )
                    }}</span>
                  </VCol>
                  <VCol cols="4" />
                </VRow>
                <VRow>
                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>애드온</span>
                  </VCol>

                  <VCol cols="4" class="pa-0 text-center text-xs">
                    <span>{{
                      addCommasToNumber(timerDetail.structure.value.addOn)
                    }}</span>
                  </VCol>
                  <VCol cols="4" />
                </VRow>

                <VRow class="mt-4">
                  <VCol class="pa-0 text-center text-xs">
                    <p>엔트리 제한</p>
                  </VCol>

                  <VCol class="pa-0 text-center text-xs justify-center">
                    <span
                      v-if="timerDetail.structure.master.entryLimitFlag === 0"
                      label
                      color="info"
                      class="text-xs text-info"
                      >없음</span
                    >
                    <span v-else label color="error" class="text-xs text-error"
                      >있음</span
                    >
                    <p v-if="timerDetail.structure.master.entryLimitFlag != 0">
                      {{ timerDetail.structure.master.entryLimit }}회
                    </p>
                  </VCol>
                </VRow>
              </VCol>

              <VCol
                cols="12"
                md="5"
                class="py-0 px-2 text-center text-xs border-l"
              >
                <p class="mb-2 text-xs text-secondary">
                  {{ displayCurrentLevel }}
                </p>
                <div class="border border-black my-2 py-4">
                  <p class="text-h2 text-md-h1 font-bold mb-0">
                    {{
                      displayTimeFormat(
                        timerDetail.levels.current.remainLevelTime
                      )
                    }}
                  </p>
                </div>
                <div class="text-center">
                  <p class="mb-2">블라인드</p>
                  <p class="mb-2 text-2xl text-secondary">
                    {{ displayCurrentLevelInfo }}
                  </p>
                  <p class="mb-2">다음 블라인드</p>
                  <p class="mb-2 text-xs text-secondary">
                    {{ displayNextLevelInfo }}
                  </p>
                  <p class="mb-2 text-xs text-secondary">
                    다음 휴식
                    {{
                      displayTimeFormat(
                        timerDetail.levels.current.nextBreakTime
                      )
                    }}
                  </p>
                </div>
              </VCol>
              <!-- mobile 전용 -->
              <VRow class="d-md-none my-4 mx-1">
                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">바이인 칩</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">{{
                    addCommasToNumber(timerDetail.structure.value.buyIn)
                  }}</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-gray-100">얼리 칩</span></VCol
                >
                <VCol cols="2" class="py-0"></VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">{{
                    addCommasToNumber(
                      timerDetail.structure.value.earlyBuyIn -
                        timerDetail.structure.value.buyIn
                    )
                  }}</span></VCol
                >

                <!-- 줄바꿈 -->
                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">리바인 칩</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">{{
                    displayReBuyIn(1, timerDetail.structure.value.firstReBuyIn)
                  }}</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">{{
                    displayReBuyIn(2, timerDetail.structure.value.secondReBuyIn)
                  }}</span></VCol
                >
                <VCol cols="2" class="d-flex justify-center pa-0 align-center"
                  ><span class="text-xs text-secondary">{{
                    displayReBuyIn(3, timerDetail.structure.value.thirdReBuyIn)
                  }}</span>
                </VCol>
                <!-- 줄바꿈 -->
                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">애드온</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">{{
                    addCommasToNumber(timerDetail.structure.value.addOn)
                  }}</span>
                </VCol>
                <VCol cols="2" class="py-0"> </VCol>
                <VCol cols="2" class="py-0"></VCol>
                <VCol cols="2" class="py-0"> </VCol>
                <!-- 줄바꿈 -->

                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">엔트리 제한</span>
                </VCol>
                <!-- <VCol class="pa-0 text-center text-xs justify-center">
                  <span v-if="timerDetail.structure.master.entryLimitFlag === 0" label color="info" class="text-xs text-info">없음</span>
                  <span v-else label color="error" class="text-xs text-error">있음</span>
                  <p>{{ timerDetail.structure.master.entryLimit }}회</p>
                </VCol> -->
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span
                    v-if="timerDetail.structure.master.entryLimitFlag === 0"
                    label
                    color="info"
                    class="text-xs text-info"
                    >없음</span
                  >
                  <span v-else label color="error" class="text-xs text-error">
                    있음
                  </span>
                </VCol>

                <VCol v-if="timerDetail.structure.master.entryLimitFlag === 0" cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-gray-100">
                    Lv.{{ timerDetail.structure.master.registrationDeadlineLevel }}
                  </span>
                </VCol>
                <VCol v-if="timerDetail.structure.master.entryLimitFlag === 0" cols="2" class="d-flex justify-center pa-0 align-center">
                </VCol>

                <VCol v-if="timerDetail.structure.master.entryLimitFlag != 0" cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">
                    {{ timerDetail.structure.master.entryLimit }}회
                  </span>
                </VCol>
                <VCol v-if="timerDetail.structure.master.entryLimitFlag != 0" cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-gray-100">
                    Lv.{{ timerDetail.structure.master.registrationDeadlineLevel }}
                  </span>
                </VCol>

                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">듀레이션</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">
                    {{
                      displayTimeFormat(
                        timerDetail.structure.master
                          .representativeDurationInSecond
                      )
                    }}
                  </span>
                </VCol>
                <VCol cols="2" class="py-0"></VCol>

                <VCol cols="4" class="d-flex justify-center pa-0 align-center">
                  <VChip label color="warning" size="small">
                    ₩
                    {{
                      addCommasToNumber(
                        timerDetail.structure.master.buyInAmount
                      )
                    }}
                  </VChip></VCol
                >

                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">전체 칩</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center"
                  ><span class="text-xs text-secondary">{{
                    addCommasToNumber(timerDetail.game.stack.totalChips)
                  }}</span>
                </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-gray-100">평균 칩</span></VCol
                >
                <VCol cols="2" class="py-0"> </VCol>
                <VCol cols="2" class="d-flex justify-center pa-0 align-center">
                  <span class="text-xs text-secondary">{{
                    addCommasToNumber(timerDetail.game.stack.avgStacks)
                  }}</span></VCol
                >

                <VCol cols="4" class="text-left py-0">
                  <span class="text-xs text-gray-100">PRIZE</span>
                </VCol>
                <VCol cols="8" class="text-right">
                  <span class="text-xs text-secondary">{{
                    timerDetail.structure.master.prize
                  }}</span>
                </VCol>
              </VRow>

              <!-- PC 전용 -->
              <VCol cols="12" md="4" class="d-none d-md-block">
                <div class="border d-flex w-100 py-4">
                  <div class="text-center w-100 text-xs">
                    <span>블라인드 시간</span>
                  </div>
                  <div class="text-center w-100 text-xs">
                    <span>{{
                      displayTimeFormat(
                        timerDetail.levels.current.durationInSeconds
                      )
                    }}</span>
                  </div>
                </div>
                <div class="border w-100 py-4 align-center d-flex flex-column">
                  <div
                    class="w-50 text-xs d-flex justify-space-evenly text-center border-b mb-1"
                  >
                    <span class="w-100">전체 칩</span>
                    <span class="w-100">{{
                      addCommasToNumber(timerDetail.game.stack.totalChips)
                    }}</span>
                  </div>
                  <div
                    class="w-50 text-xs d-flex justify-space-evenly text-center border-b mb-1"
                  >
                    <span class="w-100">평균 칩</span>
                    <span class="w-100">{{
                      addCommasToNumber(timerDetail.game.stack.avgStacks)
                    }}</span>
                  </div>
                </div>
                <div class="border text-xs text-center">
                  <span>시상</span>
                </div>
                <div class="border text-lg text-center pa-3">
                  <span>{{ timerDetail.structure.master.prize }}</span>
                </div>
              </VCol>
              <!-- <VCol cols="6" class="d-flex justify-center">
                <VBtn
                  ripple
                  rounded="lg"
                  color="primary"
                  density="default"
                  class="btn"
                >
                  예약하기
                </VBtn>
              </VCol>
              <VCol cols="6" class="d-flex justify-center">
                <VBtn
                  ripple
                  rounded="lg"
                  color="primary"
                  density="default"
                  class="btn"
                >
                  명단 보기
                </VBtn>
              </VCol> -->
            </VRow>
          </div>
        </VExpansionPanelText>
      </VExpansionPanel>
    </VExpansionPanels>
  </VCard>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Seoul");

const minutesToSecond = 60;

export default {
  name: "TimerList",
  data() {
    return {
      code: {
        address1: [{ id: "", name: "전체" }],
        address2: [{ id: "", name: "전체" }],
        address3: [{ id: "", name: "전체" }],
        gameStatus: [
          { id: "", name: "전체" }, // ALL
          { id: "OPEN", name: "등록중" }, // OPEN
          { id: "READY", name: "예약중" }, // READY
        ],
      },
      searchCondition: {
        address1: "",
        address2: "",
        address3: "",
        // OPEN, READY, CLOSED
        gameStatus: "",
      },
      timers: [
        /*
        // dummy
        {
          game: {
            master: {
              id: "3b34cd8a-d76d-4786-a4f8-b3ef38e7c495",
              name: "zcxv",
              status: "PAUSED",
              gameStatus: "OPEN",
            },
            player: { current: 0, total: 0 },
            log: { level: 0, totalTime: 0, actionDate: "2023-06-26 17:01:20" },
          },
          structure: {
            master: {
              id: "35f09a5a-53d7-4dbe-bd5f-1b428df063d2",
              name: "매장1-템플릿1",
              buyInAmount: 10000,
              regDeadlineTimeInSeconds: 1800,
            },
          },
          store: {
            master: {
              id: "04ea2bb9-d237-11ed-b7ba-309c23e6ab85",
              name: "Goldmoon 성서점",
            },
          },
          regDeadlineCountdown: 0,
        },
        {
          game: {
            master: {
              id: "3b34cd8a-d76d-4786-a4f8-b3ef38e7c495",
              name: "zcxv",
              status: "PAUSED",
              gameStatus: "CLOSED",
            },
            player: { current: 0, total: 0 },
            log: { level: 0, totalTime: 0, actionDate: "2023-06-26 17:01:20" },
          },
          structure: {
            master: {
              id: "35f09a5a-53d7-4dbe-bd5f-1b428df063d2",
              name: "매장1-템플릿1",
              buyInAmount: 10000,
              regDeadlineTimeInSeconds: 1800,
            },
          },
          store: {
            master: {
              id: "04ea2bb9-d237-11ed-b7ba-309c23e6ab85",
              name: "Goldmoon 성서점",
            },
          },
          regDeadlineCountdown: 0,
        }, */
        /*
        game: {
          master: {
            id: '',
            name: '',
            status: '',
            gameStatus: ''
          },
          player: {
            current: 0,
            total: 0
          },
          log: {
            totalTime: 0,
            actionDate: null
          }
        },
        structure: {
          master:{
            id: '',
            name: '',
            buyInAmount: 0,
            regDeadlineTimeInSeconds: 0
          }
        },
        store: {
          master:{
            id: '',
            name: ''
          }
        }
        */
      ],

      timerDetail: {
        game: {
          master: {
            id: "",
            status: "",
          },
          stack: {
            totalChips: 0,
            avgStacks: 0,
          },
        },
        structure: {
          master: {
            id: "",
            buyInAmount: 0,
            entryLimit: 0,
            entryLimitFlag: 0,
            registrationDeadlineLevel: 0,
            prize: "",
            representativeDurationInSecond: 0,
          },
          value: {
            earlyBuyIn: 0,
            buyIn: 0,
            firstReBuyIn: 0,
            secondReBuyIn: 0,
            thirdReBuyIn: 0,
            addOn: 0,
          },
        },
        levels: {
          current: {
            level: 0,
            smallBlind: 0,
            bigBlind: 0,
            ante: 0,
            durationInSeconds: 0,
            remainLevelTime: 0,
            nextBreakTime: 0,
          },
          next: {
            level: 0,
            smallBlind: 0,
            bigBlind: 0,
            ante: 0,
          },
        },
      },
      url: {
        address1: "/api/code/address/tier1",
        address2: "/api/code/address/tier2",
        address3: "/api/code/address/tier3",
        timerList: "/api/timer-list",
        timerDetail: "/api/timer-list/detail",
      },
      timer: null,
      expand: {
        index: -1,
        open: false,
      },
    };
  },
  created() {
    /* this.readTimerList();

    // readTimerList 완료 후 수행 - 타임아웃 너무 짧으면 안됨
    setTimeout(() => {
      this.runTimer();
    }, 500); */

    this.searchTimerList();
    this.loadAddress1();
  },
  beforeUnmount() {
    this.stopTimer();
  },
  computed: {
    displayCurrentLevel() {
      if (this.timerDetail.levels.current.level === 0) {
        return "BREAK";
      } else {
        return "LEVEL " + this.timerDetail.levels.current.level;
      }
    },
    displayCurrentLevelInfo() {
      if (this.timerDetail.levels.current.level === 0) {
        return "BREAK";
      } else {
        return (
          this.addCommasToNumber(this.timerDetail.levels.current.smallBlind) +
          " / " +
          this.addCommasToNumber(this.timerDetail.levels.current.bigBlind) +
          " (A " +
          this.addCommasToNumber(this.timerDetail.levels.current.ante) +
          ")"
        );
      }
    },
    displayNextLevelInfo() {
      if (this.timerDetail.levels.next.level === -1) {
        return "END GAME";
      } else if (this.timerDetail.levels.next.level === 0) {
        return "BREAK";
      } else {
        return (
          this.addCommasToNumber(this.timerDetail.levels.next.smallBlind) +
          " / " +
          this.addCommasToNumber(this.timerDetail.levels.next.bigBlind) +
          " (A " +
          this.addCommasToNumber(this.timerDetail.levels.next.ante) +
          ")"
        );
      }
    },
  },
  watch: {
    "searchCondition.address1"() {
      this.searchCondition.address2 = "";
      if (this.searchCondition.address1 === "") {
        this.resetAddress2();
        this.resetAddress3();
      } else {
        this.loadAddress2();
      }
    },
    "searchCondition.address2"() {
      this.searchCondition.address3 = "";
      if (this.searchCondition.address2 === "") {
        this.resetAddress3();
      } else {
        this.loadAddress3();
      }
    },
  },
  methods: {
    loadAddress1() {
      this.$axios
        .post(this.url.address1, {
          parentId: "N/A",
          tier: 1,
        })
        .then((res) => {
          let address1 = [{ id: "", name: "전체" }];
          const addresses = res.data;
          addresses.forEach((address) => {
            address1.push({ id: address.id, name: address.name });
          });
          this.code.address1 = address1;
        });
    },
    loadAddress2() {
      this.$axios
        .post(this.url.address2, {
          parentId: this.searchCondition.address1,
          tier: 2,
        })
        .then((res) => {
          let address2 = [{ id: "", name: "전체" }];
          const addresses = res.data;
          addresses.forEach((address) => {
            address2.push({ id: address.id, name: address.name });
          });
          this.code.address2 = address2;
        });
    },
    resetAddress2() {
      console.log("resetAddress2");
      this.code.address2 = [{ id: "", name: "전체" }];
    },
    loadAddress3() {
      this.$axios
        .post(this.url.address3, {
          parentId: this.searchCondition.address2,
          tier: 3,
        })
        .then((res) => {
          let address3 = [{ id: "", name: "전체" }];
          const addresses = res.data;
          addresses.forEach((address) => {
            address3.push({ id: address.id, name: address.name });
          });
          this.code.address3 = address3;
        });
    },
    resetAddress3() {
      console.log("resetAddress3");
      this.code.address3 = [{ id: "", name: "전체" }];
    },
    /* Buttons */
    async searchTimerList() {
      await this.readTimerList();
      this.stopTimer();
      this.runTimer();
    },
    /* APIs */
    readTimerList() {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.timerList, {
            address1: this.searchCondition.address1,
            address2: this.searchCondition.address2,
            address3: this.searchCondition.address3,
            // OPEN, READY, CLOSED
            gameStatus: this.searchCondition.gameStatus,
          })
          .then((res) => {
            this.timers = [];
            const timerList = res.data;
            timerList.forEach((timer) => {
              this.timers.push({
                game: {
                  master: {
                    id: timer.id,
                    name: timer.name,
                    status: timer.status,
                    gameStatus: timer.gameStatus,
                  },
                  player: {
                    current: timer.currentPlayer,
                    total: timer.totalPlayer,
                  },
                  log: {
                    level: timer.level,
                    totalTime: timer.totalTime,
                    actionDate: dayjs(timer.actionDate)
                      .tz()
                      .format("YYYY-MM-DD HH:mm:ss"),
                  },
                },
                structure: {
                  master: {
                    id: timer.structureId,
                    name: timer.structureName,
                    buyInAmount: timer.buyInAmount,
                    regDeadlineTimeInSeconds:
                      Number(timer.regDeadlineTime) * minutesToSecond,
                  },
                },
                store: {
                  master: {
                    id: timer.storeId,
                    name: timer.storeName,
                  },
                },
                // for timer
                regDeadlineCountdown: 0,
              });
            });

            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    readTimerDetail(index, { item }) {
      // expansion panel 열때만 액션

      if (this.expand.open && this.expand.index === index) {
        // 같은 인덱스 누르면 닫히는걸로 간주하여 api 호출하지 않음
        this.expand.open = !this.expand.open;
        return;
      }
      this.expand.open = true;
      this.expand.index = index;

      this.$axios
        .post(this.url.timerDetail, {
          id: item.game.master.id,
        })
        .then((res) => {
          const detail = res.data;
          let actionDateWithTimezone = dayjs(detail.actionDate).tz().format("YYYY-MM-DD HH:mm:ss");
          this.timerDetail = {
            game: {
              master: {
                id: detail.id,
                status: detail.status,
              },
              stack: {
                totalChips: detail.totalChips,
                avgStacks: detail.avgStacks,
              },
              log: {
                actionDate: actionDateWithTimezone,
              },
            },
            structure: {
              master: {
                id: detail.structureId,
                buyInAmount: detail.buyInAmount,
                entryLimit: detail.entryLimit,
                entryLimitFlag: detail.entryLimitFlag,
                registrationDeadlineLevel: detail.registrationDeadlineLevel,
                prize: detail.prize,
                representativeDurationInSecond:
                  detail.representativeDuration * minutesToSecond, // 1레벨의 듀레이션을 대표 듀레이션으로 함
              },
              value: {
                earlyBuyIn: detail.earlyBuyIn,
                buyIn: detail.buyIn,
                firstReBuyIn: detail.firstReBuyIn,
                secondReBuyIn: detail.secondReBuyIn,
                thirdReBuyIn: detail.thirdReBuyIn,
                addOn: detail.addOn,
              },
            },
            levels: {
              current: {
                level: detail.currentLevel.level,
                smallBlind: detail.currentLevel.smallBlind,
                bigBlind: detail.currentLevel.bigBlind,
                ante: detail.currentLevel.ante,
                durationInSeconds:
                  Number(detail.currentLevel.duration) * minutesToSecond,
                remainLevelTime: this.calcRemainLevelTime(
                  detail.status,
                  detail.remainLevelTime,
                  actionDateWithTimezone
                ),
                nextBreakTime: this.calcNextBreakTime(
                  detail.status,
                  detail.nextBreakTime,
                  actionDateWithTimezone
                ),
              },
              next: {
                level: detail.nextLevel.level,
                smallBlind: detail.nextLevel.smallBlind,
                bigBlind: detail.nextLevel.bigBlind,
                ante: detail.nextLevel.ante,
              },
            },
          };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calcRemainLevelTime(status, remainLevelTime, actionDateWithTimezone) {
      if (remainLevelTime === 0) {
        return 0;
      }

      if (status === "PLAYING") {
        let result =
          remainLevelTime - dayjs().tz().diff(actionDateWithTimezone, "second");
        if (result < 0) {
          // 레벨이 넘어갔는데 전광판이 꺼져있으면 시간이 - 값이 되므로 그럴때는 0으로 표기한다.
          result = 0;
        }
        return result;
      } else {
        return remainLevelTime;
      }
    },
    calcNextBreakTime(status, nextBreakTime, actionDateWithTimezone) {
      if (nextBreakTime === 0) {
        return 0;
      }

      if (status === "PLAYING") {
        let result =
          nextBreakTime - dayjs().tz().diff(actionDateWithTimezone, "second");
        if (result < 0) {
          // 레벨이 넘어갔는데 전광판이 꺼져있으면 시간이 - 값이 되므로 그럴때는 0으로 표기한다.
          result = 0;
        }
        return result;
      } else {
        return nextBreakTime;
      }
    },

    /* Display */
    addCommasToNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    displayGameStatusBorderColor(gameStatus) {
      if (gameStatus === "OPEN") {
        // 등록중
        return "border-success border border-opacity-100 pb-2";
      } else if (gameStatus === "READY") {
        // 예약중
        return "border-warning border border-opacity-100 pb-2";
      } else if (gameStatus === "CLOSED") {
        // 등록마감
        return "border-error border border-opacity-100 pb-2";
      } else {
        return "border-error border border-opacity-100 pb-2";
      }
    },
    displayGameStatusBgColor(gameStatus) {
      if (gameStatus === "OPEN") {
        // 등록중
        return "bg-success pa-0 text-white text-sm py-md-3";
      } else if (gameStatus === "READY") {
        // 예약중
        return "bg-warning pa-0 text-white text-sm py-md-3";
      } else if (gameStatus === "CLOSED") {
        // 등록마감
        return "bg-error pa-0 text-white text-sm py-md-3";
      } else {
        return "bg-error pa-0 text-white text-sm py-md-3";
      }
    },
    displayGameStatusTextColor(gameStatus) {
      if (gameStatus === "OPEN") {
        // 등록중
        // return "text-success py-2 px-0";
        return "text-success py-2 px-0 center text-center text-xs";
      } else if (gameStatus === "READY") {
        // 예약중
        return "text-warning py-2 px-0 center text-center text-xs";
      } else if (gameStatus === "CLOSED") {
        // 등록마감
        return "text-error py-2 px-0 center text-center text-xs";
      } else {
        return "text-error py-2 px-0 center text-center text-xs";
      }
    },
    displayGameStatus(gameStatus) {
      if (gameStatus === "OPEN") {
        return "등록중";
      } else if (gameStatus === "READY") {
        return "예약중";
      } else if (gameStatus === "CLOSED") {
        return "등록마감";
      } else {
        return "Undefined";
      }
    },
    displayRegDeadlineTime({ item }) {
      if (item.game.master.gameStatus === "OPEN") {
        if (item.regDeadlineCountdown < 0) {
          // 레벨이 넘어갔는데 전광판이 꺼져있으면 시간이 - 값이 되므로 그럴때는 0으로 표기한다.
          return this.displayTimeFormat(0);
        } else {
          return this.displayTimeFormat(item.regDeadlineCountdown);
        }
      } else if (item.game.master.gameStatus === "READY") {
        return this.displayTimeFormat(item.regDeadlineCountdown);
      } else if (item.game.master.gameStatus === "CLOSED") {
        if (item.game.log.level === 0) {
          return "Break";
        } else {
          return "Level " + item.game.log.level;
        }
      } else {
        return 0;
      }
    },
    displayStatus(status) {
      if (status === "READY") {
        return "게임 시작 전";
      } else if (status === "PLAYING") {
        return "게임 중";
      } else if (status === "PAUSED") {
        return "일시정지";
      } else {
        return "Undefined";
      }
    },
    displayTimeFormat(sec) {
      const hours = Math.floor(sec / (60 * 60));
      const minutes = Math.floor((sec % (60 * 60)) / 60);
      const seconds = sec % 60;
      return (
        (hours > 0 ? (hours < 10 ? "0" + hours : hours) : "") +
        (hours > 0 ? ":" : "") +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
    displayReBuyIn(count, value) {
      // count : firstReBuyIn=1, secondReBuyIn=2, thirdReBuyIn=3
      if (this.timerDetail.structure.master.entryLimit < count) {
        return "-";
      } else {
        return this.addCommasToNumber(value);
      }
    },

    /* Timer */
    runTimer() {
      // 현재시간 기준으로 등록마감까지 남은 시간 계산
      for (let i = 0; i < this.timers.length; i++) {
        if (this.timers[i].game.master.gameStatus === "OPEN") {
          if (this.timers[i].game.master.status === "PLAYING") {
            // 남은시간(s) = 등록마감까지 총시간 - (총 게임시간 + 마지막 액션부터 흐른시간(= 현재시간-마지막 액션 시간))
            this.timers[i].regDeadlineCountdown =
              this.timers[i].structure.master.regDeadlineTimeInSeconds -
              (this.timers[i].game.log.totalTime +
                dayjs()
                  .tz()
                  .diff(this.timers[i].game.log.actionDate, "second"));
          } else if (this.timers[i].game.master.status === "PAUSED") {
            // 남은시간(s) = 등록마감까지 총시간 - 총 게임시간
            this.timers[i].regDeadlineCountdown =
              this.timers[i].structure.master.regDeadlineTimeInSeconds -
              this.timers[i].game.log.totalTime;
          }
        } else if (this.timers[i].game.master.gameStatus === "READY") {
          // 남은시간(s) = 등록마감까지 총시간 - 총 게임시간
          this.timers[i].regDeadlineCountdown =
            this.timers[i].structure.master.regDeadlineTimeInSeconds -
            this.timers[i].game.log.totalTime;
        }
      }

      this.$nextTick(() => {
        this.timer = setInterval(() => {
          // 등록마감시간 타이머
          for (let i = 0; i < this.timers.length; i++) {
            if (this.timers[i].game.master.status === "PLAYING") {
              if (this.timers[i].regDeadlineCountdown > 0) {
                this.timers[i].regDeadlineCountdown -= 1;
              }
            }
          }

          // 레벨남은시간, 휴식시간  타이머 : expandable panel이 open일때만 동작
          if (
            this.expand.open &&
            this.timerDetail.game.master.status === "PLAYING"
          ) {
            if (this.timerDetail.levels.current.remainLevelTime > 0) {
              this.timerDetail.levels.current.remainLevelTime -= 1;
            }
            if (this.timerDetail.levels.current.nextBreakTime > 0) {
              this.timerDetail.levels.current.nextBreakTime -= 1;
            }
          }
        }, 1000);
      });
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="scss">
.accordian-title {
  padding: 10px;
  padding-block-end: 12px;
  text-align: center;
}

@media (max-width: 960px) {
  .accordian-title {
    text-align: center;
  }

  @media (max-width: 960px) {
    .largehidden {
      display: "hidden";
    }
  }
}

.selector,
.v-select__selection-text {
  font-size: 12px;
  white-space: nowrap;
  vertical-align: center;
  padding-left: 4px;
  padding-right: 4px;
}
.selector,
.v-select__selection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selector,
.v-field__input {
  padding-left: 4px;
}
.selector,
.v-field-labe {
  margin-left: 4px;
}
.v-select__menu-icon {
  margin-left: 0;
}
.v-field,
.v-field--active {
  padding-right: 4px;
}

.v-expansion-panel-title__icon {
  text-shadow: 1px 1px 0px #363662, 3px 3px 2px #000000;
}
.v-expansion-panel-title__icon:active {
  text-shadow: 0px 0px 0px;
}
/*
.selector, .v-select-select {

}

.selector, .v-select-select option {
	padding: 4px;
	line-height: 12px;
	height: 12px;
	font-size: 12px;
} */
.selector,
.v-icon {
}
</style>
