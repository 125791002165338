<template>

    <VCard class="pa-1 mb-3">
        <VCardTitle class="">
            <VRow>
                <VCol cols="12">    
                    <h5 class="text-h5">시상 포인트</h5>
                </VCol>
                <VCol cols="12">
                    <v-range-slider thumb-size={0} hide-details="true" density="compact" color="primary"></v-range-slider>
                    <div class="d-flex justify-space-between">
                        <span class="text-sm">0%</span>
                        <span class="text-sm">50%</span>
                        <span class="text-sm">100%</span>
                    </div>
                </VCol>
                <VCol class="text-center" cols="12">
                    <span class="text-sm">게이지가 다 차고나서 3일 뒤 마갑됩니다.</span>
                </VCol>
            </VRow>
        </VCardTitle>
    </VCard>

    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-title>
                <div class="text-center w-100 d-flex justify-center align-center">
                    <span class="text-lg">상금 보기</span>
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <div class="d-flex flex-column align-center">
                    <div>
                        <span>1등 : 500만 포인트</span>
                    </div>
                    <div>
                        <span>2등 : 300만 포인트</span>
                    </div>
                     <VBtn
                        color="primary"
                        class="w-50 h-50 btn rounded my-2 pa-2 my-md-0 text-center"
                        
                    >
                        <VIcon start size="20" icon="mdi-content-save-all" />
                        저 장
                    </VBtn>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
         <v-expansion-panel>
            <v-expansion-panel-title>
                <div class="text-center w-100 d-flex justify-center align-center">
                    <span class="text-lg">순위 보기</span>
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
               <VTable class="text-no-wrap invoice-list-table bg-in">
        <!-- 👉 Table head -->
        <thead>
          <tr class="v-data-table tr">
            <!-- 👉 Check/Uncheck all checkbox -->
           
            <th  class="text-caption px-2 text-center" scope="col">
              순위
            </th>
           
            <th  class="text-caption px-0 text-center" scope="col">
              
            </th>

            <th class="text-caption px-2 text-center"  scope="col">
              닉네임
            </th>
            <th
              scope="col"
              class="text-center px-2 text-caption"
            >
              승점
            </th>
            <th scope="col" class="text-center px-2 text-caption">
              포인트(K)
            </th>
          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr v-for="i in 3" :key="i" class="bg-in t-body-tr">
            <!-- 👉 순위 -->
        
            <td class="text-center px-2 text-caption id">
                1
            </td>
            <!-- profile img -->
            <td class="text-center w-100 px-0 text-caption d-flex justify-center">
              <div class="d-flex justify-center">
                <VImg
                  :src="require('@/assets/images/logos/profile.png')"
                  width="15"
                  class="logo"
                />  
                </div>
              
            </td>

            <!-- 👉 Client Avatar and Email -->
            <td class="px-1">
              <div class="d-flex align-center justify-center">
                <div class="d-flex flex-column align-center">
                  <h6 class="text-sm font-weight-medium mb-0">
                    한글로여덟글자요
                  </h6>
                </div>
              </div>
            </td>

            <!-- 👉 total -->
            <td class="text-center px-2 text-caption">
              3,000
            </td>

            <!-- 👉 Date -->
            <td class="text-center px-2 text-caption">
              33,000
            </td>

            <!-- 👉 Balance -->
         
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
        
      </VTable>
      <VCardText class="d-flex flex-wrap align-center justify-end gap-4 pa-2">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center me-3"
        style="width: 81px;"
        >

          <VSelect
            density="compact"
            variant="outlined"
            hide-details="true"
            class="invoice-pagination-select text-sm"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <!-- 👉 Pagination and pagination meta -->
        <div class="d-flex align-center">

          <VPagination            
            size="small"
          />
        </div>
      </VCardText>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>

</template>

<script></script>

<style>
    .text-lg{
        font-size:17px;
    }
    .text-sm{
        font-size:12px;
    }
    .text-xs{
        font-size:10px;
    }

    .v-expansion-panel-text__wrapper{
        padding : 8px 4px;
    }
    .v-container .v-container--fluid .v-locale--is-ltr{
        padding : 0px;
    }
</style>