<template>
  <VContainer fluid>
    <VCardTitle class="mb-5">
      <h5 class="text-h5">회원정보 수정</h5>
    </VCardTitle>
    <VRow class="justify-center">
      <!-- 반복 Col -->
      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">아이디</span>
        </div>
        <div class="mb-2 w-100">
          <VTextField v-model="store.id" label="Id" />
        </div>
      </VCol>
      <VCol cols="12" md="8">
        <div
          class="w-100 mb-2 text-left d-flex justify-space-between align-center"
        >
          <span class="text-20">닉네임</span>
        </div>
        <div class="mb-2 w-100">
          <VTextField v-model="store.id" label="닉네임" />
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">비밀번호</span>
        </div>
        <div class="mb-2">
          <VTextField
            v-model="store.passWord"
            label="Password"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-inner-icon="
              isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
          />
        </div>
      </VCol>
      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">비밀번호 확인</span>
        </div>
        <div class="mb-2">
          <VTextField
            v-model="store.passWordConfirm"
            label="Password"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-inner-icon="
              isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
          />
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">전화번호 </span>
        </div>
        <div class="mb-2">
          <VTextField v-model="store.phone" label="전화번호" />
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div
          class="w-100 mb-2 text-left d-flex justify-space-between align-center"
        >
          <span class="text-20">로고 이미지 </span>
          <div>
            <VBtn
              class="ml-2 btn"
              ripple
              rounded="lg"
              color="primary"
              width="7%"
              density="default"
            >
              첨부
            </VBtn>
            <VBtn
              class="ml-2 btn"
              ripple
              rounded="lg"
              color="primary"
              width="7%"
              density="default"
            >
              삭제
            </VBtn>
          </div>
        </div>
        <div class="mb-2 w-100 d-flex justify-space-between align-center">
          <div class="w-100">
            <VTextField
              class="border-0"
              v-model="store.name"
              label="로고 이미지 파일"
            />
          </div>
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div class="w-100 d-md-flex justify-end align-center">
          <!-- <div class="col cols-12 w-md-25 cols-md-3">
            <VBtn
              class="btn"
              v-if="!editMode"
              rounded="lg"
              color="primary"
              @click.stop="toggleEditMode"
              width="100%"
              min-width="150px"
            >
              수정
            </VBtn>
          </div> -->
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="save"
              width="100%"
              min-width="150px"
            >
              저장
            </VBtn>
          </div>
          <!-- <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0">

            <VBtn
              class="btn"
              rounded="lg"
              color="secondary"
              link
              :to="path.list"
              width="100%"
              min-width="150px"
            >
              목록
            </VBtn>
          </div> -->
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import axios from "axios";

export default {
  name: "StoreDetail",
  data: () => ({
    /* Display Variables */
    editMode: false,

    /* Data Object */
    store: {
      id: "",
      name: "",
      gameType: "",
      address1: "",
      address2: "",
      adminId: "",
      comment: "",
      createUserId: "",
      createDate: "",
      updateUserId: "",
      updateDate: "",
    },

    path: {
      list: "/store-list",
    },
    url: {
      store: "/store",
    },
  }),
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    load() {
      axios
        .get(this.url.store + "?id=6432dabc-c725-11ed-9700-309c23e6ab85")
        .then((res) => {
          console.log(res.data);
        });
    },
    save() {
      axios
        .post(this.url.store, {
          name: this.store.name,
          gameType: this.store.gameType,
          address1: this.store.address1,
          address2: this.store.address2,
          adminId: this.store.adminId,
          comment: this.store.comment,
          createUserId: this.store.createUserId,
          updateUserId: this.store.updateUserId,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.text-20 {
  font-size: 20px;
}
</style>
