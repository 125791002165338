export const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "상금 획득 내역",
      backgroundColor: "#f87979",
      data: [40, 39, 10, 40, 39, 80, 40],
      fill: true,
      borderColor: "white",
      borderWidth: 1,
    },
  ],
};

export const options = {
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        boxWidth: 15,

        font: {
          size: 12,
        },
      },
      fullSize: true,
      align: "center",
    },
    tooltip: {
      boxWidth: 15,
      bodyFont: {
        size: 14,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        padding: 3,
      },
    },
    y: {
      grid: {
        drawBorder: false,
        color: "#808080",
        lineWidth: 1,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 1000,
  },
};
