<template>
    <div class="popup">
      <div class="popup-body">
          <VCard class="pa-3 popupContent">
          <VRow>
              <VCol cols="12">
              <span class="text-h5">{{ popupProps.title }}</span>
              </VCol>
              <VCol cols="12 border-t border-b border-opacity-30 border-secondary">
              <span class="text-body-1">{{ popupProps.contents }}</span>
              </VCol>
              <VCol cols="12">
              <VRow class="justify-center px-3">
                  <VCol cols="6" class="">
                  <VBtn
                      class="btn w-100 h-100 py-3 text-16"
                      color="success"
                      @click.stop="clickConfirm"
                  >
                      확인
                  </VBtn>
                  </VCol>
                  <VCol cols="6">
                  <VBtn
                      class="btn w-100 h-100 py-3 text-16"
                      color="error"
                      @click.stop="clickCancel"
                  >
                      취소
                  </VBtn>
                  </VCol>
              </VRow>
              </VCol>
          </VRow>
          </VCard>

      </div>
    </div>
</template>

<script>
export default {
  name: "PopupDefault",
  props: {
    popupProps: {
      type: Object,
      required: true
      /*
        title: '',
        contents: ''
      */
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clickConfirm(){
      this.$emit('popup-event', true);
    },
    clickCancel(){
      this.$emit('popup-event', false);
    }
  }
}
</script>

<style>
  .popupContent{
    min-width: 50%;
  }

  .popup{
    position: relative;
    z-index:9999;
  }
</style>
