<template>
  <VContainer fluid>
    <VCardTitle class="mb-5">
      <h5 class="text-h5">매장 정보</h5>
    </VCardTitle>
    <VRow class="justify-center">

      <VCol cols="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">업체명</span>
        </div>
        <div class="mb-2">
          <VTextField v-model="store.name" label="업체명" />
        </div>
      </VCol>

      <VCol cols="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">관리자 아이디</span>
        </div>
        <div class="mb-2 w-100">
          <!-- <VTextField v-model="store.adminId" label="Id" /> -->
          <!-- <v-autocomplete
            label="관리자 아이디"
            class="pa-0"
            density="compact"
            variant="filled"
            v-model="store.adminId"
            :items="user.items"
            item-title="name"
            item-value="id"
            clearable
            @input="onInputAdminId($event)"
          ></v-autocomplete> -->
          <v-autocomplete
            label="검색하려면 두 글자 이상 입력하세요."
            class="pa-0"
            density="compact"
            variant="filled"
            v-model="user.select"
            v-model:search="user.search"
            :loading="user.loading"
            :items="user.items"
            hide-no-data
            hide-details
            clearable
          ></v-autocomplete>
            <!-- hint="검색하려면 두 글자 이상 입력하세요." -->
        </div>
      </VCol>

      <VCol cols="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">주소 </span>
        </div>
        <div class="w-100 d-flex justify-space-between align-center">

          <v-col cols="4" class="pl-0">
            <v-autocomplete
              label="주소1"
              class="pa-0"
              density="compact"
              variant="filled"
              v-model="store.address1"
              :items="code.address1"
              item-title="name"
              item-value="id"
              clearable
            ></v-autocomplete>
          </v-col>

          <VCol cols="4">
            <v-autocomplete
              label="주소2"
              class="pa-0"
              density="compact"
              variant="filled"
              v-model="store.address2"
              :items="code.address2"
              item-title="name"
              item-value="id"
              clearable
            ></v-autocomplete>
          </VCol>
          <VCol cols="4" class="pr-0">
            <v-autocomplete
              label="주소3"
              class="pa-0"
              density="compact"
              variant="filled"
              v-model="store.address3"
              :items="code.address3"
              item-title="name"
              item-value="id"
              clearable
            ></v-autocomplete>
          </VCol>

          <!-- <VCol cols="4" class="pl-0">
            <VSelect
              class="pa-0"
              density="compact"
              hide-details="false"
              label="도"
              :items="[
                '경기도',
                '서울',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            />
          </VCol>
          <VCol cols="4">
            <VSelect
              class="pa-0"
              density="compact"
              hide-details="false"
              label="시"
              :items="[
                '수원시',
                '서울시',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            />
          </VCol>
          <VCol cols="4" class="pr-0">
            <VSelect
              class="pa-0"
              density="compact"
              hide-details="false"
              label="구"
              :items="[
                '팔달구',
                '장안구',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            />
          </VCol> -->
        </div>
        <div class="mb-2">
          <VTextField v-model="store.detailAddress" label="상세주소" />
        </div>
      </VCol>

      <VCol cols="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">전화번호 </span>
        </div>
        <div class="mb-2">
          <VTextField v-model="store.phoneNo" label="전화번호" />
        </div>
      </VCol>

      <VCol cols="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">가게 소개</span>
        </div>
        <div class="mb-2">
          <VTextField v-model="store.comment" label="가게 소개" />
        </div>
      </VCol>

      <VCol cols="8">
        <div class="w-100 d-md-flex justify-end align-center">
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0 px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="sendRegisterStore"
              width="100%"
              min-width="150px"
            >
              App 전송(생성)
            </VBtn>
          </div>
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0 px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="sendModifyStore"
              width="100%"
              min-width="150px"
            >
              App 전송(수정)
            </VBtn>
          </div>
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0 px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="clickModifyStore"
              width="100%"
              min-width="150px"
            >
              저장
            </VBtn>
          </div>
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0 px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="clickRemoveStore"
              width="100%"
              min-width="150px"
            >
              삭제
            </VBtn>
          </div>
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0 px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="clickMoveToStoreList"
              width="100%"
              min-width="150px"
            >
              목록
            </VBtn>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />

  <!-- Popup -->
  <Popup v-show="popup.show" :popup-props="popup.props" @popup-event="handlePopupEvent"/>

</template>

<script>
import Alert from "@/components/AlertDefault";
import Popup from "@/components/PopupDefault";

export default {
  name: "StoreEdit",
  components: {
    Alert,
    Popup,
  },
  data: () => ({
    /* Data Object */
    store: {
      id: "",
      name: "",
      adminId: "",
      address1: "",
      address2: "",
      address3: "",
      detailAddress: "",
      phoneNo: "",
      comment: "",
      createUserId: "",
    },
    user: {
      items: [],
      loading: false,
      search: null,
      select: null,
    },

    code: {
      address1: [],
      address2: [],
      address3: []
    },
    path: {
      list: "/api/store-list",
    },
    url: {
      userList: "/api/user/list",
      address1: "/api/code/address/tier1",
      address2: "/api/code/address/tier2",
      address3: "/api/code/address/tier3",
      store: "/api/store",
      storeDetail: "/api/store/detail",

      /* App I/F */
      send: "/api/store/send",
    },

    popup: {
      show: false,
      owner: '',
      id: '',
      props: {
        title: '',
        contents: ''
      }
    },
    alert: {
      msg: '',
      type: 'success'
    },
  }),
  created() {
    console.log('id')
    console.log(this.$route.params.id)
    this.readStoreDetail(this.$route.params.id);
    this.loadAddress1();
  },
  watch: {
    "user.search" (val) {
      val && val !== this.user.select && this.querySelections(val)
    },
    "user.select"() {
      this.store.adminId = this.user.select
    },
    "store.address1"() {
      this.loadAddress2();
    },
    "store.address2"() {
      this.loadAddress3();
    },
  },
  methods: {
    querySelections (v) {
      // 입력값의 길이가 2이상일 때만 데이터 검색
      if(v.length >= 2){
        this.user.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.loadUserList(v)
          this.user.loading = false
        }, 500)
      }
    },
    /* APIs */
    readStoreDetail(id) {
      this.$axios
        .post(this.url.storeDetail, { id: id })
        .then((res) => {
          console.log('readStoreDetail');
          console.log(res.data);
          this.store.id = res.data.id;
          this.store.name = res.data.name;
          this.store.adminId = res.data.adminId;
          this.user.select = res.data.adminId;
          this.store.address1 = res.data.address1;
          this.store.address2 = res.data.address2;
          this.store.address3 = res.data.address3;
          this.store.detailAddress = res.data.detailAddress;
          this.store.phoneNo = res.data.phoneNo;
          this.store.comment = res.data.comment;
        });
    },
    loadUserList(v) {
      this.$axios
        // .post(this.url.userList, { id: this.store.adminId })
        .post(this.url.userList, { id: v })
        .then((res) => {
          console.log(res.data);
          // this.user.items = res.data;
          const users = res.data;
          this.user.items = []
          users.forEach(user => {
            this.user.items.push(user.id)
          });
        });
    },
    loadAddress1() {
      this.$axios
        .post(this.url.address1, {
          parentId: "N/A",
          tier: 1
        })
        .then((res) => {
          console.log(res.data);
          this.code.address1 = res.data;
        });
    },
    loadAddress2() {
      this.$axios
        .post(this.url.address2, {
          parentId: this.store.address1,
          tier: 2
        })
        .then((res) => {
          console.log(res.data);
          this.code.address2 = res.data;
        });
    },
    loadAddress3() {
      this.$axios
        .post(this.url.address3, {
          parentId: this.store.address2,
          tier: 3
        })
        .then((res) => {
          console.log(res.data);
          this.code.address3 = res.data;
        });
    },
    modifyStore() {
      this.$axios
        .put(this.url.store, {
          id: this.store.id,
          name: this.store.name,
          adminId: this.store.adminId,
          address1: this.store.address1,
          address2: this.store.address2,
          address3: this.store.address3,
          detailAddress: this.store.detailAddress,
          phoneNo: this.store.phoneNo,
          comment: this.store.comment,
          updateUserId: this.$store.getters.id
        })
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('매장 정보가 변경되었습니다.', 'success');
            this.readStoreDetail(this.store.id);
          }else if(res.data.code === -1){
            this.openAlert('매장은 정보가 변경되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
            this.readStoreDetail(this.store.id);
          }else{
            this.openAlert('매장 정보 변경을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('매장 정보 변경을 실패하였습니다.', 'error');
        });
    },
    removeStore() {
      this.$axios.delete(this.url.store, { data: this.store.id })
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('매장이 삭제되었습니다. 매장 리스트 화면으로 이동합니다.', 'success');
            setTimeout(() => {
              this.$router.push({
                name: "StoreInfoList",
              });
            }, 2000);
          }else if(res.data.code === -1){
            this.openAlert('매장이 삭제되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
            setTimeout(() => {
              this.$router.push({
                name: "StoreInfoList",
              });
            }, 2000);
          }else{
            this.openAlert('매장 삭제를 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('매장 삭제를 실패하였습니다.', 'error');
        });
    },

    /* App I/F */
    sendRegisterStore(){
      this.$axios
        .post(this.url.send, {
          id: this.store.id,
          name: this.store.name,
          address1: this.store.address1,
          address2: this.store.address2,
          address3: this.store.address3,
          detailAddress: this.store.detailAddress,
          phoneNo: this.store.phoneNo,
        })
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('매장 데이터가 App에 전송되었습니다.', 'success');
          }else{
            this.openAlert('매장 데이터 App 전송을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('매장 데이터 App 전송을 실패하였습니다.', 'error');
        });
    },
    sendModifyStore(){
      this.$axios
        .put(this.url.send, {
          id: this.store.id,
          name: this.store.name,
          address1: this.store.address1,
          address2: this.store.address2,
          address3: this.store.address3,
          detailAddress: this.store.detailAddress,
          phoneNo: this.store.phoneNo,
        })
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('매장 데이터가 App에 전송되었습니다.', 'success');
          }else{
            this.openAlert('매장 데이터 App 전송을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('매장 데이터 App 전송을 실패하였습니다.', 'error');
        });
    },

    /* Button */
    clickModifyStore(){
      this.openPopup(
        '매장 정보 변경',
        "'" + this.store.name + "' 매장 정보를 변경하시겠습니까?",
        'modifyStore',
        this.store.id
      );
    },
    clickRemoveStore(){
      this.openPopup(
        '매장 삭제',
        "'" + this.store.name + "' 매장을 삭제하시겠습니까?",
        'removeStore',
        this.store.id
      );
    },
    clickMoveToStoreList(){
      this.$router.push({
        name: "StoreInfoList",
      });
    },

    /* Display */
    customFilter(item, queryText, itemText) {
      console.log('customFilter')
      console.log(item)
      console.log(queryText)
      console.log(itemText)

      if (this.search !== "") {
        // 사용자가 입력한 검색어로 필터링
        return itemText.toLowerCase().includes(queryText.toLowerCase());
      }
      return true;
    },
    customNoFilter(item) {
      console.log('customnoFilter')
      // 검색어가 없을 때 사용자가 직접 입력한 값을 필터링하지 않음
      return item !== this.search;
    },

    /* Popup */
    openPopup(title, contents, owner, id){
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
      // confirm 시 해당 item 삭제를 위한 id 저장
      this.popup.id = id;
    },
    closePopup(){
        this.popup.owner = '';
        this.popup.id = '';
        this.togglePopup();
    },
    togglePopup(){
      this.popup.show = !this.popup.show;
    },
    handlePopupEvent(isConfirm){
      if(isConfirm){
        if(this.popup.owner === 'modifyStore'){
          this.modifyStore();
        }else if(this.popup.owner === 'removeStore'){
          this.removeStore();
        }else{
          console.log('Undefined Popup owner.');
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },
    /* Alert */
    openAlert(msg, type){
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

  },
};
</script>
<style scoped>
.text-20 {
  font-size: 20px;
}
</style>
