<template>
  <VCard class="pa-3 mb-3">
    <VCardTitle>
      <h5 class="text-h5">매장 리스트</h5>
    </VCardTitle>
    <VCardTitle>
      <VRow class="justify-end">
        <VCol cols="2">
          <VTextField
            hide-details
            variant="outlined"
            placeholder="업체명 입력"
            label="업체명"
            v-model="searchParam.name"
            @keyup.enter="clickReadStoreList"
            clearable
          />
        </VCol>
        <VCol cols="2">
          <VTextField
            hide-details
            variant="outlined"
            placeholder="관리자 ID 입력"
            label="관리자 ID"
            v-model="searchParam.adminId"
            @keyup.enter="clickReadStoreList"
            clearable
          />
        </VCol>
        <VCol cols="2">
          <VBtn
            class="btn w-100 h-100 py-3 text-16"
            color="primary"
            prepend-icon="mdi-magnify"
            @click.stop="clickReadStoreList"
          >
            검색
          </VBtn>
        </VCol>
        <VCol cols="2">
          <VBtn
            class="btn w-100 h-100 py-3 text-16"
            color="warning"
            prepend-icon="mdi-plus-circle"
            @click.stop="clickManageAddress"
          >
            주소관리
          </VBtn>
        </VCol>
        <VCol cols="2">
          <VBtn
            class="btn w-100 h-100 py-3 text-16"
            color="warning"
            prepend-icon="mdi-plus-circle"
            @click.stop="clickAddStore"
          >
            매장추가
          </VBtn>
        </VCol>
        <VCol cols="2">
          <VBtn
            class="btn w-100 h-100 py-3 text-16"
            color="warning"
            prepend-icon="mdi-plus-circle"
            @click.stop="clickManagePassword"
          >
            비밀번호 변경
          </VBtn>
        </VCol>
      </VRow>
    </VCardTitle>
  </VCard>

  <VCard class="pa-3 mb-3">
    <VCardItem>
      <VRow>
        <VCol cols="12">
          <v-data-table
            :headers="headers"
            :items="stores"
            :items-per-page=10
            density="compact"
            item-key="id"
            class="elevation-0 hover"
            @click:row="clickEditStore"
          />
        </VCol>
      </VRow>
    </VCardItem>
  </VCard>

</template>
<script>
export default {
  name: "StoreInfoList",
  data: () => ({
    searchParam: {
      name: '',
      adminId: ''
    },
    headers: [
      { title: '매장명', align: 'center', key: 'name', sortable: false },
      { title: 'ID', align: 'center', key: 'adminId', sortable: false },
      { title: '전화번호', align: 'center', key: 'phoneNo', sortable: false },
      { title: '주소', align: 'center', key: 'address', sortable: false },
    ],
    stores: [
      /*
      id: '',
      name: '',
      phoneNo: '',
      address: '',
      point: '',
      */
    ],
    url: {
      storeList: '/api/store/list',
      store: '/api/store'
    }
  }),
  created() {
    this.readStoreList();
  },
  methods: {
    /* Button */
    clickReadStoreList(){
      this.readStoreList();
    },
    clickManageAddress(){
      this.$router.push({
        name: "AddressManage"
      });
    },
    clickAddStore(){
      this.$router.push({
        name: "StoreCreate"
      });
    },
    clickManagePassword(){
      this.$router.push({
        name: "PasswordManage"
      });
    },
    clickEditStore(event, {item}){
      console.log(item.raw)
      console.log(item.raw.id)
      this.$router.push({
        name: "StoreEdit",
        params: { id: item.raw.id }
      });
    },
    /* APIs */
    readStoreList(){
      this.$axios
        .post(this.url.storeList, {
          name: this.searchParam.name,
          adminId: this.searchParam.adminId
        })
        .then((res) => {
          // this.stores = res.data;
          const stores = res.data;
          this.stores = [];
          stores.forEach(store => {
            this.stores.push({
              id: store.id,
              name: store.name,
              adminId: store.adminId,
              phoneNo: store.phoneNo,
              address:
                store.address1 + ' ' +
                store.address2 + ' ' +
                store.address3 + ' ' +
                store.detailAddress,
            })
          });
        });
    },
  },

}
</script>
<style scoped>
.text-lg {
  font-size: 17px;
}
.text-sm {
  font-size: 12px;
}
.text-xs {
  font-size: 10px;
}


</style>
