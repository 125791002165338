import axios from 'axios'
import { store } from '@/store/index'
import router from '@/router/index'
// import i18n from '@/i18n'

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const instance = axios.create({
  // cors 때문에 주석처리함
  // baseURL: 'http://localhost:9090'

  /* 0708 */
  // baseURL: process.env.VUE_APP_API_URL,
  /* headers: {
    "Content-type":"application/json",
    'Access-Control-Allow-Origin': 'http://localhost:9090', // 서버 domain
    // 'X-AUTH-TOKEN': `${localStorage.getItem('X-AUTH-TOKEN')}`
  },
  withCredentials: true */
  /* 0708 */
});

/*
  1. 요청 인터셉터
    1) 요청 바로 직전: param = axios.config
    2) 요청 에러: param = error
*/
instance.interceptors.request.use(
  (config) => {
    // 운영, qa 빌드시에는 '/api' 삭제
    if(process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'qa'){
      let url = config.url.replace(/\/api/g, '')
      config.url = url;
    }

    // header 에 token 설정
    let access_token = store.getters.token;

    if(access_token){
      config.headers.Authorization = `${access_token}`;

      // login 중이면 lastActionTime 설정
      const lastActionTime = store.getters.lastActionTime;
      // 5시간 =  60 * 60 * 5
      const sleepLimitInSecond = 60 * 60 * 5;
      const now = dayjs().tz()
      const sleepTimeInSecond = now.diff(lastActionTime, "second");

      if(sleepTimeInSecond > sleepLimitInSecond){
        alert('5시간 동안 입력이 없어 로그아웃됩니다.');
        // alert(i18n.t('message.login.expired'));
        store.dispatch('logout');
        router.push('/login');
        return Promise.reject(new axios.Cancel('Request canceled'));
      }else{
        // 마지막 액션 시간 저장
        store.dispatch('lastActionTime');
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

/*
  2. 응답 인터셉터
    1) 응답 바로 직전: param = http response
    2) 응답 에러: param = http error
*/
instance.interceptors.response.use(
  (response) => {
    /*
      http status 가 200 인 경우
      응답 바로 직전에 대해 작성합니다.
      .then() 으로 이어집니다.
    */

    return response;
  },
  (error) => {
    /*
      http status 가 200 이 아닌 경우
      응답 에러 처리를 작성합니다.
      .catch() 로 이어집니다.
    */

    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거
    // 응답 오류가 있는 작업 수행
    if (error.response && error.response.status) {
      switch (error.response.status) {
        // status code가 401(SC_UNAUTHORIZED)인 경우 `logout`을 커밋하고 `/login` 페이지로 리다이렉트
        case 401:
          // alert('로그인 시간이 만료되었습니다. 다시 로그인 해주세요.')
          // alert(i18n.t('message.login.error.401'));
          store.dispatch('logout');
          router.push('/login');
          break;
        // status code가 403(FORBIDDEN)인 경우(권한없음) `logout`을 커밋하고 `/login` 페이지로 리다이렉트
        case 403:
          alert('권한이 없습니다. 로그인 화면으로 이동합니다.')
          // alert(i18n.t('message.login.error.403'));
          store.dispatch('logout');
          router.push('/login');
          break;
        default:
          return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
)

// 생성한 인스턴스를 익스포트 합니다.
export default instance;
