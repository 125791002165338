<template>
  <div class="slide">
  <!-- <div class="slide" v-show="show"> -->
  <!-- <div class="slide" v-show="show" v-click-outside="onClickOutside"> -->
   <VCard class="pa-3 mb-3">
      <VRow class="">
        <VCol cols="12">
          <VCardTitle class="">
            <h5 class="text-h5">게임 컨트롤러</h5>
          </VCardTitle>
        </VCol>

        <VCol cols="6">
          <VBtn
            color="info"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0 text-center"
            @click.stop="clickSendControl"
          >
            <VIcon start size="30" icon="mdi-check-circle-outline" />
            적&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;용
          </VBtn>
        </VCol>

        <VCol clos="12" class="">
          <VBtn
            color="error"
            class="w-100 h-100 btn rounded my-0 pa-2 my-md-0 text-center"
            @click.stop="clickSendAction('END')"
          >
            <VIcon start icon="mdi-door-closed" size="30" />
            게임 종료
          </VBtn>
        </VCol>
        <!-- <VCol cols="6">
          <div class="text-body-1 text-secondary w-100 d-flex flex-column">
            <VSelect
              :items="structure.level.items"
              hide-details="true"
              v-model="structure.level.selected"
              label="Lv 선택"
              item-title="level"
              item-value="seq"
              density="compact"
              class="px-2"
            />
          </div>
        </VCol>
        <VCol cols="6">
          <div>
            <VBtn
              color="success"
              class="w-100 h-100 btn rounded my-0 pa-2 my-md-0"
              @click.stop="clickSendLevel"
            >
              <VIcon start size="30" icon="mdi-play-outline" />
              레벨 시작
            </VBtn>
          </div>
        </VCol> -->
      </VRow>
    </VCard>

    <v-expansion-panels >
      <v-expansion-panel >

        <v-expansion-panel-title>
          <div class="text-center w-100 d-flex justify-center align-center">
            <span class="text-h5">플레이어 설정</span>
          </div>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <VRow class="">
            <!-- 반복 컴포넌트 시작 부분 -->
            <VCol cols="6" class="d-flex align-center">
              <VCol cols="4" class="pa-0 d-flex flex-column align-center">
                <span class="title nowrap">현재 인원</span>
              </VCol>
              <VCol cols="8" class="pa-0 d-flex flex-column align-center">
                <div class="d-flex w-75 align-center justify-space-evenly">
                  <VIcon
                    icon="mdi-minus-box-outline"
                    size="30"
                    @click.stop="clickSubCurrentPlayer"

                    class="text-btn"
                  />
                  <div class="d-flex w-50 px-2 align-center textField">
                    <v-text-field
                      hide-details="true"
                      center-affix="true"
                      class="centered-input"
                      label=""
                      type="number"
                      density="compact"
                      hide-spin-buttons
                      readonly
                        v-model="game.player.current"

                    />
                  </div>
                  <VIcon
                    icon="mdi-plus-box-outline"
                    size="30"
                    @click.stop="clickAddCurrentPlayer"

                    class="text-btn"
                  />
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex align-center">
             <VCol cols="4" class="pa-0 d-flex flex-column align-center">
                <span class="title">엔트리</span>
              </VCol>
              <VCol cols="8" class="pa-0 d-flex flex-column align-center">
                <div class="d-flex align-center w-30 justify-center">
                  <VTextField
                    hide-details="true"
                    density="compact"
                    class="text-center centered-input"
                    label=""
                    type="number"
                    readonly
                    v-model="game.player.total"
                  />
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                cols="4"

                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block">바이인 + 얼리칩</span>
                  <div class="pa-3 rounded-lg">
                    <span>{{
                      addCommasToNumber(structure.value.earlyBuyIn)
                    }}</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="8" class="justify-center d-flex">
                <div
                  class="d-flex w-100 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex align-center justify-space-between"
                    >
                      <VIcon
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubEarlyBuyInPlayer"
                      />
                      <VTextField
                        hide-details="true"
                        label=""
                        density="compact"
                        variant="outlined"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.earlyBuyIn"
                      />
                      <VIcon
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddEarlyBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <!-- 마지막 부분 -->
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                cols="4"
                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block title">첫번째 리바인</span>
                  <div class="pa-3 rounded-lg">
                    <span v-if="!game.player.disabled.firstReBuyIn">{{
                      addCommasToNumber(structure.value.firstReBuyIn)
                    }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-100 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                       <VIcon
                        v-if="!game.player.disabled.firstReBuyIn"
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubFirstReBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.firstReBuyIn"
                      />
                      <VIcon
                        v-if="!game.player.disabled.firstReBuyIn"
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddFirstReBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="4"

                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                 <span class="d-block">바이인</span>
                  <div class="pa-3 rounded-lg">
                    <span>{{ addCommasToNumber(structure.value.buyIn) }}</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-100 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                   <VIcon
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.buyIn"
                      />
                      <VIcon
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="4"

                class="d-flex justify-center align-center justify-center"
              >
                  <div class="text-center px-3">
                  <span class="d-block">두번째 리바인</span>
                  <div class="pa-3 rounded-lg">
                    <span v-if="!game.player.disabled.secondReBuyIn">{{
                      addCommasToNumber(structure.value.secondReBuyIn)
                    }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-100 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                        <VIcon
                        v-if="!game.player.disabled.secondReBuyIn"
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubSecondReBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.secondReBuyIn"
                      />
                      <VIcon
                        v-if="!game.player.disabled.secondReBuyIn"
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddSecondReBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="4"

                class="d-flex justify-center align-center justify-center"
              >
               <div class="text-center px-3">
                  <span class="d-block">애드온</span>
                  <div class="pa-3 rounded-lg">
                    <span>{{ addCommasToNumber(structure.value.addOn) }}</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-100 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                      <VIcon
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubAddOnPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.addOn"
                      />
                      <VIcon
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddAddOnPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
            <VCol cols="6" class="d-flex justify-center">
              <VCol
                   cols="4"

                class="d-flex justify-center align-center justify-center"
              >
                <div class="text-center px-3">
                  <span class="d-block title">세번째 리바인</span>
                  <div class="pa-3 rounded-lg">
                    <span v-if="!game.player.disabled.thirdReBuyIn">0</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </VCol>
              <VCol cols="8"  class="justify-center d-flex">
                <div
                  class="d-flex w-100 flex-column justify-space-around align-center px-3"
                >
                  <VIcon size="30" icon="mdi-account-group-outline" />
                  <div class="d-flex align-center">
                    <div
                      class="d-flex w-100 align-center justify-space-between"
                    >
                     <VIcon
                        v-if="!game.player.disabled.thirdReBuyIn"
                        class="ml-3 text-btn"
                        icon="mdi-minus-box-outline"
                        size="30"
                        @click.stop="clickSubThirdReBuyInPlayer"
                      />
                      <VTextField
                        variant="outlined"
                        hide-details="true"
                        label=""
                        density="compact"
                        type="number"
                        class="centered-input border border-primary rounded-lg border-opacity-100 mx-3"
                        readonly
                        v-model="game.player.thirdReBuyIn"
                      />
                      <VIcon
                        v-if="!game.player.disabled.thirdReBuyIn"
                        class="mr-3 text-btn"
                        icon="mdi-plus-box-outline"
                        size="30"
                        @click.stop="clickAddThirdReBuyInPlayer"
                      />
                    </div>
                  </div>
                </div>
              </VCol>
            </VCol>
          </VRow>
        </v-expansion-panel-text>


      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mt-3">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="w-100 d-flex justify-center align-center">
            <span class="text-h5">프라이즈 설정</span>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <VRow class="mt-0">
            <VCol>
              <ck-editor
                v-model="ckeditor.content"
                :editor="ckeditor.editor"
                :config="ckeditor.editorConfig"
              />
            </VCol>
          </VRow>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mt-3">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <div class="w-100 d-flex justify-center align-center">
            <span class="text-h5">레벨 설정</span>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <!-- LEVEL 행 헤더 -->
          <VRow class="mt-5">
            <VCol cols="3" class="d-flex justify-start align-center">
              <div class="w-75 text-center">
                <span class="text-body-1 text-grey-800">Level</span>
              </div>
            </VCol>
            <VCol cols="8" class="d-flex justify-space-between">
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >스몰 블라인드</VInput
                >
              </div>
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >빅 블라인드</VInput
                >
              </div>
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >엔티</VInput
                >
              </div>
              <div class="v-col v-col-2 px-1 rounded-lg">
                <VInput
                  density="compact"
                  hide-details="true"
                  class="w-100 text-body-1 d-flex justify-center text-grey-800"
                  >지속시간(분)</VInput
                >
              </div>
            </VCol>
          </VRow>
          <!-- 1행 끝 -->
          <!-- v-for start -->
          <div v-for="(item, index) in structure.level.items" :key="index">
            <v-row v-if="item.level === 0" class="align-center">
              <v-col cols="3" class="text-center">
                <div
                  class="bg-warning py-2 w-75 pr-3 d-flex justify-center align-center"
                >
                  <v-icon class="mr-2" icon="mdi-pause" size="35" />
                  <span>Break</span>
                </div>
              </v-col>
              <v-col cols="8" class="d-flex justify-space-between">
                <div class="w-100 text-center bg-warning rounded-lg py-3">
                  <v-text-field
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    class="text-center centered centered-input"
                    v-model="item.duration"
                    readonly
                  />
                </div>
              </v-col>
            </v-row>
            <v-row v-else class="align-center">
              <v-col cols="3" class="text-center hover">
                <div
                  class="btn hover bg-primary py-2 w-75 pr-3 d-flex justify-center align-center"
                  @click.stop="clickSendLevelFromList({ item })"
                >
                  <v-icon class="mr-2" icon="mdi-play" size="35" />
                  <span>Level {{ item.level }}</span>
                </div>
              </v-col>
              <v-col cols="8" class="d-flex justify-space-between">
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    center-affix="true"
                    class="centered-input w-100"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.smallBlind"
                    readonly
                  />
                </div>
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    class="centered-input w-100"
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.bigBlind"
                    readonly
                  />
                </div>
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    class="centered-input w-100"
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.ante"
                    readonly
                  />
                </div>
                <div
                  class="v-col v-col-2 px-1 text-center border border-primary border-opacity-100 rounded-lg py-2"
                >
                  <v-text-field
                    class="centered-input w-100"
                    center-affix="true"
                    hide-details="true"
                    density="compact"
                    variant="plain"
                    v-model="item.duration"
                    readonly
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <!-- v-for end -->
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Popup -->
    <Popup
      v-show="popup.show"
      :popup-props="popup.props"
      @popup-event="handlePopupEvent"
    />

    <!-- alert -->
    <Alert ref="alert" :alert-props="alert" />

  </div>

</template>

<script>
import Alert from "@/components/AlertDefault";
import Popup from "@/components/PopupDefault";
// CKEditor5
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "BoardModal",
  components: {
    Alert,
    Popup,
    "ck-editor": CKEditor.component,
  },
  props: {
    gameId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false,
      clickOutside: false,

      game: {
        master: {
          id: '',
          name: '',
        },
        player: {
          current: 0,
          total: 0,
          earlyBuyIn: 0,
          buyIn: 0,
          firstReBuyIn: 0,
          secondReBuyIn: 0,
          thirdReBuyIn: 0,
          addOn: 0,
          disabled: {
            firstReBuyIn: false,
            secondReBuyIn: false,
            thirdReBuyIn: false,
          },
        },
        stack: {
          avg: 0,
          total: 0,
        },
      },

      structure: {
        master: {
          id: '',
          name: '',
          entryLimit: 1,
        },
        value: {
          earlyBuyIn: 10000,
          buyIn: 10000,
          firstReBuyIn: 10000,
          secondReBuyIn: 10000,
          thirdReBuyIn: 10000,
          addOn: 10000,
        },
        level: {
          items: [],
          selected: 0,
        },
      },

      url: {
        gameInfo: "/api/game/info",
        gamePlayer: "/api/game/player",
        gamePrize: "/api/game/prize"
      },

      popup: {
        show: false,
        owner: '', // open popup call function name
        param: '', // callback function param
        props: {
          title: '',
          contents: '',
        },
      },
      alert: {
        msg: "",
        type: "success",
      },

      /* CKEditor */
      ckeditor: {
        // ✅ v-model
        content:
          '<p><span style="color:hsl(0,0%,0%);"><strong>GOLDMOON 세틀라이트</strong></span><br><span style="color:hsl(0,0%,0%);">6엔트리당 KSOP 시드권 1장</span><br><span style="color:hsl(0,0%,0%);">1등 : KSOP 시드권 3장</span><br><span style="color:hsl(0,0%,0%);">2등 : KSOP 시드권 1장</span><br><span style="color:hsl(0,0%,0%);">3등 : GOLDMOON 실버티켓 1장</span></p>',
        // ✅ :editor
        editor: ClassicEditor,
        // ✅ :config
        editorConfig: {
          // height: '1000px',
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "strikethrough",
              "underline",
              "|",
              "fontSize",
              "fontColor",
              "fontBackgroundColor",
              "highlight",
              "|",
              "link",
              "specialCharacters",
              "|",
              "undo",
              "redo",
              "|",
              // 'selectAll', 'fontFamily', 'horizontalLine',
              "|",
            ],
          },
        },
      },
    }
  },
  created() {
    // gameId from props
    this.game.master.id = this.gameId;
    this.readGameInfo();
  },
  watch: {
    "game.player.current"() {
      this.calcAvgStack();
    },
    "game.player.earlyBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.buyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.firstReBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.secondReBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.thirdReBuyIn"() {
      this.calcTotalPlayer();
      this.calcTotalStack();
    },
    "game.player.addOn"() {
      this.calcTotalStack();
      this.calcAvgStack();
    },
  },
  methods: {
    /* open(){
      this.show = true;
      this.clickOutside = true;
    },
    close(){
      this.show = false;
      this.clickOutside = false;
    }, */

    /* APIs */
    readGameInfo(){
      // game 정보 조회
      this.$axios
        .post(this.url.gameInfo, {
          // FIXME props
          // id: this.props.gameId,
          id: this.game.master.id
        })
        .then((res) => {
          console.log(res.data);
          this.game.master.id = res.data.id;
          this.game.master.name = res.data.name;

          this.game.player.current = res.data.currentPlayer;
          this.game.player.total = res.data.totalPlayer;
          this.game.player.earlyBuyIn = res.data.earlyBuyInPlayer;
          this.game.player.buyIn = res.data.buyInPlayer;
          this.game.player.firstReBuyIn = res.data.firstReBuyInPlayer;
          this.game.player.secondReBuyIn = res.data.secondReBuyInPlayer;
          this.game.player.thirdReBuyIn = res.data.thirdReBuyInPlayer;
          this.game.player.addOn = res.data.addOnPlayer;

          this.structure.master.id = res.data.structureId;
          this.structure.master.name = res.data.structureName;
          this.structure.master.entryLimit = res.data.entryLimit;

          this.structure.value.earlyBuyIn = res.data.earlyBuyIn;
          this.structure.value.buyIn = res.data.buyIn;
          this.structure.value.firstReBuyIn = res.data.firstReBuyIn;
          this.structure.value.secondReBuyIn = res.data.secondReBuyIn;
          this.structure.value.thirdReBuyIn = res.data.thirdReBuyIn;
          this.structure.value.addOn = res.data.addOn;

          this.structure.level.items = res.data.levelList;

          this.ckeditor.content = res.data.prizeContent;

          this.$nextTick(() => {
            // structure.master.entryLimit 에 따라 disable
            const entryLimit = this.structure.master.entryLimit;
            if (entryLimit === 0) {
              this.game.player.disabled.firstReBuyIn = true;
              this.game.player.disabled.secondReBuyIn = true;
              this.game.player.disabled.thirdReBuyIn = true;
            } else if (entryLimit === 1) {
              this.game.player.disabled.firstReBuyIn = false;
              this.game.player.disabled.secondReBuyIn = true;
              this.game.player.disabled.thirdReBuyIn = true;
            } else if (entryLimit === 2) {
              this.game.player.disabled.firstReBuyIn = false;
              this.game.player.disabled.secondReBuyIn = false;
              this.game.player.disabled.thirdReBuyIn = true;
            } else if (entryLimit === 3) {
              this.game.player.disabled.firstReBuyIn = false;
              this.game.player.disabled.secondReBuyIn = false;
              this.game.player.disabled.thirdReBuyIn = false;
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modifyGamePlayer() {
      this.$axios
        .put(this.url.gamePlayer, {
          id: this.game.master.id,
          current: this.game.player.current,
          total: this.game.player.total,
          earlyBuyIn: this.game.player.earlyBuyIn,
          buyIn: this.game.player.buyIn,
          firstReBuyIn: this.game.player.firstReBuyIn,
          secondReBuyIn: this.game.player.secondReBuyIn,
          thirdReBuyIn: this.game.player.thirdReBuyIn,
          addOn: this.game.player.addOn,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modifyGamePrize() {
      this.$axios
        .put(this.url.gamePrize, {
          id: this.game.master.id,
          prizeContent: this.ckeditor.content,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /* Buttons */
    clickSendControl() {
      this.openPopup(
        "변경사항 적용",
        "변경된 값을 타이머에 적용하시겠습니까?",
        "sendControl"
      );
    },
    sendControl() {
      this.modifyGamePlayer();
      this.modifyGamePrize();

      const controlObject = {
        currentPlayer: this.game.player.current,
        totalPlayer: this.game.player.total,
        totalChips: this.game.stack.total,
        avgStacks: this.game.stack.avg
      };

      const prizeObject = {
        content: this.ckeditor.content
      };

      this.$emit('modal-control-event', controlObject, prizeObject);
    },
    clickSendAction(action) {
      let msg = "";
      if (action === "END") {
        msg = "종료";
        this.popup.param = action;
      } else {
        console.log("Undefined action.");
        return;
      }

      this.openPopup(
        "타이머 " + msg,
        "타이머를 " + msg + "하시겠습니까?",
        "sendAction"
      );
    },
    sendAction() {
      const action = this.popup.param;
      const actionObject = {
        action: action,
        actioinUserId: this.$store.getters.id
      };
      this.$emit('modal-action-event', actionObject);
    },
    clickSendLevelFromList({ item }) {
      this.structure.level.selected = item.seq;
      const selectedLevel = this.structure.level.selected;
      const level = this.structure.level.items[selectedLevel].level;
      this.openPopup(
        "레벨로 이동",
        "레벨 " + level + "로 이동하시겠습니까?",
        "sendLevelFromList"
      );
    },
    sendLevelFromList() {
      const selectedLevel = this.structure.level.selected;
      const levelObject = this.structure.level.items[selectedLevel];
      const level = this.structure.level.items[selectedLevel].level;
      this.$emit('modal-level-event', levelObject);

      this.openAlert("레벨 " + level + "로 이동되었습니다.", "success");

      /* if (result === 1) {
        this.openAlert("레벨 " + level + "로 이동되었습니다.", "success");
      } else {
        this.openAlert("레벨 이동을 실패하였습니다.", "error");
      } */
    },

    /* Popup */
    openPopup(title, contents, owner) {
      this.popup.props.title = title;
      this.popup.props.contents = contents;
      this.togglePopup();
      this.popup.owner = owner;
    },
    closePopup() {
      this.popup.owner = "";
      this.popup.param = "";
      this.togglePopup();
    },
    togglePopup() {
      this.popup.show = !this.popup.show;
    },
    handlePopupEvent(isConfirm) {
      if (isConfirm) {
        if (this.popup.owner === "sendControl") {
          this.sendControl();
        } else if (this.popup.owner === "sendAction") {
          this.sendAction();
        } else if (this.popup.owner === "sendLevelFromList") {
          this.sendLevelFromList();
        } else {
          console.log("Undefined Popup owner.");
        }
      }
      this.$nextTick(() => {
        this.closePopup();
      });
    },

    /* Alert */
    openAlert(msg, type) {
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    /* display */
    addCommasToNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    /* total stack */
    calcTotalStack() {
      this.game.stack.total =
        this.structure.value.earlyBuyIn * this.game.player.earlyBuyIn +
        this.structure.value.buyIn * this.game.player.buyIn +
        this.structure.value.firstReBuyIn * this.game.player.firstReBuyIn +
        this.structure.value.secondReBuyIn * this.game.player.secondReBuyIn +
        this.structure.value.thirdReBuyIn * this.game.player.thirdReBuyIn +
        this.structure.value.addOn * this.game.player.addOn;
    },
    /* avg stack */
    calcAvgStack() {
      if (this.game.player.current === 0) {
        this.game.stack.avg = 0;
      } else {
        this.game.stack.avg = Number(
          (this.game.stack.total / this.game.player.current).toFixed(0)
        );
      }
    },
    preventMinusValue(value) {
      if (value - 1 < 0) {
        return 0;
      } else {
        return value - 1;
      }
    },
    /* total */
    calcTotalPlayer() {
      this.game.player.total =
        this.game.player.earlyBuyIn +
        this.game.player.buyIn +
        this.game.player.firstReBuyIn +
        this.game.player.secondReBuyIn +
        this.game.player.thirdReBuyIn;
    },
    /* current */
    clickSubCurrentPlayer() {
      this.game.player.current = this.preventMinusValue(
        this.game.player.current
      );
    },
    clickAddCurrentPlayer() {
      this.game.player.current++;
    },
    /* earlyBuyIn */
    clickSubEarlyBuyInPlayer() {
      if (this.game.player.earlyBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.earlyBuyIn = this.preventMinusValue(
        this.game.player.earlyBuyIn
      );
    },
    clickAddEarlyBuyInPlayer() {
      this.game.player.earlyBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* buyIn */
    clickSubBuyInPlayer() {
      if (this.game.player.buyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.buyIn = this.preventMinusValue(this.game.player.buyIn);
    },
    clickAddBuyInPlayer() {
      this.game.player.buyIn++;
      this.clickAddCurrentPlayer();
    },
    /* firstReBuyIn */
    clickSubFirstReBuyInPlayer() {
      if (this.game.player.firstReBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.firstReBuyIn = this.preventMinusValue(
        this.game.player.firstReBuyIn
      );
    },
    clickAddFirstReBuyInPlayer() {
      this.game.player.firstReBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* secondReBuyIn */
    clickSubSecondReBuyInPlayer() {
      if (this.game.player.secondReBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.secondReBuyIn = this.preventMinusValue(
        this.game.player.secondReBuyIn
      );
    },
    clickAddSecondReBuyInPlayer() {
      this.game.player.secondReBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* thirdReBuyIn */
    clickSubThirdReBuyInPlayer() {
      if (this.game.player.thirdReBuyIn !== 0) {
        this.clickSubCurrentPlayer();
      }
      this.game.player.thirdReBuyIn = this.preventMinusValue(
        this.game.player.thirdReBuyIn
      );
    },
    clickAddThirdReBuyInPlayer() {
      this.game.player.thirdReBuyIn++;
      this.clickAddCurrentPlayer();
    },
    /* addOn */
    clickSubAddOnPlayer() {
      this.game.player.addOn = this.preventMinusValue(this.game.player.addOn);
    },
    clickAddAddOnPlayer() {
      this.game.player.addOn++;
    },


  },

}
</script>

<style scoped>
.title {
  white-space: noWrap;
}

.slide{
  background-color:rgb(40, 36, 61);
  position:fixed;
  top:5vh;
  left:0;
  height : 90vh;
  width : 80vw;

  z-index:9990;
  overflow : auto;
  padding : 5px
}

.slide {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    left: -100vw;
  }

  to {
    left: 0;
  }
}

.centered-input :deep() input {
  text-align: center;
}

.centered-input :deep() input[type="number"] {
  -moz-appearance: textfield;
  padding-left: 0;
  padding-right: 0;
}
.v-field
  .v-field--active
  .v-field--center-affix
  .v-field--dirty
  .v-field--no-label
  .v-field--variant-outlined
  .v-theme--light
  .v-locale--is-ltr {
  padding-right: 0;
}
.centered-input :deep() input::-webkit-outer-spin-button,
.centered-input :deep() input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.btn :hover {
  cursor: pointer;
}
</style>
