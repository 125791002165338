// WebSocketService.js

import SockJS from 'sockjs-client';
// import Stomp from 'stompjs';
import Stomp from 'webstomp-client';

import { store } from '@/store/index'

export default class WebSocketService {
  constructor() {
    this.stompClient = null;
  }

  connect() {
    // const serverURL = "http://localhost:8080/websocket"; // SockJS 서버 URL
    let serverURL = null; // SockJS 서버 URL
    if(process.env.VUE_APP_ENV === 'prod'){
      serverURL = "http://hdx.kr/websocket"
    }else{
      serverURL = "http://localhost:8080/websocket"
    }
    const socket = new SockJS(serverURL);
    this.stompClient = Stomp.over(socket);
    console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`)

    // with jwt header
    let headers = { Authorization: store.getters.token };
    this.stompClient.connect(headers, this.onConnect, this.onError);

    // with no header
    // this.stompClient.connect({}, this.onConnect, this.onError);

    // const serverURL = "http://localhost:8080/ws"
    // let socket = new SockJS(serverURL);
    // this.stompClient = Stomp.over(socket);
    // console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`)
    // this.stompClient.connect({}, this.onConnect, this.onError);
  }

  onConnect() {
    console.log('WebSocket connected');
    // 연결이 성공한 후의 로직을 여기에 작성합니다.
  }

  onError(error) {
    console.error('WebSocket error:', error);
    // 연결 오류 처리 로직을 여기에 작성합니다.
  }

  /* disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  } */

  disconnect(headers) {
    if (this.stompClient) {
      this.stompClient.disconnect(
        () => {
          console.log('Websocket Disconnected By WebSocketService.');
        },
        headers
      );
    }
  }

  // 필요에 따라 메시지 구독, 발행 등의 기능을 추가할 수 있습니다.
  subscribe(destination, callback) {
    if (this.stompClient) {
      return this.stompClient.subscribe(destination, callback);
    }
  }

  send(destination, body, headers) {
    if (this.stompClient) {
      this.stompClient.send(destination, body, headers);
    }
  }

  // gh.nam
  // connected 여부 리턴
  isConnected(){
    return this.stompClient.connected;
  }
}
