export default {
  "language": {
    "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일본어"])}
  },
  "menu": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메인"])},
    "timerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 리스트"])},
    "structureCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 생성"])},
    "gameCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 생성"])},
    "gameList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 리스트"])},
    "storeManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매장정보 관리"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀딕스에 오신걸 환영합니다"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀딕스는 실시간 타이머 리스트 플랫폼 입니다."])},
    "rememberLoginInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 정보 기억하기"])},
    "button": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
      "findId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 찾기"])},
      "findPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 찾기"])}
    },
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 문의 : 010-7519-7037"])},
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상담시간 : 00:00 ~ 24:00"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])}
  },
  "signUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
    "button": {
      "availableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID 중복확인"])},
      "availableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 중복확인"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
      "moveToLoginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 화면으로 이동"])}
    }
  },
  "main": {
    "sir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["님"])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손님(Guest)"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀딕스에 오신 것을 환영합니다!"])}
  },
  "structure": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 생성"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 초기화"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 삭제"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 저장"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 이름"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 이름 입력"])},
    "modifyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 이름 변경"])},
    "value": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["밸류 설정"])},
      "earlyBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바이인+얼리칩"])},
      "buyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바이인 칩"])},
      "firstReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫번째 리바인 칩"])},
      "secondReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["두번째 리바인 칩"])},
      "thirdReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세번째 리바인 칩"])},
      "addOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애드온 칩"])},
      "buyInAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바이인 금액"])},
      "registrationDeadline": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록마감"])},
        "selectLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lv 선택"])}
      },
      "prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시상"])},
      "entryLimit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔트리 제한"])},
        "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["있음"])},
        "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["없음"])}
      },
      "reEntryCount": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리바인 횟수"])},
        "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회"])}
      }
    },
    "level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨 설정"])},
      "smallBlind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스몰 블라인드"])},
      "bigBlind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빅 블라인드"])},
      "ante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앤티"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지속시간(분)"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
      "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨 추가"])},
      "addBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break 추가"])}
    },
    "prize": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프라이즈 설정"])}
    },
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처"])}
  },
  "timerDesign": {
    "backgroundImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 배경화면"])},
    "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부"])},
    "attachPopup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 배경화면 첨부"])},
      "notice": {
        "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 파일크기: 5MB 제한"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 파일명: 한글, 영문, 숫자만 허용"])},
        "extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 확장자: png, jpeg, jpg"])},
        "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 기존 배경화면은 삭제되고 새 배경화면으로 적용됩니다."])}
      },
      "dragndrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 배경화면 파일을 드래그해서 여기에 놓아주세요."])},
      "buttonAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼으로 첨부하기"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 디자인"])},
    "selectSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 선택"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미리보기"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버튼"])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 1"])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 2"])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 3"])},
    "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 4"])},
    "section5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 5"])},
    "section6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["섹션 6"])},
    "selectColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["색상 선택"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 디자인 저장"])},
    "displayGameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 이름 디스플레이"])},
    "displayStructureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 이름 디스플레이"])}
  },
  "game": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 이름"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 생성"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 삭제"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 이름 입력"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 리스트"])},
    "listNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성된지 8시간 내의 게임 또는 종료 상태가 아닌 게임만 표시됩니다."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 추가"])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행상황"])}
    },
    "regStatus": {
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록중"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록마감"])}
    },
    "readyToStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 시작 전"])}
  },
  "controller": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 컨트롤러"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타 이 머"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["적 용"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시 작"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시 정지"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 종료"])},
    "level": {
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨 이동"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨 시작"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨 정보"])}
    },
    "player": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이어 설정"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 인원"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엔트리"])},
      "earlyBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바이인 + 얼리칩"])},
      "buyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바이인"])},
      "firstReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫번째 리바인"])},
      "secondReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["두번째 리바인"])},
      "thirdReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세번째 리바인"])},
      "addOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애드온"])}
    }
  },
  "common": {
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로고침"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성시간"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트로 돌아가기"])}
  },
  "message": {
    "login": {
      "enterIdPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID, Password를 입력해주세요."])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5시간 동안 입력이 없어 로그아웃됩니다."])},
      "error": {
        "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 시간이 만료되었습니다. 다시 로그인 해주세요."])},
        "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["권한이 없습니다. 로그인 화면으로 이동합니다."])}
      }
    },
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃 하시겠습니까?"])}
    },
    "signUp": {
      "enterId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID를 입력해주세요."])},
      "availableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 ID 입니다."])},
      "unavailableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 수 없는 ID 입니다."])},
      "enterNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임을 입력해주세요."])},
      "availableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 닉네임입니다."])},
      "unavailableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 수 없는 닉네임입니다."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입이 완료되었습니다. 로그인 화면으로 이동합니다."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입을 실패하였습니다."])},
      "checkDupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["먼저 ID 중복확인을 해주세요."])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력해주세요."])},
      "enterConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인을 입력해주세요."])},
      "checkPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호와 비밀번호 확인이 일치하지 않습니다."])},
      "checkDupNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["먼저 닉네임 중복확인을 해주세요."])}
    },
    "structure": {
      "reset": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성 중인 스트럭처를 초기화 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처가 초기화 되었습니다."])}
      },
      "save": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처를 저장 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처가 저장 되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 저장을 실패하였습니다."])}
      },
      "create": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 생성 화면으로 이동 하시겠습니까?"])}
      },
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처를 삭제 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처가 삭제 되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처 삭제를 실패하였습니다."])}
      }
    },
    "timerDesign": {
      "save": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스트럭처의 타이머 디자인을 저장 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 디자인이 저장되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 디자인 저장을 실패하였습니다."])}
      },
      "attachPopup": {
        "error": {
          "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자, 한글, 영문을 포함한 공백없는 파일명만 가능합니다."])},
          "fileCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일은 하나만 첨부해주세요."])},
          "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일크기는 5MB 이하로 제한됩니다."])}
        }
      }
    },
    "game": {
      "create": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임을 생성 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임이 생성되었습니다. 게임 리스트로 이동합니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 생성을 실패하였습니다."])}
      },
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임을 삭제 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임이 삭제 되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 삭제를 실패하였습니다."])}
      }
    },
    "controller": {
      "timer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 화면 이동"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 화면으로 이동하시겠습니까?"])}
      },
      "apply": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경사항 적용"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경된 값을 타이머에 적용하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경사항이 타이머에 적용되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경사항 적용을 실패하였습니다."])}
      },
      "action": {
        "type": {
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작"])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일시정지"])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["타이머 ", _interpolate(_named("action"))])},
        "confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["타이머를 ", _interpolate(_named("action")), " 하시겠습니까?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["타이머가 ", _interpolate(_named("action")), " 되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타이머 상태 변경을 실패하였습니다."])},
        "handle": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임이 생성완료 상태입니다."])},
          "init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임이 시작대기 상태입니다."])},
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임이 이미 진행 상태입니다."])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임이 이미 일시정지 상태입니다."])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료된 게임입니다."])}
        },
        "level": {
          "confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["레벨 ", _interpolate(_named("level")), "로 이동하시겠습니까?"])},
          "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["레벨 ", _interpolate(_named("level")), "로 이동되었습니다"])},
          "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨 이동을 실패하였습니다."])}
        }
      }
    },
    "common": {
      "refresh": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로고침이 완료되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로고침을 실패하였습니다."])}
      },
      "shortenUrl": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전광판 링크 생성 완료"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전광판 링크 생성을 실패하였습니다."])}
      }
    }
  }
}