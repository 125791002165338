<template>
  <VContainer fluid>
    <VCardTitle class="mb-5">
      <h5 class="text-h5">주소 정보</h5>
    </VCardTitle>

    <!-- 주소1 -->
    <div>
      <VRow class="justify-center">
        <VCol cols="8">
          <div class="w-100 mb-2 text-left">
            <span class="text-20">주소1</span>
          </div>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="1" class="px-1 py-5"></VCol>
        <VCol cols="5" class="px-1">
          <VSelect
            active
            variant="outlined"
            hide-details
            density="compact"
            label="주소1"
            v-model="code.address1.selected"
            :items="code.address1.items"
            item-title="name"
            item-value="id"
          />
        </VCol>
        <VCol cols="2" class="py-1">
          <v-switch
            hide-details
            label="수정"
            v-model="toggle.address1"
            inset
            @click="toggleAddress1"
          />
        </VCol>
      </VRow>

      <!-- 주소1 수정 -->
      <div v-if="toggle.address1 && code.address1.selected != ''">
        <VRow class="justify-center">
          <VCol cols="5" class="px-1">
            <VTextField
              v-model="modify.address1"
              placeholder="위 주소1 값을 여기에 입력된 값으로 변경합니다."
              label="수정 후 주소1"
              clearable
            />
          </VCol>
          <VCol cols="1" class="px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              width="100%"
              min-width="5rem"
              @click.stop="modifyAddress1"
            >
              수정
            </VBtn>
          </VCol>
        </VRow>
      </div>

      <!-- 주소1 추가 -->
      <VRow class="justify-center px-1 py-5">
        <VCol cols="8">
          <span class="text-20">주소1 추가</span>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <!-- <VCol cols="1" class="px-1 py-5"></VCol> -->
        <!-- <VCol cols="1" class="px-1 py-5"></VCol> -->
        <VCol cols="5" class="px-1 py-5">
          <v-text-field
            hide-details
            variant="outlined"
            density="compact"
            label="추가할 주소1 값을 입력해주세요."
            placeholder=""
            v-model="register.address1"
            clearable
          />
        </VCol>
        <VCol cols="1" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="5rem"
            @click.stop="registerAddress1"
          >
            저장
          </VBtn>
        </VCol>
      </VRow>

      <!-- 주소1 App 전송 -->
      <VRow class="justify-center px-1 py-5">
        <VCol cols="8">
          <span class="text-20">주소1 App 전송</span>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="2" class="px-1 py-5">
          <VSelect
            variant="plain"
            hide-details
            density="compact"
            label="주소1"
            v-model="code.address1.selected"
            :items="code.address1.items"
            item-title="name"
            item-value="id"
            readonly
          />
        </VCol>
        <VCol cols="2" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="6rem"
            @click.stop="sendRegisterAddress('address1')"
          >
            App 전송(생성)
          </VBtn>
        </VCol>
        <VCol cols="2" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="6rem"
            @click.stop="sendModifyAddress('address1')"
          >
            App 전송(수정)
          </VBtn>
        </VCol>
      </VRow>
    </div>

    <br><v-divider inset></v-divider><br>

    <!-- 주소2 -->
    <div>
      <VRow class="justify-center">
        <VCol cols="8">
          <div class="w-100 mb-2 text-left">
            <span class="text-20">주소2</span>
          </div>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="1" class="px-1 py-5"></VCol>
        <VCol cols="5" class="px-1">
          <VSelect
            active
            variant="outlined"
            hide-details
            density="compact"
            label="주소2"
            v-model="code.address2.selected"
            :items="code.address2.items"
            item-title="name"
            item-value="id"
          />
        </VCol>
        <VCol cols="2" class="py-1">
          <v-switch
            v-if="code.address2.selected != ''"
            hide-details
            label="수정"
            v-model="toggle.address2"
            inset
            @click="toggleAddress2"
          />
        </VCol>
      </VRow>

      <!-- 주소2 수정 -->
      <div v-if="toggle.address2 && code.address2.selected != ''">
        <VRow class="justify-center">
          <VCol cols="5" class="px-1">
            <VTextField
              v-model="modify.address2"
              placeholder="위 주소2 값을 여기에 입력된 값으로 변경합니다."
              label="수정 후 주소2"
              clearable
            />
          </VCol>
          <VCol cols="1" class="px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              width="100%"
              min-width="5rem"
              @click.stop="modifyAddress2"
            >
              수정
            </VBtn>
          </VCol>
        </VRow>
      </div>

      <!-- 주소2 추가 -->
      <VRow class="justify-center px-1 py-5">
        <VCol cols="8">
          <span class="text-20">주소2 추가</span>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="1" class="px-1 py-5">
          <VSelect
            variant="plain"
            hide-details
            density="compact"
            label="주소1"
            v-model="code.address1.selected"
            :items="code.address1.items"
            item-title="name"
            item-value="id"
            readonly
          />
        </VCol>
        <VCol cols="4" class="px-1 py-5">
          <v-text-field
            hide-details
            variant="outlined"
            density="compact"
            label="추가할 주소2 값을 입력해주세요."
            placeholder=""
            v-model="register.address2"
            clearable
          />
        </VCol>
        <VCol cols="1" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="50px"
            @click.stop="registerAddress2"
          >
            저장
          </VBtn>
        </VCol>
      </VRow>

      <!-- 주소2 App 전송 -->
      <VRow class="justify-center px-1 py-5">
        <VCol cols="8">
          <span class="text-20">주소2 App 전송</span>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="2" class="px-1 py-5">
          <VSelect
            variant="plain"
            hide-details
            density="compact"
            label="주소2"
            v-model="code.address2.selected"
            :items="code.address2.items"
            item-title="name"
            item-value="id"
            readonly
          />
        </VCol>
        <VCol cols="2" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="6rem"
            @click.stop="sendRegisterAddress('address2')"
          >
            App 전송(생성)
          </VBtn>
        </VCol>
        <VCol cols="2" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="6rem"
            @click.stop="sendModifyAddress('address2')"
          >
            App 전송(수정)
          </VBtn>
        </VCol>
      </VRow>
    </div>

    <br><v-divider inset></v-divider><br>

    <!-- 주소3 -->
    <div>
      <VRow class="justify-center">
        <VCol cols="8">
          <div class="w-100 mb-2 text-left">
            <span class="text-20">주소3</span>
          </div>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="1" class="px-1 py-5"></VCol>
        <VCol cols="5" class="px-1">
          <VSelect
            active
            variant="outlined"
            hide-details
            density="compact"
            label="주소3"
            v-model="code.address3.selected"
            :items="code.address3.items"
            item-title="name"
            item-value="id"
          />
        </VCol>
        <VCol cols="2" class="py-1">
          <v-switch
            v-if="code.address3.selected != ''"
            hide-details
            label="수정"
            v-model="toggle.address3"
            inset
            @click="toggleAddress3"
          />
        </VCol>
      </VRow>

      <!-- 주소3 수정 -->
      <div v-if="toggle.address3 && code.address3.selected != ''">
        <VRow class="justify-center">
          <VCol cols="5" class="px-1">
            <VTextField
              v-model="modify.address3"
              placeholder="위 주소3 값을 여기에 입력된 값으로 변경합니다."
              label="수정 후 주소3"
              clearable
            />
          </VCol>
          <VCol cols="1" class="px-1 py-5">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              width="100%"
              min-width="5rem"
              @click.stop="modifyAddress3"
            >
              수정
            </VBtn>
          </VCol>
        </VRow>
      </div>

      <!-- 주소3 추가 -->
      <VRow class="justify-center px-1 py-5">
        <VCol cols="8">
          <span class="text-20">주소3 추가</span>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="1" class="px-1 py-5">
          <VSelect
            variant="plain"
            hide-details
            density="compact"
            label="주소1"
            v-model="code.address1.selected"
            :items="code.address1.items"
            item-title="name"
            item-value="id"
            readonly
          />
        </VCol>
        <VCol cols="1" class="px-1 py-5">
          <VSelect
            variant="plain"
            hide-details
            density="compact"
            label="주소2"
            v-model="code.address2.selected"
            :items="code.address2.items"
            item-title="name"
            item-value="id"
            readonly
          />
        </VCol>
        <VCol cols="3" class="px-1 py-5">
          <v-text-field
            hide-details
            variant="outlined"
            density="compact"
            label="추가할 주소3 값을 입력해주세요."
            placeholder=""
            v-model="register.address3"
            clearable
          />
        </VCol>
        <VCol cols="1" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="5rem"
            @click.stop="registerAddress3"
          >
            저장
          </VBtn>
        </VCol>
      </VRow>

      <!-- 주소3 App 전송 -->
      <VRow class="justify-center px-1 py-5">
        <VCol cols="8">
          <span class="text-20">주소3 App 전송</span>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="2" class="px-1 py-5">
          <VSelect
            variant="plain"
            hide-details
            density="compact"
            label="주소3"
            v-model="code.address3.selected"
            :items="code.address3.items"
            item-title="name"
            item-value="id"
            readonly
          />
        </VCol>
        <VCol cols="2" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="6rem"
            @click.stop="sendRegisterAddress('address3')"
          >
            App 전송(생성)
          </VBtn>
        </VCol>
        <VCol cols="2" class="px-1 py-5">
          <VBtn
            class="btn"
            rounded="lg"
            color="primary"
            width="100%"
            min-width="6rem"
            @click.stop="sendModifyAddress('address3')"
          >
            App 전송(수정)
          </VBtn>
        </VCol>
      </VRow>
    </div>

    <br><v-divider inset></v-divider><br>

    <VRow class="justify-center">
      <VCol cols="8">
        <div class="w-100 d-md-flex justify-end align-center">
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              @click.stop="clickMoveToStoreList"
              width="100%"
              min-width="150px"
            >
              목록
            </VBtn>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />

</template>

<script>
import Alert from "@/components/AlertDefault";

export default {
  name: "AddressManage",
  components: {
    Alert
  },
  data: () => ({
    code: {
      address1: {
        selected: '',
        items : []
      },
      address2: {
        selected: '',
        items : []
      },
      address3: {
        selected: '',
        items : []
      },
    },
    toggle: {
      address1: false,
      address2: false,
      address3: false
    },
    modify: {
      address1: '',
      address2: '',
      address3: '',
    },
    register: {
      address1: '',
      address2: '',
      address3: '',
    },

    url: {
      /* find */
      address1: "/api/code/address/tier1",
      address2: "/api/code/address/tier2",
      address3: "/api/code/address/tier3",
      /* create, modify */
      address1Value: "/api/code/address/tier1/value",
      address2Value: "/api/code/address/tier2/value",
      address3Value: "/api/code/address/tier3/value",
      /* App */
      send: "/api/code/address/send"
    },

    alert: {
      msg: '',
      type: 'success'
    },
  }),
  created() {
    this.refreshAllAddress();
  },
  watch: {
    "code.address1.selected"() {
      this.loadAddress2();
    },
    "code.address2.selected"() {
      this.loadAddress3();
    },
  },
  methods: {
    async refreshAllAddress(){
      await this.loadAddress1();
      await this.loadAddress2();
      await this.loadAddress3();
    },

    /* APIs */
    loadAddress1() {
      /* this.$axios
        .post(this.url.address1, {
          parentId: "N/A",
          tier: 1
        })
        .then((res) => {
          console.log(res.data);
          this.code.address1.items = res.data;
          this.code.address1.selected = this.code.address1.items[0].id;
        }); */

      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.address1, {
            parentId: "N/A",
            tier: 1
          })
          .then((res) => {
            this.code.address1.items = res.data;
            this.code.address1.selected = this.code.address1.items[0].id;
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    loadAddress2() {
      /* this.$axios
        .post(this.url.address2, {
          parentId: this.code.address1.selected,
          tier: 2
        })
        .then((res) => {
          console.log(res.data);
          this.code.address2.items = res.data;
          this.code.address2.selected = this.code.address2.items[0].id;
        }); */

      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.address2, {
            parentId: this.code.address1.selected,
            tier: 2
          })
          .then((res) => {
            this.code.address2.items = res.data;
            if(res.data.length != 0){
              this.code.address2.selected = this.code.address2.items[0].id;
            }else{
              this.code.address2.selected = '';
            }
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    loadAddress3() {
      /* this.$axios
        .post(this.url.address3, {
          parentId: this.code.address2.selected,
          tier: 3
        })
        .then((res) => {
          console.log(res.data);
          this.code.address3.items = res.data;
          this.code.address3.selected = this.code.address3.items[0].id;
        }); */

      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.address3, {
            parentId: this.code.address2.selected,
            tier: 3
          })
          .then((res) => {
            this.code.address3.items = res.data;
            if(res.data.length != 0){
              this.code.address3.selected = this.code.address3.items[0].id;
            }else{
              this.code.address3.selected = '';
            }
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    registerAddress1(){
      this.$axios
        .post(this.url.address1Value, {
          name: this.register.address1
        })
        .then((res) => {
          if(res.data.code === 1){
            this.refreshAllAddress();
            this.register.address1 = '';
            this.openAlert('주소1이 생성되었습니다.', 'success');
          }else if(res.data.code === -1){
            this.openAlert('주소1은 생성되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          }else{
            this.openAlert('주소1 생성을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소1 생성을 실패하였습니다.', 'error');
        });
    },
    registerAddress2(){
      this.$axios
        .post(this.url.address2Value, {
          name: this.register.address2,
          parentId: this.code.address1.selected
        })
        .then((res) => {
          if(res.data.code === 1){
            this.loadAddress2();
            this.register.address2 = '';
            this.openAlert('주소2가 생성되었습니다.', 'success');
          }else if(res.data.code === -1){
            this.openAlert('주소2는 생성되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          }else{
            this.openAlert('주소2 생성을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소2 생성을 실패하였습니다.', 'error');
        });
    },
    registerAddress3(){
      this.$axios
        .post(this.url.address3Value, {
          name: this.register.address3,
          parentId: this.code.address2.selected
        })
        .then((res) => {
          if(res.data.code === 1){
            this.loadAddress3();
            this.register.address3 = '';
            this.openAlert('주소3이 생성되었습니다.', 'success');
          }else if(res.data.code === -1){
            this.openAlert('주소3은 생성되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          }else{
            this.openAlert('주소3 생성을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소3 생성을 실패하였습니다.', 'error');
        });
    },

    modifyAddress1(){
      this.$axios
        .put(this.url.address1Value, {
          id: this.code.address1.selected,
          name: this.modify.address1
        })
        .then((res) => {
          if(res.data.code === 1){
            this.loadAddress1();
            this.modify.address1 = '';
            this.openAlert('주소1이 수정되었습니다.', 'success');
          }else if(res.data.code === -1){
            this.openAlert('주소1은 수정되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          }else{
            this.openAlert('주소1 수정을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소1 수정을 실패하였습니다.', 'error');
        });
    },
    modifyAddress2(){
      this.$axios
        .put(this.url.address2Value, {
          id: this.code.address2.selected,
          name: this.modify.address2
        })
        .then((res) => {
          if(res.data.code === 1){
            this.loadAddress2();
            this.modify.address2 = '';
            this.openAlert('주소2가 수정되었습니다.', 'success');
          }else if(res.data.code === -1){
            this.openAlert('주소2는 수정되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          }else{
            this.openAlert('주소2 수정을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소2 수정을 실패하였습니다.', 'error');
        });
    },
    modifyAddress3(){
      this.$axios
        .put(this.url.address3Value, {
          id: this.code.address3.selected,
          name: this.modify.address3
        })
        .then((res) => {
          if(res.data.code === 1){
            this.loadAddress3();
            this.modify.address3 = '';
            this.openAlert('주소3이 수정되었습니다.', 'success');
          }else if(res.data.code === -1){
            this.openAlert('주소3은 수정되었으나 App 연계에 실패하였습니다(' + res.data.message + ').', 'error');
          }else{
            this.openAlert('주소3 수정을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소3 수정을 실패하였습니다.', 'error');
        });
    },

    /* App I/F */
    sendRegisterAddress(addressTier){
      let address = {
        id: this.code[`${addressTier}`].selected
      }
      this.$axios
        .post(this.url.send, address)
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('주소 데이터가 App에 전송되었습니다.', 'success');
          }else{
            this.openAlert('주소 데이터 App 전송을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소 데이터 App 전송을 실패하였습니다.', 'error');
        });
    },
    sendModifyAddress(addressTier){
      let address = {
        id: this.code[`${addressTier}`].selected
      }
      this.$axios
        .put(this.url.send, address)
        .then((res) => {
          if(res.data.code === 1){
            this.openAlert('주소 데이터가 App에 전송되었습니다.', 'success');
          }else{
            this.openAlert('주소 데이터 App 전송을 실패하였습니다(' + res.data.message + ').', 'error');
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert('주소 데이터 App 전송을 실패하였습니다.', 'error');
        });
    },


    /* Button */
    toggleAddress1(){
      this.toggle.address1 = !this.toggle.address1;
    },
    toggleAddress2(){
      this.toggle.address2 = !this.toggle.address2;
    },
    toggleAddress3(){
      this.toggle.address3 = !this.toggle.address3;
    },
    clickMoveToStoreList(){
      this.$router.push({
        name: "StoreInfoList",
      });
    },

    /* Alert */
    openAlert(msg, type){
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

  },
};
</script>
