import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
// import Vuetify from 'vuetify'
import { loadFonts } from "./plugins/webfontloader";

import dayjs from "dayjs";

import router from "./router";
import "@/assets/css/main.css";

import { store } from "@/store/index"
import axios from "@/common/axios-interceptor";
import i18n from './i18n'

// import theme from './plugins/theme'

loadFonts();

const app = createApp(App).use(i18n);
app.use(router)
app.use(store)
app.use(vuetify);
app.use(dayjs);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$i18n = i18n;

app.mount('#app');
