<template>
  <!-- <div class="d-flex flex-column"> -->
  <div class="d-flex flex-column" v-if="env === 'prod'">

    <!-- SUPER_ADMIN -->
    <div v-if="role === 'SUPER_ADMIN'">
      <span align="center">
        <h2>
          {{ store.name }} {{ $store.getters.nickname }}({{ $store.getters.id }}) 님
          <br>
          홀딕스에 오신 것을 환영합니다 !
        </h2>
      </span>

      <v-text-field>ID : {{ $store.getters.id }}</v-text-field>
      <v-text-field>Nickname : {{ $store.getters.nickname }}</v-text-field>
      <v-text-field>Name : {{ $store.getters.name }}</v-text-field>
      <v-text-field>Phone No. : {{ $store.getters.phoneNo }}</v-text-field>
      <v-text-field>E-mail : {{ $store.getters.email }}</v-text-field>
      <v-text-field>Role : {{ $store.getters.role }}</v-text-field>
      <VSelect
        class="text-xs w-100"
        hide-detail="true"
        v-model="store.selected"
        :items="store.items"
        item-title="name"
        item-value="id"
        label="매장정보 선택"
        size
      />
    </div>

    <!-- STORE_ADMIN -->
    <div v-else-if="role === 'STORE_ADMIN'">
      <span align="center">
        <h2>
          {{ store.name }} {{ $store.getters.nickname }}({{ $store.getters.id }}) {{ $t('main.sir') }}
          <br>
          {{ $t('main.welcome') }}
        </h2>
      </span>
    </div>

    <!-- USER -->
    <div v-else-if="role === 'USER'">
      <span align="center">
        <h2>
          {{ $store.getters.nickname }}({{ $store.getters.id }}) {{ $t('main.sir') }}
          <br>
          {{ $t('main.welcome') }}
        </h2>
      </span>
    </div>

    <!-- Anonymous -->
    <div v-else>
      <span align="center">
        <h2>
          {{ $t('main.guest') }}
          <br>
          {{ $t('main.welcome') }}
        </h2>
      </span>
    </div>
  </div>

  <!-- qa -->
  <div class="d-flex flex-column" v-if="env === 'qa'">
    <div v-if="role === 'SUPER_ADMIN'">
      <v-text-field>ID : {{ $store.getters.id }}</v-text-field>
      <v-text-field>Nickname : {{ $store.getters.nickname }}</v-text-field>
      <v-text-field>Name : {{ $store.getters.name }}</v-text-field>
      <v-text-field>Phone No. : {{ $store.getters.phoneNo }}</v-text-field>
      <v-text-field>E-mail : {{ $store.getters.email }}</v-text-field>
      <v-text-field>Role : {{ $store.getters.role }}</v-text-field>
      <VSelect
        class="text-xs w-100"
        hide-detail="true"
        v-model="store.selected"
        :items="store.items"
        item-title="name"
        item-value="id"
        label="매장정보 선택"
        size
      />
    </div>

    <div v-if="role === 'STORE_ADMIN'">
      <span align="center">
        <h2>
          {{ store.name }} {{ $store.getters.nickname }}({{ $store.getters.id }}) 님
          <br>
          홀딕스에 오신 것을 환영합니다 !
        </h2>
      </span>
    </div>

    <!-- USER -->
    <div v-else>
      <span align="center">
        <h2>
          {{ $store.getters.nickname }}({{ $store.getters.id }}) 님
          <br>
          홀딕스에 오신 것을 환영합니다 !
        </h2>
      </span>
    </div>
  </div>

  <!-- dev -->
  <!-- <div class="d-flex flex-column" v-else>
    <div v-if="role === 'SUPER_ADMIN'">
      <v-text-field>ID : {{ $store.getters.id }}</v-text-field>
      <v-text-field>Nickname : {{ $store.getters.nickname }}</v-text-field>
      <v-text-field>Name : {{ $store.getters.name }}</v-text-field>
      <v-text-field>Phone No. : {{ $store.getters.phoneNo }}</v-text-field>
      <v-text-field>E-mail : {{ $store.getters.email }}</v-text-field>
      <v-text-field>Role : {{ $store.getters.role }}</v-text-field>
      <VSelect
        class="text-xs w-100"
        hide-detail="true"
        v-model="store.selected"
        :items="store.items"
        item-title="name"
        item-value="id"
        label="매장정보 선택"
        size
      />
    </div>

    <div v-if="role === 'STORE_ADMIN'">
      <span align="center">
        <h2>
          {{ store.name }} {{ $store.getters.nickname }}({{ $store.getters.id }}) 님
          <br>
          홀딕스에 오신 것을 환영합니다 !
        </h2>
      </span>
    </div>

    <div v-else>
      <span align="center">
        <h2>
          {{ $store.getters.nickname }}({{ $store.getters.id }}) 님
          <br>
          홀딕스에 오신 것을 환영합니다 !
        </h2>
      </span>
    </div>
  </div> -->
  <div class="d-flex flex-column" v-if="env === 'dev'">
    <v-text-field>token : {{ $store.getters.token }}</v-text-field>
    <v-text-field>ID : {{ $store.getters.id }}</v-text-field>
    <v-text-field>Nickname : {{ $store.getters.nickname }}</v-text-field>
    <v-text-field>Name : {{ $store.getters.name }}</v-text-field>
    <v-text-field>Phone No. : {{ $store.getters.phoneNo }}</v-text-field>
    <v-text-field>E-mail : {{ $store.getters.email }}</v-text-field>
    <v-text-field>Role : {{ $store.getters.role }}</v-text-field>
    <v-text-field>Store ID : {{ $store.getters.storeId }}</v-text-field>
    <VSelect
      v-if="$store.getters.role === 'SUPER_ADMIN'"
      class="text-xs w-100"
      hide-detail="true"
      v-model="store.selected"
      :items="store.items"
      item-title="name"
      item-value="id"
      label="매장정보 선택"
      size
    />
    <v-text-field v-else-if="$store.getters.role === 'STORE_ADMIN'">
      매장이름 : {{ store.name }}
    </v-text-field>
  </div>

  <!-- language tester start -->
  <!-- <div class="d-flex flex-column" v-if="env === 'dev'">
    <v-select
      label="Language selector"
      :items="$i18n.availableLocales"
      :model-value="$i18n.locale"
      @update:model-value="updateLanguage"
    />
    <p>Vuetify Current locale : {{ $vuetify.locale.current }}</p>
    <p>Vuetify Fallback locale : {{ $vuetify.locale.fallback }}</p>
    <p>Vue locale : {{ $i18n.locale }}</p>
    <p>Vue available locale : {{ $i18n.availableLocales }}</p>
    <p>{{ $i18n.t('language.ko') }}</p>

    <v-select />
  </div> -->
  <!-- language tester end -->

</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      env: '',
      role: '',

      store: {
        // for STORE_ADMIN
        name: '',
        // for SUPER_ADMIN
        items: [],
        selected: ''
      },
      url: {
        storeDetail: '/api/store/detail',
        storeList: '/api/store/list'
      }
    }
  },
  created(){
    if(this.$store.getters.role === 'SUPER_ADMIN'){
      this.readStores()
    }else if(this.$store.getters.role === 'STORE_ADMIN'){
      this.readStore()
    }

    // 빌드환경마다 다르게 보이게 설정
    this.env = process.env.VUE_APP_ENV;
    this.role = this.$store.getters.role;
  },
  watch: {
    'store.selected'(){
      const storeId = this.store.selected
      this.$store.dispatch('storeId', { storeId })
    }
  },
  methods: {
    readStores(){
      this.$axios
        .post(this.url.storeList, {})
        .then((res) => {
          console.log(res.data);
          this.store.items = res.data;

          // SUPER_ADMIN 초기 로딩 시 매장정보 첫번째 값으로 세팅
          if(this.$store.getters.storeId === 'ALL'){
            this.store.selected = this.store.items[0].id
          }else{
            this.store.selected = this.$store.getters.storeId
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    readStore(){
      this.$axios
        .post(this.url.storeDetail, { id: this.$store.getters.storeId })
        .then((res) => {
          console.log(res.data);
          this.store.name = res.data.name;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // tester
    updateLanguage(newLanguage){
      this.$i18n.locale = newLanguage;
      this.$vuetify.locale.current = newLanguage;

      // vuex set
      const language = newLanguage;
      this.$store.dispatch('language', {language});
    }
  }
}
</script>
