<template>
  <VContainer fluid>
    <VCard class="pa-3">
      <VCardTitle class="mb-5">
        <h5 class="text-h5">비밀번호 변경</h5>
      </VCardTitle>

      <br><v-divider inset></v-divider><br>

      <VRow class="justify-center">
        <VCol cols="2">
          <div class="w-100 mb-2 text-left">
            <span class="text-20">아이디</span>
          </div>
        </VCol>
        <VCol cols="6" class="px-1">
          <v-autocomplete
            label="검색하려면 두 글자 이상 입력하세요."
            class="pa-0"
            density="compact"
            variant="filled"
            v-model="user.select"
            v-model:search="user.search"
            :loading="user.loading"
            :items="user.items"
            hide-no-data
            hide-details
            clearable
          ></v-autocomplete>
        </VCol>
      </VRow>
      <VRow class="justify-center">
        <VCol cols="2">
          <div class="w-100 mb-2 text-left">
            <span class="text-20">비밀번호</span>
          </div>
        </VCol>
        <VCol cols="6" class="px-1">
          <v-text-field
            label="변경할 비밀번호를 입력하세요."
            class="pa-0"
            density="compact"
            variant="filled"
            v-model="changeInfo.password"
          />
        </VCol>
      </VRow>

      <br><v-divider inset></v-divider><br>

      <VRow class="justify-end align-center">
        <v-col cols="2" class="mx-0">
          <VBtn
              class="btn"
              rounded="md"
              color="primary"
              @click="changePassword"
              min-width="150px"
              >
            {{ $t('signUp.button.save') }}
            </VBtn>
        </v-col>
        <v-col cols="2" class="mx-5">
            <VBtn
              class="btn"
              rounded="md"
              color="secondary"
              @click.stop="clickBackToList"
              min-width="150px"
              >
            뒤로가기
            </VBtn>
        </v-col>
      </VRow>
    </VCard>
  </VContainer>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />
</template>
<script>
import Alert from "@/components/AlertDefault";

export default {
  name: "PasswordManage",
  components: {
    Alert,
  },
  data: () => ({
    user: {
      items: [],
      loading: false,
      search: null,
      select: null,
    },
    changeInfo: {
      id: '',
      password: ''
    },
      alert: {
        msg: "",
        type: "success",
      },
    url: {
      userList: "/api/user/list",
      changePassword: "/api/user/password"
    },
  }),
  watch: {
    "user.search" (val) {
      val && val !== this.user.select && this.querySelections(val)
    },
    "user.select"() {
      this.changeInfo.id = this.user.select
    },
  },
  methods: {
    querySelections (v) {
      // 입력값의 길이가 2이상일 때만 데이터 검색
      if(v.length >= 2){
        this.user.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.loadUserList(v)
          this.user.loading = false
        }, 500)
      }
    },
    loadUserList(v) {
      this.$axios
        // .post(this.url.userList, { id: this.store.adminId })
        .post(this.url.userList, { id: v })
        .then((res) => {
          console.log(res.data);
          // this.user.items = res.data;
          const users = res.data;
          this.user.items = []
          users.forEach(user => {
            this.user.items.push(user.id)
          });
        });
    },
    changePassword(){
      if(!this.validation()){
        return;
      }

      const param = {
        id: this.changeInfo.id,
        password: this.changeInfo.password
      }
      this.$axios
        .post(this.url.changePassword, param)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            this.openAlert("패스워드가 변경되었습니다.", "success");
          } else {
            this.openAlert("패스워드 변경을 실패하였습니다.", "error");
          }
        })
        .catch((error) => {
          console.log(error);
          this.openAlert("패스워드 변경을 실패하였습니다.", "error");
        });
    },
    validation(){
      if(!this.changeInfo.id){
        this.openAlert("아이디를 입력해주세요.", 'warning');
        return false;
      }else if(!this.changeInfo.password){
        this.openAlert(this.$i18n.t('message.signUp.enterPassword'), 'warning');
        return false;
      }else{
        return true;
      }
    },
    clickBackToList(){
      this.$router.push({
        name: "StoreInfoList"
      });
    },

    /* Alert */
    openAlert(msg, type){
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },
  }
}
</script>
