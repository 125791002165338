<template>
  <!-- advertisement start -->
  <div v-if="ad.master.isValid && timer.currentLevel.level === 0"
    :style="{
      backgroundSize: '100% 100%',
      backgroundColor: '#000000',
      position: 'absolute',
      inset: '0',
    }"
  >
    <v-row class="overflow">

      <!-- ad left side -->
      <v-col
        cols="3"
        class="d-flex flex-column"
        style="height: 100vh; max-height: 100vh; overflow-y: auto;"
      >
        <v-row class="d-flex flex-column align-center pt-3">
          <v-input
            style="font-weight: bold; font-size: 2.5vw;"
          >
            {{ displayCurrentLevel }}
          </v-input>
        </v-row>

        <v-row class="d-flex flex-column align-center">
          <v-input
            style="font-weight: bold; font-size: 5vw;"
          >
            {{ displayRemainLevelTime }}
          </v-input>
        </v-row>

        <v-row class="d-flex flex-column align-center">
          <v-input
            style="font-weight: bold; font-size: 1.8vw;"
            class="d-inline-block"
          >
            <div class="d-flex flex-column align-center">
              <span>NEXT LEVEL {{ displayNextLevelAtAdPage }}</span>
              <span>BLINDS: {{ displayNextBlindsValue }}</span>
              <span>{{ displayNextAnteTitle }} {{ displayNextAnteValue }}</span>
              <span class="pt-1" style="font-size: 2vw;">PLAYER {{ game.player.current }} / {{ game.player.total }}</span>
            </div>
          </v-input>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-center">
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              icon="mdi-play-box-outline"
              @click.stop="clickStartGame"
            />
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              icon="mdi-pause"
              @click.stop="clickPauseGame"
            />
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              icon="mdi-refresh"
              @click.stop="clickRefresh"
            />
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              :icon="control.icon"
              @click.stop="clickToggleControlPanel"
            />
          </v-col>
        </v-row>

        <v-row class="d-flex flex-column align-center">
          <v-input
            class="d-inline-block"
            style="font-weight: bold; font-size: 1.6vw;"
          >
            <div>
              <div class="d-flex flex-column align-center pb-3">
                <span style="font-weight: bold; font-size: 2vw; color: white; background-color: #02A1B4; display: block;">
                  &nbsp;&nbsp;&nbsp;HOLDIX 앱 출시!&nbsp;&nbsp;&nbsp;
                </span>
              </div>
              <div style="font-weight: bold; font-size: 1.4vw;">
                <div class="d-flex flex-column align-center">
                  <span>
                    <span style="color: #02A1B4; font-size: 1.8vw;">HOLDIX 마일리지</span>를 획득하여
                  </span>
                </div>
                <div class="d-flex flex-column align-center">
                  <span>PAY OUT EVENT에 참가해보세요!</span>
                </div>
              </div>
            </div>
          </v-input>
        </v-row>

        <v-row>
          <v-col cols="1"/>
          <v-col cols="5" class="d-flex flex-column align-center">
            <v-img
              :src="require(`@/assets/images/display/android_qr.jpg`)"
              width="5vw"
            />
            <v-input
              style="font-weight: bold; font-size: 1vw;"
            >
              안드로이드
            </v-input>
          </v-col>
          <v-col cols="5" class="d-flex flex-column align-center">
            <v-img
              :src="require(`@/assets/images/display/ios_qr.jpg`)"
              width="5vw"
            />
            <v-input
              style="font-weight: bold; font-size: 1vw;"
            >
              아이폰
            </v-input>
          </v-col>
          <v-col cols="1"/>
        </v-row>


        <v-row class="d-flex flex-column align-center">
          <v-input
            style="font-size: 1.2vw;"
          >
            <div class="d-flex flex-column">
              <div>마켓에서 <span style="font-weight: bold; color: #02A1B4;">'홀딕스'</span> 검색</div>
            </div>
          </v-input>
        </v-row>
      </v-col>

      <!-- ad main page -->
      <v-col
        cols="9"
        class="d-flex h-screen flex-column justify-space-between align-start pl-5 relative pt-0"
      >
        <v-carousel
          class="fill-height"
          :interval="ad.master.cycleTime"
          cycle
          show-arrows="hover"
          hide-delimiters
        >
          <v-carousel-item v-for="(page, index) in ad.page.items" :key="index">
            <div style="position: relative; display: flex; justify-content: center; align-items: center; height: 100vh;">
              <v-img
                :src="page"
                max-width="100vw"
                max-height="100vh"
                style="margin: auto;"
              />
              <v-icon
                class="text-btn"
                style="position: absolute; top: 1rem; right: 1rem;"
                size="2vw"
                icon="mdi-fullscreen"
                @click.stop="toggleFullscreen"
              ></v-icon>
            </div>
          </v-carousel-item>
        </v-carousel>

        <!-- Control Panel -->
        <div v-if="control.show"
          class="d-flex w-100 justify-space-between overlay pl-10 pr-10"
        >
          <!-- Player 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Player</span
              >
              <v-tooltip activator="parent" location="top"
                > ({{ game.player.current }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('current', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('current', 1)"
              />
            </div>
          </div>

          <!-- BuyIn + Early Chip 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Buy In + E</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.earlyBuyIn) }} ({{ game.player.earlyBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('earlyBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('earlyBuyIn', 1)"
              />
            </div>
          </div>

          <!-- BuyIn 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Buy In</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.buyIn) }} ({{ game.player.buyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('buyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('buyIn', 1)"
              />
            </div>
          </div>

          <!-- 1st ReBuyIn 버튼 -->
          <div
            v-if="control.structure.value.firstReBuyIn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >1st</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.firstReBuyIn) }} ({{ game.player.firstReBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('firstReBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('firstReBuyIn', 1)"
              />
            </div>
          </div>

          <!-- 2nd ReBuyIn 버튼 -->
          <div
            v-if="control.structure.value.secondReBuyIn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >2nd</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.secondReBuyIn) }} ({{ game.player.secondReBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('secondReBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('secondReBuyIn', 1)"
              />
            </div>
          </div>

          <!-- 3rd ReBuyIn 버튼 -->
          <div
            v-if="control.structure.value.thirdReBuyIn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >3rd</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.thirdReBuyIn) }} ({{ game.player.thirdReBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('thirdReBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('thirdReBuyIn', 1)"
              />
            </div>
          </div>

          <!-- AddOn 버튼 -->
          <div
            v-if="control.structure.value.addOn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Add On</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.addOn) }} ({{ game.player.addOn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('addOn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('addOn', 1)"
              />
            </div>
          </div>

          <!-- Lv 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Lv</span
              >
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelMoveLevel(-1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelMoveLevel(1)"
              />
            </div>
          </div>

          <!-- Lv 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >1 Min.</span
              >
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-rewind-60"
                size="2vw"
                @click.stop="clickControlPanelMoveTime(60)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-fast-forward-60"
                size="2vw"
                @click.stop="clickControlPanelMoveTime(-60)"
              />
            </div>
          </div>

        </div>
      </v-col>

    </v-row>
  </div>
  <!-- advertisement end -->

  <!-- 전광판 메인 -->
  <div
    v-else
    class="h-screen"
    :style="{
      backgroundImage: 'url(' + structure.backgroundImageUrl + ')',
      backgroundSize: '100% 100%',
      position: 'absolute',
      inset: '0',
    }"
  >
    <VRow class="overflow">
      <!-- Left Side -->
      <VCol
        cols="3"
        md="3"
        class="d-flex h-screen flex-column justify-space-between align-start pl-5 relative pt-0"
      >
        <div class="w-100">
          <VIcon
            class="text-btn"
            :style="{ color: design[7] }"
            size="2vw"
            icon="mdi-menu"
            @click.stop="toggleModal"
            ref="modalIcon"
          >
          </VIcon>
        </div>
        <div
          class="d-flex text-lg-h5 text-base flex-column justify-center align-center"
        >
          <p v-html="game.prizeContent"></p>
          <!-- FIXME for test -->
          <!-- <div class="mt-2">
            <v-btn @click.stop="startGame" class="mr-2 pa-2"
              ripple append-icon="mdi-play" color="primary">START</v-btn>
            <v-btn @click.stop="stopGame">STOP</v-btn>
            <v-btn @click.stop="resetGame">RESET</v-btn>
            <v-btn @click.stop="initGame">INIT</v-btn>
            <v-btn @click.stop="toggleFullscreen">전체화면</v-btn>
          </div> -->
        </div>

        <!-- Control Buttons -->
        <div class="align-start">
          <!-- xl -->
          <div class="d-flex justify-center w-100">
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              icon="mdi-play-box-outline"
              @click.stop="clickStartGame"
            />
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              icon="mdi-pause"
              @click.stop="clickPauseGame"
            />
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              icon="mdi-refresh"
              @click.stop="clickRefresh"
            />
            <v-icon
              class="text-btn"
              :style="{ color: design[7] }"
              size="5vh"
              :icon="control.icon"
              @click.stop="clickToggleControlPanel"
            />
            <!-- <div class="text-glod">
              <VIcon
                size="70"
                class="text-glod text-btn"
                icon="mdi-controller-classic"
                @click.stop="moveToGameController"
              />
            </div> -->
          </div>
          <!-- Control Buttons -->

          <!-- 홀딕스 로고 -->
          <div
            class="d-flex d-sm-none justify-center align-center w-100 holdix-logo"
          >
            <VImg
              :src="require(`@/assets/images/pages/goldmoon.png`)"
              class="holdix-logo"
              :width="80"
            ></VImg>
          </div>
          <div
            class="d-none d-md-none d-sm-flex justify-center align-center w-100 holdix-logo"
          >
            <div>
              <VImg
                :src="require(`@/assets/images/pages/goldmoon.png`)"
                :width="80"
              ></VImg>
            </div>
          </div>
          <div class="d-none d-md-flex justify-center align-center w-100">
            <VImg
              :src="require(`@/assets/images/pages/goldmoon.png`)"
              :width="150"
            ></VImg>
          </div>
        </div>
      </VCol>

      <!-- Center -->
      <VCol
        cols="6"
        md="6"
        class="d-flex h-screen flex-column justify-space-between align-center px-5"
      >
        <div class="mb-0 mb-lg-10 d-flex flex-column align-center">
          <span
            class="text text-60 whitespace-nowrap"
            :style="{ color: design[1] }"
          >
            {{ game.master.name }}
          </span>
          <span
            class="text text-34 whitespace-nowrap"
            :style="{ color: design[1] }"
          >
            {{ structure.name }}
          </span>
        </div>

        <div
          class="d-flex flex-column align-center justify-center"
          @click="clickStartGame"
        >
          <v-card
            class="d-flex flex-column align-center justify-center"
            v-if="game.master.status == 'PAUSED'"
            :style= "{ opacity:pause.content.opacity, backgroundColor:pause.content.backgroundColor }"
            width="35vw"
            height="9vh"
            rounded
            @mouseover="handlePauseButtonMouseOver"
            @mouseleave="handlePauseButtonMouseLeave"
          >
            <v-card-text
              class="d-flex align-center justify-center"
              style="font-size:3vw; font-weight:bolder;"
            >
              <v-icon
                size="3vw"
                :icon="pause.content.icon"
              />
              {{ pause.content.text }}
            </v-card-text>
          </v-card>
        </div>

        <div class="d-flex flex-column align-center">
          <span
            ref="remainLevelTimer"
            class="text-main whitespace-nowrap"
            :style="{ color: design[2] }"
          >
            {{ displayRemainLevelTime }}
          </span>
        </div>

        <div class="d-flex flex-column align-center">
          <!-- Current Blinds/Ante -->
          <div class="align-center">
            <div class="d-inline-block">
              <span
                class="text text-sub whitespace-nowrap"
                :style="{ color: design[3] }"
              >
                {{ displayCurrentBlindsTitle }}
              </span>
              <span
                class="text text-sub whitespace-nowrap"
                :style="{ color: design[4] }"
              >
                {{ displayCurrentBlindsValue }}
              </span>
            </div>
          </div>

          <div class="align-center">
            <div class="d-inline-block">
              <span
                class="text text-sub whitespace-nowrap"
                :style="{ color: design[3] }"
                >{{ displayCurrentAnteTitle }}</span
              >
              <span
                class="text text-sub whitespace-nowrap"
                :style="{ color: design[4] }"
                >{{ displayCurrentAnteValue }}</span
              >
            </div>
          </div>

          <!-- Next Blinds/Ante -->
          <div class="align-center">
            <div class="d-inline-block">
              <span
                class="text text-next whitespace-nowrap"
                :style="{ color: design[3] }"
              >
                {{ displayNextBlindsTitle }}
              </span>
              <span
                class="text text-next whitespace-nowrap"
                :style="{ color: design[4] }"
              >
                {{ displayNextBlindsValue }}
              </span>
              <span
                class="text text-next whitespace-nowrap"
                :style="{ color: design[3] }"
              >
                {{ displayNextAnteTitle }}
              </span>
              <span
                class="text text-next whitespace-nowrap"
                :style="{ color: design[4] }"
              >
                {{ displayNextAnteValue }}
              </span>
            </div>
          </div>
        </div>

        <!-- Control Panel -->
        <div v-if="control.show" class="d-flex w-100 justify-space-between">
          <!-- 버튼 하나 -> 컴포넌트로 쓰시면 편할 것 같습니다. -->
          <!-- Player 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Player</span
              >
              <v-tooltip activator="parent" location="top"
                > ({{ game.player.current }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('current', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('current', 1)"
              />
            </div>
          </div>

          <!-- BuyIn + Early Chip 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Buy In + E</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.earlyBuyIn) }} ({{ game.player.earlyBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('earlyBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('earlyBuyIn', 1)"
              />
            </div>
          </div>

          <!-- BuyIn 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Buy In</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.buyIn) }} ({{ game.player.buyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('buyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('buyIn', 1)"
              />
            </div>
          </div>

          <!-- 1st ReBuyIn 버튼 -->
          <div
            v-if="control.structure.value.firstReBuyIn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >1st</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.firstReBuyIn) }} ({{ game.player.firstReBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('firstReBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('firstReBuyIn', 1)"
              />
            </div>
          </div>

          <!-- 2nd ReBuyIn 버튼 -->
          <div
            v-if="control.structure.value.secondReBuyIn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >2nd</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.secondReBuyIn) }} ({{ game.player.secondReBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('secondReBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('secondReBuyIn', 1)"
              />
            </div>
          </div>

          <!-- 3rd ReBuyIn 버튼 -->
          <div
            v-if="control.structure.value.thirdReBuyIn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >3rd</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.thirdReBuyIn) }} ({{ game.player.thirdReBuyIn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('thirdReBuyIn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('thirdReBuyIn', 1)"
              />
            </div>
          </div>

          <!-- AddOn 버튼 -->
          <div
            v-if="control.structure.value.addOn != 0"
            class="d-flex flex-column justify-center"
          >
            <!-- <div class="d-flex flex-column justify-center"> -->
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Add On</span
              >
              <v-tooltip activator="parent" location="top"
                >{{ addCommasToNumber(control.structure.value.addOn) }} ({{ game.player.addOn }})
              </v-tooltip>
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('addOn', -1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelButton('addOn', 1)"
              />
            </div>
          </div>

          <!-- Lv 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >Lv</span
              >
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-minus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelMoveLevel(-1)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-plus-box-outline"
                size="2vw"
                @click.stop="clickControlPanelMoveLevel(1)"
              />
            </div>
          </div>

          <!-- Lv 버튼 -->
          <div class="d-flex flex-column justify-center">
            <div class="text-center">
              <span
                class="text-display-board-button"
                :style="{ color: design[7] }"
                >1 Min.</span
              >
            </div>

            <!-- xl -->
            <div class="d-flex justify-center">
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-rewind-60"
                size="2vw"
                @click.stop="clickControlPanelMoveTime(60)"
              />
              <VIcon
                class="text-btn"
                :style="{ color: design[7] }"
                icon="mdi-fast-forward-60"
                size="2vw"
                @click.stop="clickControlPanelMoveTime(-60)"
              />
            </div>
          </div>

        </div>

        <div></div>
      </VCol>

      <!-- Right Side v1-->
      <VCol
        v-if="this.version != 2"
        cols="3"
        md="3"
        class="d-flex h-screen flex-column pt-0 justify-space-between align-end"
      >
        <div>
          <VIcon
            class="text-btn"
            :style="{ color: design[7] }"
            size="2vw"
            icon="mdi-fullscreen"
            @click.stop="toggleFullscreen"
          ></VIcon>
        </div>

        <div
          class="h-100 right-side d-flex flex-column justify-space-between align-end"
        >
          <div class="mb-1 mb-lg-10 py-lg-5 text-left">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[5] }"
            >
              {{ displayCurrentLevel }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >TOTAL TIME :
            </span>
          </div>
          <div class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ displayTotalTime }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >TOTAL CHIPS :
            </span>
          </div>
          <div ref="totalChips" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ addCommasToNumber(game.stack.total) }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >AVG STACKS :
            </span>
          </div>
          <div ref="avgStacks" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ addCommasToNumber(game.stack.avg) }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >PLAYER :
            </span>
          </div>
          <div ref="player" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ game.player.current }} / {{ game.player.total }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >NEXT BREAK :
            </span>
          </div>
          <div class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ displayNextBreakTime }}
            </span>
          </div>
        </div>
      </VCol>

      <!-- Right Side v2-->
      <VCol
        v-if="this.version == 2"
        cols="3"
        md="3"
        class="d-flex h-screen flex-column pt-0 justify-space-between align-end"
      >
        <div>
          <VIcon
            class="text-btn"
            :style="{ color: design[7] }"
            size="2vw"
            icon="mdi-fullscreen"
            @click.stop="toggleFullscreen"
          ></VIcon>
        </div>

        <div
          class="h-100 right-side d-flex flex-column justify-space-between align-end"
        >
          <div class="mb-1 mb-lg-10 py-lg-5 text-left">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[5] }"
            >
              {{ displayCurrentLevel }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >PLAYER :
            </span>
          </div>
          <div ref="player" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ game.player.current }} / {{ game.player.buyIn + game.player.earlyBuyIn }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >RE-BUY / ADD ON :
            </span>
          </div>
          <div ref="playerV2" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ game.player.firstReBuyIn }} / {{ game.player.secondReBuyIn }} / {{ game.player.thirdReBuyIn }} | {{ game.player.addOn }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >TOTAL TIME :
            </span>
          </div>
          <div class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ displayTotalTime }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >TOTAL STACKS :
            </span>
          </div>
          <div ref="totalChips" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ addCommasToNumber(game.stack.total) }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >AVG STACKS :
            </span>
          </div>
          <div ref="avgStacks" class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ addCommasToNumber(game.stack.avg) }}
            </span>
          </div>

          <div class="text-left">
            <span
              class="text text-34 whitespace-nowrap"
              :style="{ color: design[5] }"
              >NEXT BREAK :
            </span>
          </div>
          <div class="mb-0 mb-sm-1 mb-md-7 text-right">
            <span
              class="text text-48 whitespace-nowrap"
              :style="{ color: design[6] }"
            >
              {{ displayNextBreakTime }}
            </span>
          </div>
        </div>
      </VCol>
    </VRow>

    <!-- Board Modal -->
    <BoardModal
      ref="modal"
      v-show="modal.show"
      v-click-outside="clickModalOutside"
      :game-id="modal.props"
      @modal-control-event="handleModalControlEvent"
      @modal-action-event="handleModalActionEvent"
      @modal-level-event="handleModalLevelEvent"
    />

    <!-- Pause Overlay -> 오버레이 삭제 -->
    <!-- <v-overlay
      v-model="overlay.show"
      class="d-flex align-center justify-center"
      persistent
    >
      <div
        class="d-flex align-center justify-center hover"
        @click="toggleOverlay"
      >
        <v-card
          class="d-flex align-center justify-center"
          :style= "{ opacity:overlay.content.opacity, backgroundColor:overlay.content.backgroundColor }"
          width="50vw"
          height="30vh"
          rounded
          @mouseover="handleOverlayButtonMouseOver"
          @mouseleave="handleOverlayButtonMouseLeave"
          v-click-outside="handleOverlayClick"
        >
          <v-card-text
            class="d-flex align-center justify-center"
            style="font-size:6vw;font-weight:bolder;"
          >
            <v-icon
              size="6vw"
              :icon="overlay.content.icon"
            />
            {{ overlay.content.text }}
          </v-card-text>
        </v-card>
      </div>
    </v-overlay> -->

  </div>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />
</template>
<script>
import Alert from "@/components/AlertDefault";
import BoardModal from "./BoardModal";

// import WebSocketService from '@/websocket/WebSocketService.js';
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault("Asia/Seoul");

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

// FIXME for test set 1
const minutesToSecond = 60;
// const minutesToSecond = 10;

// audios
const beepAudio = new Audio(require(`@/assets/audio/beep.mp3`));
const breakTimeAudio = new Audio(require(`@/assets/audio/breakTime.mp3`));
const blindsUpAudio = new Audio(require(`@/assets/audio/blindsUp.mp3`));

export default {
  name: "DisplayBoard",
  components: {
    Alert,
    BoardModal,
  },
  data() {
    return {
      /* game: {
      id: '',
      name: '',
      player: {
        current: 0,
        total: 0,
      },
      stack: {
        avg: 0,
        total: 0
      }
    },
    store: {
      id: '',
      name: '',
    },
    structure: {
      prize: '',
      level: [],
    } */
      version: 1,
      /* FIXME for test */
      game: {
        master: {
          id: "df8b66f5-cc97-47a2-9fb7-03068d62c5a3",
          name: "Holdix 게임 이름",
          status: "",
        },
        player: {
          current: 9,
          total: 21,
          earlyBuyIn: 0,
          buyIn: 0,
          firstReBuyIn: 0,
          secondReBuyIn: 0,
          thirdReBuyIn: 0,
          addOn: 0,
        },
        stack: {
          avg: 113800,
          total: 1885500,
        },
        prizeContent: "",
      },
      store: {
        id: "",
        name: "Holdix 성서점",
        address1: '',
      },
      structure: {
        id: "",
        name: "",
        backgroundImageName: "",
        backgroundImageUrl: null,
        // backgroundImagePath: "@/assets/images/pages/timer_bg_4.jpg",
        registrationDeadline: 0,
        level: [],
      },
      latestGameLog: {
        action: "",
        levelSeq: 0,
        level: 0,
        remainLevelTime: 0,
        remainRegTime: 0,
        nextBreakTime: 0,
        totalTime: 0,
        actionDate: null,
      },
      timer: {
        timeTicker: null,
        totalTimeInSec: 0,
        currentLevel: {
          seq: 0,
          level: 1,
          smallBlind: 100,
          bigBlind: 200,
          ante: 300,
          durationInSec: 10 * 60,
          remainLevelTimeInSec: 10 * 60,
          remainRegTimeInSec: 10 * 60,
          nextBreakTimeInSec: 50 * 60,
        },
      },

      url: {
        displayBoard: "/api/display-board",
        gameLog: "/api/game-log",
        game: "/api/game",
        backgroundImage: "/api/structure-attachment/",
        gamePlayer: "/api/game/player",
        structureValue: "/api/structure/value",
        ad: "/api/ad",
        adPage: "/api/ad/page",
      },

      webSocketService: null,

      /* modal */
      modal: {
        show: false,
        props: null,
      },

      /* alert */
      alert: {
        msg: "",
        type: "success",
      },

      /* inner control panel */
      control: {
        show: false,
        icon: "mdi-microsoft-xbox-controller",
        structure: {
          value: {
            earlyBuyIn: 0,
            buyIn: 0,
            firstReBuyIn: 0,
            secondReBuyIn: 0,
            thirdReBuyIn: 0,
            addOn: 0,
          },
        },
      },

      /* Display Board Design */
      design: [
        "#FFFFFF", // 0 preview
        "#FFFFFF", // 1 게임 이름, 스트럭처 이름
        "#FFFFFF", // 2 타이머
        "#FFFFFF", // 3 current/next blind, ante 제목
        "#FFFFFF", // 4 current/next blind, ante 값
        "#FFFFFF", // 5 우단 제목
        "#FFFFFF", // 6 우단 값
        "#FFFFFF", // 7 버튼
      ],

      /* Pause Overlay -> 오버레이 삭제 */
      /* overlay: {
        show: false,
        content: {
          icon: "mdi-pause",
          text: "일시정지",
          opacity: "85%",
          backgroundColor: "darkgoldenrod",
        },
      }, */
      /* Pause Button */
      pause: {
        content: {
          icon: "mdi-pause",
          text: "일시정지",
          opacity: "85%",
          backgroundColor: "darkgoldenrod",
        },
      },

      /* Ad page */
      ad: {
        master: {
          isValid: false,
          cycleTime: 3000
        },
        page: {
          ids: [],
          items: [],
        }
      }

    };
  },
  async created() {
    // 넘어오는 파라미터 = game id
    // this.game.master.id = this.$route.params.id;

    // 전광판 새로 고침 시 로딩 되도록 storedParam 사용
    this.game.master.id =
      this.$route.params.id || this.$store.getters.storedParam;

    // modal용 props
    this.modal.props = this.$route.params.id || this.$store.getters.storedParam;

    // 게임컨트롤러 뒤로가기를 위해 game id를 storedParam에 저장
    const storedParam = this.$route.params.id;
    this.$store.dispatch("storedParam", { storedParam });

    // display board version 세팅
    this.version = this.$store.getters.boardVersion || 1;

    // @nextTick
    // 받아오는 object
    // 1. game : id, name, status / current, total player / avg, total stack
    // 2. structure : prize, level
    // 3. store : name
    // 4. game log : latest game log
    // this.$nextTick(() => {
    await this.readDisplayBoardInfo();
    this.fetchBackgroundImageUrl();
    this.connect();
    // });

    // advertisement
    await this.readAdMaster();
    await this.readAdPageImages();

  },
  mounted() {
    // 브라우저 뒤로가기(Pause Overlay 제거) 이벤트 감지
    // window.addEventListener("popstate", this.handleBrowserBack);
  },
  beforeUnmount() {
    // 브라우저 뒤로가기(Pause Overlay 제거) 이벤트 리스너 제거
    // window.removeEventListener("popstate", this.handleBrowserBack);

    // 전체화면 해제
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    // 화면 떠날때 타이머 삭제
    clearInterval(this.timer.timeTicker);
    this.disconnect();

    // 컴포넌트가 파기되기 전에 Blob URL을 해제합니다.
    if (this.structure.backgroundImageUrl) {
      URL.revokeObjectURL(this.structure.backgroundImageUrl);
    }

    // 게임컨트롤러로 돌아갈때만 storedParam 유지하고 아닐때는 clear
    if (this.$route.path !== "/game-controller") {
      // storedParam 초기화
      this.$store.dispatch("storedParam", {});
    }
  },
  watch: {
    /* Pause Overlay -> 오버레이 삭제 */
    /* 'game.master.status'(val) {
      if(val === "PAUSED"){
        this.overlay.show = true
        this.overlay.content.icon = "mdi-pause"
        this.overlay.content.text = "일시정지"
        this.overlay.content.backgroundColor = "darkgoldenrod"
      }else{
        this.overlay.show = false
      }
    } */
    /* Pause Button */
    'game.master.status'(val) {
      if(val === "PAUSED"){
        this.pause.content.icon = "mdi-pause"
        this.pause.content.text = "일시정지"
        this.pause.content.backgroundColor = "darkgoldenrod"
      }
    }
  },
  computed: {
    displayRemainLevelTime() {
      return this.displayTimeFormat(
        this.timer.currentLevel.remainLevelTimeInSec
      );
    },
    displayTotalTime() {
      return this.displayTimeFormat(this.timer.totalTimeInSec);
    },
    displayNextBreakTime() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (
        nextIndex < this.structure.level.length &&
        this.timer.currentLevel.nextBreakTimeInSec > 0
      ) {
        return this.displayTimeFormat(
          this.timer.currentLevel.nextBreakTimeInSec
        );
      } else {
        // 현재 레벨이 마지막 레벨
        return "-";
      }
    },
    displayCurrentLevel() {
      if (this.timer.currentLevel.level === 0) {
        return "BREAK";
      } else {
        return "LEVEL " + this.timer.currentLevel.level;
      }
    },
    displayCurrentBlinds() {
      if (this.timer.currentLevel.level === 0) {
        return "BREAK";
      } else {
        return (
          "BLINDS: " +
          this.addCommasToNumber(this.timer.currentLevel.smallBlind) +
          " / " +
          this.addCommasToNumber(this.timer.currentLevel.bigBlind)
        );
      }
    },
    displayCurrentAnte() {
      if (this.timer.currentLevel.level === 0) {
        return "";
      } else {
        return "ANTE: " + this.addCommasToNumber(this.timer.currentLevel.ante);
      }
    },
    displayNextBlinds() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "NEXT LEVEL: BREAK";
        } else {
          return (
            "NEXT BLINDS: " +
            this.addCommasToNumber(this.structure.level[nextIndex].smallBlind) +
            " / " +
            this.addCommasToNumber(this.structure.level[nextIndex].bigBlind) +
            ","
          );
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "NEXT LEVEL: END GAME";
      }
    },
    displayNextAnte() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "";
        } else {
          return (
            "ANTE: " +
            this.addCommasToNumber(this.structure.level[nextIndex].ante)
          );
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "";
      }
    },

    displayCurrentBlindsTitle() {
      if (this.timer.currentLevel.level === 0) {
        return "BREAK";
      } else {
        return "BLINDS: ";
      }
    },
    displayCurrentBlindsValue() {
      if (this.timer.currentLevel.level === 0) {
        return "";
      } else {
        return (
          this.addCommasToNumber(this.timer.currentLevel.smallBlind) +
          " / " +
          this.addCommasToNumber(this.timer.currentLevel.bigBlind)
        );
      }
    },
    displayCurrentAnteTitle() {
      if (this.timer.currentLevel.level === 0) {
        return "";
      } else {
        return "ANTE: ";
      }
    },
    displayCurrentAnteValue() {
      if (this.timer.currentLevel.level === 0) {
        return "";
      } else {
        return this.addCommasToNumber(this.timer.currentLevel.ante);
      }
    },
    displayNextLevelAtAdPage() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "BREAK";
        } else {
          return this.structure.level[nextIndex].level;
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "NEXT LEVEL: END GAME";
      }
    },
    displayNextBlindsTitle() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "NEXT LEVEL: BREAK";
        } else {
          return "NEXT BLINDS LV" + this.structure.level[nextIndex].level + ": ";
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "NEXT LEVEL: END GAME";
      }
    },
    displayNextBlindsValue() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "";
        } else {
          return (
            this.addCommasToNumber(this.structure.level[nextIndex].smallBlind) +
            " / " +
            this.addCommasToNumber(this.structure.level[nextIndex].bigBlind) +
            " "
          );
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "";
      }
    },
    displayNextAnteTitle() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "";
        } else {
          return "ANTE: ";
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "";
      }
    },
    displayNextAnteValue() {
      const nextIndex = this.timer.currentLevel.seq + 1;
      if (nextIndex < this.structure.level.length) {
        if (this.structure.level[nextIndex].level === 0) {
          return "";
        } else {
          return this.addCommasToNumber(this.structure.level[nextIndex].ante);
        }
      } else {
        // 현재 레벨이 마지막 레벨
        return "";
      }
    },
  },
  methods: {
    /* Buttons */
    buttonActionResolver(action) {
      const status = this.game.master.status;
      let message = "";

      if (action === "PLAY") {
        switch (status) {
          case "READY":
            // this.startGame();
            this.sendActionToDisplayBoard(action);
            return;
          case "INIT":
            // this.startGame();
            this.sendActionToDisplayBoard(action);
            return;
          case "PLAYING":
            message = "게임이 이미 진행 상태입니다.";
            break;
          case "PAUSED":
            // this.startGame();
            this.sendActionToDisplayBoard(action);
            this.openAlert("게임이 시작되었습니다.", "success");
            return;
          case "END":
            message = "종료된 게임입니다.";
            break;
          default:
            message = "Undefined Last Action.";
            break;
        }
        this.openAlert(message, "warning");
      } else if (action === "PAUSE") {
        switch (status) {
          case "READY":
            message = "게임이 준비 상태입니다.";
            break;
          case "INIT":
            message = "게임이 시작 대기 상태입니다.";
            break;
          case "PLAYING":
            // this.stopGame();
            this.sendActionToDisplayBoard(action);
            return;
          case "PAUSED":
            message = "게임이 이미 일시정지 상태입니다.";
            break;
          case "END":
            message = "종료된 게임입니다.";
            break;
          default:
            message = "Undefined Last Action.";
            break;
        }
        this.openAlert(message, "warning");
      }
    },
    sendActionToDisplayBoard(action) {
      let headers = { Authorization: this.$store.getters.token };

      try {
        if (
          this.webSocketService == null ||
          this.webSocketService == undefined
        ) {
          this.connect();
        }
        const msg = {
          action: action,
          actionUserId: this.$store.getters.id,
        };
        this.webSocketService.send(
          "/pub/receiveAction/gameId=" + this.game.master.id,
          JSON.stringify(msg),
          headers
        );
        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },
    clickStartGame() {
      this.buttonActionResolver("PLAY");
    },
    clickPauseGame() {
      this.buttonActionResolver("PAUSE");
    },
    startGame() {
      this.game.master.status = "PLAYING";
      this.modifyGameStatus();
      this.saveGameLog("PLAY");
      this.runTimer();
    },
    stopGame() {
      this.game.master.status = "PAUSED";
      this.modifyGameStatus();
      this.saveGameLog("PAUSE");
      this.stopTimer();
    },
    endGame() {
      // 타이머 정지 및 삭제
      this.stopTimer();
      this.timer.timeTicker = null;
      this.game.master.status = "END";
      this.modifyGameStatus();
      this.saveGameLog("END");
    },
    /* moveToGameController() {
      this.$router.push({
        name: "GameController",
        params: { id: this.game.master.id },
      });
    }, */
    /* TESTER */
    // FIXME 삭제
    resetGame() {
      this.stopTimer();
      this.game.master.status = "READY";
      this.modifyGameStatus();
      this.saveGameLog("CREATE");
      this.timer.timeTicker = null;

      const nextLevel = this.structure.level[0];
      const currentLevel = {
        seq: nextLevel.seq,
        level: nextLevel.level,
        smallBlind: nextLevel.smallBlind,
        bigBlind: nextLevel.bigBlind,
        ante: nextLevel.ante,
        remainLevelTimeInSec: Number(nextLevel.duration) * minutesToSecond,
        nextBreakTimeInSec: Number(nextLevel.nextBreak) * minutesToSecond,
      };
      this.timer.currentLevel = currentLevel;

      this.timer.totalTimeInSec = 0;
    },

    async clickRefresh() {
      // 타이머 삭제
      clearInterval(this.timer.timeTicker);
      // this.disconnect();

      // 새로고침을 위해 game id를 storedParam에 저장
      const storedParam = this.$store.getters.storedParam;
      this.$store.dispatch("storedParam", { storedParam });

      // init
      await this.readDisplayBoardInfo();
      this.fetchBackgroundImageUrl();
      // this.connect();
    },

    clickToggleControlPanel() {
      this.control.show = !this.control.show;
      if (this.control.show) {
        this.initInnerControlPanel();
        this.control.icon = "mdi-microsoft-xbox-controller-off";
      } else {
        this.control.icon = "mdi-microsoft-xbox-controller";
      }
    },
    /* Inner Control Panel */
    initInnerControlPanel() {
      this.readStructureValue();
    },
    readStructureValue() {
      this.$axios
        // .get(this.url.structureValue, { data: this.structure.id })
        .post(this.url.structureValue, { id: this.structure.id })
        .then((res) => {
          console.log(res.data);
          this.control.structure.value.earlyBuyIn = res.data.earlyBuyIn;
          this.control.structure.value.buyIn = res.data.buyIn;
          this.control.structure.value.firstReBuyIn = res.data.firstReBuyIn;
          this.control.structure.value.secondReBuyIn = res.data.secondReBuyIn;
          this.control.structure.value.thirdReBuyIn = res.data.thirdReBuyIn;
          this.control.structure.value.addOn = res.data.addOn;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async clickControlPanelButton(buttonType, value) {
      let result = await this.calcControlPanelValues(buttonType, value);
      if(result === 1){
        this.sendControlToDisplayBoard();
      }
    },
    calcControlPanelValues(buttonType, value) {
      return new Promise((resolve, reject) => {
        try {
          let formerValue = this.game.player[`${buttonType}`];
          let updateValue = formerValue + value;

          // 1-1. prevent minus
          if(updateValue < 0){
            console.log('prevent minus : ' + formerValue + '>>' + updateValue);
            resolve(0);
            return;
          }

          // 1-2. currentPlayer (+)버튼을 눌렀을떄, current > total 일 수 없다.
          if(buttonType === "current" && value === 1
            && updateValue > this.game.player.total
          ){
            console.log('currentPlayer (+)버튼을 눌렀을떄, current > total 일 수 없다: ' + formerValue + '>>' + updateValue);
            resolve(0);
            return;
          }

          // 2. (1 통과하면) buttonType 해당 값 갱신
          // *** current : currentPlayer만 갱신이므로 아래 3번 수행하지 않음
          this.game.player[`${buttonType}`] = updateValue;

          // 3. current/total player 갱신
          let currentPlayer = this.game.player.current;
          let totalPlayer = this.game.player.total;
          // 3-1. earlyBuyIn, buyIn, firstReBuyIn, secondReBuyIn, thirdReBuyIn : current, total 갱신
          if(buttonType != "current" && buttonType != "addOn"){
            if (value === -1) {
              // current/total prevent minus
              if(currentPlayer != 0){
                currentPlayer += value;
              }
              if(totalPlayer != 0){
                totalPlayer += value;
              }
            }else{
              currentPlayer += value;
              totalPlayer += value;
            }
          }
          // 3-3. addOn : 갱신안함

          // 3 assign
          this.game.player.current = currentPlayer;
          this.game.player.total = totalPlayer;

          // 4. total chips, avg stacks 갱신
          let totalChips = this.game.stack.total;
          let avgStacks = this.game.stack.avg;

          if (buttonType != "current") {
            let buttonValue = Number(
              this.control.structure.value[`${buttonType}`]
            );
            // calcTotalChips
            totalChips = totalChips + buttonValue * value;
            // calcAvgStacks
            if (currentPlayer === 0) {
              avgStacks = 0;
            } else {
              avgStacks = Number((totalChips / currentPlayer).toFixed(0));
            }
          }
          // buttonType == current(player)
          else {
            // calcAvgStacks
            if (currentPlayer === 0) {
              avgStacks = 0;
            } else {
              avgStacks = Number((totalChips / currentPlayer).toFixed(0));
            }
          }

          // 4 assign
          this.game.stack.total = totalChips;
          this.game.stack.avg = avgStacks;

          resolve(1);

        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    /* Socket API Calls */
    sendControlToDisplayBoard() {
      let headers = { Authorization: this.$store.getters.token };

      try {
        this.modifyGamePlayer();

        if (
          this.webSocketService == null ||
          this.webSocketService == undefined
        ) {
          this.connect();
        }
        // Player
        const msg = {
          currentPlayer: this.game.player.current,
          totalPlayer: this.game.player.total,
          earlyBuyInPlayer: this.game.player.earlyBuyIn,
          buyInPlayer: this.game.player.buyIn,
          firstReBuyInPlayer: this.game.player.firstReBuyIn,
          secondReBuyInPlayer: this.game.player.secondReBuyIn,
          thirdReBuyInPlayer: this.game.player.thirdReBuyIn,
          addOnPlayer: this.game.player.addOn,
          totalChips: this.game.stack.total,
          avgStacks: this.game.stack.avg,
        };

        this.webSocketService.send(
          "/pub/receiveControl/gameId=" + this.game.master.id,
          JSON.stringify(msg),
          headers
        );

        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },
    /* APIs */
    modifyGamePlayer() {
      this.$axios
        .put(this.url.gamePlayer, {
          id: this.game.master.id,
          current: this.game.player.current,
          total: this.game.player.total,
          earlyBuyIn: this.game.player.earlyBuyIn,
          buyIn: this.game.player.buyIn,
          firstReBuyIn: this.game.player.firstReBuyIn,
          secondReBuyIn: this.game.player.secondReBuyIn,
          thirdReBuyIn: this.game.player.thirdReBuyIn,
          addOn: this.game.player.addOn,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickControlPanelMoveLevel(value) {
      const currentLevelSeq = this.timer.currentLevel.seq;
      const moveLevelSeq = currentLevelSeq + value;

      // 첫번째 레벨이면 Lv - 버튼 동작 방지, 마지막 레벨이면 Lv + 버튼 동작 방지
      if (moveLevelSeq < 0 || moveLevelSeq > this.structure.level.length) {
        return;
      }
      this.sendLevelToDisplayBoard(moveLevelSeq);
    },
    sendLevelToDisplayBoard(moveLevelSeq) {
      let headers = { Authorization: this.$store.getters.token };

      try {
        if (
          this.webSocketService == null ||
          this.webSocketService == undefined
        ) {
          this.connect();
        }
        const msg = {
          seq: moveLevelSeq,
          updateUserId: this.$store.getters.id,
        };
        this.webSocketService.send(
          "/pub/receiveLevel/gameId=" + this.game.master.id,
          JSON.stringify(msg),
          headers
        );
        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },
    async clickControlPanelMoveTime(forwardInSec){
      const remainLevelTimeInSec = this.timer.currentLevel.remainLevelTimeInSec;
      const remainRegTimeInSec = this.timer.currentLevel.remainRegTimeInSec;
      const nextBreakTimeInSec = this.timer.currentLevel.nextBreakTimeInSec;
      const durationInSec = this.timer.currentLevel.durationInSec;

      // #0. 현재 레벨의 duration 내에서만 동작
      if(remainLevelTimeInSec + forwardInSec > 0
        && remainLevelTimeInSec + forwardInSec <= durationInSec
      ){
        // #1. 현재 타이머만 일시정지 -> 시간조정 -> DB 저장
        // #1-1. 일시정지
        this.game.master.status = "PAUSED";
        this.modifyGameStatus();
        this.stopTimer();

        // #1-2. 시간조정 await
        await this.moveTime(remainLevelTimeInSec, remainRegTimeInSec, nextBreakTimeInSec, forwardInSec);

        // #1-3. DB 저장 await
        await this.saveGameLog("PAUSE");

        // (#1이 완료된 이후) #2. 다른 타이머는 구독해서 강제 새로고침
        this.sendRefreshRequestToDisplayBoard();

      }else {
        return;
      }
    },
    moveTime(remainLevelTimeInSec, remainRegTimeInSec, nextBreakTimeInSec, forwardInSec){
      return new Promise((resolve, reject) => {
        try{
          // 시간조정
          this.timer.currentLevel.remainLevelTimeInSec = remainLevelTimeInSec + forwardInSec;
          if(remainRegTimeInSec + forwardInSec >= 0){
            this.timer.currentLevel.remainRegTimeInSec = remainRegTimeInSec + forwardInSec;
          }
          if(nextBreakTimeInSec + forwardInSec >= 0){
            this.timer.currentLevel.nextBreakTimeInSec = nextBreakTimeInSec + forwardInSec;
          }

          resolve();
        }catch(error){
          console.log(error);
          reject();
        }
      });
    },
    sendRefreshRequestToDisplayBoard() {
      let headers = { Authorization: this.$store.getters.token };

      try {
        if (
          this.webSocketService == null ||
          this.webSocketService == undefined
        ) {
          this.connect();
        }
        /* const msg = {
          seq: moveLevelSeq,
          updateUserId: this.$store.getters.id,
        }; */
        this.webSocketService.send(
          "/pub/receiveRefresh/gameId=" + this.game.master.id,
          // JSON.stringify(msg),
          headers
        );
        return 1;
      } catch (error) {
        console.log(error);
        return 0;
      }
    },

    initGame() {
      const nextLevel = this.structure.level[0];
      const currentLevel = {
        seq: nextLevel.seq,
        level: nextLevel.level,
        smallBlind: nextLevel.smallBlind,
        bigBlind: nextLevel.bigBlind,
        ante: nextLevel.ante,
        remainLevelTimeInSec: Number(nextLevel.duration) * minutesToSecond,
        nextBreakTimeInSec: Number(nextLevel.nextBreak) * minutesToSecond,
      };
      this.timer.currentLevel = currentLevel;

      this.timer.totalTimeInSec = 0;

      this.startGame();
    },

    /* Modal */
    toggleModal() {
      this.modal.show = !this.modal.show;
    },
    handleModalToggleEvent() {
      if (this.modal.show) {
        this.modal.show = false;
      }
    },
    clickModalOutside(event) {
      // modal 오픈을 처리하기 위해,
      // modalIcon.$el 을 클릭한 경우 이벤트를 처리하지 않는다.
      if (event.target === this.$refs.modalIcon.$el || !this.modal.show) {
        return;
      } else {
        this.modal.show = false;
      }
    },
    handleModalControlEvent(controlObject, prizeObject) {
      this.controlResolver(controlObject);
      this.prizeResolver(prizeObject);
    },
    handleModalActionEvent(actionObject) {
      if (actionObject.action === "END") {
        this.actionResolver(actionObject);
      }
    },
    handleModalLevelEvent(levelObject) {
      this.levelResolver(levelObject);
    },

    /* Alert */
    openAlert(msg, type) {
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },

    /* display */
    addCommasToNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    displayTimeFormat(sec) {
      const hours = Math.floor(sec / (60 * 60));
      const minutes = Math.floor((sec % (60 * 60)) / 60);
      const seconds = sec % 60;
      return (
        (hours > 0 ? (hours < 10 ? "0" + hours : hours) : "") +
        (hours > 0 ? ":" : "") +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
    isThisLevelBreak() {
      if (this.timer.currentLevel.level === 0) {
        return true;
      } else {
        return false;
      }
    },
    isNextLevelBreak() {
      // 현재 레벨이 마지막 레벨이면 다음 레벨은 없음
      if (this.timer.currentLevel.seq === this.structure.level.length) {
        return false;
      } else if (this.structure.level[this.timer.currentLevel.seq + 1] === 0) {
        return true;
      } else {
        return false;
      }
    },
    /* APIs */
    fetchBackgroundImageUrl() {
      // storeid structureid filename
      // this.$axios.get('/api/structure-attachment/04ea2bb9-d237-11ed-b7ba-309c23e6ab85/77f9857f-430d-4330-80e9-93fc3fe55b51/display_board_bg.png', { responseType: 'blob' })

      let filename = this.structure.backgroundImageName;
      if (this.structure.backgroundImageName == "") {
        filename = "default";
      }

      let url =
        this.url.backgroundImage +
        this.store.id +
        "/" +
        this.structure.id +
        "/" +
        filename;

      this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          // Blob을 이용하여 원하는 동작 수행
          this.structure.backgroundImageUrl = URL.createObjectURL(blob);
        })
        .catch((error) => {
          console.error("파일 다운로드 에러:", error);
        });
    },
    async readDisplayBoardInfo() {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.displayBoard, {
            id: this.game.master.id,
          })
          .then((res) => {
            console.log(res.data);
            /* Game Master */
            this.game.master.id = res.data.id;
            this.game.master.name = res.data.name;
            this.game.master.status = res.data.status;
            /* Game Player */
            this.game.player.current = res.data.currentPlayer;
            this.game.player.total = res.data.totalPlayer;
            this.game.player.earlyBuyIn = res.data.earlyBuyInPlayer;
            this.game.player.buyIn = res.data.buyInPlayer;
            this.game.player.firstReBuyIn = res.data.firstReBuyInPlayer;
            this.game.player.secondReBuyIn = res.data.secondReBuyInPlayer;
            this.game.player.thirdReBuyIn = res.data.thirdReBuyInPlayer;
            this.game.player.addOn = res.data.addOnPlayer;
            /* Game Prize */
            this.game.prizeContent = res.data.prizeContent;
            /* Stack */
            this.game.stack.avg = res.data.avgStacks;
            this.game.stack.total = res.data.totalChips;
            /* Latest Game Log */
            this.latestGameLog.action = res.data.action;
            this.latestGameLog.levelSeq = res.data.levelSeq;
            this.latestGameLog.level = res.data.level;
            this.latestGameLog.remainLevelTime = res.data.remainLevelTime;
            this.latestGameLog.remainRegTime = res.data.remainRegTime;
            this.latestGameLog.nextBreakTime = res.data.nextBreakTime;
            this.latestGameLog.totalTime = res.data.totalTime;
            // timezone 변경
            this.latestGameLog.actionDate = dayjs(res.data.actionDate)
              .tz()
              .format("YYYY-MM-DD HH:mm:ss");
            /* Store */
            this.store.id = res.data.storeId;
            this.store.name = res.data.storeName;
            this.store.address1 = res.data.address1;
            /* Structure */
            this.structure.id = res.data.structureId;
            this.structure.name = res.data.structureName;
            this.structure.backgroundImageName = res.data.backgroundImageName;
            this.structure.registrationDeadline = res.data.registrationDeadline;
            this.structure.level = res.data.levelList;

            /* Timer current level setting (first level)*/
            this.$nextTick(() => {
              this.currentLevelResolver(res.data.action);
            });

            /* Display Board Design */
            const designList = res.data.designList;
            if (designList.length != 0) {
              for (let i = 0; i < designList.length; i++) {
                this.design[i] = designList[i].color;
              }
            }

            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    currentLevelResolver(lastAction) {
      console.log("currentLevelResolver:" + lastAction);
      if (lastAction === "CREATE") {
        const thisLevel = this.structure.level[0];
        const currentLevel = {
          seq: thisLevel.seq,
          level: thisLevel.level,
          smallBlind: thisLevel.smallBlind,
          bigBlind: thisLevel.bigBlind,
          ante: thisLevel.ante,
          durationInSec: Number(thisLevel.duration) * minutesToSecond,
          remainLevelTimeInSec: Number(thisLevel.duration) * minutesToSecond,
          remainRegTimeInSec: Number(thisLevel.remainRegTime) * minutesToSecond,
          nextBreakTimeInSec: Number(thisLevel.nextBreak) * minutesToSecond,
        };
        this.timer.currentLevel = currentLevel;
      } else if (lastAction === "INIT") {
        const thisLevel = this.structure.level[0];
        const currentLevel = {
          seq: thisLevel.seq,
          level: thisLevel.level,
          smallBlind: thisLevel.smallBlind,
          bigBlind: thisLevel.bigBlind,
          ante: thisLevel.ante,
          durationInSec: Number(thisLevel.duration) * minutesToSecond,
          remainLevelTimeInSec: Number(thisLevel.duration) * minutesToSecond,
          remainRegTimeInSec: Number(thisLevel.remainRegTime) * minutesToSecond,
          nextBreakTimeInSec: Number(thisLevel.nextBreak) * minutesToSecond,
        };
        this.timer.currentLevel = currentLevel;
        this.timer.totalTimeInSec = 0;

        this.startGame();
      } else if (lastAction === "PLAY") {
        /* const now = dayjs().tz().format('YYYY-MM-DD HH:mm:ss');
      const actionDate = dayjs(this.latestGameLog.actionDate).tz().format('YYYY-MM-DD HH:mm:ss');
      this.duration = dayjs.duration(now.diff(dayjs("2023-03-13 00:00:00", "YYYY-MM-DD HH:mm:ss"))).format('HH:mm:ss');
      console.log('now:'+now)
      console.log('actionDate:'+this.latestGameLog.actionDate)
      const timeDiff = dayjs.duration(now.diff(this.latestGameLog.actionDate)).seconds();
      const timeDiff3 = now.diff(this.latestGameLog.actionDate, 'minute');
      console.log(timeDiff);
      console.log(timeDiff2);
      console.log(timeDiff3); */
        // timezone Asia/Seoul 로 고정
        const now = dayjs().tz('Asia/Seoul');
        const timeDiff = now.diff(this.latestGameLog.actionDate, "second");

        /* 0615 */
        let remainLevelTime = this.latestGameLog.remainLevelTime;
        let thisLevelSeq = this.latestGameLog.levelSeq;

        let resultRemainLevelTimeInSeconds = 0;
        let resultRemainRegTimeInSeconds = 0;
        let resultNextBreakTimeInSeconds = 0;
        let resultLevelSeq = 0;

        console.log("마지막 액션으로부터 지나간 시간");
        console.log(timeDiff);
        console.log("남았던 레벨 시간");
        console.log(remainLevelTime);
        console.log("조건식");
        console.log(remainLevelTime < timeDiff);

        // TODO 둘이 같은 값일 경우
        // 전광판을 다시 켰는데 이번 레벨이 지나갔을 경우
        if (remainLevelTime < timeDiff) {
          // 이번 레벨이 마지막 레벨이었으면 게임은 끝
          if (thisLevelSeq == this.structure.level.length - 1) {
            console.log("최초 이번 레벨이 마지막 레벨이었으면 게임은 끝");
            console.log("이번레벨seq:" + thisLevelSeq);
            console.log("마지막레벨seq:" + this.structure.level.length - 1);
            this.endGame();
            resultRemainLevelTimeInSeconds = 0;
            resultNextBreakTimeInSeconds = 0;

            const thisLevel = this.structure.level[thisLevelSeq];
            const currentLevel = {
              seq: thisLevel.seq,
              level: thisLevel.level,
              smallBlind: thisLevel.smallBlind,
              bigBlind: thisLevel.bigBlind,
              ante: thisLevel.ante,
              durationInSec: Number(thisLevel.duration) * minutesToSecond,
              remainLevelTimeInSec: resultRemainLevelTimeInSeconds,
              remainRegTimeInSec: resultRemainRegTimeInSeconds,
              nextBreakTimeInSec: resultNextBreakTimeInSeconds,
            };
            this.timer.currentLevel = currentLevel;
            this.timer.totalTimeInSec = this.latestGameLog.totalTime + timeDiff;
            // 뒤에 runtimer 안시키기위해 리턴
            return;
          } else {
            /* 0708 */
            let 진입시간 = timeDiff - remainLevelTime;
            let 탈출시간 = 0;
            let 계산레벨 = this.latestGameLog.levelSeq + 1; // while에서 변경탐색

            let 답레벨남은시간 = 0;
            let 답레벨휴식남은시간 = 0;
            let 답레벨레지마감남은시간 = 0;
            let 답레벨시퀀스 = 0;

            let 수행횟수 = 0;
            for (;;) {
              // while(true){
              console.log("[" + 수행횟수 + "]");
              if (this.latestGameLog.levelSeq + 1 != 계산레벨) {
                console.log("[" + 수행횟수 + "] 최초 진입은 빼고 바꿔줌");
                // 최초 진입은 빼고 바꿔줌
                진입시간 = 탈출시간; // 현재 레벨의 진입시간은 지난 레벨의 탈출시간
              }
              console.log("[1]현재계산레벨seq:" + 계산레벨);
              console.log("[1]현재계산레벨진입시간:" + 진입시간);

              // 여기에 마지막 레벨 넘어갔는지 체크
              if (계산레벨 == this.structure.level.length) {
                console.log("계산중에 여기에서 마지막 레벨 넘어갔는지 체크");
                console.log("계산레벨seq:" + 계산레벨);
                console.log("마지막레벨seq:" + this.structure.level.length - 1);
                답레벨남은시간 = 0;
                답레벨휴식남은시간 = 0;
                답레벨레지마감남은시간 = 0;
                답레벨시퀀스 = 계산레벨 - 1; // 마지막 레벨
                this.endGame();
                break;
              }

              const 계산레벨의듀레이션초 =
                Number(this.structure.level[계산레벨].duration) *
                minutesToSecond;
              탈출시간 = 진입시간 - 계산레벨의듀레이션초;

              if (탈출시간 < 0) {
                console.log("-----------계산레벨정보---------");
                console.log(this.structure.level[계산레벨]);

                // calc next break time
                const 계산레벨의휴식남은시간초 =
                  Number(this.structure.level[계산레벨].nextBreak) *
                  minutesToSecond;
                console.log("계산레벨의휴식남은시간초");
                console.log(this.structure.level[계산레벨].nextBreak);
                console.log(Number(this.structure.level[계산레벨].nextBreak));
                console.log(계산레벨의휴식남은시간초);

                답레벨남은시간 = 계산레벨의듀레이션초 - 진입시간;
                if (계산레벨의휴식남은시간초 === 0) {
                  답레벨휴식남은시간 = 0;
                } else {
                  답레벨휴식남은시간 = 계산레벨의휴식남은시간초 - 진입시간;
                }

                // calc remain reg time
                const 계산레벨의레지마감남은시간초 =
                  Number(this.structure.level[계산레벨].remainRegTime) *
                  minutesToSecond;
                if (계산레벨의레지마감남은시간초 === 0) {
                  답레벨레지마감남은시간 = 0;
                } else {
                  답레벨레지마감남은시간 = 계산레벨의레지마감남은시간초 - 진입시간;
                }

                // calc level seq
                답레벨시퀀스 = 계산레벨;
                break;
              }
              계산레벨++; // 다음레벨로 다시 진입시킴

              수행횟수++;
            } // end of while

            resultRemainLevelTimeInSeconds = 답레벨남은시간;
            resultNextBreakTimeInSeconds = 답레벨휴식남은시간;
            resultRemainRegTimeInSeconds = 답레벨레지마감남은시간;
            resultLevelSeq = 답레벨시퀀스;

            console.log("마지막 액션으로부터 지나간 시간");
            console.log(timeDiff);
            console.log("답레벨남은시간:" + resultRemainLevelTimeInSeconds);
            console.log("답레벨휴식남은시간:" + resultNextBreakTimeInSeconds);
            console.log("답레벨시퀀스:" + resultLevelSeq);
          }

          /* else {
            let elapsedTimeInSeconds = remainLevelTime - timeDiff;
            let calcLevelSeq = thisLevelSeq + 1; // 다음 레벨부터 시작

            // 다시 켰는데 게임이 진행중
            while (elapsedTimeInSeconds > 0) {
              // elapsedTimeInSeconds -= this.structure.level[calcLevelSeq];
              elapsedTimeInSeconds -= Number(this.structure.level[calcLevelSeq].duration) * minutesToSecond;
              calcLevelSeq++;

              // 이번 레벨이 마지막 레벨이었으면 게임이 끝난걸로 하고 브레이크
              if (calcLevelSeq == this.structure.level.length - 1) {
                this.endGame();
                return;
                // break;
              }
            }

            // 다 돌고 남은 시간이 remainLevelTime
            resultRemainLevelTimeInSeconds =
              Number(this.structure.level[calcLevelSeq].duration) * minutesToSecond - elapsedTimeInSeconds;
            resultNextBreakTimeInSeconds =
              Number(this.structure.level[calcLevelSeq].nextBreakTime) * minutesToSecond - elapsedTimeInSeconds;
            resultLevelSeq = calcLevelSeq;
          } */
        } else {
          console.log("아직 동일 레벨일 경우");
          // 아직 동일 레벨일 경우
          resultRemainLevelTimeInSeconds = this.latestGameLog.remainLevelTime - timeDiff;

          // 값이 0 밑으로 내려가지 않도록 함
          const calcedRemainRegTime = this.latestGameLog.remainRegTime - timeDiff;
          if(calcedRemainRegTime > 0){
            resultRemainRegTimeInSeconds = calcedRemainRegTime;
          }else{
            resultRemainRegTimeInSeconds = 0;
          }
          // 값이 0 밑으로 내려가지 않도록 함
          const calcedNextBreakTime = this.latestGameLog.nextBreakTime - timeDiff;
          if(calcedNextBreakTime > 0){
            resultNextBreakTimeInSeconds = this.latestGameLog.nextBreakTime - timeDiff;
          }else{
            resultNextBreakTimeInSeconds = 0;
          }

          resultLevelSeq = this.latestGameLog.levelSeq;

          console.log("마지막 액션으로부터 지나간 시간");
          console.log(timeDiff);
          console.log("답레벨남은시간:" + resultRemainLevelTimeInSeconds);
          console.log("답레벨휴식남은시간:" + resultNextBreakTimeInSeconds);
          console.log("답레벨시퀀스:" + resultLevelSeq);
        }

        const thisLevel = this.structure.level[resultLevelSeq];
        // const thisLevel = this.structure.level[this.latestGameLog.levelSeq];
        const currentLevel = {
          seq: thisLevel.seq,
          level: thisLevel.level,
          smallBlind: thisLevel.smallBlind,
          bigBlind: thisLevel.bigBlind,
          ante: thisLevel.ante,
          durationInSec: Number(thisLevel.duration) * minutesToSecond,
          remainLevelTimeInSec: resultRemainLevelTimeInSeconds,
          remainRegTimeInSec: resultRemainRegTimeInSeconds,
          nextBreakTimeInSec: resultNextBreakTimeInSeconds,
        };
        this.timer.currentLevel = currentLevel;
        this.timer.totalTimeInSec = this.latestGameLog.totalTime + timeDiff;

        this.runTimer();
      } else if (lastAction === "PAUSE") {
        const thisLevel = this.structure.level[this.latestGameLog.levelSeq];
        const currentLevel = {
          seq: thisLevel.seq,
          level: thisLevel.level,
          smallBlind: thisLevel.smallBlind,
          bigBlind: thisLevel.bigBlind,
          ante: thisLevel.ante,
          durationInSec: Number(thisLevel.duration) * minutesToSecond,
          remainLevelTimeInSec: this.latestGameLog.remainLevelTime,
          remainRegTimeInSec: this.latestGameLog.remainRegTime,
          nextBreakTimeInSec: this.latestGameLog.nextBreakTime,
        };
        this.timer.currentLevel = currentLevel;
        this.timer.totalTimeInSec = this.latestGameLog.totalTime;
      } else if (lastAction === "END") {
        const thisLevel = this.structure.level[this.latestGameLog.levelSeq];
        const currentLevel = {
          seq: thisLevel.seq,
          level: thisLevel.level,
          smallBlind: thisLevel.smallBlind,
          bigBlind: thisLevel.bigBlind,
          ante: thisLevel.ante,
          durationInSec: Number(thisLevel.duration) * minutesToSecond,
          remainLevelTimeInSec: this.latestGameLog.remainLevelTime,
          remainRegTimeInSec: this.latestGameLog.remainRegTime,
          nextBreakTimeInSec: this.latestGameLog.nextBreakTime,
        };
        this.timer.currentLevel = currentLevel;
        this.timer.totalTimeInSec = this.latestGameLog.totalTime;
      } else {
        console.log("Unidentified Last Action.");
        this.timer.currentLevel = {
          seq: 0,
          level: 0,
          smallBlind: 0,
          bigBlind: 0,
          ante: 0,
          durationInSec: 0,
          remainLevelTimeInSec: 0,
          remainRegTimeInSec: 0,
          nextBreakTimeInSec: 0,
        };
        this.timer.totalTimeInSec = 0;
      }

      // 현재 레벨 시간이 5초 이하로 남은 상태에서 전광판에 진입한 경우, css 변경
      if (this.timer.currentLevel.remainLevelTimeInSec <= 5) {
        this.toggleCountdownCss(true);
      }
    },
    saveGameLog(action) {
      console.log("saveGameLog");
      const gameLog = {
        id: this.game.master.id,
        action: action,
        levelSeq: this.timer.currentLevel.seq,
        level: this.timer.currentLevel.level,
        remainLevelTime: this.timer.currentLevel.remainLevelTimeInSec,
        remainRegTime: this.timer.currentLevel.remainRegTimeInSec,
        nextBreakTime: this.timer.currentLevel.nextBreakTimeInSec,
        totalTime: this.timer.totalTimeInSec,
        actionUserId: this.$store.getters.id,
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.url.gameLog, gameLog)
          .then((res) => {
            console.log("saveGameLog result");
            console.log(gameLog);
            console.log(res);

            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
    },
    modifyGameStatus() {
      const param = {
        id: this.game.master.id,
        status: this.game.master.status,
        updateUserId: this.$store.getters.id,
      };
      this.$axios.put(this.url.game, param).then((res) => {
        console.log(res.data);
      });
    },
    modifyRegistrationStatusClosed() {
      const param = {
        id: this.game.master.id,
        registrationStatus: "CLOSED",
        updateUserId: this.$store.getters.id,
      };
      this.$axios.put(this.url.game, param).then((res) => {
        console.log(res.data);
      });
    },
    /* Timer */
    runTimer() {
      this.timer.timeTicker = setInterval(() => {
        // 레벨 끝나는지 체크
        const isLevelEnds =
          this.timer.currentLevel.remainLevelTimeInSec === 1 ? true : false;

        // 1. 일반 동작
        if (!isLevelEnds) {
          // 레지마감 남은 시간 갱신
          if(this.timer.currentLevel.remainRegTimeInSec > 0){
            this.timer.currentLevel.remainRegTimeInSec -= 1;
          }
          // 레벨 남은 시간 갱신
          this.timer.currentLevel.remainLevelTimeInSec -= 1;
          // 소리
          if (this.timer.currentLevel.remainLevelTimeInSec <= 5) {
            this.playBeepAudio();
          }

          if (this.timer.currentLevel.nextBreakTimeInSec > 0) {
            // 다음 break가 있을때만
            this.timer.currentLevel.nextBreakTimeInSec -= 1;
          }
          // css 변경
          if (this.timer.currentLevel.remainLevelTimeInSec === 5) {
            this.toggleCountdownCss(true);
          }
        }
        // 2. 레벨이 끝날때
        else {
          // let action = null;
          // 현재 레벨이 마지막 레벨인지 체크
          const isGameEnds =
            this.timer.currentLevel.seq === this.structure.level.length - 1
              ? true
              : false;

          // 현재 레벨 = 마지막 레벨
          if (isGameEnds) {
            // action = "END";
            this.endGame();
            this.timer.currentLevel.remainLevelTimeInSec -= 1;
          }
          // 현재 레벨 != 마지막 레벨
          else {
            // 현재 레벨 정보를 다음 레벨 정보로 변경
            this.changeCurrentLevel();
            // action = "PLAY";
            // css 변경
            this.toggleCountdownCss(false);
          }

          // 레벨 변경(PLAY) or 게임 종료(END) game log 저장
          /* this.$nextTick(() => {
            this.saveGameLog(action);
          }); */
        }

        // 총 게임 시간
        this.timer.totalTimeInSec += 1;
      }, 1000);
    },
    changeCurrentLevel() {
      const currLevelIndex = this.timer.currentLevel.seq;
      this.changeRegistrationStatusClosed(currLevelIndex);

      const nextLevelIndex = currLevelIndex + 1;
      const nextLevel = this.structure.level[nextLevelIndex];
      const currentLevel = {
        seq: nextLevel.seq,
        level: nextLevel.level,
        smallBlind: nextLevel.smallBlind,
        bigBlind: nextLevel.bigBlind,
        ante: Number(nextLevel.ante),
        durationInSec: Number(nextLevel.duration) * minutesToSecond,
        remainLevelTimeInSec: Number(nextLevel.duration) * minutesToSecond,
        remainRegTimeInSec: Number(nextLevel.remainRegTime) * minutesToSecond,
        nextBreakTimeInSec: Number(nextLevel.nextBreak) * minutesToSecond,
      };
      this.timer.currentLevel = currentLevel;

      this.saveGameLog("PLAY");

      // 소리
      this.playLevelChangeAudio(nextLevel.level);
    },
    changeRegistrationStatusClosed(currLevelSeq) {
      if (currLevelSeq === this.structure.registrationDeadline) {
        this.modifyRegistrationStatusClosed();
      }
    },
    stopTimer() {
      clearInterval(this.timer.timeTicker);
    },
    toggleCountdownCss(on) {
      const element = this.$refs.remainLevelTimer;
      if (on) {
        element.classList.add("timer");
      } else {
        element.classList.remove("timer");
      }
    },
    playBeepAudio() {
      beepAudio.play();
    },
    playLevelChangeAudio(type) {
      if (type === 0) {
        breakTimeAudio.play();
      } else {
        blindsUpAudio.play();
      }
    },
    toggleTextCss() {
      const totalChipsElement = this.$refs.totalChips;
      const avgStacksElement = this.$refs.avgStacks;
      const playerElement = this.$refs.player;

      totalChipsElement.classList.add("text-action");
      avgStacksElement.classList.add("text-action");
      playerElement.classList.add("text-action");

      let playerV2Element;
      if(this.version == 2){
        playerV2Element = this.$refs.playerV2;
        playerV2Element.classList.add("text-action");
      }

      setTimeout(() => {
        totalChipsElement.classList.remove("text-action");
        avgStacksElement.classList.remove("text-action");
        playerElement.classList.remove("text-action");
        if(this.version == 2){
          playerV2Element.classList.remove("text-action");
        }
      }, 1000);
    },
    toggleFullscreen() {
      const element = document.documentElement;

      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        element.requestFullscreen().catch((err) => {
          console.log(
            `Error attempting to enable full-screen mode: ${err.message}`
          );
        });
      }
    },
    /* Socket IO */
    connect() {
      let serverURL = null; // SockJS 서버 URL
      if (process.env.VUE_APP_ENV === "prod") {
        serverURL = "http://hdx.kr/websocket";
      } else {
        serverURL = "http://localhost:8080/websocket";
      }
      const socket = new SockJS(serverURL);
      this.webSocketService = Stomp.over(socket);
      console.log(`소켓 연결을 시도합니다. 서버 주소: ${serverURL}`);

      let headers = { Authorization: this.$store.getters.token };

      this.webSocketService.connect(
        headers,
        (frame) => {
          console.log("소켓 연결 성공", frame);
          // 구독
          this.subscribe();
        },
        (error) => {
          alert(
            "전광판 연결이 실패하였습니다. 다시 로그인 후 전광판을 실행해주세요."
          );
          // 소켓 연결 실패
          console.log("소켓 연결 실패", error);
        }
      );
    },
    subscribe() {
      this.subscribeControl();
      this.subscribePrize();
      this.subscribeAction();
      this.subscribeLevel();
      this.subscribeRefresh();
    },
    subscribeControl() {
      let headers = { Authorization: this.$store.getters.token };
      console.log("subscribeControl");
      this.webSocketService.subscribe(
        "/topic/sendControl/gameId=" + this.game.master.id,
        (res) => {
          console.log("구독으로 받은 메시지 입니다.", res.body);
          // 받은 데이터를 json으로 파싱하고 리스트에 넣어줍니다.
          const resBody = JSON.parse(res.body);
          this.controlResolver(resBody);
        },
        headers
      );
    },
    subscribePrize() {
      let headers = { Authorization: this.$store.getters.token };
      console.log("subscribePrize");
      this.webSocketService.subscribe(
        "/topic/sendPrize/gameId=" + this.game.master.id,
        (res) => {
          console.log("구독으로 받은 메시지 입니다.", res.body);
          // 받은 데이터를 json으로 파싱하고 리스트에 넣어줍니다.
          const resBody = JSON.parse(res.body);
          this.prizeResolver(resBody);
        },
        headers
      );
    },
    subscribeAction() {
      let headers = { Authorization: this.$store.getters.token };
      this.webSocketService.subscribe(
        "/topic/sendAction/gameId=" + this.game.master.id,
        (res) => {
          console.log("구독으로 받은 메시지 입니다.", res.body);
          // 받은 데이터를 json으로 파싱하고 리스트에 넣어줍니다.
          this.actionResolver(JSON.parse(res.body));
        },
        headers
      );
    },
    subscribeLevel() {
      let headers = { Authorization: this.$store.getters.token };
      this.webSocketService.subscribe(
        "/topic/sendLevel/gameId=" + this.game.master.id,
        (res) => {
          console.log("구독으로 받은 메시지 입니다.", res.body);
          // 받은 데이터를 json으로 파싱하고 리스트에 넣어줍니다.
          this.levelResolver(JSON.parse(res.body));
        },
        headers
      );
    },
    subscribeRefresh() {
      let headers = { Authorization: this.$store.getters.token };
      this.webSocketService.subscribe(
        "/topic/sendRefresh/gameId=" + this.game.master.id,
        (res) => {
          console.log("구독으로 받은 메시지 입니다.", res.body);
          // 받은 데이터를 json으로 파싱하고 리스트에 넣어줍니다.
          this.clickRefresh();
        },
        headers
      );
    },
    disconnect() {
      console.log("Websocket Disconnect...");
      let headers = { Authorization: this.$store.getters.token };
      if (this.webSocketService) {
        this.webSocketService.disconnect(() => {
          console.log("Websocket Disconnected.");
        }, headers);
      }
    },
    actionResolver(actionObject) {
      console.log(actionObject);
      console.log(this.game.master.status);
      console.log("start actionResolver");
      // Action 내용을 보고 timer 및 data 설정

      // 1. 현재상태: 시작 전(READY)
      // 1-1. Action=PLAY -> 시작 / Action=PAUSE -> return
      if (this.game.master.status === "READY") {
        if (actionObject.action === "PLAY") {
          this.startGame();
        } else if (actionObject.action === "END") {
          this.endGame();
        } else {
          return;
        }
      }
      // 2. 현재상태: 게임 중(PLAYING)
      // 2-1. Action=START -> return / Action=PAUSE -> pause
      else if (this.game.master.status === "PLAYING") {
        if (actionObject.action === "PAUSE") {
          this.stopGame();
        } else if (actionObject.action === "END") {
          this.endGame();
        } else {
          return;
        }
      }
      // 3. 현재상태: 일시정지(PAUSED)
      // 3-1. Action=PLAY -> 시작 / Action=PAUSE -> return
      else if (this.game.master.status === "PAUSED") {
        if (actionObject.action === "PLAY") {
          this.startGame();
        } else if (actionObject.action === "END") {
          this.endGame();
        } else {
          return;
        }
      }
      // 4. 현재상태: 게임종료(END)
      // 모든 Action -> return
      else if (this.game.master.status === "END") {
        return;
      }
    },
    controlResolver(controlObject) {
      console.log("controlObject:");
      console.log(controlObject);
      this.game.player.current = controlObject.currentPlayer;
      this.game.player.total = controlObject.totalPlayer;
      this.game.player.earlyBuyIn = controlObject.earlyBuyInPlayer;
      this.game.player.buyIn = controlObject.buyInPlayer;
      this.game.player.firstReBuyIn = controlObject.firstReBuyInPlayer;
      this.game.player.secondReBuyIn = controlObject.secondReBuyInPlayer;
      this.game.player.thirdReBuyIn = controlObject.thirdReBuyInPlayer;
      this.game.player.addOn = controlObject.addOnPlayer;
      this.game.stack.total = controlObject.totalChips;
      this.game.stack.avg = controlObject.avgStacks;
      this.toggleTextCss();
    },
    levelResolver(levelObject) {
      console.log(levelObject);
      const seq = levelObject.seq;
      const nextLevel = this.structure.level[seq];
      console.log(nextLevel);
      const currentLevel = {
        seq: nextLevel.seq,
        level: nextLevel.level,
        smallBlind: nextLevel.smallBlind,
        bigBlind: nextLevel.bigBlind,
        ante: Number(nextLevel.ante),
        durationInSec: Number(nextLevel.duration) * minutesToSecond,
        remainLevelTimeInSec: Number(nextLevel.duration) * minutesToSecond,
        remainRegTimeInSec: Number(nextLevel.remainRegTime) * minutesToSecond,
        nextBreakTimeInSec: Number(nextLevel.nextBreak) * minutesToSecond,
      };
      this.timer.currentLevel = currentLevel;
      this.stopGame();
    },
    prizeResolver(prizeObject) {
      console.log(prizeObject);
      console.log(prizeObject.content);
      this.game.prizeContent = prizeObject.content;
    },

    /* Pause Overlay -> 오버레이 삭제 */
    /*
    // toggleOverlay(){
    //   // 게임재개 클릭 시 액션
    //   this.overlay.show = !this.overlay.show;
    //   this.clickStartGame();
    // },
    // handleOverlayClick() {
    //   // 오버레이 클릭 시 동작 처리(가운데 게임재개 버튼 제외 부분 클릭)
    //   this.openAlert("게임을 재개하시려면 화면 중앙 버튼을 클릭해주세요.", "warning");
    // },
    // handleOverlayButtonMouseOver() {
    //   this.overlay.content.icon = "mdi-play"
    //   this.overlay.content.text = "게임재개"
    //   this.overlay.content.backgroundColor = "darkcyan"
    // },
    // handleOverlayButtonMouseLeave() {
    //   this.overlay.content.icon = "mdi-pause"
    //   this.overlay.content.text = "일시정지"
    //   this.overlay.content.backgroundColor = "darkgoldenrod"
    // },
    // handleBrowserBack() {
    //   // overlay가 켜져있으면 브라우저 뒤로가기 이벤트가 작동하지 않아 여기서 강제로 꺼줌
    //   this.overlay.show = false
    // },
    */

    /* Pause Button */
    handlePauseButtonMouseOver() {
      this.pause.content.icon = "mdi-play"
      this.pause.content.text = "게임재개"
      this.pause.content.backgroundColor = "darkcyan"
    },
    handlePauseButtonMouseLeave() {
      this.pause.content.icon = "mdi-pause"
      this.pause.content.text = "일시정지"
      this.pause.content.backgroundColor = "darkgoldenrod"
    },

    /* Advertisement */
    async readAdMaster(){
      return new Promise((resolve, reject) => {
        let url = this.url.ad + "/" + this.store.address1;
        this.$axios
          .get(url)
          .then((res) => {
            if(res.data == null || res.data == ""){
              this.ad.master.isValid = false;
              this.ad.master.cycleTime = 0;
              this.ad.page.items = [];
            }else{
              this.ad.master.isValid = (res.data.isValid === 1 ? true : false);
              this.ad.master.cycleTime = res.data.cycleTime;
              const pages = res.data.pages || [];
              if(res.data.pages.length > 0){
                pages.forEach(page => {
                  this.ad.page.ids.push(page.fileId);
                });
              }else{
                this.ad.master.isValid = false;
                this.ad.page.ids = [];
              }
            }

            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    async readAdPageImages(){
      if(!this.ad.master.isValid || this.ad.page.ids.length === 0){
        return;
      }

      try {
        const fileIds = this.ad.page.ids;
        const requests = fileIds.map(fileId => {
          let url = this.url.adPage + "/" + fileId;
          return this.$axios.get(url, { responseType: "blob" })
            .then(res => {
              // status = 204 : no content
              if (res.status === 200) {
                const blob = new Blob([res.data]);
                return URL.createObjectURL(blob);
              } else {
                throw new Error('Invalid response status');
              }
            });
        });

        const results = await Promise.all(requests);

        this.ad.page.items = results;
        console.log('All images loaded:', this.ad.page.items);

      } catch (e) {
        console.log(e);
        this.ad.master.isValid = false;
        this.ad.page.ids = [];
      }

      /*
      // 순서가 안맞음
      return new Promise((resolve, reject) => {
        const fileIds = this.ad.page.ids;
        fileIds.forEach(fileId => {
          let url = this.url.adPage + "/" + fileId;

          this.$axios
          .get(url, { responseType: "blob" })
          .then((res) => {
            // status = 204 : no content
            if(res.status == 200){
              const blob = new Blob([res.data]);
              console.log('readAdPageImages fileid:', fileId);
              this.ad.page.items.push(URL.createObjectURL(blob));
            }else{
              this.ad.master.isValid = false;
              this.ad.page.ids = [];
            }
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
        });
      }); */
    },

  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@400;500;600;700&family=Nanum+Myeongjo:wght@400;700;800&family=Noto+Sans+KR:wght@400;500;700;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");

* {
  /* font-family: 'Nanum Myeongjo', serif; */
  /* font-family: "Noto Sans KR", sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
  /* font-family: 'IBM Plex Sans KR', sans-serif;   */
}

.v-main {
  width: 100vw;
}

.v-container {
  padding: 0;
}

.mt {
  margin-top: 10vh;
}

.d-level {
  vertical-align: middle;
  padding: 1vh 0;
}

.text-main {
  font-size: 18vw;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -25px;
  color: #ffffff;
}

.text-gold {
  color: gold;
}
.text-sub {
  font-size: 4.2vw;
  line-height: 1.3;
  white-space: nowrap;
}
.text-60 {
  font-size: 3vw;
  font-weight: 600;
}
.text-34 {
  font-size: 1.6vw;
  font-weight: 500;
}
.text-next {
  font-size: 2.5vw;
  font-weight: 500;
}
.text-48 {
  font-size: 2.5vw;
  font-weight: 600;
}
.text {
  color: white;
}

.text-display-board-button {
  font-size: 1.2vw;
  font-weight: 500;
}

@media (min-width: 2400px) {
  .text-main {
    font-size: 21vw;
  }
}

@media (max-width: 2000px) {
  .text-display-board-button {
    font-size: 1.5vw;
    font-weight: 500;
  }
}

@media (max-width: 1700px) {
  .text-display-board-button {
    font-size: 1.5vw;
    font-weight: 500;
  }
}

@media (max-width: 1400px) {
  .text-60 {
    font-size: 4vw;
    font-weight: 600;
  }
  .text-base {
    font-size: 1.5vw;
  }
  .text-34 {
    font-size: 1.8vw;
    font-weight: 500;
  }
  .text-next {
    font-size: 1.8vw;
    font-weight: 500;
  }

  .text-48 {
    font-size: 3vw;
    font-weight: 600;
  }
  .text-main {
    font-size: 18vw;
    font-weight: 400;
    letter-spacing: -10px;
    color: #ffffff;
  }
  .text-sub {
    font-size: 4vw;
    line-height: 1.3;
    white-space: nowrap;
  }
  .text-display-board-button {
    font-size: 1.2vw;
    font-weight: 500;
  }
}
@media (max-width: 1024px) {
  .text-60 {
    font-size: 2.5vw;
    font-weight: 600;
  }
  .text-base {
    font-size: 1vw;
  }
  .text-34 {
    font-size: 1.7vw;
    font-weight: 500;
    line-height: 16px;
  }
  .text-next {
    font-size: 1.7vw;
    font-weight: 500;
    line-height: 16px;
  }

  .text-48 {
    font-size: 2vw;
    font-weight: 600;
    line-height: 16px;
  }
  .text-main {
    font-size: 14vw;
    font-weight: 400;
    letter-spacing: -10px;
    color: #ffffff;
  }
  .text-sub {
    font-size: 3vw;
    line-height: 1.3;
    white-space: nowrap;
  }

  .text-display-board-button {
    font-size: 1.2vw;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .text-60 {
    font-size: 2vw;
    font-weight: 600;
  }
  .text-base {
    font-size: 1vw;
  }
  .text-34 {
    font-size: 1.5vw;
    font-weight: 500;
  }
  .text-next {
    font-size: 1.5vw;
    font-weight: 500;
  }
  .text-48 {
    font-size: 2vw;
    font-weight: 600;
  }
  .text-main {
    font-size: 18vw;
    font-weight: 400;
    letter-spacing: -10px;
    color: #ffffff;
  }
  .text-sub {
    font-size: 4vw;
    line-height: 1.3;
    white-space: nowrap;
  }
  .text-display-board-button {
    font-size: 1vw;
    font-weight: 500;
  }
}

/* mobile timer-list */
@media (max-width: 470px) {
  .text-60 {
    font-size: 2vw;
    font-weight: 600;
  }
  .text-base {
    font-size: 1vw;
  }
  .text-34 {
    font-size: 1vw;
    font-weight: 500;
    white-space: nowrap;
  }
  .text-48 {
    font-size: 1.3vw;
    font-weight: 600;
    white-space: nowrap;
  }
  .text-main {
    font-size: 18vw;
    font-weight: 400;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .text-sub {
    font-size: 3vw;
    line-height: 1.3;
    white-space: nowrap;
  }
}

.fullscreenBg {
  position: absolute;
  z-index: 9980;
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: #28243d;
  block-size: 100vh;
  inline-size: 100vw;
  inset-block-start: 0;
  inset-inline-start: 0;
  top: 0;
  left: 0;
  right: 0;
}

.fullscreenBg::after {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  background-image: url("@/assets/images/pages/timer_bg_4.jpg");
  background-size: cover;
  content: "";
  inset: 0;
  opacity: 0.2;
}
.overflow {
  overflow: hidden;
  margin: 0;
  margin-right: 10px;
}

/* 개발자님 여기 text-action 클래스 적용시키면 한 번 크기가 줄었다가 커지는 애니메이션이 적용됩니다. */
/* 가능하다면 이벤트 발생 할 때 클래스 이름을 적용시키거나, 두개의 컴포넌트를 만들어두시고 v-if else 처리하셔서 보여주는것도 가능 할 것 같습니다!  */
.text-action {
  animation: text-animation 1s normal;
}
@keyframes text-animation {
  0% {
    scale: 100%;
  }
  20% {
    scale: 80%;
  }
  100% {
    scale: 100%;
  }
}

.timer {
  animation: my-animation 1s infinite;
  color: white;
}
/* .timer:hover {
  animation: my-animation 0.5s infinite alternate;
} */

@keyframes my-animation {
  0% {
    color: red;
  }
  50% {
    color: #ffffff;
  }
  100% {
    color: red;
  }
}

.effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0%;
  background-color: yellow;
  animation: bg-animation 1s infinite;
}
@keyframes bg-animation {
  0% {
    opacity: 30%;
  }
  20% {
    opacity: 0%;
  }
  50% {
    opacity: 0%;
  }
  70% {
    opacity: 0%;
  }
  100% {
    opacity: 30%;
  }
}
.fullscreenBg::-webkit-scrollbar {
  display: none;
}
.overflow::-webkit-scrollbar {
  display: none;
}
.overflow {
  overflow: hidden;
}
.overflow {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
body {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.title {
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.slide {
  background-color: rgb(40, 36, 61);
  position: fixed;
  top: 5vh;
  left: 0;
  height: 90vh;
  width: 80vw;

  z-index: 9990;
  overflow: auto;
  padding: 5px;
}

/* .slide {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    left: -100vw;
  }

  to {
    left: 0;
  }
} */
.text-action {
  animation: text-animation 1s infinite;
}
@keyframes text-animation {
  0% {
    scale: 100%;
  }
  20% {
    scale: 80%;
  }
  100% {
    scale: 100%;
  }
}

.relative {
  position: relative;
}
.left-side {
  position: absolute;
  top: 15%;
  left: 0;
  height: 85%;
  width: 75%;
  max-width: 80%;
}
.right-side {
  width: 75%;
}
.h-100 {
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 0; /* 하단에 정렬 */
  left: 0;
  width: 100%;
  height: 10%;
  /* 이미지와 겹치도록 z-index를 조정합니다. */
  z-index: 1;
  /* 원하는 스타일을 추가합니다. */
  background-color: rgba(0, 0, 0, 0.5); /* 투명한 검정 배경 */
}
</style>
