export default {
  "language": {
    "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓國語"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])}
  },
  "menu": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メイン"])},
    "timerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイマーリスト"])},
    "structureCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー作成"])},
    "gameCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム作成"])},
    "gameList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームリスト"])},
    "storeManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Store Info"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ようこそ、HOLDIXへ"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLDIXはリアルタイムタイマーリストプラットフォームです."])},
    "rememberLoginInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン情報を記憶する"])},
    "button": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
      "findId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find ID"])},
      "findPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Password"])}
    },
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録に関する問い合わせ : 010-7519-7037"])},
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Hours : 00:00 ~ 24:00"])}
  },
  "logout": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "signUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-Up"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "button": {
      "availableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Duplicate ID"])},
      "availableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Duplicate Nickname"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "moveToLoginPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to the Login page"])}
    }
  },
  "main": {
    "sir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to HOLDIX!"])}
  },
  "structure": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー作成"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー初期化"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー削除"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー保存"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー名"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー名入力"])},
    "modifyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー名變更"])},
    "value": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バリュー設定"])},
      "earlyBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スターティング チップ + アーリーチップ"])},
      "buyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スターティング チップ"])},
      "firstReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["１回目のリバーインチップ"])},
      "secondReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["２回目のリバーインチップ"])},
      "thirdReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["３回目のリバーインチップ"])},
      "addOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドオンチップ"])},
      "buyInAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイイン金額"])},
      "registrationDeadline": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録締切り"])},
        "selectLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル選択"])}
      },
      "prize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライズ"])},
      "entryLimit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリー制限"])},
        "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有"])},
        "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])}
      },
      "reEntryCount": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リーバイイン回数"])},
        "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回"])}
      }
    },
    "level": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル設定"])},
      "smallBlind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スモールブラインド"])},
      "bigBlind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビッグブラインド"])},
      "ante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンティ"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブラインドタイム(分"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "addLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル追加"])},
      "addBreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブレーク追加"])}
    },
    "prize": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライズ設定"])}
    },
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストラクチャー"])}
  },
  "timerDesign": {
    "backgroundImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイマー背景画面"])},
    "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付"])},
    "attachPopup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Timer Background Image"])},
      "notice": {
        "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- File size: 5MB limit"])},
        "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- File name: Only allow Korean, English, and numbers"])},
        "extensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Extensions: png, jpeg, jpg"])},
        "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- The current Timer Background Image file will be replaced by the attachment."])}
      },
      "dragndrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag the Timer Background Image file and drop it here."])},
      "buttonAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open file explorer"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイマー·デザイン"])},
    "selectSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カラー選択"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボタン"])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セクション 1"])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セクション 2"])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セクション 3"])},
    "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セクション 4"])},
    "section5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セクション 5"])},
    "section6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セクション 6"])},
    "selectColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カラー選択"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイマー デザイン 保存"])},
    "displayGameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Title"])},
    "displayStructureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure Title"])}
  },
  "game": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム名"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム作成"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム削除"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームタイトル入力"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームリスト"])},
    "listNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成から 8 時間以内のゲームまたは終了状態ではないゲームのみが表示されます。"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム追加"])},
    "status": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行状況"])}
    },
    "regStatus": {
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録中"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録締切り"])}
    },
    "readyToStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム開始前"])}
  },
  "controller": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームコントローラー"])},
    "timer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイマー"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エンター"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタート"])},
    "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一時停止"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム終了"])},
    "level": {
      "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル移動"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベルスタート"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル情報"])}
    },
    "player": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Settings"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリー人数"])},
      "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリー"])},
      "earlyBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スターティング + アーリー"])},
      "buyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スターティング"])},
      "firstReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["１回目のリバーイン"])},
      "secondReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2回目のリバーイン"])},
      "thirdReBuyIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3回目のリバーイン"])},
      "addOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドオンチップ"])}
    }
  },
  "common": {
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成時間"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the list"])}
  },
  "message": {
    "login": {
      "enterIdPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID and Password."])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login session has expired. Please log in again."])},
      "error": {
        "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login session has expired. Please log in again."])},
        "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the necessary permissions. Redirecting to the Login page."])}
      }
    },
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to logout?"])}
    },
    "signUp": {
      "enterId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter ID."])},
      "availableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available ID."])},
      "unavailableId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate ID already exist."])},
      "enterNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter nickname."])},
      "availableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available nickname."])},
      "unavailableNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate nickname already exist."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully registered! You will be automatically redirected to the login page."])},
      "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to sign up."])},
      "checkDupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check duplicate ID first."])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password."])},
      "enterConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password confirmation."])},
      "checkPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and password confirmation do not match."])},
      "checkDupNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check duplicate nickname first."])}
    },
    "structure": {
      "reset": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reset the structure you're currently editing?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure has been successfully reset."])}
      },
      "save": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save the structure?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure has been successfully saved."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to save structure."])}
      },
      "create": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to move to the Structure Create page?"])}
      },
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the structure?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure has been successfully deleted."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to delete structure."])}
      }
    },
    "timerDesign": {
      "save": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to save the timer design settings?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer design settings have been successfully saved."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to delete timer design settings."])}
      },
      "attachPopup": {
        "error": {
          "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숫자, 한글, 영문을 포함한 공백없는 파일명만 가능합니다."])},
          "fileCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일은 하나만 첨부해주세요."])},
          "fileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일크기는 5MB 이하로 제한됩니다."])}
        }
      }
    },
    "game": {
      "create": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to create the game?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created. You will be automatically redirected to the Game List page."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to create game."])}
      },
      "delete": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete the game?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game has been successfully deleted."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to save game."])}
      }
    },
    "controller": {
      "timer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to the Timer page"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to move to the Timer page?"])}
      },
      "apply": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Changes"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to apply changes to the Timer?"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully applied to the Timer."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to apply changes."])}
      },
      "action": {
        "type": {
          "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["START"])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAUSE"])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["END"])}
        },
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " Timer"])},
        "confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to change Timer status to ", _interpolate(_named("action")), "?"])},
        "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully changed to ", _interpolate(_named("action")), " status."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to change Timer status."])},
        "handle": {
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is in the CREATE status."])},
          "init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is in the INIT status."])},
          "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is already in PLAY status."])},
          "pause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is already in PAUSE status."])},
          "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game has already ended."])}
        },
        "level": {
          "confirm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to move Timer level to Level ", _interpolate(_named("level")), "?"])},
          "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully moved to Level ", _interpolate(_named("level")), "."])},
          "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to move level."])}
        }
      }
    },
    "common": {
      "refresh": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game List has been successfully refreshed."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to refresh game list."])}
      },
      "shortenUrl": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer Link successfully created"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail to create Timer link."])}
      }
    }
  }
}