<template>
  <div class="container">
    <v-alert
      v-model="alert"
      class="custom-alert"
      :type="alertProps.type"
      closable
      >
      {{ alertProps.msg }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "AlertComponent",
  props:{
    alertProps: {
      type: Object,
      required: true
      /*
      msg: '',
      type: 'success | info | warning | error'
      */
    }
  },
  data() {
    return {
      alert: false
    }
  },
  methods: {
    open() {
      this.alert = true;
      setTimeout(() => {
        this.close();
      }, 1500);
    },
    close() {
      this.alert = false;
    }
  }
}
</script>

<style>
.container {
  display: flex; /* 요소들을 가운데로 정렬하기 위해 flexbox를 사용 */
  justify-content: center; /* 가로 방향 가운데 정렬 */
  align-items: center; /* 세로 방향 가운데 정렬 */
  height: 100vh; /* 컨테이너의 높이를 화면 높이로 설정 */
}

.custom-alert {
  position: fixed; /* 위치 고정 */
  bottom: 0; /* 하단에 위치 */
  width: 60%; /* 가로 폭 60% */
  z-index: 9999; /* 가장 앞쪽에 위치 */
  margin-bottom: 20px;
}
</style>
