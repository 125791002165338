import axios from "@/common/axios-interceptor"
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const state = {
  token: null,
  lastActionTime: null,
  id: null,
  nickname: null,
  name: null,
  phoneNo: null,
  email: null,
  role: null,
  language: 'ko',
  boardVersion: 1,
  storeId: null,
  storedParam: null
}

const getters = {
  token(state) {
    return state.token;
  },
  lastActionTime(state) {
    return state.lastActionTime;
  },
  id(state) {
    return state.id;
  },
  nickname(state) {
    return state.nickname;
  },
  name(state) {
    return state.name;
  },
  phoneNo(state) {
    return state.phoneNo;
  },
  email(state) {
    return state.email;
  },
  role(state) {
    return state.role;
  },
  language(state) {
    return state.language;
  },
  boardVersion(state) {
    return state.boardVersion;
  },
  storeId(state) {
    return state.storeId;
  },
  storedParam(state) {
    return state.storedParam;
  }
}

const mutations = {
  login(state, payload){
    state.token = payload.headers.authorization
    state.lastActionTime = dayjs().tz().format("YYYY-MM-DD HH:mm:ss")
    state.id = payload.data.id
    state.nickname = payload.data.nickname
    state.name = payload.data.name
    state.phoneNo = payload.data.phoneNo
    state.email = payload.data.email
    state.role = payload.data.role
    state.language = payload.data.language || 'ko'
    state.boardVersion = payload.data.boardVersion || 1
    state.storeId = payload.data.storeId
  },
  logout(state){
    state.token = null
    state.lastActionTime = null
    state.id = null
    state.nickname = null
    state.name = null
    state.phoneNo = null
    state.email = null
    state.role = null
    state.language = null
    state.boardVersion = null
    state.storeId = null
  },
  lastActionTime(state){
    state.lastActionTime = dayjs().tz().format("YYYY-MM-DD HH:mm:ss");
  },
  language(state, payload){
    state.language = payload;
  },
  storeId(state, payload){
    state.storeId = payload;
  },
  storedParam(state, payload){
    state.storedParam = payload;
  }
}

const actions = {
  login({commit}, {id, password}){
    const params = {
      "id": id,
      "password": password
    }

    return new Promise((resolve, reject) => {
      axios.post("/api/login", params)
      .then(res => {
        commit('login', res)
        resolve(res)
      })
      .catch(e => {
        console.log(e)
        // alert('error 발생')
        reject(e)
      })
    })
  },
  logout({commit}){
    commit('logout')
  },

  lastActionTime({commit}){
    commit('lastActionTime')
  },

  language({commit}, {language}){
    commit('language', language)
  },

  // storeId
  storeId({commit}, {storeId}){
    commit('storeId', storeId)
  },

  storedParam({commit}, {storedParam}){
    // { 파라미터명 } 으로 사용하면 this.$store.dispatch('storedParam', { storedParam }) 이렇게 이름 맞춰줘야함
    commit('storedParam', storedParam)
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}
