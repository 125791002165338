<template>
  <div class="w-screen scroll flex-column d-flex justify-center align-center">
    <!-- Title and Logo -->
    <div class="auth-logo w-100 d-flex justify-space-between">
      <h1 class="font-weight-medium leading-normal text-2xl text-uppercase">
        HOLDIX
      </h1>

      <!-- language -->
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            color="surface"
            icon="mdi-translate"
          ></v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(language, index) in languages"
            :key="index"
            rounded="xl"
            @click="updateLanguage(language.locale)"
          >
            <template v-slot:prepend>
              <span style="font-weight:bold; text-transform:uppercase;">
                {{ language.locale }}&nbsp;
              </span>
            </template>
            <v-item-list-title>{{ language.title }}</v-item-list-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <VRow no-gutters class="auth-wrapper">
      <VCol cols="12" class="auth-card-v2 d-flex align-center justify-center">
        <VCard flat :max-width="500" class="my-5 mt-sm-0 pa-4">
          <VCardText class="text-center">
            <h5 class="text-h5 mb-1">{{ $t('login.welcome') }}👋🏻</h5>
            <p class="mb-0">{{ $t('login.notice') }}</p>
          </VCardText>
          <VCardText>
            <VRow>
              <!-- id -->
              <VCol cols="12">
                <VTextField label="ID" v-model="id" />
              </VCol>
              <!-- password -->
              <VCol cols="12">
                <VTextField label="Password" type="password" v-model="password" @keyup.enter="login" />
                <div class="d-flex align-center flex-wrap justify-space-between mt-1 mb-4">
                  <VCheckbox :label="$i18n.t('login.rememberLoginInfo')" v-model="rememberMe"/>
                </div>

                <VBtn block class="btn" type="submit" color="primary" @click.stop="login">
                  {{ $t('login.button.login') }}
                </VBtn>

                <VBtn block class="btn mt-5" type="submit" color="primary" @click.stop="moveToSignUp">
                  {{ $t('login.button.signUp') }}
                </VBtn>
              </VCol>
              <VCol cols="12" class="d-flex justify-center" v-if="env != 'prod'">
                <span class="text-btn">{{ $t('login.button.findId') }}</span>
                <span class="mx-2">/</span>
                <span class="text-btn">{{ $t('login.button.findPassword') }}</span>
              </VCol>
              <VCol cols="12" class="text-left">
                <span>{{ $t('login.contactUs') }}</span><br />
                <span>{{ $t('login.contactHours') }}</span>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <!-- 새로고침 alert -->
    <Alert ref="alert" :alert-props="alert" />

  </div>
</template>

<script>
import Alert from "@/components/AlertDefault";

export default {
  name: "LoginPage",
  components: {
    Alert
  },
  data() {
    return {
      id: null,
      password: null,
      rememberMe: false,
      alert: {
        msg: '',
        type: 'success'
      },

      env: '',

      languages: [
      {
        title: 'Korean',
        locale: 'ko',
      },
      {
        title: 'English',
        locale: 'en',
      },
      {
        title: 'Japanese',
        locale: 'ja',
      }
    ],
    }
  },
  created() {
    // 빌드환경마다 다르게 보이게 설정
    this.env = process.env.VUE_APP_ENV;

    if(this.$store.getters.token != null){
      this.$router.push({
        name: 'MainPage'
      })
    }
  },
  mounted() {
    // 로컬 스토리지에서 저장된 아이디 가져오기
    const savedUserId = localStorage.getItem('rememberedUserId');
    if (savedUserId) {
      this.id = savedUserId;
      this.rememberMe = true;
    }
  },
  methods: {
    login(){
      if(this.id && this.password){
        const id = this.id
        const password = this.password

        this.$store.dispatch('login', {id, password})
        .then((res) => {
          console.log('then')
          console.log(res)

          // 로그인이 성공하면, 선택된 경우에 아이디를 로컬 스토리지에 저장
          if (this.rememberMe) {
            localStorage.setItem('rememberedUserId', this.id);
          } else {
            localStorage.removeItem('rememberedUserId');
          }

          this.$router.push({
            name: 'MainPage'
          })
        })
        .catch((e) => {
          console.log('error')
          console.log(e)
          console.log(e.response)
          console.log(e.response.data)

          this.openAlert(e.response.data, 'error')
          this.id = null;
          this.password = null;

          this.$router.push({
            name: 'LoginPage'
          })
        })

      }else{
        this.openAlert(this.$i18n.t('message.login.enterIdPw'), 'error')
        return false
      }
    },
    logout(){
      this.$store.dispatch('logout')
    },
    openAlert(msg, type){
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },
    moveToSignUp(){
      this.$router.push({
        name: 'SignUp'
      })
    },
    // language
    updateLanguage(newLanguage){
      this.$i18n.locale = newLanguage;
      this.$vuetify.locale.current = newLanguage;
      // language selector set
      this.setLanguageSelectorTitle();
    },
    setLanguageSelectorTitle(){
      this.languages[0].title = this.$i18n.t('language.ko');
      this.languages[1].title = this.$i18n.t('language.en');
      this.languages[2].title = this.$i18n.t('language.ja');
    },
  }
}
</script>
<style>
  .text-btn :hover {
  cursor: pointer;
}
</style>
