<template>
  <VContainer fluid>
    <VCardTitle class="mb-5">
      <h5 class="text-h5">{{ $t('signUp.title') }}</h5>
    </VCardTitle>
    <VRow class="justify-center">
      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left d-flex justify-space-between align-center">
          <span class="text-20">{{ $t('signUp.id') }}</span>
          <VBtn
            class="btn"
            ripple
            rounded="lg"
            color="primary"
            width="20%"
            density="default"
            @click.stop="idAvailableCheck"
          >
          {{ $t('signUp.button.availableId') }}
          </VBtn>
        </div>
        <div class="mb-2 w-100">
          <VTextField v-model="signUpForm.id" label="ID" clearable />
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">{{ $t('signUp.password') }}</span>
        </div>
        <div class="mb-2">
          <VTextField
            v-model="signUpForm.password"
            :label="$i18n.t('signUp.password')"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-inner-icon="
              isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
          />
        </div>
      </VCol>
      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left">
          <span class="text-20">{{ $t('signUp.confirmPassword') }}</span>
        </div>
        <div class="mb-2">
          <VTextField
            v-model="signUpForm.passwordConfirm"
            :label="$i18n.t('signUp.confirmPassword')"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-inner-icon="
              isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
            "
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
          />
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left d-flex justify-space-between align-center">
          <span class="text-20">{{ $t('signUp.nickname') }}</span>
          <VBtn
            class="btn"
            ripple
            rounded="lg"
            color="primary"
            width="20%"
            density="default"
            @click.stop="nicknameAvailableCheck"
            >
            {{ $t('signUp.button.availableNickname') }}
          </VBtn>
        </div>
        <div class="mb-2 w-100">
          <VTextField v-model="signUpForm.nickname" :label="$i18n.t('signUp.nickname')" clearable />
        </div>
      </VCol>

      <!-- <VCol cols="12" md="8">
        <div class="w-100 mb-2 text-left d-flex justify-space-between align-center">
          <span class="text-20">이름</span>
        </div>
        <div class="mb-2 w-100">
          <VTextField v-model="signUpForm.name" label="이름" clearable />
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div
          class="w-100 mb-2 text-left d-flex justify-space-between align-center"
        >
          <span class="text-20">전화번호</span>
          <div>
            <VBtn
              class="ml-2 btn"
              ripple
              rounded="lg"
              color="primary"
              width="7%"
              density="default"
            >
              인증번호 발송
            </VBtn>

          </div>
        </div>
        <div class="mb-2">
          <VTextField v-model="signUpForm.phoneNo" label="전화번호" />
          <VTextField type="number" v-model="signUpForm.phoneNo" label="전화번호" />
        </div>
        <div class="mb-2 d-flex justify-end align-center">
          <div class="w-100 d-flex align-center">
            <VTextField type="number" class="w-25" v-model="signUpForm.phoneNo" label="인증번호" />

            <span class="ml-2">남은시간 05:00</span>

            <VBtn
              class="ml-2 btn"
              ripple
              rounded="lg"
              color="primary"
              width="7%"
              density="default"
            >
              인증
            </VBtn>

          </div>
        </div>
      </VCol>

      <VCol cols="12" md="8">
        <div
          class="w-100 mb-2 text-left d-flex justify-space-between align-center"
        >
          <span class="text-20">이메일</span>
          <div>
            <VBtn
              class="ml-2 btn"
              ripple
              rounded="lg"
              color="primary"
              width="7%"
              density="default"
            >
              인증번호 발송
            </VBtn>
          </div>
        </div>
        <div class="mb-2">
          <VTextField v-model="signUpForm.email" label="이메일" />
        </div>
        <div class="mb-2 d-flex justify-end align-center">
          <div class="w-100 d-flex align-center">
            <VTextField type="number" class="w-25" v-model="signUpForm.email" label="인증번호" />

            <span class="ml-2">남은시간 05:00</span>

            <VBtn
              class="ml-2 btn"
              ripple
              rounded="lg"
              color="primary"
              width="7%"
              density="default"
            >
              인증
            </VBtn>

          </div>
        </div>
      </VCol> -->

      <!-- <VCol cols="12" md="8">
        <div
          class="w-100 mb-2 text-left d-flex justify-space-between align-center"
        >
          <span class="text-20">이용약관</span>
          <div>
            <v-checkbox
              hide-details="true"
              label="동의합니다."
            />
          </div>
        </div>
        <textarea class="role bg-white w-100" disabled></textarea>
      </VCol>
      <VCol cols="12" md="8">
        <div
          class="w-100 mb-2 text-left d-flex justify-space-between align-center"
        >
          <span class="text-20">이용약관</span>
          <div>
            <v-checkbox
              hide-details="true"
              label="동의합니다."
            />
          </div>
        </div>
        <textarea class="role bg-white w-100" disabled></textarea>
      </VCol> -->


      <VCol cols="12" md="8">
        <div class="w-100 d-md-flex justify-end align-center">
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0">
            <VBtn
              class="btn"
              rounded="lg"
              :color="saveBtnColor"
              @click.stop="save"
              width="100%"
              min-width="150px"
            >
            {{ $t('signUp.button.save') }}
            </VBtn>
          </div>
          <div class="col cols-12 w-md-25 cols-md-3 mt-4 mt-md-0">
            <VBtn
              class="btn"
              rounded="lg"
              color="primary"
              link
              :to="path.login"
              width="100%"
              min-width="150px"
            >
            {{ $t('signUp.button.moveToLoginPage') }}
            </VBtn>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>

  <!-- alert -->
  <Alert ref="alert" :alert-props="alert" />

</template>

<script>
import Alert from "@/components/AlertDefault";

export default {
  name: "SignUp",
  components: {
    Alert
  },
  data: () => ({
    signUpForm: {
      id: "",
      nickname: "",
      name: "",
      password: "",
      passwordConfirm: "",
      email: "",
      phoneNo: ""
    },

    isAvailableId: {
      id: '',
      check: false,
    },
    isAvailableNickname: {
      nickname: '',
      check: false,
    },
    // saveBtnColor: 'secondary',
    saveBtnColor: 'primary',
    isPasswordVisible: false,

    alert: {
      msg: '',
      type: 'success'
    },
    path: {
      login: '/'
    },
    url: {
      signUp: "/api/user/sign-up",
      isAvailableId: "/api/user/is-available-id",
      isAvailableNickname: "/api/user/is-available-nickname",
    },
  }),
  /* watch: {
    'signUpForm.id'(){
      if(this.signUpForm.id !== this.isAvailableId.id){
        this.isAvailableId.id = '';
        this.isAvailableId.check = false;
        this.saveBtnColor = 'secondary';
      }
    }
  }, */
  methods: {
    idAvailableCheck(){
      if(this.signUpForm.id === ''){
        this.openAlert(this.$i18n.t('message.signUp.enterId'), 'warning');
        return;
      }

      this.$axios
        .post(this.url.isAvailableId, { id: this.signUpForm.id })
        .then((res) => {
          if(res.data){
            this.openAlert(this.$i18n.t('message.signUp.availableId'), 'success');
            this.isAvailableId.id = this.signUpForm.id;
            this.isAvailableId.check = res.data;
            // this.saveBtnColor = 'primary';
          }else{
            this.openAlert(this.$i18n.t('message.signUp.unavailableId'), 'error');
            this.signUpForm.id = '';
            this.isAvailableId.id = '';
            this.isAvailableId.check = res.data;
            // this.saveBtnColor = 'secondary';
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    nicknameAvailableCheck(){
      if(this.signUpForm.nickname === ''){
        this.openAlert(this.$i18n.t('message.signUp.enterNickname'), 'warning');
        return;
      }

      this.$axios
        .post(this.url.isAvailableNickname, { nickname: this.signUpForm.nickname })
        .then((res) => {
          if(res.data){
            this.openAlert(this.$i18n.t('message.signUp.availableNickname'), 'success');
            this.isAvailableNickname.nickname = this.signUpForm.nickname;
            this.isAvailableNickname.check = res.data;
            // this.saveBtnColor = 'primary';
          }else{
            this.openAlert(this.$i18n.t('message.signUp.unavailableNickname'), 'error');
            this.signUpForm.nickname = '';
            this.isAvailableNickname.nickname = '';
            this.isAvailableNickname.check = res.data;
            // this.saveBtnColor = 'secondary';
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    save() {
      if(!this.saveValidation()){
        return;
      }

      this.$axios
        .post(this.url.signUp, {
          id: this.signUpForm.id,
          password: this.signUpForm.password,
          nickname: this.signUpForm.nickname,
          name: this.signUpForm.name,
          email: this.signUpForm.email,
          phoneNo: this.signUpForm.phoneNo
        })
        .then((res) => {
          console.log(res.data);
          if(res.data){
            this.openAlert(this.$i18n.t('message.signUp.success'), 'success');
            // TODO 로그인 화면 이동
            setTimeout(() => {
              this.$router.push({
                name: 'LoginPage'
              });
            }, 2000);
          }else{
            this.openAlert(this.$i18n.t('message.signUp.fail'), 'error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveValidation(){
      if(!this.isAvailableId.check){
        this.openAlert(this.$i18n.t('message.signUp.checkDupId'), 'warning');
        return false;
      }else if(this.signUpForm.password === ''){
        this.openAlert(this.$i18n.t('message.signUp.enterPassword'), 'warning');
        return false;
      }else if(this.signUpForm.passwordConfirm === ''){
        this.openAlert(this.$i18n.t('message.signUp.enterConfirmPassword'), 'warning');
        return false;
      }else if(this.signUpForm.password !== this.signUpForm.passwordConfirm){
        this.openAlert(this.$i18n.t('message.signUp.checkPassword'), 'warning');
        return false;
      }else if(!this.isAvailableNickname.check){
        this.openAlert(this.$i18n.t('message.signUp.checkDupNickname'), 'warning');
        return false;
      /* }else if(this.signUpForm.name === ''){
        this.openAlert('이름을 입력해주세요.', 'warning');
        return false;
      }else if(this.signUpForm.phoneNo === ''){
        this.openAlert('전화번호를 입력해주세요.', 'warning');
        return false;
      }else if(this.signUpForm.email === ''){
        this.openAlert('이메일을 입력해주세요.', 'warning');
        return false; */
      }else{
        return true;
      }
    },

    /* Alert */
    openAlert(msg, type){
      this.alert.msg = msg;
      this.alert.type = type;
      this.$refs.alert.open();
    },
  },
};
</script>
<style scoped>
.text-20 {
  font-size: 20px;
}
.role{
  min-height : 100px;
}
</style>
